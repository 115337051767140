<template>
  <v-app :class="appClasses">
    <div v-if="isAppLoading" class="app-loading">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :model-value="appLoadingValue"
        color="newPrimaryRegular"
        class="text-14"
      >
        {{ appLoadingValue }}%
      </v-progress-circular>
    </div>

    <template v-else>
      <template v-if="displayNav">
        <Navbar />

        <LeftSidebarWrapper />
      </template>

      <v-main>
        <router-view />
      </v-main>
    </template>
  </v-app>
</template>

<script lang="ts">
import {storeToRefs} from "pinia";
import {defineComponent, inject, ref, unref, watch} from "vue";
import {useStorage} from "@vueuse/core";
import moment from "moment";
import Navbar from "@/components/Navigation/Navbar.vue";
import LeftSidebarWrapper from "@/components/Navigation/LeftSidebarWrapper.vue";
import {onSnapshot} from "firebase/firestore";
import _ from "lodash";
import {VERSION} from "@/config/version";
import loggerHelper from "./tscript/loggerHelper";
import {environment} from "./firebase/init";
import {VueClassObject, User, Segment} from "@/interfaces";
import {levelCorresp} from "@oplit/shared-module";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {buildLocalStorageUtils} from "@/tscript/utils/localStorageUtils";

import {useMainStore} from "@/stores/mainStore";
import {useTheme} from "vuetify";
import {useI18n} from "vue-i18n";
import {useRouteAssertions} from "./composables/useRouteAssertions";

export default defineComponent({
  components: {LeftSidebarWrapper, Navbar},
  name: "App",
  setup() {
    const segment = inject<Segment>("segment");

    const isUpdateAvailable = ref(false);

    const {buildStorageKey} = buildLocalStorageUtils("oplit");
    const localStorageLightMode = useStorage<User["light_mode"]>(
      buildStorageKey("light_mode"),
      true,
    );

    const theme = useTheme();
    const {locale: i18nLocale} = useI18n();

    const mainStore = useMainStore();
    const {
      perimeters,
      userData,
      userId,
      apiClient,
      isDevEnv,
      isAppLoading,
      appLoadingValue,
      version,
      teams,
      variables,
      hasStock,
    } = storeToRefs(mainStore);
    const {loadVersion} = mainStore;
    const {isParametersRoute} = useRouteAssertions();

    watch(
      () => userData.value,
      (newUser, oldUser) => {
        if (!newUser) return;
        const {light_mode: lightMode, locale: userLocale, id} = newUser;
        const crisp = (window as any)?.$crisp;
        try {
          if (
            id !== oldUser?.id &&
            crisp &&
            (crisp.get("user:nickname") || "").startsWith("visitor")
          ) {
            const {first_name, last_name, client_name} = newUser || {};
            const envName = environment !== "prod" ? ` - ${environment}` : "";
            const fullName = `${first_name} ${last_name} (${client_name})${envName}`;
            updateCrispNickname(fullName);
          }
        } catch (e) {
          loggerHelper.log(e);
        }

        const jimo = (window as any)?.jimo;
        try {
          jimo.push(["do", "identify", [newUser.id]]);
          jimo.push(["set", "user:name", [newUser.email]]);
          (window as any).jimoInit();
        } catch (e) {
          loggerHelper.log(e);
        }

        if (
          lightMode !== oldUser?.light_mode ||
          lightMode !== unref(localStorageLightMode)
        ) {
          theme.global.name.value = "light";
          localStorageLightMode.value = lightMode;
        }

        if (userLocale && userLocale !== i18nLocale.value) {
          i18nLocale.value = userLocale;
          moment.updateLocale(userLocale, {
            week: {
              dow: 1,
            },
          });
        }

        //segment analytics
        if (segment && id && id !== oldUser?.id) {
          const team = getTeam(newUser);

          segment.value.identify(newUser.id, {
            id: newUser.id,
            username: newUser.name,
            name: newUser.name,
            email: newUser.email,
            role: newUser.role,
            job: newUser.job,
            company: {
              id: newUser.client_id,
              name: newUser.client_name,
            },
            locale: newUser.locale,
            has_production_planning: !!newUser.has_production_planning,
            has_scheduling: !!newUser.has_scheduling,
            environment,
            team_id: team?.id,
            team_name: team?.name,
            coalesced_team_id: team?.id ?? newUser.client_id,
            coalesced_team_name: team?.name ?? newUser.client_name,
          });
          segment.value.group(id, {
            type: "client",
            id: newUser.client_id,
            name: newUser.client_name,
          });
          segment.value.group(newUser.role, {
            type: "role",
            id: newUser.role,
          });
          if (team?.id) {
            segment.value.group(team.id, {
              type: "team",
              id: team.id,
              name: team.name,
              secteur_id: team.secteur_id,
              company: {
                id: team.client_id,
              },
            });
          }
        }

        if (id !== oldUser?.id)
          segment.value.track("[General] User Logged In", {user: newUser});
      },
      {immediate: true},
    );

    function updateCrispNickname(nickname: string) {
      (window as any).$crisp.push(["set", "user:nickname", [nickname]]);
    }
    function getTeam(userData: User) {
      if (!teams.value?.length || !perimeters.value?.sites?.length) return;
      const defaultSector = userData.default_sector;
      if (!defaultSector?.id) return;
      const {id: secteur_id, collection} = defaultSector;
      const sectorMatch = (perimeters[collection] || []).find(
        (x: any) => x.id === secteur_id,
      );
      if (!sectorMatch) return;
      let team: any;
      levelCorresp.forEach((level) => {
        const match = teams.value.find(
          (t: any) =>
            (sectorMatch[level.type + "_id"] === t.secteur_id ||
              sectorMatch.id === t.secteur_id) &&
            t.level / 1 === level.level / 1,
        );
        if (match) team = {...match};
      });
      return team;
    }

    return {
      isUpdateAvailable,
      localStorageLightMode,
      perimeters,
      userData,
      userId,
      apiClient,
      isDevEnv,
      isAppLoading,
      appLoadingValue,
      version,
      teams,
      variables,
      hasStock,
      loadVersion,
      theme,
      isParametersRoute,
    };
  },
  computed: {
    appClasses(): VueClassObject {
      return {
        login: this.$route.name === "login",
        analytics: this.$route.name === "dashboard",
        parameters: this.isParametersRoute,
      };
    },

    displayNav() {
      // Hide bar when logout
      return !!this.userId;
    },
  },
  watch: {
    version: {
      handler(val: any) {
        if (val && VERSION < this.version && !this.isDevEnv) {
          this.isUpdateAvailable = true;
          this.$openSnackbar({
            timeout: -1,
            message: this.$t("App.new_version"),
            action: this.hotReload,
            actionLabel: this.$t("App.reload"),
            lowPriority: true,
            location: "top",
            value: !this.isAppLoading,
          });
        }
      },
    },
  },
  created() {
    this.apiClient.warmup();
    setInterval(() => this.apiClient.warmup, 5 * 60000);
    document.title = "Oplit - v" + VERSION;

    if (this.localStorageLightMode) this.$vuetify.theme.dark = false;

    // Set language to navigator language
    if (navigator.language && navigator.language.length >= 2) {
      const localeIso = navigator.language.substring(0, 2);
      this.$i18n.locale = localeIso;
      moment.updateLocale(localeIso, {
        week: {
          dow: 1,
        },
      });
    }

    //Handle version
    this.loadVersion();
    this.loadGeneralNotification();
    this.monitorConnection();
  },
  methods: {
    loadGeneralNotification: _.debounce(function (this: any) {
      const q = dbHelper.createRef("public", {
        where: [{field: "id", value: "general_notification", compare: "=="}],
      });
      onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const doc = change.doc;
          const data = doc.data();
          if ([data.status, change.type].includes("removed")) return;

          if (
            ["added", "modified"].includes(change.type) &&
            !this.isUpdateAvailable
          ) {
            this.$openSnackbar({
              timeout: -1,
              message: data?.message,
              action: this.hotReload,
              actionLabel: this.$t("App.reload"),
              lowPriority: true,
              location: "top",
              // value: !this.isAppLoading,
            });
          }
        });
      });
    }, 1000),
    hotReload() {
      const location: string = window.location.toString();
      // if (location.includes("cache=")) return
      const d = new Date().getTime();
      if (location.includes("?"))
        window.location.href = window.location + "&cache=" + d?.toString();
      else window.location.href = window.location + "?cache=" + d?.toString();
    },
    monitorConnection() {
      const nav: any = navigator;
      const connection: any =
        nav.connection || nav.mozConnection || nav.webkitConnection;
      let isDisplayingSnackbar = false;

      const slowConnection = ["slow-2g", "2g"];
      // const fastConnection = ["3g", "4g"];
      try {
        const connectionCheckFn = () => {
          const effectiveType = connection?.effectiveType;
          const isSlowConnection = slowConnection.includes(effectiveType);
          if (!isSlowConnection && !isDisplayingSnackbar) return;

          const snackbarProps: Record<string, unknown> = {
            lowPriority: true,
            top: true,
            loading: !isDisplayingSnackbar,
            message: this.$t("App.slow_connection"),
          };
          isDisplayingSnackbar = !isDisplayingSnackbar;
          this.$nextTick(() => {
            this.$openSnackbar(snackbarProps);
          });
        };
        connectionCheckFn();
        connection.addEventListener("change", connectionCheckFn);
      } catch (e) {
        loggerHelper.log(e);
      }
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/app.scss";
@import "@/scss/overrides.scss";
@import "@/scss/colors.scss";
@import "@/scss/constants.scss";
@import "@/scss/effects.scss";
@import "@/scss/colors_categories.scss";
@import "@/scss/tooltip.scss";

// new 7_1 architecture
@import "@/scss/main";

html {
  overflow: hidden !important;
  scrollbar-color: rgb(var(--v-theme-newSelected)) transparent;
  scrollbar-width: thin;
  -webkit-print-color-adjust: exact; // required for proper bg color while printing
  &::-webkit-scrollbar {
    background: transparent;
    width: 8px;
    &-track {
      background: transparent;
    }
    &-button {
      background: transparent;
    }
    &-thumb {
      background: rgb(var(--v-theme-newSelected));
      border-radius: 4px;
      &:hover {
        background: rgb(var(--v-theme-newHover));
      }
    }
  }
}
div.v-application,
div.v-overlay-container {
  background-color: rgb(var(--v-theme-newLayerBackground)) !important;

  & pre {
    font-family: inherit;
  }

  & .app-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  & .v-application__wrap {
    max-height: 100vh;
  }

  & .hide-input-number-arrows {
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button,
    & input[type="number"] {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }
  }

  .w-auto {
    width: auto;
  }
  .w-fit {
    width: fit-content;
  }
  .w-100 {
    width: 100%;
  }
  .w-75 {
    width: 75%;
  }
  .w-50 {
    width: 50%;
  }
  .w-25 {
    width: 25%;
  }
  .h-100 {
    height: 100%;
  }
  .h-90 {
    height: 90%;
  }
  .h-75 {
    height: 75%;
  }
  .h-50 {
    height: 50%;
  }
  .h-25 {
    height: 25%;
  }
  .apexcharts-text tspan {
    font-family: "Inter", sans-serif;
  }
  .no-uppercase {
    text-transform: none;
  }
  .vue-feather--message-circle.has-messages::after {
    content: "";
    position: absolute;
    right: 5px;
    bottom: 0;
    height: 6px;
    width: 6px;
    background: rgb(var(--v-theme-newPrimaryRegular));
    border-radius: 50%;
  }

  &.analytics .v-main .v-main__wrap > .container {
    height: 100%;
    display: flex;
  }

  &:not(.login) .v-main .v-main__wrap {
    min-height: 0;
    overflow: auto;
  }

  & .d-none {
    &.d-flex {
      display: none !important;
    }
  }

  .v-main {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
    overflow: auto;

    & > div {
      width: 100%;
    }

    & .body-content {
      padding-bottom: var(--g-vertical-spacing);
    }

    & a {
      color: inherit;
      text-decoration: none;
    }
  }
  .rounded-img {
    border-radius: 50%;
  }
  .no-decoration {
    text-decoration: none;
  }
  .v-icon.close-modal-icon,
  .close-modal-feather {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    &:hover {
      color: rgb(var(--v-theme-newMainText));
    }
    &.close-outside {
      right: -28px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .position-relative {
    position: relative;
  }
  .small-badge {
    padding: 0 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .v-btn {
    &.gapped-button .v-btn__content {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &.fbutton {
      text-transform: none;
      box-sizing: border-box;
      border-radius: 8px;

      &:hover,
      &:focus,
      &:active {
        color: rgb(var(--v-theme-newMainText));
      }
      &:disabled {
        color: rgb(var(--v-theme-newDisableText));
      }

      &.outlined-btn {
        border: 1px solid rgb(var(--v-theme-newSelected));
        &.blue-border {
          border: 1px solid rgb(var(--v-theme-newPrimaryRegular));
        }
        &.white-border {
          border: 1px solid rgb(var(--v-theme-blanc));
        }
      }
      &.btn1 {
        //filled buttons
        background: rgb(var(--v-theme-newPrimaryRegular)) !important;
        color: rgb(var(--v-theme-blanc));
      }
      &.btn-dark {
        background: rgb(
          var(--v-theme-newHover)
        ) !important; //should not exist anymore
      }
      &.no-border {
        border: none;
        border-radius: 8px;
        background: transparent !important;
        box-shadow: none;
        color: rgb(var(--v-theme-newSubText));

        svg {
          stroke: rgb(var(--v-theme-newSubText));
        }

        &:hover,
        &:focus,
        &:active {
          color: rgb(var(--v-theme-newMainText));

          svg {
            stroke: rgb(var(--v-theme-newMainText));
          }
        }
        &:disabled {
          color: rgb(var(--v-theme-newDisableText));

          svg {
            stroke: rgb(var(--v-theme-newDisableText));
          }
        }

        &:hover,
        &:focus {
          background: rgb(var(--v-theme-newSubBackground));
        }
        &:focus {
          border: 1px solid rgb(var(--v-theme-newPrimaryRegular));
        }
        &:active {
          background: rgb(var(--v-theme-newHover));
        }
      }
    }
  }
  .filter-n1 {
    filter: drop-shadow(0px 4px 4px rgba(24, 32, 57, 0.15));
  }
  .underlined {
    text-decoration-line: underline !important;
  }
  .cursor-pointer,
  [data-cy*="close"] {
    cursor: pointer;
  }
  .cursor-help {
    cursor: help;
  }
  .cursor-not-allowed {
    cursor: not-allowed;
  }
  .small {
    &-x {
      width: 24px;
    }

    &-y {
      height: 24px;
    }
  }
  .fs {
    height: 14px;
  }
  .width-100 {
    width: 100%;
  }
  .max-width-100 {
    max-width: 100%;
  }
  .max-width-75 {
    max-width: 75%;
  }
  .no-border-y {
    border-top: none !important;
    border-bottom: none !important;
  }
  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-nowrap {
    text-wrap: nowrap;
  }
  .semi-bold {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    font-style: normal;
    &.bold {
      font-weight: 700;
    }
    &.semi-bold {
      font-weight: 600;
    }
  }
  h1 {
    @extend .display-32, .semi-bold;
  }
  h2 {
    @extend .display-24, .semi-bold;
  }
  h3 {
    @extend .display-24;
  }
  h4 {
    @extend .display-18;
  }
  .fbody-1 {
    @extend .text-16;
    &.force-body {
      @extend .text-14;
    }
    &.active {
      @extend .semi-bold;
    }
  }
  .fbody-2 {
    @extend .text-14;
    &.force-body {
      @extend .text-14;
    }
    &.active {
      @extend .semi-bold;
    }
  }
  .fcaption {
    @extend .text-12;
  }
  .fcaption-mini {
    font-size: 8px;
  }
  .br-4 {
    border-radius: 4px;
  }
  .br-8 {
    border-radius: 8px;
  }
  .br-12 {
    border-radius: 12px;
  }
  .btrr-0 {
    border-top-right-radius: 0;
  }
  .btlr-0 {
    border-top-left-radius: 0;
  }
  .bbrr-0 {
    border-bottom-right-radius: 0;
  }
  .bblr-0 {
    border-bottom-left-radius: 0;
  }
  .overflow-scroll {
    overflow: scroll;
  }
  .overflow-auto {
    overflow: auto;
  }
  :not(.keep-scrollbar):not(.child-keep-scrollbar > :first-child):not(
      .v-table__wrapper
    ) {
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0px; /* remove scrollbar space */
      height: 0px;
      background: transparent; /* optional: just make scrollbar invisible */
    }
  }
  .keep-scrollbar,
  .v-table__wrapper,
  .child-keep-scrollbar > *:first-child {
    scrollbar-color: rgb(var(--v-theme-newSelected)) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      background: transparent;
      width: 8px;
      height: 8px;
      &-track {
        background: transparent;
      }
      &-button {
        background: transparent;
      }
      &-thumb {
        background: rgb(var(--v-theme-newSelected));
        border-radius: 4px;
        &:hover {
          background: rgb(var(--v-theme-newHover));
        }
      }
    }
  }
  .white-space-initial {
    white-space: initial;
  }
  .chip-height {
    height: 18px;
    padding: 0 8px;
  }
  .v-tab {
    letter-spacing: normal;
  }
  .v-chip {
    color: rgb(var(--v-theme-newMainText));
    box-shadow: none;

    &:not(.transparent) {
      color: rgb(var(--v-theme-blanc));
    }
  }
  .width-100 {
    width: 100%;
  }
  .figma-page {
    padding: 50px;
    width: 100%;
  }

  .tooltip-wrapper {
    min-width: 275px;

    & .icons-list {
      list-style: none; /* Remove default bullets */
      padding: 0;
      & .custom-chip {
        @extend .text-12;
        border-radius: 50%;
        height: 10px;
        max-width: 10px;
        padding: 5px;
        width: 10px;
      }
    }
  }

  .v-list-item {
    &--active {
      color: rgb(var(--v-theme-blanc)) !important;
      background-color: rgb(var(--v-theme-newPrimaryRegular));

      & svg {
        stroke: rgb(var(--v-theme-blanc));
      }
    }
  }

  .v-list-item__content {
    display: flex;
  }

  .red-border {
    border: 1px solid rgb(var(--v-theme-newPinkRegular));
  }
  .full-height {
    height: 100%;
  }
  .full-width {
    width: 100%;
  }
  .theme--dark.v-card > .v-card-text,
  .theme--dark.v-card .v-card__subtitle {
    color: rgb(var(--v-theme-newMainText));
  }
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--selected),
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--selected) > .v-icon,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--selected) > .v-btn,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
    color: rgb(var(--v-theme-newMainText));
  }
  .icon-wrapper {
    .icon-placeholder {
      background: rgb(var(--v-theme-newPrimaryLight2));
      color: rgb(var(--v-theme-newPrimaryDark1));
      border-radius: 50%;
      padding-right: 8px;
      padding-left: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
  .fd-flex-center {
    display: flex;
    align-items: center;
  }
  .row {
    margin-top: 0;
    margin-bottom: 0;
  }
  .min-width-24 {
    min-width: 24px;
  }
  .min-width-20 {
    min-width: 20px;
  }
  .strike-on-hover:hover {
    svg {
      stroke: rgb(var(--v-theme-newMainText));
    }
  }
  .v-skeleton-loader {
    align-items: initial;
    display: initial;
  }
}
.flex-1 {
  flex: 1;
}
.import-form-dropzone {
  font-family: "Inter", sans-serif;
  background: rgb(var(--v-theme-newAppBackground));
  border: 2px dashed rgb(var(--v-theme-newSelected)) !important;
}
.fdialog {
  &.v-dialog {
    background: rgb(var(--v-theme-newAppBackground));
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: scroll;
    .modal-title,
    .modal-body {
      border-bottom: 1px solid rgb(var(--v-theme-newHover));
    }
    .side-comments {
      display: flex;
      flex-direction: column;
      border-left: 1px solid rgb(var(--v-theme-newHover));
    }
    .comments-body {
      flex: 1;
      overflow: scroll;
    }
  }
}
.modal-title {
  color: rgb(var(--v-theme-newMainText));
}
.new-feature {
  position: relative;
  &::after {
    content: "✨";
    position: absolute;
    top: 0;
    right: 0;
    @extend .text-14;
  }
}
.apexcharts {
  &-legend-text {
    color: rgb(var(--v-theme-newMainText)) !important;
  }

  &-tooltip {
    box-shadow: none;
  }
}
@for $i from 1 through 40 {
  .fs-#{$i} {
    font-size: 1px * $i;
  }

  .h-#{$i * 20px} {
    $height: 20px * $i;

    height: $height;

    &-important {
      height: $height !important;
    }
  }

  .w-#{$i * 25px} {
    width: 25px * $i;
  }

  .max-w-#{$i * 25px} {
    max-width: 25px * $i !important;
  }

  .-ml-#{$i} {
    margin-left: #{$i * -4px};
  }
}
/**
* the loop is going to 10, but above 7 digits (9.999.999 operations within a group)
* the text goes beyond the box boundaries
* (OperationsCardsGroup)
*/
@for $i from 1 to 10 {
  .str-len-#{$i} {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      height: #{min($maxChipHeight, max($minChipRadius, $i + 1))}ch;
      width: #{max($minChipRadius, $i + 1)}ch;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
    }
  }
}

.skeleton-list-item {
  &.theme--dark.v-skeleton-loader {
    background-color: transparent;
    .v-skeleton-loader {
      &__list-item {
        background-color: transparent;
        padding: 0;
        height: 20px;
      }
    }
  }
}

#crisp-chatbox {
  z-index: 9;

  // & a {
  //   -webkit-transform: scale($crispLogoScaleValue);
  //   -ms-transform: scale($crispLogoScaleValue);
  //   transform: scale($crispLogoScaleValue);
  //   left: auto !important;
  //   right: 32px !important;
  //   bottom: 48px !important;
  //   top: auto !important;
  // }
}

.print-only {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.underlined {
  text-decoration-line: underline !important;
}

.break-word {
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.leading-none {
  line-height: 1;
}

.min-width-0 {
  min-width: 0 !important;
}

@media print {
  @page {
    size: landscape;
    margin: 0 !important;
  }

  .print-only {
    display: block !important;
  }

  .print-hide {
    display: none !important;
  }
}

.white-space-normal {
  white-space: normal;
}

.mandatory::after {
  content: "*";
}

$positions: start, center, end;
@each $position in $positions {
  .items-#{$position} {
    align-items: #{$position};
  }
  .justify-#{$position} {
    justify-content: #{$position};
  }
}

@for $i from 0 through 24 {
  .gap-#{$i} {
    gap: #{2px * $i} !important;
  }
}

.v-btn.style--outlined {
  background-color: white !important;
  border: 1px solid $newSubText;
  border-radius: 8px;
  box-shadow: none;
  padding: 8px 12px !important;
  color: $newSelected;

  & i {
    margin-right: 0 !important;

    &::before {
      font-size: larger;
    }
  }

  & .v-btn__content {
    letter-spacing: initial;
    text-transform: initial;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}

.bounce-enter-active {
  animation: bounce 0.5s;
}
.bounce-leave-active {
  animation: bounce 0.5s reverse;
}
@keyframes bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.bounce-in-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-in-leave-active {
  animation: bounce-in 0.25s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes persistent {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}

.persistent-enter-active {
  animation: persistent 0.15s;
}
.scale-enter-active {
  animation: scale 0.25s;
}
.scale-leave-active {
  animation: scale 0.25s reverse;
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.opened i.vue-feather--chevron-right {
  animation: rotate-square 0.25s linear forwards;
}
@keyframes rotate-square {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(90deg);
  }
}

.slide-down-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-down-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-down-enter-to,
.slide-down-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.05s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.vue-feather {
  width: -moz-max-content;
}
</style>
