import {SIMULATION_STATUS} from "@/config/constants";
import type {Simulation} from "@/interfaces";

const isArchivedSimulation = (simulation: Simulation) =>
  simulation?.status === SIMULATION_STATUS.ARCHIVED;

const isArchivedSimulationStrict = (simulation: Simulation) =>
  isArchivedSimulation(simulation) && simulation.archived_at;

export {isArchivedSimulation, isArchivedSimulationStrict};
