<script setup lang="ts">
import {computed, nextTick, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import SubOFTableRow from "@/components/Scheduling/SubOF/SubOFTableRow.vue";
import FCheck from "@/components/Global/Homemade/Controls/FCheck.vue";
import {SchedulingOperation} from "@/interfaces";

interface SubOFTableProps {
  operations: SchedulingOperation[];
  selectedOperations: SchedulingOperation["op_id"][];
  isUpdate: boolean;
}

const props = defineProps<SubOFTableProps>();
const emit = defineEmits<{
  (e: "remove-operation", operation: SchedulingOperation);
  (
    e: "update-operations",
    payload: {
      field: string;
      value: string;
      op_id: SchedulingOperation["op_id"];
    },
  );
  (
    e: "update-selected-operations",
    operations: SubOFTableProps["selectedOperations"],
  );
  (
    e: "update-operation-order",
    payload: {
      operation: SchedulingOperation;
      new_order: number;
    },
  );
}>();

const {t} = useI18n();

const subOfTableContent = ref(null);

const headers = computed(() => [
  {
    label: "",
    field: "select",
  },
  {label: "", field: "index"},
  {label: t("SubOFTable.header_op_name"), field: "op_name"},
  {label: t("SubOFTable.header_secteur_id"), field: "secteur_id"},
  {
    label: t("SubOFTable.header_quantite"),
    field: "quantite",
    icon: "clock",
  },
  {label: t("SubOFTable.header_day_date"), field: "day_date", icon: "calendar"},
  {
    label: "",
    field: "actions",
  },
]);
const areAllOperationsSelected = computed(
  () => props.operations.length === props.selectedOperations.length,
);

watch(
  () => props.operations.length,
  async () => {
    if (!subOfTableContent.value) return;

    await nextTick();

    // scrolls to bottom of operations list on adding a new operation
    const subOfTableContentWrapper = subOfTableContent.value.parentNode;
    subOfTableContentWrapper.scrollTo({
      top: subOfTableContent.value.offsetHeight,
      behavior: "smooth",
    });
  },
);

function selectOperation({op_id}: SchedulingOperation) {
  const operationIndex = props.selectedOperations.findIndex(
    (opID) => opID === op_id,
  );
  const newSelectedOperations =
    operationIndex > -1
      ? props.selectedOperations.filter((_, i) => i !== operationIndex)
      : [...props.selectedOperations, op_id];
  emit("update-selected-operations", newSelectedOperations);
}
function toggleSelectOnAllOperations() {
  const newSelectedOperations = areAllOperationsSelected.value
    ? []
    : Array.from(props.operations, ({op_id}) => op_id);
  emit("update-selected-operations", newSelectedOperations);
}
function updateOperation(
  operation: SchedulingOperation,
  {
    field,
    value,
  }: {
    field: string;
    value: string;
  },
) {
  const {op_id} = operation;

  emit("update-operations", {
    op_id,
    field,
    value,
  });
}
function updateOperationOrder(
  operation: SchedulingOperation,
  new_order: number,
) {
  emit("update-operation-order", {operation, new_order});
}
</script>

<template>
  <div class="sub-of-table">
    <div class="sub-of-table__header">
      <div
        v-for="{field, label, icon} in headers"
        :key="field"
        :class="[
          `sub-of-table__header-${field}`,
          {'flex-1': !['select', 'actions'].includes(field)},
          {'pa-0': field === 'select'},
        ]"
      >
        <FCheck
          v-if="field === 'select'"
          :model-value="areAllOperationsSelected"
          checked-icon="minus-square"
          color="newSelected"
          @click="toggleSelectOnAllOperations"
        />

        <template v-else>
          <vue-feather v-if="icon" :type="icon" size="16" />
          {{ label }}
        </template>
      </div>
    </div>

    <div class="sub-of-table__content-wrapper keep-scrollbar padded-scrollbar">
      <div ref="subOfTableContent" class="sub-of-table__content">
        <SubOFTableRow
          v-for="operation in operations"
          :key="operation.op_id"
          :operation="operation"
          :headers="headers"
          :selected-operations="selectedOperations"
          :is-update="isUpdate"
          @remove-operation="() => emit('remove-operation', operation)"
          @select-operation="() => selectOperation(operation)"
          @update-operation-order="
            (new_order) => updateOperationOrder(operation, new_order)
          "
          @update-operation="
            ({field, value}) =>
              updateOperation(operation, {
                field,
                value,
              })
          "
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/scss/mixins/sub-of-table.scss";

@include sub-of-table(".sub-of-table__header");

.sub-of-table__header {
  padding-right: var(--g-scrollbar-area);
  width: calc(100% + var(--g-scrollbar-area));

  & [class*="sub-of-table__header-"] {
    font-weight: bold;
    color: rgb(var(--v-theme-newSubText));
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.sub-of-table__content-wrapper {
  max-height: 200px;
  overflow-y: scroll;
  width: calc(100% + var(--g-scrollbar-area));
  padding-right: calc(var(--g-scrollbar-area) - 11px);

  & > .sub-of-table__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
