import {defineStore, storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

export const useAPIStore = defineStore("api", () => {
  const mainStore = useMainStore();

  async function getDailyDelay(params: {
    defined_periods?: any[];
    endDate?: string;
    import_id?: string;
    load_auto_orga?: boolean;
    maille?: string;
    // Real Capacity Value may also be forced
    real_capacity?: boolean | number;
    sector_tree?: any;
    sector?: any;
    simulation_id?: string;
    startDate?: string;
    today_date?: string;
  }): Promise<{
    pg_retard?: number[];
    raw_delay: {day_date: string; delay: string}[];
  }> {
    // Params from store
    const {clientParameters, apiClient, hasStock} = storeToRefs(mainStore);
    const keep_advance = clientParameters.value?.keep_advance;
    const [, results] = await apiClient.value.getDailyDelay({
      ...params,
      has_stock: hasStock.value,
      keep_advance,
    });
    return results;
  }

  async function chargeFn(payload: {
    secteur_id: string;
    simulation_id: string;
    startDate: string;
    endDate: string;
    params: {
      detailed?: boolean;
      // detailed_light will be prioritized over detailed
      detailed_light?: boolean;
      is_scheduling?: boolean;
      no_done_status?: boolean;
      secteur_type?: string;
      has_stock?: boolean;
      should_aggregate_lower_levels?: boolean;
    };
  }): Promise<unknown[]> {
    const {apiClient, hasStock} = storeToRefs(mainStore);
    const {
      secteur_id,
      simulation_id,
      startDate,
      endDate,
      params = {},
    } = payload;

    const results = await apiClient.value.chargeFn(
      secteur_id,
      simulation_id,
      startDate,
      endDate,
      {
        has_stock: hasStock.value ?? false,
        detailed: params.detailed ?? false,
        detailed_light: params.detailed_light ?? false,
        is_scheduling: params.is_scheduling ?? false,
        no_done_status: params.no_done_status ?? false,
        secteur_type: params.secteur_type ?? "",
        should_aggregate_lower_levels:
          params.should_aggregate_lower_levels ?? false,
      },
    );

    return results || [];
  }

  async function getLoadsGroupedBy(payload: {
    simulation_id: string;
    secteur_id: string;
    secteur_type?: string;
    startDate: string;
    endDate: string;
    categories: string[];
  }): Promise<unknown[]> {
    const {
      secteur_id,
      secteur_type,
      simulation_id,
      startDate,
      endDate,
      categories,
    } = payload;
    const has_stock = mainStore.hasStock;

    const results = await mainStore.apiClient.getLoadsGroupedBy({
      simulation_id,
      secteur_id,
      secteur_type,
      startDate,
      endDate,
      has_stock,
      categories,
    });

    return results || [];
  }

  return {
    chargeFn,
    getDailyDelay,
    getLoadsGroupedBy,
  };
});
