import _ from "lodash";
import moment from "moment";
import {Action, Event} from "@/interfaces";
import loggerHelper from "@/tscript/loggerHelper";
import {EVENT_STATUS} from "@/config/constants";
import {serverTimestamp} from "firebase/firestore";
import CapacitiesAPIClient from "@/api/capacities";
import DbHelper from "@/tscript/dbHelper";
import db from "@/firebase/db";
import {asyncForEach} from "@oplit/shared-module";

const dbHelper = new DbHelper(db);
const apiClient = new CapacitiesAPIClient();

type EventSaverParams = {
  use_event_sse?: boolean;
  disable_pg_refresh?: boolean;
  shouldErasePreviousMacroEvent?: boolean;
  stopAfterEventSave?: boolean;
  sseCallback?: (event: Event) => Promise<unknown>;
  storeCommitCallback?: () => void;
  doNotDeletePreviousChildren?: boolean;
  fullEvent?: any; //Event;
};

const saveEventInFirestore = async (
  event: Event,
  params: EventSaverParams = {},
) => {
  if (!event?.id) return {e: "No children to save"};

  const {
    use_event_sse = false,
    disable_pg_refresh = false,
    shouldErasePreviousMacroEvent = false,
    stopAfterEventSave = false,
    sseCallback = () => Promise.resolve(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    storeCommitCallback = () => {},
    doNotDeletePreviousChildren = false,
  } = params;
  const fullEvent = params.fullEvent || {...event};

  //first delete previous macro events if relevant:
  if (shouldErasePreviousMacroEvent && !use_event_sse) {
    if (disable_pg_refresh) storeCommitCallback();
    const isSuccess = await sendToBackend(fullEvent, "removed");
    if (!isSuccess) return {e: "Error while erasing previous macro event"};
  }

  if (use_event_sse) {
    //We'll parse this event directly in app-engine
    event.pg_parsed = moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS");
  } else event.pg_parsed = null;

  //separate the event from its children
  const children = (event.children || []).map((child: any) => {
    const id =
      child.id || dbHelper.getCollectionId("events", [event.id, "children"]);
    const client_id = child.client_id || event.client_id;
    return {...child, id, client_id};
  });
  /**
   * EVSV
   * FIXME: this alters the original `event` object that we may want to use afterwards :  is it a desired behaviour ?
   */
  if (children.length) event.children = _.map(children, "id");

  //save event
  const error: any = await dbHelper.setDataToCollection(
    "events",
    event.id,
    event,
    true,
  );

  if (error?.e) return error;

  //we need to delete the children subcollection first to make sure that we don't update children with removed children
  if (children.length && !doNotDeletePreviousChildren) {
    await dbHelper
      .deleteCollection("events", 500, {
        childrenPath: [event.id, "children"],
      })
      .catch(loggerHelper.log);
  }

  //save children
  let batch = dbHelper.createBatch(),
    batchIdx = 0,
    batchError = null,
    batchByteSize = 0;
  await asyncForEach(children, async (child: any, idx: number) => {
    const docSize = JSON.stringify(child).length;
    if (batchIdx >= 498 || batchByteSize + docSize >= 11534336 * 0.8) {
      //firestore also has a size limit on the same batch call
      const subresult = await dbHelper
        .commitBatch(batch)
        .catch((e: any) => (batchError = e));
      if (batchError) {
        loggerHelper.log("ici", {
          subresult,
          idx,
          batchError,
          batchIdx,
          batchByteSize,
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 500)); //we wait to avoid firestore backoff delay error
      batch = dbHelper.createBatch();
      batchIdx = 0;
      batchByteSize = 0;
    }
    dbHelper.setBatch(batch, ["events", event.id, "children", child.id], child);
    batchIdx++;
    batchByteSize += docSize;
  });
  const results = await dbHelper
    .commitBatch(batch)
    .catch((e: any) => (batchError = e));
  loggerHelper.log(
    {results, batchError},
    children.map((x) => JSON.stringify(x).length),
  );
  if (batchError) return {e: "Error while saving children : " + batchError};

  if (stopAfterEventSave) return error;

  //If event is removed, we delete the associated actions:
  if (event.status === "removed") {
    (event.actions || []).forEach((action: Action) => {
      if (!action?.id) return false;
      dbHelper.setDataToCollection(
        "actions",
        action.id,
        {status: "removed", updated_at: serverTimestamp()},
        true,
      );
    });
  }

  //send to backend to convert event in macro event
  if (use_event_sse) {
    try {
      await sseCallback(event);
    } catch (e) {
      loggerHelper.log({e});
      return {e};
    }
  } else {
    if (disable_pg_refresh) storeCommitCallback();
    const {e, isSuccess} = await sendToBackend(
      fullEvent,
      fullEvent.status === EVENT_STATUS.ACTIVE
        ? EVENT_STATUS.ADDED
        : EVENT_STATUS.REMOVED,
    );
    if (e) return {e};
    if (!isSuccess) return {e: "Failure to send event to backend"};
  }

  return error;
};

const sendToBackend = async (event: any, type: any) => {
  try {
    const result = await apiClient.postEventChange(event, type);
    return {isSuccess: result?.isParseSuccess};
  } catch (e) {
    return {e};
  }
};

export {saveEventInFirestore, sendToBackend};
