<template>
  <v-btn
    :class="{
      'icon-button': true,
      'icon-button--disabled': disabled,
      'icon-button--active': active,
      'transparent-button': transparent,
      'icon-button--with-dot': withDot,
      'icon-button--with-numeral-dot': withNumeralDot,
      'input-button': input,
      'white-button': white,
      'filled-button': filled,
      'medium-button': medium,
      'small-button': small,
      'square-button': square,
    }"
    :variant="!!outlined ? 'outlined' : 'elevated'"
    :ripple="!transparent"
    :loading="loading"
    :disabled="disabled"
    :v-on="activator"
    elevation="0"
    :id="id"
    :height="
      height || size || (large ? '48' : medium ? '32' : small ? '24' : '40')
    "
    :width="
      width || size || (large ? '48' : medium ? '32' : small ? '24' : '40')
    "
    icon
    :style="formComponentStyle"
    :data-cy="dataCy"
    :data-testid="dataTestid"
    @click="(e) => $emit('click', e)"
  >
    <v-tooltip location="top" :disabled="!tooltip">
      <template v-slot:activator="{props}">
        <OplitIcon
          v-if="isOplitIcon"
          v-bind="props"
          :name="icon"
          :fill="iconFill"
          :size="computedIconSize"
        />
        <vue-feather
          v-else-if="icon"
          v-bind="props"
          :class="{'button-icon': true, 'mr-auto ml-2': withDot}"
          tag="div"
          :size="computedIconSize"
          :type="icon"
          :fill="iconFill"
          :stroke="iconStroke"
        />
        <div v-if="text" :class="['no-uppercase', textClass]">
          {{ text }}
        </div>
      </template>
      <div>{{ tooltip }}</div>
    </v-tooltip>
    <slot name="numeral-dot" />

    <div v-if="showDot" class="blue-dot" />
  </v-btn>
</template>

<script scoped lang="ts">
import {defineComponent, computed} from "vue";
import {storeToRefs} from "pinia";
import {useFormComponents} from "@/composables/formComponents";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "figma-button-icon",

  props: {
    size: String,
    width: String,
    height: String,
    iconSize: [String, Number],
    iconStroke: String,
    iconFill: String,
    activator: Object,
    active: Boolean,
    id: {type: String, default: ""},
    square: Boolean,
    large: Boolean,
    medium: {type: Boolean, default: false},
    small: Boolean,
    input: Boolean,
    transparent: Boolean,
    filled: Boolean,
    white: Boolean,
    loading: Boolean,
    disabled: Boolean,
    icon: String,
    text: {type: String, default: ""},
    withDot: {type: Boolean, default: false},
    showDot: {type: Boolean, default: false},
    withNumeralDot: {type: Boolean, default: false},
    textClass: {type: String, default: ""},
    tooltip: {type: String, default: ""},
    outlined: {type: [Boolean, Array], default: false},
    dataCy: {type: String},
    dataTestid: {type: String},
    isOplitIcon: {type: Boolean, default: false},
  },
  emits: ["click"],
  setup(props) {
    const {formComponentStyle} = useFormComponents(props);

    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);

    const computedIconSize = computed(
      () => `${props.iconSize || (props.small ? 16 : props.medium ? 20 : 24)}`,
    );

    return {formComponentStyle, variables, computedIconSize};
  },
});
</script>

<style scoped lang="scss">
.icon-button {
  color: rgb(var(--v-theme-newSubText)) !important;
  background-color: transparent;
  outline: none !important;
  min-width: unset;

  &::before {
    opacity: 0 !important;
  }

  &.square-button {
    border-radius: 8px;

    &.small-button {
      border-radius: 4px;
    }
  }

  &.white-button {
    color: rgb(var(--v-theme-blanc)) !important;
    background-color: transparent !important;

    &:hover {
      border: none;
      background-color: rgb(var(--v-theme-newSubBackground)) !important;
    }

    &.v-btn--variant-outlined {
      border-color: rgb(var(--v-theme-blanc));
    }
  }

  &.input-button {
    font-size: 16px;
    background-color: rgb(var(--v-theme-newSubBackground));

    &.v-btn {
      padding-left: 12px;
      padding-right: 12px;
    }

    &:hover {
      background-color: rgb(var(--v-theme-newHover)) !important;
      color: rgb(var(--v-theme-newMainText)) !important;
    }

    &:focus {
      background-color: rgb(var(--v-theme-newLayerBackground)) !important;
      color: rgb(var(--v-theme-newMainText)) !important;
    }

    &.v-btn.v-btn--disabled {
      background-color: rgb(var(--v-theme-newDisableBG));
    }
  }

  // States
  // Every one of these is !important because Vuetify judged it wise to integrate
  // !important stylings in a public styling framework (that or I'm just incompetent)
  &.filled-button {
    background-color: rgb(var(--v-theme-newPrimaryRegular));
    color: rgb(var(--v-theme-blanc)) !important;

    &:hover,
    &:focus {
      color: rgb(var(--v-theme-blanc)) !important;
      border: none;
    }

    &:hover {
      background-color: rgb(var(--v-theme-newPrimaryDark1));
    }

    &:focus {
      background-color: rgb(var(--v-theme-newPrimaryDark2));
    }

    &.v-btn.v-btn--disabled {
      background-color: rgb(var(--v-theme-newDisableBG));
    }
  }

  &.transparent-button {
    color: rgb(var(--v-theme-newSubText)) !important;

    &:hover,
    &:focus,
    &:active {
      color: rgb(var(--v-theme-newMainText)) !important;
      background-color: transparent;
      border: none;
    }
  }

  &.v-btn.v-btn--variant-outlined {
    border-color: rgb(var(--v-theme-newSelected));

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    &:hover {
      border-color: rgb(var(--v-theme-newSubText));
    }

    &:focus {
      border-color: rgb(var(--v-theme-newMainText));
    }
  }

  &:hover {
    background-color: rgb(var(--v-theme-newSubBackground));
    color: rgb(var(--v-theme-newMainText)) !important;
  }

  &:focus,
  &:active {
    background-color: rgb(var(--v-theme-newHover));
  }

  &:focus {
    border: 1px solid rgb(var(--v-theme-newPrimaryRegular));
  }

  &.v-btn.v-btn--disabled {
    color: rgb(var(--v-theme-newDisableText)) !important;
    border: none;

    &.v-btn--variant-outlined {
      border: 1px solid rgb(var(--v-theme-newDisableText));
    }
  }
  &--with-dot {
    position: relative;
    min-width: 56px;
    justify-content: flex-start;
    .blue-dot {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateX(-100%) translateY(-50%);
      background-color: rgb(var(--v-theme-newPrimaryRegular));
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
  &--with-numeral-dot {
    min-width: max-content;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
