import {storeToRefs} from "pinia";
import {Simulation} from "@/interfaces";
import {computed, unref, MaybeRef} from "vue";
import {SIMULATION_STATUS} from "@/config/constants";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "../stores/mainStore";
import {usePermissionsStore} from "@/stores/permissionsStore";

export const useSimulation = (simulationId?: MaybeRef<string>) => {
  const mainStore = useMainStore();
  const {isScheduling, simulations, team} = storeToRefs(mainStore);
  const {schedulingSimulations} = storeToRefs(useSchedulingStore());
  const {currentPermissions} = storeToRefs(usePermissionsStore());
  /**
   * list of simulations in which to search given the current `is_scheduling` context
   */
  const contextualSimulationsList = computed<Simulation[]>(() =>
    isScheduling.value ? schedulingSimulations.value : simulations.value,
  );

  /**
   * list of simulations that are viewable given the current permissions
   */
  const viewableContextualSimulationsList = computed<Simulation[]>(() => {
    const {read_simulation_active} = currentPermissions.value.general;
    return read_simulation_active
      ? contextualSimulationsList.value
      : contextualSimulationsList.value.filter(
          ({status}: Simulation) => status === SIMULATION_STATUS.ARCHIVED,
        );
  });

  const simulation = computed<Simulation>(() => {
    const _simulationId = unref(simulationId);
    /**
     * if no `simulationId` is provided, return the first active simulation of the current team
     */
    if (!_simulationId) {
      return viewableContextualSimulationsList.value.find(
        ({status, team_ids}: Simulation) =>
          status === SIMULATION_STATUS.ACTIVE &&
          (!team_ids?.length || team_ids.includes(team.value?.id)),
      );
    }
    return viewableContextualSimulationsList.value.find(
      ({id}: Simulation) => id === _simulationId,
    );
  });

  return {
    simulation,
  };
};
