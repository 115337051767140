// used in bugbug
const LEFT_SIDEBAR__DASHBOARD = "left-sidebar--dashboard";
const LEFT_SIDEBAR__ACTION_PLAN = "left-sidebar--plan-action";
const LEFT_SIDEBAR__PLANNING = "left-sidebar--planning";
const LEFT_SIDEBAR__PARAMETERS = "left-sidebar--sidebar-action--parameters";
const LEFT_SIDEBAR__CUSTOMER_INTERFACE =
  "left-sidebar--sidebar-action--customer-interface";
const PARAMETERS_SECTOR_INFORMATION__SECTOR_NAME =
  "parameters-sector-information--input--sector-name";
const LAYOUT_CONTENT_WITH_ACTIONS__CONFIRM_BUTTON =
  "layout-content-with-actions--button--confirm";
const SIMULATION_DROPDOWN = "simulation-dropdown";
const SIMULATION_DROPDOWN__CREATE_SIMULATION_BUTTON =
  "simulation-dropdown--button--create-simulation";
const SIMULATION_STEPPER_BTN = "simulation-stepper-btn";
const PARAMETERS_OPLIT_USERS__TABLE = "parameters-oplit-users--table";
const PARAMETERS_OPLIT_USERS__CREATE_BUTTON =
  "parameters-oplit-users--button--create-user";
const PARAMETERS_OPLIT_USERS__UPDATE_BUTTON =
  "parameters-oplit-users--button--update-user";
const PARAMETERS_OPLIT_USERS__DELETE_BUTTON =
  "parameters-oplit-users--button--delete-user";
const SIMULATION_WRAPPER__STEPPER_CONTENT_ACTIVE =
  "simulation-wrapper--stepper-content-active";
const SIMULATION__LOAD_EVENT_BUTTON = "simulation--button--load-event";
const RIGHT_SIDEBAR = "right-sidebar--planning";
const RIGHT_SIDE_FIELDS__FIELDS_WRAPPER = "right-side-fields--fields-wrapper";
const PLANNING_NODE_ASSIGNMENT_INPUT__INPUT =
  "planning-node-assignment-input--input";
const PLANNING_API__PLANNING_ROW = "planning-api--planning-row";
const PLANNING_NODE_WRAPPER__DELETE_BUTTON =
  "planning-node-wrapper--button--delete";
const PLANNING_CELL__ADD_WRAPPER_BUTTON = "planning-cell--button--add-wrapper";
const WRAPPER_SETTING__ACTIVATOR = "wrapper-setting--button--activator";
const WRAPPER_SETTING__PLANNING__CREATE_SIMULATION_BUTTON =
  "wrapper-setting--button--create-simulation--planning";
const WRAPPER_SETTING__PLANNING__UPDATE_SIMULATION_BUTTON =
  "wrapper-setting--button--update-simulation--planning";
const WRAPPER_SETTING__PLANNING__ARCHIVE_SIMULATION_BUTTON =
  "wrapper-setting--button--archive-simulation--planning";
const WRAPPER_SETTING__PLANNING__DELETE_SIMULATION_BUTTON =
  "wrapper-setting--button--delete-simulation--planning";
const WRAPPER_SETTING__SCHEDULING__UPDATE_CAPACITY_BUTTON =
  "wrapper-setting--button--update-capacity--scheduling";
const WRAPPER_SETTING__SCHEDULING__CREATE_SIMULATION_BUTTON =
  "wrapper-setting--button--create-simulation--scheduling";
const WRAPPER_SETTING__SCHEDULING__UPDATE_SIMULATION_BUTTON =
  "wrapper-setting--button--update-simulation--scheduling";
const WRAPPER_SETTING__SCHEDULING__DELETE_SIMULATION_BUTTON =
  "wrapper-setting--button--delete-simulation--scheduling";
const DRAG_AND_DROP = "drag-and-drop";

const SIMULATION_FORM__TITLE = "simulation-form--title";
const SIMULATION_FORM__CONFIRM_BUTTON = "simulation-form--confirm-button";
const SIMULATION_FORM__TYPE_SWITCH = "simulation-form--type-switch";
const SIMULATION_FORM__FLOATING_INPUT_TRUE =
  "simulation-form--is-floating-simulation-input-true";
const SIMULATION_FORM__STATIC_INPUT =
  "simulation-form--static-simulation-input";
const SIMULATION_FORM__STEP_ONE_CONTENT = "simulation-form--step-one-content";
const SIMULATION_FORM__STEP_TWO_CONTENT = "simulation-form--step-two-content";
// FIXME: format properly after ensuring selector is not used in bugbug
const SIMULATION_FORM__NAME_INPUT = "new-simulation-name";

const SECTOR_BREADCRUMBS__LEVEL_PREFIX = "sector-breadcrumbs--level--";
const SIMULATION__CONTENT_TABS__PREFIX = "simulation--content-tabs--";
const FBUTTON__DEFAULT_DATA_TEST_ID = "fbutton--default-data-test-id";
const FBUTTON__DEFAULT_SLOT = "fbutton--default-slot";

const HOME_WELCOME__TITLE = "homepage-header-text";

const HOME_DISPLAY_MENU__PLANNING_SWITCH =
  "homepage-display-menu-switch-planning";
const HOME_DISPLAY_MENU__SCHEDULING_SWITCH =
  "homepage-display-menu-switch-scheduling";
const HOME_DISPLAY_MENU__PILOTING_SWITCH =
  "homepage-display-menu-switch-piloting";

const OPERATION_PRIORITY_CHIP__CHIP_PREFIX = "operation-priority-chip-";
const SCHEDULING_DISPLAY_MENU__MENU_BUTTON = "scheduling-display-menu-button";
const SCHEDULING_DISPLAY_MENU__WEEKEND_SWITCH =
  "scheduling-display-menu-switch-weekend";
const SCHEDULING_DISPLAY_MENU__SLIPPERY_SWITCH =
  "scheduling-display-menu-switch-slippery";
const SCHEDULING_DISPLAY_MENU__SYNTHETIC_SWITCH =
  "scheduling-display-menu-switch-synthetic";
const F_MAILLE_BUTTON__MESH_ITEM_PREFIX = "maille-button-";

const SCHEDULING_NEW_TAGS_MENU__ACTIVATOR =
  "scheduling-new-tags-menu__activator";
const SCHEDULING_NEW_TAGS_MENU__SEARCH_INPUT =
  "scheduling-new-tags-menu__search-input";
const SCHEDULING_NEW_TAGS_MENU__CONFIRM_BUTTON =
  "scheduling-new-tags-menu__confirm-button";

const LIST_OFS__CELL__TAGS_CONTAINER = "list-ofs__cell__tags-container";
const LIST_OFS__ADD_TAGS = "list-ofs__add-tags";
const LIST_OFS__ACTIONS_BUTTON = "list-ofs__actions-button";
const LIST_OFS__UPDATE_OPS = "list-ofs__update-ops";
const LIST_OFS__SELECT_CHECKBOX_PREFIX = "list-ofs__select-checkbox-";
const LIST_OFS__TABLE = "list-ofs__table";
const LIST_OFS__REF_ARTICLE = "list-ofs__ref-article";

const OPERATION_UPDATE_MODAL__PERIMETERS_DROPDOWN =
  "operation-update-modal__perimeters-dropdown";
const SCHEDULING_NEW_TAGS_MENU__TAGS_WRAPPER =
  "scheduling-new-tags-menu__tags-wrapper";

const OPERATIONS_RIGHT_BAR__CLOSE_ICON = "operations-right-bar__close-icon";

const FLUX_DATA_BODY__SECTORS_SELECTOR = "flux-data-body__sectors-selector";
const FLUX_DATA_BODY__STATUS_CHIP = "flux-data-body__status-chip";

const OPERATION_UPDATE_MODAL__STATUS_INPUT =
  "operation-update-modal__status-input";
const OPERATION_UPDATE_MODAL__STATUS_CHIP_PREFIX =
  "operation-update-modal__status-";

const FILTERS_DIALOG__TAGS_INPUT = "filters-dialog__tags-input";
const FILTERS_DIALOG__VALIDATE_BUTTON = "filters-dialog-validate-button";
const FILTERS_DIALOG__FILTERS_COUNT = "filters-dialog__filters-count";
const SCHEDULING_OFS__SEARCH_INPUT = "scheduling-ofs__search-input";
const SCHEDULING_OFS__PAGE_WRAPPER = "scheduling-ofs__page-wrapper";
const GROUP_BY_SWITCH__SWITCH__ENABLED = "toggle-group-by-enabled";
const GROUP_BY_SWITCH__SWITCH__DISABLED = "toggle-group-by-disabled";
const SCHEDULING_GROUP_BY_MENU__ACTIVATOR =
  "scheduling-group-by-menu__activator";
const SCHEDULING_GROUP_BY_MENU__COLORS_CATEGORIES_SWITCH =
  "scheduling-group-by-menu__colors-categories-switch";

const OPERATION_STATUS__ACTIVATOR_PREFIX = "operation-status__activator-";
const OPERATION_STATUS__STATUS_PREFIX = "operation-status-";

const PILOTING_EN_COURS__PRIORITIZATION__ACTIVATOR =
  "piloting-en-cours__prioritization__activator";
const PILOTING_EN_COURS__PRIORITIZATION__RULE_PREFIX =
  "piloting-en-cours__prioritization__rule-";
const PILOTING_EN_COURS__PRIORITIZATION__LIST_ITEM_PREFIX =
  "piloting-en-cours__prioritization__list-item-";
const PILOTING_EN_COURS__DISPLAY_MENU__ACTIVATOR =
  "piloting-en-cours__display-menu-activator";
const PILOTING_EN_COURS__SECONDARY_INFO_SWITCH =
  "piloting-en-cours__secondary-info-switch";
const PILOTING_EN_COURS__STAGNATION_SWITCH =
  "piloting-en-cours__stagnation-switch";
const PILOTING_EN_COURS__KPIS_SWITCH = "piloting-en-cours__kpis-switch";
const PILOTING_EN_COURS__STATUS_AUTOCOMPLETE =
  "piloting-en-cours__status-autocomplete";
const PILOTING_EN_COURS__VALIDATE_BUTTON = "piloting-en-cours__validate-button";
const PILOTING_EN_COURS__COLUMN__SECTOR = "piloting-en-cours__column-sector";
const OPERATIONS_RIGHT_BAR__DELAY_TEXT = "operations-right-bar__delay-text";

const DELAY_CHIP = "delay-chip";

const SCHEDULING_OPERATION_CARD__MAXIMIZE_ICON =
  "scheduling-operation-card__maximize-icon";

const GRAPH_SETTING__LIST_ITEM_PREFIX = "filter-list-";
const ENTITY_DETAILS_TOOLTIP__LABEL_PREFIX = "entity-details-tooltip__label-";

const PARAMETERS_SECTORS_GROUPS_MODAL__IS_CONWIP_CONTAINER =
  "parameters-sectors-groups-modal__is-conwip-container";
const PARAMETERS_SECTORS_GROUPS_MODAL__CONWIP_MAX_TICKETS_TEXTFIELD =
  "parameters-sectors-groups-modal__conwip-max-tickets-textfield";

const MINI_NUMBER_INPUT__INCREMENT_ICON = "mini-number-input__increment-icon";
const MINI_NUMBER_INPUT__DECREMENT_ICON = "mini-number-input__decrement-icon";

const CAPACITY_TABLE_ROW__CELL_PREFIX = "capacity-table-row__cell-";
const CAPACITY_TABLE_ROW__LOAD = "capacity-table-row__load";
const CAPACITY_TABLE_ROW__CAPACITY = "capacity-table-row__capacity";
const CAPACITY_TABLE_ROW__CHILDREN_SECTORS =
  "capacity-table-row__children-sectors";
const CAPACITY_TABLE_ROW__SECTOR_NAME_WRAPPER =
  "capacity-table-row__sector-name-wrapper";
const CAPACITY_TABLE_ROW__WRAPPER = "capacity-table-row__wrapper";
const KPIS_BANNER = "kpis-banner";
const KPIS_BANNER__ITEM_PREFIX = "kpis-banner__item__";
const ANALYTICS_CROSSING_TIMES_ARTICLE_TABLE_ROW__CELL_PREFIX =
  "analytics-crossing-times-article-table-row__cell__";
const ANALYTICS_CROSSING_TIMES_TABLE_ROW__CELL_PREFIX =
  "analytics-crossing-times-table-row__cell__";

const SEARCH_FIELD_INPUT__DROPDOWN = "search-field-input__dropdown";

export const TEST_IDS = {
  WRAPPER_SETTING__ACTIVATOR,
  LEFT_SIDEBAR__DASHBOARD,
  LEFT_SIDEBAR__ACTION_PLAN,
  LEFT_SIDEBAR__PARAMETERS,
  LEFT_SIDEBAR__PLANNING,
  LEFT_SIDEBAR__CUSTOMER_INTERFACE,
  PARAMETERS_SECTOR_INFORMATION__SECTOR_NAME,
  LAYOUT_CONTENT_WITH_ACTIONS__CONFIRM_BUTTON,
  SIMULATION_DROPDOWN,
  SIMULATION_DROPDOWN__CREATE_SIMULATION_BUTTON,
  SIMULATION_STEPPER_BTN,
  PARAMETERS_OPLIT_USERS__TABLE,
  PARAMETERS_OPLIT_USERS__CREATE_BUTTON,
  PARAMETERS_OPLIT_USERS__UPDATE_BUTTON,
  PARAMETERS_OPLIT_USERS__DELETE_BUTTON,
  SIMULATION_WRAPPER__STEPPER_CONTENT_ACTIVE,
  SIMULATION__LOAD_EVENT_BUTTON,
  RIGHT_SIDEBAR,
  RIGHT_SIDE_FIELDS__FIELDS_WRAPPER,
  PLANNING_NODE_ASSIGNMENT_INPUT__INPUT,
  PLANNING_API__PLANNING_ROW,
  SECTOR_BREADCRUMBS__LEVEL_PREFIX,
  SIMULATION__CONTENT_TABS__PREFIX,
  PLANNING_NODE_WRAPPER__DELETE_BUTTON,
  PLANNING_CELL__ADD_WRAPPER_BUTTON,
  WRAPPER_SETTING__PLANNING__CREATE_SIMULATION_BUTTON,
  WRAPPER_SETTING__PLANNING__UPDATE_SIMULATION_BUTTON,
  WRAPPER_SETTING__PLANNING__ARCHIVE_SIMULATION_BUTTON,
  WRAPPER_SETTING__PLANNING__DELETE_SIMULATION_BUTTON,
  WRAPPER_SETTING__SCHEDULING__UPDATE_CAPACITY_BUTTON,
  WRAPPER_SETTING__SCHEDULING__CREATE_SIMULATION_BUTTON,
  WRAPPER_SETTING__SCHEDULING__UPDATE_SIMULATION_BUTTON,
  WRAPPER_SETTING__SCHEDULING__DELETE_SIMULATION_BUTTON,
  DRAG_AND_DROP,
  SIMULATION_FORM__TITLE,
  SIMULATION_FORM__CONFIRM_BUTTON,
  SIMULATION_FORM__TYPE_SWITCH,
  SIMULATION_FORM__FLOATING_INPUT_TRUE,
  SIMULATION_FORM__STATIC_INPUT,
  SIMULATION_FORM__STEP_ONE_CONTENT,
  SIMULATION_FORM__STEP_TWO_CONTENT,
  SIMULATION_FORM__NAME_INPUT,
  FBUTTON__DEFAULT_DATA_TEST_ID,
  FBUTTON__DEFAULT_SLOT,
  HOME_WELCOME__TITLE,
  HOME_DISPLAY_MENU__PLANNING_SWITCH,
  HOME_DISPLAY_MENU__SCHEDULING_SWITCH,
  HOME_DISPLAY_MENU__PILOTING_SWITCH,
  OPERATION_PRIORITY_CHIP__CHIP_PREFIX,
  SCHEDULING_DISPLAY_MENU__MENU_BUTTON,
  SCHEDULING_DISPLAY_MENU__WEEKEND_SWITCH,
  SCHEDULING_DISPLAY_MENU__SLIPPERY_SWITCH,
  SCHEDULING_DISPLAY_MENU__SYNTHETIC_SWITCH,
  F_MAILLE_BUTTON__MESH_ITEM_PREFIX,
  SCHEDULING_NEW_TAGS_MENU__ACTIVATOR,
  SCHEDULING_NEW_TAGS_MENU__SEARCH_INPUT,
  SCHEDULING_NEW_TAGS_MENU__CONFIRM_BUTTON,
  LIST_OFS__CELL__TAGS_CONTAINER,
  LIST_OFS__ADD_TAGS,
  LIST_OFS__ACTIONS_BUTTON,
  LIST_OFS__UPDATE_OPS,
  LIST_OFS__SELECT_CHECKBOX_PREFIX,
  LIST_OFS__TABLE,
  LIST_OFS__REF_ARTICLE,
  OPERATION_UPDATE_MODAL__PERIMETERS_DROPDOWN,
  SCHEDULING_NEW_TAGS_MENU__TAGS_WRAPPER,
  OPERATIONS_RIGHT_BAR__CLOSE_ICON,
  FLUX_DATA_BODY__SECTORS_SELECTOR,
  FLUX_DATA_BODY__STATUS_CHIP,
  OPERATION_UPDATE_MODAL__STATUS_INPUT,
  OPERATION_UPDATE_MODAL__STATUS_CHIP_PREFIX,
  FILTERS_DIALOG__TAGS_INPUT,
  FILTERS_DIALOG__VALIDATE_BUTTON,
  FILTERS_DIALOG__FILTERS_COUNT,
  SCHEDULING_OFS__SEARCH_INPUT,
  SCHEDULING_OFS__PAGE_WRAPPER,
  GROUP_BY_SWITCH__SWITCH__ENABLED,
  GROUP_BY_SWITCH__SWITCH__DISABLED,
  SCHEDULING_GROUP_BY_MENU__ACTIVATOR,
  SCHEDULING_GROUP_BY_MENU__COLORS_CATEGORIES_SWITCH,
  OPERATION_STATUS__ACTIVATOR_PREFIX,
  OPERATION_STATUS__STATUS_PREFIX,
  PILOTING_EN_COURS__PRIORITIZATION__ACTIVATOR,
  PILOTING_EN_COURS__PRIORITIZATION__RULE_PREFIX,
  PILOTING_EN_COURS__PRIORITIZATION__LIST_ITEM_PREFIX,
  PILOTING_EN_COURS__DISPLAY_MENU__ACTIVATOR,
  PILOTING_EN_COURS__SECONDARY_INFO_SWITCH,
  PILOTING_EN_COURS__STAGNATION_SWITCH,
  PILOTING_EN_COURS__KPIS_SWITCH,
  PILOTING_EN_COURS__STATUS_AUTOCOMPLETE,
  PILOTING_EN_COURS__VALIDATE_BUTTON,
  PILOTING_EN_COURS__COLUMN__SECTOR,
  OPERATIONS_RIGHT_BAR__DELAY_TEXT,
  DELAY_CHIP,
  SCHEDULING_OPERATION_CARD__MAXIMIZE_ICON,
  GRAPH_SETTING__LIST_ITEM_PREFIX,
  ENTITY_DETAILS_TOOLTIP__LABEL_PREFIX,
  PARAMETERS_SECTORS_GROUPS_MODAL__IS_CONWIP_CONTAINER,
  PARAMETERS_SECTORS_GROUPS_MODAL__CONWIP_MAX_TICKETS_TEXTFIELD,
  MINI_NUMBER_INPUT__INCREMENT_ICON,
  MINI_NUMBER_INPUT__DECREMENT_ICON,
  CAPACITY_TABLE_ROW__CELL_PREFIX,
  CAPACITY_TABLE_ROW__LOAD,
  CAPACITY_TABLE_ROW__CAPACITY,
  CAPACITY_TABLE_ROW__CHILDREN_SECTORS,
  CAPACITY_TABLE_ROW__SECTOR_NAME_WRAPPER,
  CAPACITY_TABLE_ROW__WRAPPER,
  KPIS_BANNER,
  KPIS_BANNER__ITEM_PREFIX,
  ANALYTICS_CROSSING_TIMES_ARTICLE_TABLE_ROW__CELL_PREFIX,
  ANALYTICS_CROSSING_TIMES_TABLE_ROW__CELL_PREFIX,
  SEARCH_FIELD_INPUT__DROPDOWN,
};
