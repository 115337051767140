import {App, Directive, createVNode, render, ref, watchEffect} from "vue";
import {VTooltip} from "vuetify/lib/components/index.mjs";

/**
 * this directive binds a tooltip to an element using the VTooltip component
 * ```vue
 * <my-element
 *    v-tooltip="'The content of my tooltip'"
 * />
 * ```
 * by default the tooltip will be positioned at the top of the element\
 * position (e.g. `end`) can be handled using directive modifiers :
 * ```vue
 * <my-element
 *    v-tooltip.end="'The content of my tooltip on the end (right)'"
 * />
 * ```
 * the `disabled` state is handled through the binding arg :
 * ```vue
 * <my-element
 *    v-tooltip:[myBoolean]="'The content of my tooltip'"
 * />
 * ```
 * ```js
 * data: () => ({
 *   myBoolean: true // will disable the tooltip
 * })
 * ```
 */

/**
 * FIXME:
 * A PR is currently opened for the matter of using VTooltip as a directive (vuetify 3)
 * This should be ideally used (if available) instead of this custom plugin
 * https://github.com/vuetifyjs/vuetify/pull/17395
 */

const tooltip = (app: App): Directive => {
  const tooltips = new Map();

  return {
    beforeUnmount: (el: HTMLElement) => {
      const tooltip = tooltips.get(el);
      if (tooltip) {
        render(null, tooltip.container);
        tooltips.delete(el);
      }
    },
    mounted: (el: HTMLElement, binding) => {
      const {value, modifiers, arg} = binding;
      const {start = false, bottom = false, end = false} = modifiers;

      if (!value || arg) return;

      let location = "top";
      if (start) location = "start";
      else if (end) location = "end";
      else if (bottom) location = "bottom";

      const content = ref(value);

      const vNode = createVNode(
        VTooltip,
        {
          activator: el,
          location,
        },
        {
          default: () => [content.value],
        },
      );

      vNode.appContext = app._context;

      const container = document.createElement("div");
      render(vNode, container);

      tooltips.set(el, {vNode, container, content});

      watchEffect(() => {
        content.value = binding.value;
      });
    },
    updated: (el: HTMLElement, binding) => {
      const tooltip = tooltips.get(el);
      if (tooltip) tooltip.content.value = binding.value;
    },
  };
};

export default tooltip;
