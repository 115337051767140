<script lang="ts" setup>
import {computed, unref, inject} from "vue";
import _ from "lodash";
import moment from "moment";
import {storeToRefs} from "pinia";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {useMainStore} from "@/stores/mainStore";
import {FDropdown} from "../Global";
import {DATE_WITH_TIME_FORMAT} from "@oplit/shared-module";
import type {Client, OpenSnackbarFunction} from "@/interfaces";

type PinnableClient = Client & {is_pinned: boolean; pinned_at?: string};

const props = defineProps<{
  groupList: Client[];
  selectedGroup?: string;
  input?: boolean;
}>();
const emit = defineEmits<{
  (e: "change", groupId: string): void;
}>();

const mainStore = useMainStore();
const {userData} = storeToRefs(mainStore);
const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

const pinnedClients = computed(() => unref(userData).pinned_clients || []);
const clientList = computed<PinnableClient[]>(() => {
  const list = props.groupList.map((client) => {
    const pinnedClient = unref(pinnedClients).find(({id}) => id === client.id);
    return {
      ...client,
      is_pinned: !!pinnedClient,
      ...(pinnedClient && {pinned_at: pinnedClient.pinned_at}),
    };
  });
  return _.orderBy(list, ["is_pinned", "pinned_at"], ["desc", "desc"]);
});

const pinClient = async (
  client: PinnableClient,
  is_pinned: boolean,
): Promise<void> => {
  const pinned_clients = is_pinned
    ? [
        ...unref(pinnedClients),
        {id: client.id, pinned_at: moment.utc().format(DATE_WITH_TIME_FORMAT)},
      ]
    : unref(pinnedClients).filter(({id}) => id !== client.id);

  const error = await dbHelper.setDataToCollection(
    "users",
    unref(userData).id,
    {
      pinned_clients,
    },
    true,
  );
  if (!error) userData.value = {...userData.value, pinned_clients};
  else openSnackbar(null, "GENERIC_ERROR");
};
const onChange = (id: string) => emit("change", id);
</script>

<template>
  <div class="client-group-dropdown">
    <FDropdown
      content-class="client-group-content"
      :items="clientList"
      :model-value="selectedGroup || userData.client_id"
      :pink-border="!userData.client_id"
      :input="input"
      keep-scrollbar
      outlined
      block
      data-testid="client-group-dropdown"
      @change="({id}) => onChange(id)"
    >
      <template #content="{text}">
        <v-tooltip :text="text" location="bottom">
          <template #activator="{props}">
            <div v-bind="props" class="truncated-text">{{ text }}</div>
          </template>
        </v-tooltip>
      </template>
      <template #item-append="client: PinnableClient">
        <div
          :class="[
            'flex-shrink-0 flex-grow-0 pl-4 client-pin',
            {'is-pinned': client.is_pinned},
          ]"
          @click.stop="() => pinClient(client, !client.is_pinned)"
        >
          <OplitIcon name="pin" size="15" class="pin-icon" />
        </div>
      </template>
    </FDropdown>
  </div>
</template>

<style scoped lang="scss">
.client-group-content {
  .client-pin {
    opacity: 0;
    & .pin-icon {
      fill: transparent;
      stroke: black;
    }
    &:hover .pin-icon {
      stroke: rgb(var(--v-theme-newPrimaryRegular));
    }
    &.is-pinned {
      opacity: 1;
      & .pin-icon {
        fill: rgb(var(--v-theme-newPrimaryRegular));
        stroke: rgb(var(--v-theme-newPrimaryRegular));
      }
    }
  }
  .v-list-item:hover .client-pin {
    opacity: 1;
  }
}
.truncated-text {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
</style>
