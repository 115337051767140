import {pgOpsMapFn} from "@oplit/shared-module";
import CapacitiesAPIClient from "@/api/capacities";

const apiClient = new CapacitiesAPIClient();

const getOfOperationList = async (
  _client_id = "",
  of_id: string,
  simulation_id: string,
) => {
  const results = await apiClient.postRequest(
    `/api/simulations/${simulation_id}/loads/full`,
    {of_id},
  );
  return pgOpsMapFn(results, {of_ordering: true, keepOpsDone: true}); //the last true was added because we need to take into account done ops when changing the status of a given OP. Normally it should not have side effects, but here's your suspect if you're looking
};

export {getOfOperationList};
