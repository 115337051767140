<script setup lang="ts">
import {computed} from "vue";
import {Poste} from "@/interfaces";

interface EnCoursColumnSectorBlockProps {
  sector: Poste;
  hideBeforeBorder?: boolean;
}

const props = defineProps<EnCoursColumnSectorBlockProps>();

const sectorName = computed(
  () => props.sector.secteur_name ?? props.sector.name,
);
</script>

<template>
  <div
    :class="{'hide-before-border': hideBeforeBorder}"
    class="en-cours-column-sector-block"
  >
    <slot v-bind="{sectorName}" />
  </div>
</template>

<style scoped lang="scss">
.en-cours-column-sector-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: var(--sector-block--height);
  background-color: rgb(var(--v-theme-newLayerBackground));
  border: var(--sector-block--border-width) solid rgb(var(--v-theme-newHover));
  border-radius: 8px;
  position: relative;
  margin-top: var(--sector-block--before-border-height);

  &:not(.hide-before-border):before {
    content: "";
    position: absolute;
    left: 50%;
    top: calc(
      var(--sector-block--before-border-height) * -1 -
        var(--sector-block--border-width)
    );
    border-top: var(--sector-block--before-border-height) solid
      rgb(var(--v-theme-newHover));
    border-left: var(--sector-block--before-border-height) solid transparent;
    border-right: var(--sector-block--before-border-height) solid transparent;
    width: 75%;
    transform: translateX(-50%);
    z-index: 1;
  }
}
</style>
