import {computed, unref} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {MaybeRef} from "@vueuse/core";
import {levelCorresp} from "@oplit/shared-module";
import {useSectorTree} from "./useSectorTree";
import {OplitSimulationPeriod, SectorLike} from "@/interfaces";
import moment from "moment";

export const useCalendars = () => {
  const mainStore = useMainStore();
  const {calendars} = storeToRefs(mainStore);
  return calendars;
};

export const useMonthCalendars = (
  perimeter: MaybeRef<SectorLike>,
  period?: MaybeRef<OplitSimulationPeriod>,
) => {
  const calendars = useCalendars();
  const {sectorTree} = useSectorTree(perimeter);
  return computed(() => {
    if (unref(period)) {
      const startDate = moment(unref(period).startDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(unref(period).endDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      return unref(calendars).filter(
        (x: any) =>
          (x.date >= startDate &&
            x.date <= endDate &&
            x.secteur_id === unref(sectorTree).id) ||
          levelCorresp.some(
            (level: any) => x.secteur_id === sectorTree[level.type + "_id"],
          ),
      );
    }
    return unref(calendars).filter(
      (x: any) =>
        x.secteur_id === unref(sectorTree).id ||
        levelCorresp.some(
          (level: any) => x.secteur_id === sectorTree[level.type + "_id"],
        ),
    );
  });
};

export const useSectorCalendars = (
  perimeter: MaybeRef<SectorLike>,
  period?: MaybeRef<OplitSimulationPeriod>,
) => {
  const monthCalendars = useMonthCalendars(perimeter, period);
  const {sectorTree} = useSectorTree(perimeter);

  return computed(() => {
    return unref(monthCalendars).filter(
      (x: any) => x.secteur_id === sectorTree.id,
    );
  });
};

export const useOrderedCalendars = (
  perimeter: MaybeRef<SectorLike>,
  period?: MaybeRef<OplitSimulationPeriod>,
) => {
  const monthCalendars = useMonthCalendars(perimeter, period);
  return computed(() => {
    const orderedCalendars = unref(monthCalendars).filter(
      (x: any, idx: number, arr: any) => {
        const restArr = arr.slice(idx + 1);
        if (
          restArr.some(
            (c: any) => c.date === x.date && c.updated_at >= x.updated_at,
          )
        )
          return false;
        return true;
      },
    );
    return orderedCalendars;
  });
};
