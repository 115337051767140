/**
 * @file Contains prebuilt versions of the functions contained in the shared helpers' builder methods.
 * @author Bastien FROMENT
 */
import {dbHelper} from "./requirements";
import {
  buildConvertRawTags,
  buildFetchActiveMSD,
  buildGetEvent,
  buildGetMSDData,
} from "@oplit/shared-module";

const convertRawTags = buildConvertRawTags(dbHelper);
const fetchActiveMSD = buildFetchActiveMSD(dbHelper);
const getEvent = buildGetEvent(dbHelper);
const getMSDData = buildGetMSDData(dbHelper);

export {convertRawTags, fetchActiveMSD, getEvent, getMSDData};
