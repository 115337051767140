import {computed} from "vue";
import {serverTimestamp} from "firebase/firestore";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {
  User,
  OplitFirestoreDocument,
  OplitFirestoreDocumentUserMetadata,
  OplitFirestoreDocumentUpdateUserMetadata,
  VersionedOplitFirestoreDocument,
} from "@/interfaces";

export function useDBUtils() {
  const mainStore = useMainStore();

  const userMetadata = computed(() => {
    const {userData} = storeToRefs(mainStore);
    return getBaseUserForMetadataInOplitFirestoreDocument(userData.value);
  });

  function getBaseUserForMetadataInOplitFirestoreDocument(user): Partial<User> {
    // first_name & last_name have been seen being used for events
    const {name, first_name, last_name, id: user_id} = user;
    return {
      first_name,
      last_name,
      name,
      user_id,
    };
  }

  function getOplitFirestoreDocumentUpdateUserMetadata(): OplitFirestoreDocumentUpdateUserMetadata {
    return {
      updated_by: userMetadata.value,
      updated_at: serverTimestamp(),
    };
  }

  function getOplitFirestoreDocumentUserMetadata(
    payload?: Partial<OplitFirestoreDocument>,
  ): OplitFirestoreDocumentUserMetadata {
    return {
      ...getOplitFirestoreDocumentUpdateUserMetadata(),
      created_by: payload?.created_by || userMetadata.value,
      created_at: payload?.created_at || serverTimestamp(),
    };
  }

  function getBaseOplitFirestoreDocument(
    collection: string,
  ): OplitFirestoreDocument {
    const {userData} = storeToRefs(mainStore);
    return {
      id: dbHelper.getCollectionId(collection),
      client_id: userData.value.client_id,
      ...getOplitFirestoreDocumentUserMetadata(),
      status: "active",
    };
  }

  function getBaseVersionedOplitFirestoreDocument(
    collection: string,
  ): VersionedOplitFirestoreDocument {
    const {activeMsd} = storeToRefs(mainStore);
    return {
      ...getBaseOplitFirestoreDocument(collection),
      msd_id: activeMsd.value.id,
    };
  }

  return {
    getBaseUserForMetadataInOplitFirestoreDocument,
    getBaseOplitFirestoreDocument,
    getOplitFirestoreDocumentUserMetadata,
    getBaseVersionedOplitFirestoreDocument,
    getOplitFirestoreDocumentUpdateUserMetadata,
  };
}
