/**
 * inject dependencies as instance methods to be able to use without importing
 * introduced as part of circular dependencies fixes
 */
const dependenciesPlugin = {
  install(app, options) {
    app.config.globalProperties.$_router = options.router;
  },
};

export default dependenciesPlugin;
