<script setup lang="ts">
import {computed, useSlots} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

type AbsolutePositioning = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

interface DevHelperProps {
  absolute?: boolean | AbsolutePositioning;
  mini?: boolean;
  onClickCopy?: string;
}

const props = defineProps<DevHelperProps>();

const slots = useSlots();

const mainStore = useMainStore();
const {isDevEnv} = storeToRefs(mainStore);

const devHelperStyle = computed(() => {
  if (!props.absolute) return {};

  const style = {position: "absolute"};

  if (typeof props.absolute === "boolean") {
    // default positioning
    return {
      ...style,
      top: 0,
      left: 0,
    };
  }

  for (const positionalAttribute of ["top", "right", "bottom", "left"]) {
    if (positionalAttribute in props.absolute)
      style[positionalAttribute] = props.absolute[positionalAttribute];
  }

  return style;
});

function onDevHelperClick(event: Event): void {
  // prevent other events from triggering on a click on this component
  event.stopPropagation();
  if (!navigator?.clipboard) return;
  if (props.onClickCopy) navigator.clipboard.writeText(props.onClickCopy);
  const [slot] = slots.default();
  if (!slot?.children) return;
  navigator.clipboard.writeText(
    // remove unwanted parenthesis at start/end of slot
    `${slot.children}`.trim().replace(/^\(|\)$/g, ""),
  );
}
</script>

<template>
  <span
    v-if="isDevEnv"
    v-bind="$attrs"
    :class="[mini ? 'fcaption-mini' : 'fcaption']"
    :style="devHelperStyle"
    class="dev-helper font-weight-normal cursor-pointer"
    @click="onDevHelperClick"
  >
    <slot />
  </span>
</template>
