<script setup lang="ts">
import {useI18n} from "vue-i18n";
import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import {useMainStore} from "@/stores/mainStore";
import {SectorGroup} from "@/interfaces";

interface SectorGroupItemProps {
  sectorGroup: SectorGroup;
  displayPrependIcon?: boolean;
  /**
   * applies color for a dark background
   */
  dark?: boolean;
}

defineProps<SectorGroupItemProps>();

const {t} = useI18n();

const {variables} = useMainStore();
</script>

<template>
  <div class="sector-group-item">
    <OplitIcon
      v-if="displayPrependIcon"
      :stroke="dark ? variables.blanc : variables.newMainText"
      name="sector"
    />

    <span>
      {{ sectorGroup.name }}
    </span>

    <span :class="dark ? 'text-newSelected' : 'text-newDisableText'">
      {{
        t("Commons.sectors_count", {
          count: sectorGroup.sectors.length,
        })
      }}
    </span>

    <FChip
      v-if="sectorGroup.is_conwip"
      :color="dark ? 'white' : 'newPrimaryRegular'"
      :class="{'text-newPrimaryRegular': dark}"
      class="ml-2"
    >
      <template #prepend-icon>
        <OplitIcon
          name="ticket"
          :stroke="dark ? variables.newPrimaryRegular : variables.blanc"
        />
      </template>

      Conwip
    </FChip>
  </div>
</template>

<style scoped lang="scss">
.sector-group-item {
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: normal;
}
</style>
