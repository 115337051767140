<template>
  <FCheck
    v-if="hasMercateam"
    :model-value="sendPlanningToMercateam"
    :label="$t('Mercateam.send_to')"
    id="send-planning-mercateam"
    @update:model-value="onInput"
  ></FCheck>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";
import {FCheck} from "@/components/Global";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  components: {
    FCheck,
  },
  setup() {
    const mainStore = useMainStore();
    const {hasMercateam} = storeToRefs(mainStore);

    const sendPlanningToMercateam = ref(false);

    return {
      hasMercateam,
      sendPlanningToMercateam,
    };
  },
  methods: {
    onInput(bool: boolean) {
      this.sendPlanningToMercateam = bool;
    },
  },
});
</script>
