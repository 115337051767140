import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {HTMLElementStyleObject} from "@/interfaces";
import {computed} from "vue";

export function useFormComponents(props) {
  const mainStore = useMainStore();

  const outlinedStyle = computed<HTMLElementStyleObject>(() => {
    const {variables} = storeToRefs(mainStore);
    if (!props?.outlined?.length) return {};
    const [backgroundColor, borderColor] = props.outlined;
    const styleObject: HTMLElementStyleObject = {};
    if (backgroundColor)
      styleObject.backgroundColor = variables.value[backgroundColor];
    if (borderColor) styleObject.borderColor = variables.value[borderColor];
    return styleObject;
  });

  const heightStyle = computed<HTMLElementStyleObject>(() => {
    if (!props.height) return {};

    const height = props.height.match(/^\d+$/)
      ? `${props.height}px`
      : props.height;

    return {height};
  });

  const formComponentStyle = computed<HTMLElementStyleObject>(() => ({
    ...outlinedStyle.value,
    ...heightStyle.value,
  }));

  return {formComponentStyle};
}
