<template>
  <div class="custom-color-picker" :style="gridStyle">
    <div v-for="color in colors" :key="color">
      <v-chip
        :color="variables[color]"
        :class="[
          {selected: isSelectedColor(color)},
          {bordered},
          'cursor-pointer',
        ]"
        size="small"
        variant="flat"
        @click="selectColor(color)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {storeToRefs} from "pinia";
import {HTMLElementStyleObject} from "@/interfaces";
import {computed} from "vue";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "custom-color-picker",
  props: {
    colors: {type: Array as PropType<string[]>, required: true},
    currentColor: {type: String, default: null},
    bordered: {type: Boolean, default: false},
    columnsCount: {
      type: Number,
      default: function (props): number {
        return props.colors.length;
      },
    },
  },
  emits: ["update:currentColor"],
  setup(props) {
    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);

    const selectedColor = ref(props.currentColor);
    const gridStyle = computed<HTMLElementStyleObject>(() => ({
      "grid-template-columns": `repeat(${props.columnsCount}, minmax(0, 1fr))`,
    }));

    return {
      variables,
      selectedColor,
      gridStyle,
    };
  },
  watch: {
    /**
     * updates the selected color when the prop value changes
     * used to reset the value upon CustomColorDefiner menu "disappearance"
     */
    currentColor(newColor: string) {
      this.selectedColor = newColor;
    },
  },
  methods: {
    selectColor(color: string): void {
      this.selectedColor = this.isSelectedColor(color) ? null : color;
      this.$emit("update:currentColor", this.selectedColor);
    },
    isSelectedColor(color: string): boolean {
      return this.selectedColor === color;
    },
  },
});
</script>

<style scoped lang="scss">
.custom-color-picker {
  display: grid;
  gap: 12px;

  & .v-chip {
    padding: 0 13px;

    &.bordered {
      border: 1px solid rgb(var(--v-theme-newSelected)) !important;
      // removes the border width from the original padding ($chip-padding)
      padding: 0 11px;
    }

    &.selected {
      position: relative;
      overflow: visible;

      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        left: 50%;
        top: 50%;
        background: transparent;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(var(--v-theme-newPrimaryRegular));
        border-radius: 50%;
      }
    }
  }
}
</style>
