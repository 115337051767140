import {
  OAuthProvider,
  UserCredential,
  getAuth,
  getMultiFactorResolver,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {storeToRefs} from "pinia";
import router from "@/router";
import moment from "moment";
import {LOCAL_STORAGE} from "@/config/constants";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import loggerHelper from "@/tscript/loggerHelper";
import {isUserFromRole} from "@/tscript/utils/permissions";
import {
  routeRequires,
  handleNewUserDataNavigation,
} from "@/tscript/utils/routerHelper";
import {levelCorresp, Result, USER_ROLES} from "@oplit/shared-module";
import {useMainStore} from "@/stores/mainStore";
import {useHomepageStore} from "@/stores/homepageStore";
import {useParameterStore} from "@/stores/parameterStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useStocksStore} from "@/stores/stocksStore";
import type {User} from "@/interfaces";
import {useSimulationStore} from "@/stores/simulationStore";
import {linkWithMicrosoft, signInWithMicrosoft} from "@/api/microsoft_auth";
import {oplitClient} from "@/api";

export const useConnexionUser = () => {
  const mainStore = useMainStore();
  const homepageStore = useHomepageStore();
  const schedulingStore = useSchedulingStore();
  const parameterStore = useParameterStore();
  const stocksStore = useStocksStore();

  async function loadUserData(userId: string) {
    const {
      userData,
      appLoadingValue,
      isAppLoading,
      hasStock,
      breadcrumbs,
      isScheduling,
      status,
      error,
      clientParameters,
      hasMercateam,
    } = storeToRefs(mainStore);
    const {
      incrementAppValue,
      loadClientsList,
      loadEnvironment,
      updateOptim,
      loadUsers,
      loadTeams,
      loadPerimeters,
      loadOperators,
      loadMachines,
      loadClientParameters,
      loadActiveMsd,
      loadCalendars,
      loadImports,
      loadFabriqUsers,
      loadUserParameters,
      loadAutoOrga,
      loadMercateamEmployees,
    } = mainStore;
    const {loadHomepageParameters} = homepageStore;
    const {
      loadSchedulingSimulations,
      loadDailySchedulingColors,
      loadSchedulingMachineCenters,
      loadSchedulingMessages,
    } = schedulingStore;
    const {
      loadParametersSectorGroups,
      loadParametersSchedulingRules,
      loadShiftPlanning,
    } = parameterStore;
    const {loadSimulations} = useSimulationStore();

    appLoadingValue.value = 0;
    if (!userId) return;

    userData.value = null;
    isAppLoading.value = true;
    incrementAppValue(5);

    const userResult: User = await dbHelper.getDocFromCollection(
      "users",
      userId,
    );

    incrementAppValue(5);

    if (userResult && userResult.status !== "removed") {
      loadClientsList(userResult);

      // Only if the client exists in Firebase
      if (userResult.client_id) {
        // Load Teams List for the user
        loggerHelper.time("all loaded");
        const actionPromises = [];
        const currentRoutePath = router?.currentRoute.value?.path || "";
        const simplifyStore = currentRoutePath.startsWith(
          "/frames/adhesion-rate",
        );

        actionPromises.push(loadEnvironment());
        actionPromises.push(
          loadHomepageParameters({
            user_id: userId,
            client_id: userResult.client_id,
          }),
        );

        if (!simplifyStore) {
          actionPromises.push(updateOptim({cache_store: 0}));
          actionPromises.push(loadUsers(userResult.client_id));
        }

        actionPromises.push(loadTeams(userResult.client_id));
        actionPromises.push(loadPerimeters(userResult));
        actionPromises.push(loadParametersSectorGroups(userResult.client_id));
        actionPromises.push(
          loadParametersSchedulingRules(userResult.client_id),
        );

        if (!simplifyStore) {
          actionPromises.push(loadOperators(userResult.client_id));
          actionPromises.push(loadMachines(userResult.client_id));
          actionPromises.push(loadClientParameters(userResult.client_id));
          actionPromises.push(loadAutoOrga());
        }

        actionPromises.push(
          loadSimulations(
            userResult.client_id,
            isUserFromRole(userResult, USER_ROLES.ADMIN),
          ),
        );

        if (!simplifyStore) {
          actionPromises.push(loadActiveMsd(userResult.client_id));
          actionPromises.push(loadCalendars(userResult.client_id));
          actionPromises.push(loadImports(userResult.client_id));
          actionPromises.push(
            loadUserParameters({
              userId,
              clientId: userResult.client_id,
            }),
          );
        }

        // ORDO
        if (userResult?.has_scheduling) {
          actionPromises.push(
            loadSchedulingSimulations(
              userResult.client_id,
              isUserFromRole(userResult, USER_ROLES.ADMIN),
            ),
            loadDailySchedulingColors(userResult.client_id),
            loadSchedulingMachineCenters(),
            loadSchedulingMessages(userResult.client_id),
          );
        }

        loadFabriqUsers();
        const {loadSchedulingTags} = schedulingStore;
        loadSchedulingTags({
          client_id: userResult.client_id,
        });

        await Promise.all(actionPromises).catch(function (err: any) {
          loggerHelper.log(err);
        });

        // We fetch client data
        const clientResult = await dbHelper.getDocFromCollection(
          "clients",
          userResult.client_id,
        );

        if (clientResult) {
          userResult["client_name"] = clientResult.name;
          userResult["client_logo_url"] = clientResult.logo_url;
          userResult["client_slug"] = clientResult.slug;
        }

        incrementAppValue(5);
      }

      if (userResult.site_id) {
        // We fetch site data
        const siteResults = await dbHelper.getAllDataFromCollectionWithAll(
          "sectors",
          {
            where: [
              {
                field: "client_id",
                value: userResult.client_id,
                compare: "==",
              },
              {field: "id", value: userResult.site_id, compare: "=="},
            ],
          },
        );
        const siteResult = siteResults?.[0];

        if (siteResult) {
          userResult["site_name"] = siteResult.name;
          if (breadcrumbs.value?.length <= 1) {
            breadcrumbs.value = [
              {
                name: userResult.site_name,
                id: userResult.site_id,
                disabled: true,
                ...levelCorresp[0],
              },
            ];
          }
        }

        incrementAppValue(5);
      }

      const shouldBeScheduling =
        userResult.has_scheduling && userResult.has_production_planning
          ? routeRequires(router.currentRoute.value, "requiresScheduling")
          : userResult.has_scheduling;

      isScheduling.value = shouldBeScheduling;
      userData.value = userResult;

      if (clientParameters.value.has_shift_planning) loadShiftPlanning();

      if (hasStock.value) {
        const {initializeStocksModuleFromParametresUser} = stocksStore;
        initializeStocksModuleFromParametresUser();
      }

      if (hasMercateam.value) loadMercateamEmployees();

      /**
       * setting the localStorage item with values only when this action is called
       * to avoid it to be reset unwantedly ; we can do this because we only store
       * immuable values that should not be editable from within the app
       */
      localStorage.setItem(
        LOCAL_STORAGE.USER_DATA,
        JSON.stringify({
          id: userId,
          has_scheduling: userResult?.has_scheduling || false,
          has_production_planning: userResult?.has_production_planning || false,
          has_customer_interface_provider_side:
            userResult?.has_customer_interface_provider_side || false,
          role: userResult?.role || null,
          has_customer_interface_customer_side:
            userResult?.has_customer_interface_customer_side || false,
        }),
      );

      status.value = "success";
      error.value = null;
      isAppLoading.value = false;

      // check if user is authorized to see current route
      const redirectRouteProps = handleNewUserDataNavigation(
        userResult,
        router.currentRoute.value,
      );
      if (redirectRouteProps) {
        router
          .push({
            ...redirectRouteProps,
            query: router?.currentRoute.value?.query || {},
          })
          .catch(loggerHelper.log);
      }
    } else {
      const query = router?.currentRoute.value?.query || {};
      router.push({name: "login", query});
      loggerHelper.log("users in store", "error");

      userData.value = null;
      status.value = "failure";
      error.value = "error";
      isAppLoading.value = false;

      const {logOut} = useConnexionUser();
      logOut();
    }
  }
  async function reloadUser(payload: {uid: string}) {
    const mainStore = useMainStore();
    const {userId} = storeToRefs(mainStore);

    if (payload && payload.uid) {
      userId.value = payload.uid;
      await loadUserData(payload.uid);
    }
  }
  async function changeClient(client_id: string) {
    const mainStore = useMainStore();
    const {userData, clientsList} = storeToRefs(mainStore);
    if (userData.value?.role !== USER_ROLES.ADMIN) return;
    const client = clientsList.value?.find((x) => x.id === client_id);
    if (!client) return;
    return await updateUserClient(client_id);
  }
  async function updateUserClient(client_id: string): Promise<boolean> {
    const mainStore = useMainStore();
    const {userData, apiClient} = storeToRefs(mainStore);
    await dbHelper.setDataToCollection(
      "users",
      userData.value.id,
      {
        client_id,
        updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
      },
      true,
    );
    await apiClient.value.getRequest("/api/users/flush-cache");
    reloadPage();
    return true;
  }
  function reloadPage(): void {
    window.location.reload();
  }

  function redirectAfterLogin(uid: string) {
    const {status, error, redirect, userId} = storeToRefs(mainStore);

    try {
      const userPromise = loadUserData(uid);
      userId.value = uid;
      status.value = "success";
      error.value = null;
      const query = router?.currentRoute.value?.query || {};
      userPromise
        .then(() => {
          if (redirect.value?.path) {
            router.push({
              ...redirect.value,
              query: {
                ...query,
                ...(redirect.value?.query || {}),
              },
            });
          }
        })
        .catch((err) => {
          status.value = "failure";
          error.value = err.message;
        });
    } catch (err) {
      status.value = "failure";
      error.value = err.message;
    }
  }

  function signInError(credential: UserCredential): boolean {
    if (!credential.user || !credential.user.uid) return true;

    const userIdResult = credential.user.uid;
    const query = router?.currentRoute.value?.query || {};
    if (!userIdResult) {
      router.push({name: "login", query});
      logOut();

      return true;
    }
    Object.keys(query).forEach((key: string) => {
      query[key] = null;
    });
    return false;
  }

  function completeSSOSignIn(credential: UserCredential) {
    const {status, error} = storeToRefs(mainStore);
    try {
      if (signInError(credential)) return;
      const userId = credential.user.uid;
      redirectAfterLogin(userId);
    } catch (err) {
      status.value = "failure";
      error.value = err.message;
    }
  }

  function completePasswordLogin(payload: {response: UserCredential}) {
    const {apiClient, error, status} = storeToRefs(mainStore);

    try {
      const {response} = payload;
      if (signInError(response)) return;

      // No await required, on post login update user information from FB
      apiClient.value
        .postRequest("/api/users/update-from-firebase", {})
        .catch((error: Error) => {
          loggerHelper.error("/api/users/update-from-firebase", error);
        });

      // No await required, on login store latest user IP in database
      apiClient.value
        .postRequest("/api/users/check-last-login", {})
        .catch((error: Error) => {
          loggerHelper.error("/api/users/check-last-login", error);
        });

      redirectAfterLogin(response.user.uid);
    } catch (err) {
      status.value = "failure";
      error.value = err.message;
    }
  }

  async function initMicrosoftSSOSignIn(): Promise<[boolean, string]> {
    const [result, error] = await signInWithMicrosoft();
    if (error) {
      loggerHelper.error("Microsoft SSO", error);
      return Result.error(error.code);
    } else return syncMicrosoftAccount(result);
  }

  async function linkMicrosoftAccount(): Promise<[boolean, string]> {
    const [result, error] = await linkWithMicrosoft();
    if (error) {
      loggerHelper.error("Link Microsoft acount", error);
      return Result.error(error.code);
    } else return syncMicrosoftAccount(result);
  }

  async function syncMicrosoftAccount(
    userCredential: UserCredential,
  ): Promise<[boolean, string]> {
    const credential = OAuthProvider.credentialFromResult(userCredential);
    const [, error] = await oplitClient.syncMicrosoftRoles({
      accessToken: credential.accessToken,
      idToken: credential.idToken,
    });
    if (error) {
      loggerHelper.error("Microsoft sync", error);
      await logOut();
      if (error.response.status === 403)
        return [null, `${error.response.data}`];
      else if (error.response.status === 404) return [null, "user-not-found"];
      else if (error.response.status === 500) return [null, "internal-error"];
      else return [null, `backend-error-${error.code}`];
    } else {
      completeSSOSignIn(userCredential);
      return [true, null];
    }
  }

  function logIn(
    payload: {user: string; password: string},
    doLinkMicrosoftAccount: boolean,
  ) {
    const {resetPasswordNonce, status, error, mfaResolver} =
      storeToRefs(mainStore);
    const auth = getAuth();
    signInWithEmailAndPassword(getAuth(), payload.user, payload.password)
      .then((response) => {
        if (doLinkMicrosoftAccount) linkMicrosoftAccount();
        else completePasswordLogin({response});
      })
      .catch((err) => {
        if (
          err.code === "auth/internal-error" &&
          typeof err.message === "string"
        ) {
          try {
            const errorPayload = err.message.match(/{(.)+}/)[0];
            const {reason, reset_password_nonce} = JSON.parse(
              JSON.parse(errorPayload).error.message,
            );

            if (reason === "expired-password" && reset_password_nonce) {
              resetPasswordNonce.value = reset_password_nonce;
              status.value = "expiredPassword";

              return;
            }
          } catch (err) {
            loggerHelper.error(err);
          }
        }
        if (err.code == "auth/multi-factor-auth-required") {
          status.value = "mfaRequired";
          const resolver = getMultiFactorResolver(auth, err);
          mfaResolver.value = resolver;
        } else {
          status.value = "failure";
          error.value = err.message;
        }
      });
  }
  function logOut() {
    const {clearUnsubscribes} = mainStore;
    const {userId, status, error, clientsList} = storeToRefs(mainStore);
    clientsList.value = null;
    // first unsubscribe to firestore
    clearUnsubscribes();

    return signOut(getAuth())
      .then(() => {
        userId.value = null;
        status.value = "success";
        error.value = null;

        localStorage.setItem(
          LOCAL_STORAGE.USER_DATA,
          JSON.stringify({
            id: null,
          }),
        );
      })
      .catch((err) => {
        status.value = "failure";
        error.value = err.message;
      });
  }

  return {
    reloadUser,
    loadUserData,
    changeClient,
    updateUserClient,
    reloadPage,
    completeLogin: completePasswordLogin,
    logIn,
    logOut,
    initMicrosoftSSOSignIn,
  };
};
