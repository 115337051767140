<template>
  <FButton
    v-if="shouldDisplayBtn"
    :outlined="btnBgColor"
    :text-class="btnTextClasses"
    :data-testid="TEST_IDS.SIMULATION_STEPPER_BTN"
    @click="onSimulationBtnClick"
  >
    {{ btnText }}
  </FButton>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {storeToRefs} from "pinia";

import {FButton} from "@/components/Global/Homemade";

import {useMainStore} from "@/stores/mainStore";

import {
  GLOBAL_TAB_SIMULATION_MANUFACTURING,
  SIMULATION_STATUS,
  TEST_IDS,
} from "@/config/constants";

import {VueClassesArray} from "@/interfaces";

import {useSimulationStore} from "@/stores/simulationStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {usePermissionsStore} from "@/stores/permissionsStore";

export default defineComponent({
  components: {
    FButton,
  },
  setup() {
    const simulationStore = useSimulationStore();
    const {simulationStep, simulationBtnClicked} = storeToRefs(simulationStore);
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {archiveSimulation} = simulationStore;

    const mainStore = useMainStore();
    const {simulation, isScheduling, userData, isDevEnv, clientParameters} =
      storeToRefs(mainStore);

    const {selectedSimulation} = storeToRefs(useSchedulingStore());

    const {globalTabsPerRoute, setSelectedTab} = useGlobalTabs();

    return {
      simulationStep,
      simulationBtnClicked,
      archiveSimulation,
      selectedSimulation,
      TEST_IDS,
      simulation,
      isScheduling,
      userData,
      isDevEnv,
      clientParameters,
      globalTabsPerRoute,
      setSelectedTab,
      currentPermissions,
    };
  },
  computed: {
    shouldDisplayBtn(): boolean {
      const {
        clientParameters,
        isScheduling,
        selectedSimulation,
        simulation,
        currentPermissions,
        isDevEnv,
        simulationStep,
      } = this;

      if (isScheduling) {
        if (!selectedSimulation) return false;
        // VCA
        if (clientParameters.unique_scheduling_simulation && !isDevEnv)
          return false;
        if (selectedSimulation.status === SIMULATION_STATUS.ARCHIVED)
          return false;
        if (!currentPermissions.general.create_update_simulation) return false;
        return true;
      } else {
        if (!simulation) return false;
        // hide confirm button for unauthorized users
        if (
          simulationStep === 1 &&
          simulation.status !== SIMULATION_STATUS.ARCHIVED
        )
          return currentPermissions.general.create_update_simulation;
        return true;
      }
    },
    btnTextClasses(): VueClassesArray {
      if (this.simulationStep === 1) return ["text-newLayerBackground"];
      else return ["text-newMainText"];
    },
    btnBgColor(): string[] {
      if (this.simulationStep === 1) return ["newPrimaryRegular"];
      else return ["newLayerBackground"];
    },
    btnText(): string {
      if (this.isScheduling)
        // for isScheduling, the button is only displayed on active simulations
        return this.$t("Simulation.SimulationStepperBtn.validate");

      if (this.$route.name !== "simulation" || this.simulationStep === 2)
        return this.$t("Simulation.SimulationStepperBtn.see_simulation");
      else if (this.simulation.status === SIMULATION_STATUS.ARCHIVED)
        return this.$t("Simulation.SimulationStepperBtn.see_follow-up");
      else return this.$t("Simulation.SimulationStepperBtn.validate");
    },
  },
  methods: {
    onSimulationBtnClick(): void {
      const consideredSimulation = this.isScheduling
        ? this.selectedSimulation
        : this.simulation;

      if (
        this.simulationStep === 1 &&
        consideredSimulation.status !== SIMULATION_STATUS.ARCHIVED
      )
        this.archiveSimulation({simulation: consideredSimulation});
      else {
        this.simulationStep = (this.simulationStep % 2) + 1;

        /**
         * additional logic to redirect to the correct tab for clients with stocks enabled
         */
        const manufacturingTabIndex = this.globalTabsPerRoute[
          this.$route.name
        ].indexOf(GLOBAL_TAB_SIMULATION_MANUFACTURING);
        if (manufacturingTabIndex > -1)
          this.setSelectedTab(manufacturingTabIndex);
      }
    },
  },
});
</script>
