import {ref, computed} from "vue";
import {GenericObject, SchedulingOperationCardsGroup} from "@/interfaces";

function computeSchedulingGroupKey(key: GenericObject): string {
  if (!key) return "";
  const {name, secteurId, dateIso, isPast, shiftIndex} = key;
  return [name, secteurId, dateIso, isPast ? "past" : "", shiftIndex].join("_");
}

const toggledGroups = ref<GenericObject[]>([]);
const toggledKeys = computed<string[]>(() => {
  return toggledGroups.value.map(computeSchedulingGroupKey);
});

export default function useSchedulingGroups(context) {
  function isOpen(key: GenericObject) {
    return toggledKeys.value.includes(computeSchedulingGroupKey(key));
  }

  function toggleGroup(opList: SchedulingOperationCardsGroup): void {
    if (!opList?.key) return;

    if (isOpen(opList.key)) {
      toggledGroups.value = toggledGroups.value.filter(
        (group: any) => this.computeKey(group) !== this.computeKey(opList.key),
      );
    } else toggledGroups.value = [...toggledGroups.value, opList?.key];

    return context.emit("toggle-group", opList);
  }

  return {
    toggledGroups,
    isOpen,
    toggleGroup,
    computeSchedulingGroupKey,
  };
}
