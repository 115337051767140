<template>
  <v-menu :disabled="disableChange">
    <template #activator="{props: menuProps}">
      <v-tooltip location="top">
        <template #activator="{props: tooltipProps}">
          <v-img
            v-bind="{...menuProps, ...tooltipProps}"
            :width="width"
            :height="height"
            :src="computeImg(shift)"
            :class="[
              'rounded-img',
              {'cursor-pointer': !disableChange},
              className,
            ]"
            :style="{'max-width': width + 'px'}"
            cover
          />
        </template>
        <span>
          {{ $t("Simulation.shift_" + shift) }}
        </span>
      </v-tooltip>
    </template>

    <v-list>
      <v-list-item
        v-for="subshift in PARAMETERS_PLANNING_SHIFTS"
        :key="subshift"
        density="compact"
        @click="() => changeShift(subshift)"
      >
        <v-chip
          :color="getShiftColor(subshift)"
          class="status-chip cursor-pointer"
        >
          <v-img
            :src="computeImg(subshift)"
            :style="{'max-width': width + 'px'}"
            class="rounded-img mr-2"
            cover
          />
          <span>{{ $t(`Simulation.shift_${subshift}`) }}</span>
        </v-chip>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {storeToRefs} from "pinia";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {type CustomDateObject} from "@oplit/shared-module";
import {Machine, Operator, OplitShift} from "@/interfaces";
import {
  PARAMETERS_PLANNING_DEFAULT_SHIFT,
  PARAMETERS_PLANNING_SHIFTS,
  PARAMETERS_PLANNING_SHIFTS_COLOR_MAPPING,
} from "@/config/constants";
import {useParameterStore} from "@/stores/parameterStore";

import {useMainStore} from "@/stores/mainStore";
import {getAssetURL} from "@/tscript/utils/generalHelpers";

export default defineComponent({
  props: {
    operator: {
      type: Object as PropType<Operator>,
      default: () => ({} as Operator),
    },
    className: {type: String, default: ""},
    width: {type: Number, default: 22},
    height: {type: Number, default: 18},
    disableChange: {type: Boolean, default: false},
    machine: {
      type: Object as PropType<Machine>,
      default: () => ({} as Machine),
    },
    period: {
      type: Object as PropType<CustomDateObject>,
    },
  },
  inject: {
    isParameters: {
      from: "isParameters",
      default: () => false,
    },
  },
  setup() {
    const parameterStore = useParameterStore();
    const {updateDefaultPlanningModifications} = parameterStore;

    const {operators, variables, pgRefresh} = storeToRefs(useMainStore());

    return {
      updateDefaultPlanningModifications,
      operators,
      variables,
      pgRefresh,
      PARAMETERS_PLANNING_SHIFTS,
    };
  },
  computed: {
    operateur(): Operator {
      const {operator, operators} = this;
      if (!operator?.id) return {};
      if (!operators?.length) return operator;
      /**
       * to get the actual correct shift of an operator,
       * we need to retrieve its value from the store
       * which has the absolute values
       *
       * an operator object passed to this component may be retrieved from an event
       * which has a stored value for the `day_part` that is different from its current one
       */
      const match: Operator =
        operators.find((op: Operator) => op.id === operator.id) || {};
      if (!match) return operator;
      return {...operator, ...match};
    },
    shift(): OplitShift {
      return this.operateur.day_part || PARAMETERS_PLANNING_DEFAULT_SHIFT;
    },
  },
  methods: {
    async changeShift(shift: OplitShift): Promise<void> {
      const {operator, machine, period, isParameters} = this;
      if (!operator?.id || !shift) return;

      //special case for planning parameters view
      if (isParameters) {
        this.updateDefaultPlanningModifications(
          machine.id,
          machine.secteur_id,
          period.start,
          {id: operator.id, day_part: shift},
        );
      } else {
        try {
          await dbHelper.setDataToCollection(
            "operateurs",
            operator.id,
            {day_part: shift, trigger_function: true},
            true,
          );
        } catch (error) {
          this.$openSnackbar(null, null, error);
          return;
        }
      }

      //update the store
      const newOperateurs = this.operators.map((op: Operator) => ({
        ...op,
        day_part: op.id === operator.id ? shift : op.day_part,
      }));

      this.operators = newOperateurs;
    },
    computeImg(shift: OplitShift = PARAMETERS_PLANNING_DEFAULT_SHIFT) {
      return getAssetURL(`shift-${shift}.svg`);
    },
    getShiftColor(shift: OplitShift): string {
      return `new${PARAMETERS_PLANNING_SHIFTS_COLOR_MAPPING[shift]}Regular`;
    },
  },
});
</script>
