import {computed, ref, unref} from "vue";
import {throttle} from "lodash";
import {storeToRefs} from "pinia";
import router from "@/router";
import {useMainStore} from "@/stores/mainStore";
import {
  GLOBAL_TAB_SIMULATION_MANUFACTURING,
  GLOBAL_TAB_SCHEDULING_PLANNING_CALENDAR,
  GLOBAL_TAB_SCHEDULING_PLANNING_OFS,
  GLOBAL_TAB_SCHEDULING_PILOTING_IN_PROGRESS,
  GLOBAL_TAB_SCHEDULING_PILOTING_PROGRESS_TRACKING,
  GLOBAL_TAB_SCHEDULING_PLANNING_CAPACITY,
  GLOBAL_TAB_SIMULATION_DEMAND,
  GLOBAL_TAB_SIMULATION_STOCKS,
  GLOBAL_TAB_ADMIN_CLIENTS,
  GLOBAL_TAB_ADMIN_TEAMS,
  GLOBAL_TAB_ADMIN_CAPA,
  GLOBAL_TAB_ADMIN_GROUPS,
  GLOBAL_TAB_ADMIN_EMAILS,
  GLOBAL_TAB_ADMIN_ACCOUNT_MANAGER,
  GLOBAL_TABS_QUERY_PARAMETER,
  GLOBAL_TAB_ANALYTICS_PRODUCTION,
  GLOBAL_TAB_ANALYTICS_PLANNING,
  GLOBAL_TAB_ANALYTICS_LOGISTICS,
  GLOBAL_TAB_PARAMETERS_GLOBAL,
  GLOBAL_TAB_PARAMETERS_LOCAL,
  GLOBAL_TAB_PARAMETERS_IMPORTS,
  GLOBAL_TAB_ANALYTICS_CROSSING_TIMES,
} from "@/config/constants";
import {GenericObject} from "@/interfaces";

const selectedTab = ref(null);

export const useGlobalTabs = () => {
  const mainStore = useMainStore();

  const setSelectedTab = throttle(_setSelectedTab, 1000);

  const route = computed(() => router.currentRoute.value);
  const globallySelectedTab = computed({
    get: () => unref(selectedTab),
    set: setSelectedTab,
  });
  const globalTabsPerRoute = computed<GenericObject>(() => {
    const {
      clientParameters,
      hasFullAccessPDP,
      hasFullAccessScheduling,
      hasFullAccessStock,
    } = storeToRefs(mainStore);
    // SimulationWrapper.vue
    const simulation = [GLOBAL_TAB_SIMULATION_MANUFACTURING];
    if (hasFullAccessStock.value) {
      simulation.unshift(
        GLOBAL_TAB_SIMULATION_DEMAND,
        GLOBAL_TAB_SIMULATION_STOCKS,
      );
    }

    // Planning.vue
    const schedulingPlanning = [
      GLOBAL_TAB_SCHEDULING_PLANNING_CALENDAR,
      GLOBAL_TAB_SCHEDULING_PLANNING_CAPACITY,
      GLOBAL_TAB_SCHEDULING_PLANNING_OFS,
    ];

    // Piloting.vue
    const schedulingPiloting = [
      GLOBAL_TAB_SCHEDULING_PILOTING_IN_PROGRESS,
      GLOBAL_TAB_SCHEDULING_PILOTING_PROGRESS_TRACKING,
    ];
    if (clientParameters.value.unique_scheduling_simulation)
      schedulingPiloting.push(GLOBAL_TAB_SCHEDULING_PLANNING_CAPACITY);
    schedulingPiloting.push(GLOBAL_TAB_SCHEDULING_PLANNING_OFS);

    // DashboardAnalytics.vue
    const analytics = [];
    if (hasFullAccessPDP.value) analytics.push(GLOBAL_TAB_ANALYTICS_PLANNING);
    if (hasFullAccessScheduling.value) {
      analytics.unshift(GLOBAL_TAB_ANALYTICS_PRODUCTION);
      analytics.push(GLOBAL_TAB_ANALYTICS_LOGISTICS);

      if (clientParameters.value.has_analytics_crossing_times)
        analytics.push(GLOBAL_TAB_ANALYTICS_CROSSING_TIMES);
    }

    // Parametres.vue
    const parameters = [
      GLOBAL_TAB_PARAMETERS_GLOBAL,
      GLOBAL_TAB_PARAMETERS_LOCAL,
      GLOBAL_TAB_PARAMETERS_IMPORTS,
    ];

    // Admin.vue
    const admin = [
      GLOBAL_TAB_ADMIN_CLIENTS,
      GLOBAL_TAB_ADMIN_TEAMS,
      GLOBAL_TAB_ADMIN_CAPA,
      GLOBAL_TAB_ADMIN_GROUPS,
      GLOBAL_TAB_ADMIN_EMAILS,
      GLOBAL_TAB_ADMIN_ACCOUNT_MANAGER,
    ];

    return {
      admin,
      simulation,
      "scheduling-planning": schedulingPlanning,
      "scheduling-piloting": schedulingPiloting,
      dashboard: analytics,
      parameters,
    };
  });

  function _setSelectedTab(
    value: number,
    params?: {
      shouldNotUpdateRoute?: boolean;
      shouldNotCancelRequests?: boolean;
    },
  ) {
    const {query, name} = route.value || {};
    const {shouldNotUpdateRoute = false, shouldNotCancelRequests = false} =
      params || {};
    const nValue = value ?? 0;

    if (query?.[GLOBAL_TABS_QUERY_PARAMETER] === `${nValue}`) return;

    const routeCategory = Object.keys(globalTabsPerRoute.value || {}).find(
      (shortName) => (name as string)?.includes(shortName),
    );
    const routeName = globalTabsPerRoute.value?.[routeCategory]?.[nValue];

    if (!shouldNotCancelRequests) mainStore.apiClient.cancelOngoingRequests();

    selectedTab.value = nValue;

    if (!shouldNotUpdateRoute) {
      const update = {};
      if (globalTabsPerRoute.value?.parameters?.includes(routeName))
        update["name"] = routeName;

      // set a query parameter that is be used in GlobalTabs.vue* to set state.selectedTab automatically
      // *: the router object is not loaded in the store when we need to use this logic
      router.replace({
        ...update,
        query: {
          ...query,
          [GLOBAL_TABS_QUERY_PARAMETER]: `${nValue}`,
        },
      });
    }
  }
  /**
   * returns true if @constant's index within the globalTabsPerRoute matches the globallySelectedTab
   */
  function isCurrentGlobalTab(constant: string): boolean {
    const tabs = globalTabsPerRoute.value[route.value?.name as string];
    if (!tabs?.length) return false;
    return globallySelectedTab.value === tabs.indexOf(constant);
  }

  return {
    selectedTab,
    globallySelectedTab,
    globalTabsPerRoute,
    setSelectedTab,
    isCurrentGlobalTab,
  };
};
