<template>
  <v-menu close-on-content-click :min-width="250">
    <template #activator="{props}">
      <FButton
        v-bind="{...props, ...favoriteIconConfig}"
        :outlined="['newLayerBackground']"
        class="gapped-button"
        medium
        data-testid="favorites-button"
      >
        {{ $t("Commons.favorites.button_text") }}
        <template #append-icon>
          <vue-feather type="chevron-down" size="16" />
        </template>
      </FButton>
    </template>
    <div
      class="favorites-container keep-scrollbar padded-scrollbar dense-scrollbar"
    >
      <template v-if="!calculatedFavorites.length">
        <div class="no-favorites">
          <strong>{{ $t("Commons.favorites.empty_list_title") }}</strong>
          <div>
            {{ $t("Commons.favorites.empty_list_text") }}
          </div>
        </div>
      </template>
      <FavoriteRow
        v-for="sector in calculatedFavorites"
        :key="sector.id"
        :sector="sector"
        :level="1"
      />
    </div>
  </v-menu>
</template>

<script lang="ts">
import {storeToRefs} from "pinia";
import {FButton} from "@/components/Global/Homemade";
import FavoriteRow from "./FavoriteRow.vue";
import {FButtonProps, Poste} from "@/interfaces";
import {defineComponent} from "vue";

import {useMainStore} from "@/stores/mainStore";
import {getSortedDropdownOptionsList} from "@/tscript/utils/generalHelpers";

export default defineComponent({
  components: {
    FButton,
    FavoriteRow,
  },
  props: {
    // handles redirection to the simulation page upon a click on a favorite
    preventRedirect: {type: Boolean, default: false},
  },
  provide() {
    return {
      preventRedirect: this.preventRedirect,
    };
  },
  setup() {
    const mainStore = useMainStore();
    const {userData, variables, stations} = storeToRefs(mainStore);

    return {
      userData,
      variables,
      stations,
    };
  },
  computed: {
    favoriteIconConfig(): FButtonProps {
      return {
        icon: "star",
        iconSize: "14",
        iconFill: this.variables.newYellowRegular,
        iconStroke: this.variables.newYellowRegular,
      };
    },
    calculatedFavorites() {
      if (!this.userData?.favorites) return [];

      return getSortedDropdownOptionsList(
        Array.from(
          this.userData.favorites,
          ({secteur_id}: {secteur_id: string}) =>
            this.stations.find(
              (poste: Poste): boolean => poste.id === secteur_id,
            ),
        ).filter(Boolean),
        "name",
      ); // one can have favorites outside of the current environment (e.g. Oplit devs)
    },
  },
});
</script>

<style lang="scss" scoped>
.favorites-container {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: overlay;
}

.no-favorites {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  max-width: 250px;
}
</style>
