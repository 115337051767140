<template>
  <v-menu
    v-model="isShownMenu"
    location="bottom end"
    :close-on-content-click="false"
    :z-index="zIndex"
    content-class="mt-2"
  >
    <template #activator="{props}">
      <vue-feather
        v-bind="props"
        type="more-horizontal"
        class="cursor-pointer"
        size="16px"
      />
    </template>

    <div class="tag-update-menu--content-wrapper">
      <FTextField v-model="tagLabel" />

      <div class="tag-update-menu--colors-wrapper">
        <strong class="text-newSubText">
          {{ $t("Tags.color") }}
        </strong>

        <CustomColorPicker
          :colors="SCHEDULING_TAGS_AVAILABLE_COLORS"
          :current-color="tag.color_name"
          bordered
          @update:currentColor="(color) => (tagColor = color)"
        />
      </div>

      <FButton
        icon="trash-2"
        class="align-self-start"
        pink
        @click="onAskDeleteTag"
      >
        {{ $t("global.delete") }}
      </FButton>

      <FButton :disabled="isDisabledSave" filled @click="onSaveTag">
        {{ $t("global.save") }}
      </FButton>
    </div>
  </v-menu>
</template>

<script lang="ts">
import {defineComponent, PropType, toRefs, ref} from "vue";
import {storeToRefs} from "pinia";
import {FButton, FTextField} from "@/components/Global";
import {CustomColorPicker} from "@/components/Commons";
import {SCHEDULING_TAGS_AVAILABLE_COLORS} from "@/config/constants";
import {SchedulingTag} from "@oplit/shared-module";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "scheduling-tag-update-menu",
  components: {
    CustomColorPicker,
    FButton,
    FTextField,
  },
  props: {
    tag: {type: Object as PropType<SchedulingTag>, required: true},
    zIndex: {type: Number, default: 2000},
  },
  setup(props) {
    const {tag} = toRefs(props);

    const {deleteSchedulingTags, updateSchedulingTag} = useSchedulingStore();

    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);

    return {
      isShownMenu: ref<boolean>(false),
      tagLabel: ref<string>(tag.value.label),
      tagColor: ref<string>(tag.value.color_name),
      SCHEDULING_TAGS_AVAILABLE_COLORS,
      deleteSchedulingTags,
      updateSchedulingTag,
      variables,
    };
  },
  computed: {
    isDisabledSave(): boolean {
      if (!this.tagLabel) return true;
      if (!this.tagColor) return true;
      // no update made
      if (
        this.tagColor === this.tag.color_name &&
        this.tagLabel === this.tag.label
      )
        return true;
      return false;
    },
  },
  methods: {
    async onSaveTag(): Promise<void> {
      try {
        await this.updateSchedulingTag({
          ...this.tag,
          label: this.tagLabel,
          color_name: this.tagColor,
        });

        this.isShownMenu = false;
      } catch (e) {
        this.$openDialog(null, "GENERIC_ERROR");
      }
    },
    onDeleteTag(): void {
      this.deleteSchedulingTags(this.tag);
    },
    onAskDeleteTag(): void {
      this.$openDialog({
        type: "warning",
        message: this.$t("Tags.dialogs.warning_delete", {
          label: this.tag.label,
        }),
        action: this.onDeleteTag,
      });
    },
  },
});
</script>

<style lang="scss">
.tag-update-menu--content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .tag-update-menu--colors-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
