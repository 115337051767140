<template>
  <div :class="['f-modal-footer', footerWrapperClass]">
    <slot name="prepend-actions" />

    <FButton
      data-cy="btn-close-f-modal"
      @click="closeModal"
      outlined
      large
      :disabled="isOperationOngoing"
    >
      {{ defaultedCancelButtonText }}
    </FButton>

    <FButton
      v-if="!hideConfirmButton"
      filled
      large
      :disabled="isOperationOngoing || !canConfirmModal"
      :loading="isOperationOngoing"
      data-cy="btn-confirm-f-modal"
      @click="confirmModal"
    >
      {{ defaultedConfirmButtonText }}
    </FButton>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {FButton} from "@/components/Global/Homemade/Buttons";
import {useModal} from "@/composables/useModal";

export default defineComponent({
  props: {
    confirmButtonText: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: null,
    },
    canConfirmModal: {type: Boolean, default: true},
    isOperationOngoing: {type: Boolean, default: false},
    footerWrapperClass: {type: [String, Object, Array]},
    hideConfirmButton: {type: Boolean, default: false},
  },
  emits: ["close-modal", "confirm-modal"],
  setup(props, context) {
    const {closeModal, confirmModal} = useModal(context);
    return {
      closeModal,
      confirmModal,
    };
  },
  components: {
    FButton,
  },
  computed: {
    defaultedConfirmButtonText(): string {
      return this.confirmButtonText || this.$t("Simulation.Valider");
    },
    defaultedCancelButtonText(): string {
      return this.cancelButtonText || this.$t("Simulation.Annuler");
    },
  },
});
</script>

<style lang="scss">
.f-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: calc(var(--g-modal-spacing) / 2);
  padding: var(--g-modal-spacing);
}
</style>
