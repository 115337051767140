const collections = [
  // {dataName: "charge", displayName: "Charge"},
  // {dataName: "capa", displayName: "Capacité"},
  // {dataName: "realise", displayName: "Réalisé"},
  {dataName: "ofs", displayName: "OFs"},
  {dataName: "en_cours", displayName: "En cours"},
  {dataName: "production", displayName: "Production"},
];

const standardGraphGroupOptions = [
  // Default params
  {label: "PDC", field: "pdc"},
  {label: "Statut de l'opération", field: "op_status"},
  {label: "Référence article", field: "ref_article"},
  {label: "Num OF", field: "of_id"},
  // VDR
  {
    field: "of_graph_status",
    label: "Statut de l'OF",
  },
  {
    field: "type_charge",
    label: "Type charge",
  },
  {
    field: "_reference",
    label: "Référence",
  },
  {
    field: "num_boucle",
    label: "Numéro de boucle",
  },
  {
    field: "ligne_produit",
    label: "Ligne de produit",
  },
  {field: "forme_tete", label: "Forme de tête"},
  {field: "statut_composant", label: "Statut composant"},
  {field: "longueur_sous_tete", label: "Longueur sous tête"},
  {field: "texte_operation", label: "Texte opération"},
  {field: "charge_temps_poids", label: "Charge temps/poids"},
  {field: "diam_depart", label: "Diam. départ"},
  // Démo
  {
    field: "priorite",
    label: "Priorité",
  },
  {
    field: "statut_op",
    label: "Statut OP",
  },
  // Nexteam BU AT
  {field: "id_article", label: "Id Article"},
  {field: "libelle_art", label: "Libelle Art"},
  {field: "ref", label: "REF"},
  {label: "Famille", field: "famille"},
  {
    field: "famille_pic",
    label: "Famille PIC",
  },
  {
    field: "famille_planif",
    label: "Famille Planif",
  },
  {
    field: "article",
    label: "Article",
  },
  {
    field: "phase",
    label: "Phase",
  },
  {
    field: "statut_phase",
    label: "Statut Phase",
  },
  {
    field: "statut_ordre",
    label: "Statut Ordre",
  },
  // Nexteam BU Services
  {
    field: "poste_de_travail",
    label: "Poste de travail",
  },
  {
    field: "ordre",
    label: "Ordre",
  },
  {
    field: "gestionnaire",
    label: "Gestionnaire",
  },
  {field: "gestionnaire_mrp", label: "Gestionnaire MRP"},
  {field: "type_avion", label: "Type Avion"},
  {field: "article_reference", label: "Article - Référence"},
  {field: "article_libelle", label: "Article - Libellé"},
  {field: "clients", label: "Clients"},
  {field: "of_statut_global", label: "OF - Statut Global"},
  // Nexteam BU Engine
  {field: "statutop", label: "Statut OP"},
  {field: "statutcde", label: "Statut Commande"},
  {field: "qte_rebutee", label: "Quantité rebutée"},
  {field: "n_of", label: "N° OF"},
  // Nexteam BU Equipments Olemps
  {field: "customer", label: "Client"},
  // LISI COI
  {
    field: "article_type",
    label: "Article type",
  },
  {
    field: "material_availability",
    label: "Material Availability",
  },
  // LISI BAR
  {
    field: "donneur_ordre",
    label: "Donneur Ordre",
  },
  {
    field: "client",
    label: "Client",
  },
  {
    field: "ref_compl",
    label: "Référence",
  },
  {
    field: "statut_ope",
    label: "Statut Opération",
  },
  {
    field: "groupe_accord",
    label: "Groupe Accord",
  },
  // J3L
  {
    field: "site_de_production",
    label: "Site de production",
  },
  // Janson
  {label: "Semaine", field: "semaine"},
  {label: "Type", field: "type"},
  {
    field: "segmentation_group",
    label: "Segmentation",
  },
  // ADDEV, demo
  {field: "ferme_vs_prev", label: "Ferme vs. Prévisionnel"},
  {field: "lien_avec_commande", label: "Lien avec commande"},
  {field: "statut_mp", label: "Statut MP"},
  //Mostly for Tecalemit
  {field: "statut_2", label: "Statut 2"},
  {field: "refaffdes", label: "Programme client"},
  {field: "refcust", label: "Référence client"},
  //Mostly for Horlyne
  {field: "client_nom", label: "Nom Client"},
  //VCA
  {label: "Code Article", field: "code_article"},
  //Mostly for Varinor
  {field: "typologie", label: "Typologie"},
  {field: "masse_bd_est", label: "Masse BD Est."},
  {field: "alliages", label: "Alliages"},
  {field: "dimensions", label: "Dimensions"},
  {field: "n_coulee", label: "N° Coulée"},
  {field: "racine_of", label: "Racine OF"},
  {field: "couleur", label: "Couleur"},
  {field: "prio", label: "Prio"},
];

// Helps excluding grouping options from components that take
// ofTooltipMatch as a source
const ofGroupsToExclude = [
  // Janson
  "segmentation",
].sort();

// Each entry needs to be added to en.json and fr.json under Load.label
const ofTooltipToTranslate = [
  "datelancement",
  "is_late",
  "quantite",
  "refcust",
  "ref_article",
  "op_status",
  "refaffdes",
  "pdc",
];

export {
  collections,
  ofTooltipToTranslate,
  ofGroupsToExclude,
  standardGraphGroupOptions,
};
