<template>
  <nav v-if="shouldDisplayNavbar">
    <div v-if="shouldDisplayTopNavbar" :class="getNavbarClasses">
      <h3
        v-if="currentPage.title"
        class="font-weight-bold"
        data-testid="current-page-title"
      >
        {{ $t(`Navbar.${currentPage.title}`) }}
      </h3>

      <GlobalTabs v-if="!isRouteAny(['customer-interface'])" />

      <v-spacer />

      <template v-if="isOplitAdmin">
        <ClientGroupDropdown
          v-if="displayAdminClientGroupDropdown"
          :group-list="clientsList"
          @change="(id: string) => updateUserClient(id)"
        />

        <template v-if="isParametersRoute">
          <FDropdown
            v-if="msdListByClient.length > 0"
            v-model="activeMsd"
            :items="msdListByClient"
            :outlined="['newLayerBackground']"
            btn-class="gapped-button"
            input
          />
          <FButton v-else outlined @click="createMSD">
            {{ $t("Parameters.lists.create_new_msd") }}
          </FButton>
          <FDropdown
            v-if="false"
            type="other"
            prepend-icon="more-vertical"
            square
            outlined
            icon
          >
            <v-list min-width="200">
              <v-list-item
                class="cursor-pointer"
                @click="archiveCurrentParameters"
              >
                {{ $t("Parameters.lists.archive_current_parameters") }}
              </v-list-item>
            </v-list>
          </FDropdown>
        </template>
      </template>

      <CustomerInterfaceNavbar v-if="isRouteAny(['customer-interface'])" />

      <template v-if="displayNavbarActions">
        <ClientGroupDropdown
          v-if="isGroupAdmin || isOplitAdmin"
          :group-list="clientsList"
          @change="(id: string) => updateUserClient(id)"
        />
        <SimulationDropdown />
        <SimulationStepperBtn />
        <FButtonIcon
          v-if="isTablet"
          large
          icon="refresh-ccw"
          :tooltip="$t('App.reload')"
          @click="reloadPage"
        />
        <WrapperSetting />
      </template>
    </div>

    <div v-if="isParametersBySectorRoute" :class="getNavbarClasses">
      <SectorBreadcrumbs />
      <FavoriteDropdown prevent-redirect />
      <LowestLevelSectorHelper />
    </div>
  </nav>
</template>

<script lang="ts">
import {storeToRefs} from "pinia";
import _ from "lodash";

import GlobalTabs from "@/components/Navigation/GlobalTabs.vue";
import {FButtonIcon} from "@/components/Global/Homemade";
import {FDropdown} from "@/components/Global/Homemade";
import {FavoriteDropdown, LowestLevelSectorHelper} from "@/components/Commons";
import SectorBreadcrumbs from "@/components/Navigation/SectorBreadcrumbs.vue";
import SimulationDropdown from "@/components/Simulation/SimulationDropdown.vue";
import SimulationStepperBtn from "@/components/Simulation/SimulationStepperBtn.vue";
import WrapperSetting from "@/components/Simulation/WrapperSetting.vue";
import ClientGroupDropdown from "@/components/Admin/ClientGroupDropdown.vue";
import CustomerInterfaceNavbar from "../CustomerInterface/CustomerInterfaceNavbar.vue";
import {useUserStore} from "@/stores/userStore";
import {
  IClientDoc,
  type VueClassesArray,
  OpenDialogFunction,
} from "@/interfaces";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {getParametersRouteChangePreventCondition} from "@/tscript/utils/parameters";
import {levelCorresp} from "@oplit/shared-module";
import {defineComponent, ref, inject, computed} from "vue";
import {useI18n} from "vue-i18n";
import moment from "moment";

import {useMainStore} from "@/stores/mainStore";
import {useParameterStore} from "@/stores/parameterStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useConnexionUser} from "@/composables/useConnexionUser";
import {useRouteAssertions} from "@/composables/useRouteAssertions";
import {FButton} from "@/components/Global";

export default defineComponent({
  name: "nav-bar",
  props: [],
  components: {
    FavoriteDropdown,
    FButtonIcon,
    SimulationStepperBtn,
    SimulationDropdown,
    GlobalTabs,
    WrapperSetting,
    SectorBreadcrumbs,
    LowestLevelSectorHelper,
    ClientGroupDropdown,
    FDropdown,
    FButton,
    CustomerInterfaceNavbar,
  },
  provide() {
    return {
      /**
       * shared logic to prevent changing sector on parameters "by sector" pages when there are unsaved changes
       */
      beforeChange: this.onBeforeParametersSectorChange,
    };
  },
  setup() {
    const openDialog = inject<OpenDialogFunction>("openDialog");
    const {locale, t} = useI18n();

    const {isOplitAdmin, isGroupAdmin} = useUserStore();
    const parameterStore = useParameterStore();
    const {parametersView, msdListByClient} = storeToRefs(parameterStore);
    const {
      loadParametersSchedulingDisplay,
      loadParametersColorsCategories,
      fetchMSDbyClient,
      createMSD,
    } = parameterStore;
    const {isPiloting} = storeToRefs(useSchedulingStore());

    const mainStore = useMainStore();
    const {
      isSchedulingFullScreen,
      breadcrumbs,
      clientsList,
      simulation,
      isDevEnv,
      userData,
      isScheduling,
      clientParameters,
      team,
      hasStock,
      sectorTree,
      stations,
      activeMsd,
      apiClient,
    } = storeToRefs(mainStore);
    const {
      loadPerimeters,
      loadTeams,
      loadOperators,
      loadMachines,
      loadClientParameters,
      loadUserParameters,
      setBreadcrumbs,
    } = mainStore;
    const {updateUserClient, reloadPage, logOut} = useConnexionUser();

    fetchMSDbyClient();

    function archiveCurrentParameters() {
      openDialog({
        type: "warning",
        message: t(`Simulation.check_current_version`),
        hidePrefix: true,
        header: t(`global.attention`),
        cancelText: t(`global.cancel`),
        validateText: t(`global.valider`),
        action: createMSD,
      });
    }

    const {isRouteAny, isParametersRoute, isParametersBySectorRoute} =
      useRouteAssertions();

    const displayAdminClientGroupDropdown = computed<boolean>(
      () =>
        isParametersRoute.value ||
        isRouteAny(["dashboard", "list-absolute-ofs"]),
    );

    return {
      leftMini: ref<boolean>(true),
      leftDrawer: ref<boolean>(false),
      langs: ref<unknown[]>([]),
      avatar: ref<string>("src/avatar.png"),
      selectedLang: ref<string>(locale.value),
      isClientAdmin: ref<boolean>(false),
      client_logo_url: ref<string>(""),
      hover: ref<boolean>(false),
      isLastMenuOpen: ref<boolean>(false),
      selectedClient: ref<IClientDoc>(null),
      switchIsLoading: ref<boolean>(false),
      // this value should be modified with its scss counterpart (constants.scss)
      leftSideBarMiniWidth: 68,
      isTablet: "ontouchstart" in document.documentElement,
      isOplitAdmin,
      isGroupAdmin,
      isSchedulingFullScreen,
      breadcrumbs,
      clientsList,
      simulation,
      isDevEnv,
      userData,
      isScheduling,
      clientParameters,
      team,
      hasStock,
      sectorTree,
      stations,
      activeMsd,
      loadPerimeters,
      loadTeams,
      loadOperators,
      loadMachines,
      loadClientParameters,
      loadUserParameters,
      logOut,
      parametersView,
      loadParametersSchedulingDisplay,
      loadParametersColorsCategories,
      isPiloting,
      setBreadcrumbs,
      msdListByClient,
      archiveCurrentParameters,
      createMSD,
      updateUserClient,
      reloadPage,
      apiClient,
      displayAdminClientGroupDropdown,
      isParametersRoute,
      isParametersBySectorRoute,
      isRouteAny,
    };
  },
  computed: {
    getNavbarClasses(): VueClassesArray {
      return [
        {"has-bottom-padding": this.hasBottomPadding},
        {"bottom-bordered": this.hasBottomPadding},
        "app-bar",
      ];
    },
    currentPage() {
      return {
        title: this.$route.meta?.title || this.$route.matched?.[0]?.meta.title,
        icon: this.$route.meta.icon || "grid",
      };
    },
    /**
     * handles whether or not the app-bar has bottom padding (subnavigation-ish present on the page)
     */
    hasBottomPadding(): boolean {
      const routes = ["scheduling-planning", "scheduling-piloting"];
      if (this.hasStock) routes.push("simulation");

      return !routes.includes(this.$route.name);
    },
    lastBreadcrumb() {
      if (!this.breadcrumbs?.length) return false;
      const [lastItem] = this.breadcrumbs.slice(-1);
      return lastItem;
    },
    updatedBreadcrumbs() {
      const updatedBreadcrumbs = this.breadcrumbs
        .filter((i: any) =>
          this.team && !this.isDevEnv ? i.level >= this.team.level : i,
        )
        .map((b: any) => {
          if (!b?.collection) return b;
          const match =
            this.perimeters[b.collection]?.find((x: any) => x?.id === b.id) ||
            {};
          return {
            ...b,
            ...match,
            children: (match.children || []).filter(
              (c: any) => !["removed", "deleted"].includes(c.status),
            ),
          };
        });
      return updatedBreadcrumbs;
    },
    shouldDisplayTopNavbar() {
      const {isSchedulingFullScreen} = this;

      if (this.$route.meta.hideTopNavbar) return false;

      return !(
        ["scheduling-piloting", "scheduling-planning"].includes(
          this.$route.name,
        ) && isSchedulingFullScreen
      );
    },
    shouldDisplayNavbar() {
      return !(this.$route?.fullPath || "").includes("/frames");
    },
    displayNavbarActions(): boolean {
      return [
        "scheduling-planning",
        "scheduling-piloting",
        "simulation",
      ].includes(this.$route.name);
    },
  },
  created() {
    const {userData} = this;
    if (!userData || !userData.client_name || !userData.client_id) return;

    this.selectedClient = {name: userData.client_name, id: userData.client_id};
  },
  watch: {
    selectedLang: function (langIdx: number) {
      if (this.langs && this.langs.length > 0) {
        const langIsoObj: any = this.langs[langIdx];
        this.$i18n.locale = langIsoObj?.langISO;
        this.renderMenus(); //Generate the menus
      }
    },
    userData: {
      immediate: true,
      handler: function (val: any) {
        if (!val) return false;
        this.loadData(val);
      },
    },
    selectedClient: async function (client) {
      const {userData = {}} = this;
      if (client && userData.client_id !== client.id) {
        const newUserData = {
          ...this.userData,
          client_id: client.id,
          client_name: client.name,
          client_logo_url: client.logo_url,
          slug: client.slug,
        };
        this.userData = newUserData;
        this.loadPerimeters(newUserData);
        this.loadTeams(newUserData.client_id);
        this.loadOperators(newUserData.client_id);
        this.loadMachines(newUserData.client_id);
        this.loadClientParameters(newUserData.client_id);
        this.loadUserParameters({
          user_id: newUserData.client_id,
          client_id: newUserData.id,
        });

        //We fetch site data
        const [siteResult] = await dbHelper.getAllDataFromCollectionWithAll(
          "sectors",
          {
            where: [
              {
                field: "client_id",
                value: newUserData.client_id,
                compare: "==",
              },
              {
                field: "type",
                value: "site",
                compare: "==",
              },
            ],
          },
        );
        if (siteResult) {
          this.setBreadcrumbs([
            {
              name: siteResult.name,
              id: siteResult.id,
              disabled: true,
              ...levelCorresp[0],
            },
          ]);
        }
      }
    },
    isScheduling: {
      immediate: true,
      handler(newIsScheduling: boolean) {
        const routeName = this.$route.name;
        if (!routeName) return;
        const query = this.$route.query;
        // TODO: fix the route handling in the long run
        const unauthorizedSchedulingRoutesPattern = /home|simulation|login/g;
        const userHasPDP: boolean = this.userData?.has_production_planning;
        const userHasScheduling: boolean = this.userData?.has_scheduling;
        // if user has pdp & scheduling activated, we don't take any action unless the page is the login
        if (userHasScheduling && userHasPDP && !routeName.includes("login"))
          return;

        if (
          newIsScheduling &&
          routeName.match(unauthorizedSchedulingRoutesPattern)
        )
          this.$router.push({name: "scheduling-planning", query});
        else if (
          !newIsScheduling &&
          (routeName.includes("scheduling") || routeName.includes("login"))
        )
          this.$router.push({name: "home", query});
      },
    },
    /**
     * initial call for `parametersSchedulingDisplay`, `parametersColorsCategories`, `parametersCalculusRules` populate
     * this is done as such because it needs userData to be populated and because it needs to be reactive upon the current `team`
     */
    team: {
      immediate: true,
      handler: function () {
        this.loadParametersColorsCategories();
        if (this.userData?.has_scheduling)
          this.loadParametersSchedulingDisplay();
      },
    },
  },
  methods: {
    /**
     * preventing an update of breadcrumbs for the parameters if there are unsaved changes\
     * is used for the "by sector" parameters pages where users are able to change the route object
     * (that triggers a logic similar to this one, see beforeRouteUpdate in Parametres)
     * through the SectorBreadcrumbs/FavoriteRow components
     */
    async onBeforeParametersSectorChange(): Promise<boolean> {
      if (getParametersRouteChangePreventCondition()) return true;

      return new Promise((resolve) => {
        this.$openDialog(
          {
            action: () => {
              resolve(true);
            },
            onCancelClicked: () => {
              resolve(false);
            },
          },
          "PARAMETERS_ASK_UNSAVED",
        );
      });
    },
    loadData(userData: any) {
      this.client_logo_url = _.get(userData, "client_logo_url", "");
      this.renderMenus();
    },
    getLanguageData() {
      const languageData = [
        {
          langISO: "fr",
          langCode: "fr",
          text: this.$t("navbar.french"),
        },
        {
          langISO: "en",
          langCode: "gb",
          text: this.$t("navbar.english"),
        },
      ];
      return languageData;
    },
    logout() {
      this.logOut().then(() => {
        const query = this.$route?.query || {};
        this.$router.push({name: "login", query});
      });
    },
    renderMenus() {
      this.langs = this.getLanguageData();
    },
    goToProfile() {
      this.$router.push({
        name: "profile",
        query: this.$route?.query || {},
        params: {id: this.userData.id, redirect: false},
      });
    },
    async changeSite(id: string): Promise<void> {
      await dbHelper.setDataToCollection(
        "users",
        this.userData.id,
        {
          client_id: id,
          updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
        },
        true,
      );
      this.reloadPage();
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/colors.scss";
@import "@/scss/constants.scss";

.nav-block a {
  text-decoration: none;
  color: inherit;
  margin: auto 20px;
}

.app-bar {
  width: calc(100% - $leftSideBarMiniWidth);
  max-width: calc(100% - $leftSideBarMiniWidth);
  overflow-x: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgb(var(--v-theme-newLayerBackground));
  margin-left: auto;
  padding: var(--g-vertical-spacing) var(--g-horizontal-spacing);

  &:not(.has-bottom-padding) {
    padding-bottom: 0;
  }

  & h3 ~ .breadcrumbs-wrapper {
    position: relative;
    margin-left: 16px;
    padding-left: 8px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: rgb(var(--v-theme-newMainText));
    }
  }

  &.bottom-bordered {
    border-bottom: 1px solid rgb(var(--v-theme-newSelected));
  }
}

.cache {
  z-index: 10;
}
.message {
  margin: auto;
}
.toolbar-items {
  margin-right: 50px;
}

.v-breadcrumbs__item .v-breadcrumbs__item--disabled {
  font-weight: bold;
}

.v-breadcrumbs .v-breadcrumbs__item.v-breadcrumbs__item--disabled {
  font-weight: bold;
}

.env-span {
  font-weight: bold;
  background-color: white;
  color: rgb(var(--v-theme-newPrimaryDark2));
  padding: 2px;
  border-radius: 8px;

  &-prod {
    animation-name: warning-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    border: 1px solid red;
    color: red;
    font-weight: bold;
  }
}

@keyframes warning-animation {
  20% {
    font-size: 14px;
  }
  35% {
    font-size: 18px;
  }
  50% {
    font-size: 14px;
  }
  65% {
    font-size: 18px;
  }
  80% {
    font-size: 14px;
  }
}
</style>
