import {App, computed} from "vue";
import loggerHelper from "@/tscript/loggerHelper";

export default {
  install: (app: App, options) => {
    const segmentMock = {
      page: (message, value) =>
        loggerHelper.log(">> segment page", message, value),
      track: (message, value) =>
        loggerHelper.log(">> segment track", message, value),
      group: (message, value) =>
        loggerHelper.log(">> segment group", message, value),
      identify: (id, obj) =>
        loggerHelper.log(">> segment identify %s", id, obj),
    };
    const {key} = options;

    function initAnalytics(key) {
      const analytics = (window.analytics = window.analytics || []);
      if (analytics.initialize) return analytics;

      if (analytics.invoked) {
        loggerHelper.error("Segment snippet included twice.");
        return analytics;
      }

      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ];
      analytics.factory = function (e) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        const t = document.createElement("script");
        t.type = "text/javascript";
        t.async = !0;
        t.src =
          "https://cdn.segment.com/analytics.js/v1/" +
          key +
          "/analytics.min.js";
        const n = document.getElementsByTagName("script")[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = key;
      analytics.SNIPPET_VERSION = "4.15.2";
      analytics.load(key);
      analytics.page();
      return analytics;
    }

    if (import.meta.env.PROD) initAnalytics(key);

    const segment = computed(() =>
      import.meta.env.PROD ? window.analytics : segmentMock,
    );

    app.config.globalProperties.$segment = segment;
    app.provide("segment", segment);
  },
};
