<template>
  <div class="d-flex flex-column gap-6">
    <label
      v-if="showLabel"
      class="fbody-1 text-newSubText font-weight-bold"
      data-testid="form-simulations-select-label"
    >
      {{ label }}
    </label>

    <v-select
      v-model="selectedSimulation"
      :placeholder="$t('Simulation.select_simulation')"
      :items="computedSimulations"
      item-title="nameWithoutTags"
      item-value="id"
      density="compact"
      variant="outlined"
      hide-details
      return-object
      data-testid="form-simulations-select"
      @update:model-value="
        (simulation) => $emit('update-selected-simulation', simulation)
      "
    >
      <template v-slot:item="{item, props}">
        <div v-bind="props" class="fd-flex-center text-newSubText gap-4">
          <v-list-item density="compact">
            <vue-feather
              v-if="item.raw.created_at"
              type="file"
              tag="span"
              class="mr-2"
            />
            <span :data-testid="item.raw.id" class="cursor-pointer">
              {{ item.raw.created_at ? item.raw.name : $t("global.no") }}
            </span>
          </v-list-item>
        </div>
      </template>
    </v-select>

    <v-btn
      v-if="isDevEnv && !isScheduling"
      @click="onRemigrateEventsClick"
      width="256"
    >
      Force remigrate events
    </v-btn>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {
  TAG_SEPARATOR,
  REGEXP_ESCAPED_TAG_SEPARATOR,
  FORM_SIMULATIONS_SELECT_EMPTY,
} from "@/config/constants";
import {Simulation} from "@/interfaces";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";

import {useMainStore} from "@/stores/mainStore";

const defaultSimulation: {id: string} = {
  id: FORM_SIMULATIONS_SELECT_EMPTY,
};

export default defineComponent({
  props: {
    showLabel: {type: Boolean, default: true},
    label: {
      type: String,
      default: () => "",
    },
    simulations: {type: Array, required: true},
    hideDefaultOption: {type: Boolean, default: false},
    shouldIncludeUap: {type: Boolean, default: false},
  },
  setup(props) {
    const {t} = useI18n();
    const labelValue = computed(
      () => props.label || t("Simulation.migrate_events"),
    );

    const mainStore = useMainStore();
    const {isScheduling, teams, isDevEnv} = storeToRefs(mainStore);

    const selectedSimulation = ref<Simulation | null>(null);

    return {
      isScheduling,
      teams,
      isDevEnv,
      labelValue,
      selectedSimulation,
      TAG_SEPARATOR,
    };
  },
  computed: {
    computedSimulations(): Simulation[] {
      const simulationsList = [...this.simulations];
      if (!this.hideDefaultOption) simulationsList.unshift(defaultSimulation);
      return Array.from(
        simulationsList,
        (
          simulation: Simulation,
          index: number,
        ): Simulation & {nameWithoutTags: string} => ({
          ...simulation,
          nameWithoutTags:
            // if hiding the default option, we never display the i18n value below
            this.hideDefaultOption || index > 0
              ? simulation.name?.replace(
                  new RegExp(`${REGEXP_ESCAPED_TAG_SEPARATOR}.+$`, "g"),
                  "",
                )
              : this.$t("global.no"),
        }),
      );
    },
  },
  mounted() {
    // select the latest simulation by default (the 0th index being defaultSimulation)
    this.selectedSimulation = this.computedSimulations.at(
      this.hideDefaultOption ? 0 : 1,
    );
    // updates components invoking this component
    this.$emit("update-selected-simulation", this.selectedSimulation);
  },
  methods: {
    /**
     * the logic behind the events remigration has been kept within SimulationForm
     * to keep this component light and because this logic is only called at one specific point
     */
    onRemigrateEventsClick(): void {
      this.$emit("remigrate-events");
    },
  },
});
</script>
