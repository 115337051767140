import {App, h, render} from "vue";
import SimulationFormModal from "@/components/Simulation/Modals/SimulationFormModal.vue";

/**
 * opens the SimulationForm as a modal (v-dialog)
 * invoke with this.$openSimulationModal(...)
 * @isOld : a boolean determining if we want to create or update a simulation
 */
const OplitSimulationModal = {
  install(app: App) {
    app.config.globalProperties.$openSimulationModal = function (
      isOld: boolean,
    ) {
      const container = document.createElement("div");
      document.body.appendChild(container);

      const simulationModalInstance = h(SimulationFormModal, {
        isOld: !!isOld,
      });
      simulationModalInstance.appContext = app._context;

      render(simulationModalInstance, container);
    };
  },
};

export default OplitSimulationModal;
