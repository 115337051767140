<template>
  <v-menu
    v-model="value"
    :activator="activator"
    :close-on-content-click="false"
    @update:model-value="initializeColor"
  >
    <div class="custom-color-definer">
      <CustomColorPicker
        :colors="colors"
        v-model:current-color="selectedColor"
      />

      <FButton
        filled
        :disabled="selectedColor === originalColor"
        @click="onSaveClick"
      >
        {{ $t("global.save") }}
      </FButton>
    </div>
  </v-menu>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {storeToRefs} from "pinia";
import {FButton} from "@/components/Global";
import CustomColorPicker from "./CustomColorPicker.vue";
import type {SchedulingColor} from "@oplit/shared-module";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";

/**
 * this component was made to be used through the defineColor directive (v-define-color)
 * see details in @/plugins/custom/defineColor.ts
 */
export default defineComponent({
  name: "custom-color-definer",
  components: {FButton, CustomColorPicker},
  props: {
    activator: {type: HTMLDivElement, default: null as HTMLDivElement},
    parameters: {type: Object as PropType<SchedulingColor>, required: true},
  },

  setup() {
    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);
    const {updateDailySchedulingColor} = useSchedulingStore();
    const {dailySchedulingColors} = storeToRefs(useSchedulingStore());
    const selectedColor = ref(null);
    /**
     * original color prior to modifying
     * used to compare against `selectedColor` for deletion
     */
    const originalColor = ref(null);
    const value = ref(true);

    return {
      updateDailySchedulingColor,
      dailySchedulingColors,
      selectedColor,
      originalColor,
      value,
      variables,
    };
  },
  computed: {
    /**
     * list of available colors
     */
    colors(): string[] {
      return [
        "newGreenRegular",
        "newPrimaryRegular",
        "newPurpleRegular",
        "newPinkRegular",
        "newOrangeRegular",
        "newDisableText",
        "newMainText",
      ];
    },
  },
  created() {
    this.initializeColor();
  },
  methods: {
    async onSaveClick(): Promise<void> {
      await this.updateDailySchedulingColor({
        ...this.parameters,
        color_name: this.selectedColor,
      });

      this.value = false;
    },
    initializeColor(): void {
      const {day_date} = this.parameters;
      const {color_name = null} =
        this.dailySchedulingColors?.find(
          (dailySchedulingColor: SchedulingColor) =>
            dailySchedulingColor.day_date === day_date,
        ) || {};
      this.selectedColor = this.originalColor = color_name;
    },
  },
});
</script>

<style scoped lang="scss">
.custom-color-definer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;

  & > div:first-child {
    & .selected {
      position: relative;
      overflow: visible;

      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        left: 50%;
        top: 50%;
        background: transparent;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(var(--v-theme-newPrimaryRegular));
        border-radius: 50%;
      }
    }
  }
}
</style>
