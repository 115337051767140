<script setup lang="ts">
import {computed} from "vue";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import {SchedulingOperation} from "@/interfaces";

interface SchedulingOperationQuantityProgressProps {
  operation: SchedulingOperation;
}

const props = defineProps<SchedulingOperationQuantityProgressProps>();

const {operationComputedQuantity} = useComputeQuantityUnit();

const operationProducedQuantity = computed(() =>
  Math.min(
    props.operation.quantity_produced || 0,
    props.operation.quantite_of || 0,
  ),
);
const operationTotalQuantity = computed(
  () => props.operation.quantite_of ?? props.operation.quantite ?? 0,
);
const operationProgressRate = computed(() => {
  if (!operationProducedQuantity.value || !operationTotalQuantity.value)
    return 0;
  return Math.min(
    100,
    (operationProducedQuantity.value * 100) / +operationTotalQuantity.value,
  );
});
const pendingLayoutTooltipText = computed(() => {
  const {unite, unite_of} = operationComputedQuantity(props.operation);
  return `${operationProducedQuantity.value} / ${
    operationTotalQuantity.value
  } ${unite_of || unite}`;
});
</script>

<template>
  <div
    v-if="operationProducedQuantity > 0"
    v-tooltip.end="pendingLayoutTooltipText"
    class="scheduling-operation-quantity-progress"
  >
    <div class="scheduling-operation-quantity-progress__total">
      <div
        :style="{
          width: `${operationProgressRate}%`,
        }"
        class="scheduling-operation-quantity-progress__current"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.scheduling-operation-quantity-progress {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 4px;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  display: flex;
  overflow: hidden;

  & .scheduling-operation-quantity-progress__total {
    position: relative;
    width: 100%;
    height: 4px;
    display: inline-block;
    background-color: rgb(var(--v-theme-newDisableBG));
  }

  & .scheduling-operation-quantity-progress__current {
    position: absolute;
    left: 0;
    top: -1px;
    height: inherit;
    display: inline-block;
    background-color: rgb(var(--v-theme-newPrimaryRegular));
  }
}
</style>
