import {computed, ref, unref, watchEffect} from "vue";
import {storeToRefs} from "pinia";
import {levelCorresp} from "@oplit/shared-module";
import _ from "lodash";

import {useMainStore} from "../stores/mainStore";
import {MaybeRef, Perimeters} from "@/interfaces";

export const useOverloadedPerimeters = () => {
  const mainStore = useMainStore();
  const {perimeters} = storeToRefs(mainStore);

  const overloadedPerimeters = ref({});

  const highestLevel = computed<{
    type: string;
    level: number;
    collection: string;
    displayName: string;
  }>(() => {
    return (
      levelCorresp.find((level: any, i: number) => {
        if (i === 0) return false;
        return overloadedPerimeters.value[level.collection]?.length;
      }) || null
    );
  });

  const toggle = async function (secteur: any, level: any, field = "open") {
    const secteurs = _.get(overloadedPerimeters.value, secteur.collection, []);
    const newSecteurs = secteurs.map((x: any) => ({
      ...x,
      [field]: x.id === secteur.id ? !x[field] : false,
    }));
    overloadedPerimeters.value[secteur.collection] = newSecteurs;
  };

  const overloadPerimeters = (perimetres: MaybeRef<Perimeters>) => {
    const overloadedPerimetersTempObj = {};
    for (const level in unref(perimetres)) {
      overloadedPerimetersTempObj[level] = unref(perimetres)
        [level].map((x: any, i: number) => ({
          ...x,
          open: _.get(unref(overloadedPerimeters), [level, i, "open"], false),
          isFavOpen: false,
          active: _.get(
            unref(overloadedPerimeters),
            [level, i, "active"],
            false,
          ),
          isFavorite: false,
          selected: false,
        }))
        .filter((x: any) => !["removed", "deleted"].includes(x.status));
    }
    return overloadedPerimetersTempObj;
  };

  watchEffect(() => {
    if (!perimeters || !perimeters.value.sites) return false;
    overloadedPerimeters.value = overloadPerimeters(perimeters);
  });

  return {
    highestLevel,
    toggle,
    overloadPerimeters,
    overloadedPerimeters,
  };
};
