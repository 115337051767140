<template>
  <v-chip
    close-icon="mdi-close"
    :class="{
      [CSS_SCHEDULING_OPERATION_PRIORITY_CHIP_CLASS]: true,
      'chip-large': large,
      'chip-medium': medium,
      'chip--disabled': disabled,
      'chip--inert': inert,
    }"
    :closable="close"
    :color="(isActive || active) && activeColor ? activeColor : color"
    :variant="variant"
    :disabled="disabled"
    :size="large ? 'large' : medium ? 'default' : 'small'"
    :id="id"
    :data-cy="dataCy"
    :ripple="!inert"
    :key="id"
    @click="(e) => $emit('click', e)"
    @click:close.stop="() => $emit('close')"
    @mouseover="(e) => $emit('mouseover', e)"
    @mouseleave="(e) => $emit('mouseleave', e)"
  >
    <div class="fd-flex-center gap-2 chip-content">
      <slot name="prepend-icon">
        <vue-feather
          v-if="prependIcon"
          class="chip-prepend-icon"
          :type="prependIcon"
          tag="div"
          :size="iconSize"
        />
      </slot>

      <v-tooltip v-if="input" location="top" :disabled="!showTooltip">
        <template v-slot:activator="{props}">
          <input
            type="text"
            v-bind="props"
            :value="computedValue"
            :class="['input-' + textColor, {'input-hide-caret': hideCaret}]"
            :style="computedWidthStyling"
            :ref="inputRef"
            :placeholder="placeholder"
            @input="(e) => $emit('update:model-value', e.target.value)"
            @keyup="(e) => $emit('keyup', e)"
            @keydown="(e) => $emit('keydown', e)"
            @blur="(e) => onBlur(e)"
            @focus="(e) => onFocus(e)"
            @click="(e) => $emit('input-click', e)"
          />
        </template>
        <div v-if="showTooltip">
          <span>
            {{ computedValue }}
          </span>
        </div>
      </v-tooltip>

      <v-tooltip v-else-if="showTooltip" location="top">
        <template v-slot:activator="{props}">
          <div v-bind="props" class="text-elipsis">
            <slot />
          </div>
        </template>
        <div v-if="tooltipArray" class="d-flex flex-column">
          <span v-for="(tooltip, index) in tooltipArray" :key="index">
            {{ tooltip }}
          </span>
        </div>
        <slot v-else />
      </v-tooltip>

      <slot v-else />

      <vue-feather
        v-if="appendIcon && !close"
        class="chip-append-icon"
        :type="appendIcon"
        tag="div"
        size="14px"
        @click="() => $emit('confirm')"
      />
    </div>
  </v-chip>
</template>

<script scoped lang="ts">
import {PropType, defineComponent, ref} from "vue";
import {CSS_SCHEDULING_OPERATION_PRIORITY_CHIP_CLASS} from "@/config/constants";

export default defineComponent({
  name: "figma-chip",
  props: {
    color: {type: String, default: "newPrimaryRegular"},
    activeColor: String,
    textColor: {type: String, default: "blanc"},
    tooltipArray: {type: Array, default: undefined},
    modelValue: String,
    appendIcon: String,
    prependIcon: String,
    dataCy: String,
    id: {type: String, default: ""},
    inputRef: {type: String, default: "fChipInputRef"},
    // inputId: {type: String, default: ""},
    placeholder: {type: String, default: ""},
    inputId: String,
    showTooltip: Boolean,
    active: Boolean,
    hideCaret: Boolean,
    input: Boolean,
    close: Boolean,
    disabled: Boolean,
    medium: Boolean,
    large: Boolean,
    suffix: {type: [String, Boolean], default: false},
    variant: {
      type: String as PropType<
        "flat" | "elevated" | "text" | "tonal" | "outlined" | "plain"
      >,
      default: "elevated",
    },
    iconSize: {type: String, default: "14px"},
    inert: {type: Boolean, default: false},
  },
  emits: [
    "click",
    "close",
    "mouseover",
    "mouseleave",
    "update:model-value",
    "keyup",
    "keydown",
    "input-click",
    "focus",
    "blur",
    "confirm",
  ],
  setup() {
    const isActive = ref(false);
    return {
      isActive,
      CSS_SCHEDULING_OPERATION_PRIORITY_CHIP_CLASS,
    };
  },

  computed: {
    computedWidthStyling() {
      return {
        width:
          (this.computedValue
            ? this.computedValue.length
            : this.placeholder.length) +
          1 +
          "ch",
      };
    },
    computedValue() {
      const {modelValue, suffix} = this;
      return [modelValue, suffix].filter(Boolean).join(" ");
    },
  },

  methods: {
    // local method to focus the input through a ref invoke in a parent component
    focus(): void {
      if (!this.$refs[this.inputRef]) return;
      this.$refs[this.inputRef].focus();
    },

    onFocus(e: Event): void {
      this.isActive = true;
      this.$emit("focus", e);
    },

    onBlur(e: Event): void {
      this.isActive = false;
      this.$emit("blur", e);
    },
  },
});
</script>

<style lang="scss">
div.v-application,
div.v-overlay-container {
  .chip {
    box-shadow: none;
    height: 18px;
    line-height: 18px;
    font-size: 12px;

    &.chip--inert {
      cursor: default;
    }

    &-large {
      height: 30px;
      font-size: 14px;
    }
    &-medium {
      height: 22px;
      font-size: 14px;
      padding: 0 5px;
      line-height: 20px;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::placeholder {
        caret-color: rgb(var(--v-theme-newPrimaryRegular));
        color: rgb(var(--v-theme-newPrimaryRegular));
      }

      &.input {
        &-blanc {
          caret-color: rgb(var(--v-theme-blanc));
          color: rgb(var(--v-theme-blanc));
        }

        &-newMainText {
          caret-color: rgb(var(--v-theme-newMainText));
          color: rgb(var(--v-theme-newMainText));

          &::placeholder {
            caret-color: rgb(var(--v-theme-newMainText));
            color: rgb(var(--v-theme-newMainText));
          }
        }

        &-hide-caret {
          caret-color: transparent !important;
          cursor: pointer;
        }
      }
    }

    & > .v-chip__content {
      display: inline-block !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      & > .v-chip__close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        margin-right: 4px !important;
      }
    }

    &.v-chip--removable > .v-chip__content {
      margin-right: 12px;
    }
  }
}
.text-elipsis {
  max-width: 20vw;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
}
@media (max-width: 1500px) {
  .text-elipsis {
    max-width: 15vw;
  }
}
</style>
