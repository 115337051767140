<script setup lang="ts">
import {useFav} from "@/composables/useFav";
import {Sector} from "@/interfaces";
import {computed, toRefs, unref} from "vue";
import {FButton} from "../Global";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

const emit = defineEmits<{
  (e: "selected", breadcrumb: Sector): void;
}>();

const props = defineProps<{
  breadcrumb?: Sector;
  showFav?: boolean;
}>();
const {breadcrumb, showFav} = toRefs(props);

const {variables} = storeToRefs(useMainStore());
const {toggleFav, isFavorite} = useFav(breadcrumb.value);

const color = computed(() => {
  return unref(isFavorite)
    ? {
        fill: unref(variables).newYellowRegular,
        stroke: unref(variables).newYellowRegular,
      }
    : {};
});
</script>

<template>
  <div class="d-flex align-center">
    <FButton
      :disabled="breadcrumb.disabled"
      class="breadcrumb-content"
      small
      :data-testid="`sector-breadcrumb-${breadcrumb.level}`"
      @click="() => emit('selected', breadcrumb)"
    >
      {{ breadcrumb.name }}
    </FButton>
    <vue-feather
      v-if="showFav"
      type="star"
      size="14"
      class="cursor-pointer"
      v-bind="color"
      @click="toggleFav()"
    />
  </div>
</template>
