<template>
  <div>
    <v-tooltip location="top" :disabled="!disabled">
      <template v-slot:activator="{props}">
        <div
          v-bind="props"
          :class="{
            'switch-button-small': small,
            'switch-button-medium': medium,
            'switch-button-input': input,
            reverse,
          }"
          class="switch-button d-flex align-stretch"
        >
          <div
            v-for="(item, idx) in items"
            :key="`${item[itemValue]}-${idx}`"
            :class="{
              'switch-item d-flex align-center justify-center gap-2': true,
              'switch-item--active': isSelectedItem(item),
              'switch-item--disabled': disabled,
            }"
            :data-testid="`switch-item-${
              (returnObject ? item[itemText] : item) || idx
            }`"
            @click="setActive(item)"
          >
            <vue-feather
              v-if="returnObject && item.icon"
              class="mr-1"
              size="16"
              :type="item.icon"
            />
            <span class="text-14 switch-text">
              {{ returnObject ? item[itemText] : item }}
            </span>
          </div>
        </div>
      </template>
      <span>
        {{ $t("Simulation.disabled_qualif_change") }}
      </span>
    </v-tooltip>
  </div>
</template>

<script scoped lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "figma-switch-button",
  props: {
    modelValue: {type: [String, Object, Number], default: () => ({})},
    small: {type: Boolean, default: false},
    medium: {type: Boolean, default: false},
    input: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemText: {type: String, default: "name"},
    itemValue: {type: String, default: "id"},
    items: {type: Array, default: () => []},
    disabled: {type: Boolean, default: false},
    reverse: {type: Boolean, default: false},
  },
  emits: ["update:model-value"],
  methods: {
    setActive(item) {
      if (this.disabled) return;
      this.$emit("update:model-value", item);
    },
    // returns whether or not an item is selected
    isSelectedItem(item: any): boolean {
      const {returnObject, itemValue, modelValue} = this;
      if (returnObject && typeof modelValue === "object")
        return modelValue[itemValue] === item[itemValue];
      else if (typeof item === "object") return modelValue === item[itemValue];
      else return modelValue === item;
    },
  },
});
</script>

<style scoped lang="scss">
.switch-button {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid rgb(var(--v-theme-newSelected));
  border-radius: 8px;

  &-input {
    background-color: rgb(var(--v-theme-newSubBackground));

    &:hover {
      border-color: rgb(var(--v-theme-newMainText));
    }
  }

  &-small {
    height: 28px;

    .switch-item {
      max-width: 80px;
      white-space: nowrap;
      font-size: 14px;
    }
  }

  &-medium {
    height: 32px;

    .switch-item {
      padding: 6px 8px;
      white-space: nowrap;
      font-size: 14px;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  .switch-item {
    padding: 0 16px;
    text-align: center;
    flex: 1;
    color: rgb(var(--v-theme-newSubText));
    cursor: pointer;
    border-radius: 8px;
    &--disabled {
      background-color: rgb(var(--v-theme-newDisableBG));
      cursor: not-allowed;
    }
    &--active {
      background: rgb(var(--v-theme-newPrimaryRegular));
      color: rgb(var(--v-theme-blanc));
      cursor: pointer;
    }

    &:hover:not(.switch-item--active) {
      color: rgb(var(--v-theme-newMainText));
      background: rgb(var(--v-theme-newHover));
    }
  }

  .switch-text {
    line-height: 1.2rem;
  }
}
</style>
