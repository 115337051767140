<template>
  <div class="d-flex flex-column justify-space-between flex-1">
    <div class="comments-body pa-4 flex-1" id="container">
      <v-row
        class="comments-body-message mb-2"
        no-gutters
        v-for="message in messages"
        :key="message.id"
      >
        <div class="comments-body-message-header text-grey fbody-2">
          {{ getMessageHeader(message) }}
        </div>
        <div v-if="false" class="comments-body-message-status-change w-100">
          Le statut est passé de
          <span class="text-subtitle-2">{{
            `${"à faire"} à ${"en cours "}`
          }}</span>
        </div>
        <div v-if="false" class="comments-body-message-status-change w-100">
          La date de début a été modifiée de
          <span class="text-subtitle-2">{{ `${"9:00"} à ${"10:15"}` }}</span>
        </div>
        <div
          v-if="true"
          class="comments-body-message-wrapper w-100 fd-flex-center"
        >
          <AvatarPicture
            class="comments-body-message-user"
            :userId="message.uid"
            size="42"
          />
          <div class="comments-body-message-text pa-2 pb-4">
            {{ message.content }}
          </div>
        </div>
      </v-row>
    </div>
    <div
      class="d-flex align-center justify-center comments-body-footer bg-newAppBackground w-100"
    >
      <AvatarPicture
        class="comments-body-message-user"
        :userId="userData.id"
        size="42"
      />
      <form class="comments-body-footer-form" @submit.prevent="createMessage">
        <div class="d-flex align-center">
          <FTextField
            class="w-100"
            borderless
            v-model="newMessage"
            :placeholder="$t('Comments.add_message')"
          />

          <v-icon class="ml-3" size="24" @click="createMessage">
            mdi-send
          </v-icon>
        </div>

        <span id="scroll-here" class="scroll-here" />
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, ref, PropType} from "vue";
import {storeToRefs} from "pinia";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {FTextField, AvatarPicture} from "@/components/Global";
import moment from "moment";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {getSegmentPayload} from "@/tscript/utils/segmentHelper";
import {useMainStore} from "@/stores/mainStore";
import {SchedulingMessage} from "@oplit/shared-module";

export default defineComponent({
  name: "comments-chat",
  components: {
    FTextField,
    AvatarPicture,
  },
  props: {
    op: {type: Object, default: () => ({})},
    messages: {
      type: Array as PropType<
        {
          name: string;
          timestamp: number;
          id: string;
          uid: string;
          content: string;
        }[]
      >,
      default: () => [],
    },
    loading: {type: Boolean, default: false},
  },
  setup() {
    const mainStore = useMainStore();
    const {apiClient, userData} = storeToRefs(mainStore);

    const {schedulingUpdate} = storeToRefs(useSchedulingStore());

    return {
      newMessage: ref<string>(null),
      schedulingUpdate,
      apiClient,
      userData,
    };
  },
  methods: {
    createMessage() {
      this.addMessage(() => {
        this.scroll();
        this.newMessage = null;
        this.feedback = null;
      });
    },
    scroll() {
      var container = this.$el.querySelector("#container");
      if (!container) return;
      container.scrollTop = container.scrollHeight;
    },
    async addMessage() {
      const {op: operation, userData} = this;
      const {client_id, name, email} = userData;

      const message: SchedulingMessage = {
        id: dbHelper.getCollectionId("scheduling_messages"),
        client_id,
        content: this.newMessage,
        name,
        email,
        op_id: operation.op_id,
        of_id: operation.of_id,
        uid: this.userData?.id,
        timestamp: Date.now(),
      };
      this.newMessage = "";
      await dbHelper.setDataToCollection(
        "scheduling_messages",
        message.id,
        message,
        true,
      );
      //Object.assign is to keep reactivity
      Object.assign(this.schedulingUpdate, {
        type: "message",
        field: "of_id",
        value: operation.of_id,
        payload: message,
      });
      this.$segment.value.track(
        "[Ordo] Chat Message on Operation Created",
        getSegmentPayload("operation", operation),
      );
    },
    getMessageHeader(message: {name: string; timestamp: number}): string {
      return `${message.name}, ${moment(message.timestamp).format("LLL")}`;
    },
  },
});
</script>
<style scoped lang="scss">
@import "@/scss/colors.scss";
.comments-body {
  &-message {
    &-text {
      width: 300px;
      background-color: rgb(var(--v-theme-newSubBackground));
      color: rgb(var(--v-theme-newSubText));
      border-radius: 4px;
      display: inline-block;
    }
  }
  &-footer {
    bottom: 0;
    border-top: 1px solid rgb(var(--v-theme-newSelected));
    position: sticky;
    &-form {
      display: inline-block;
      width: 300px;
    }
  }
}
.sendicon {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
