import moment from "moment";
import {getLevelCorrespFieldsForSector} from "@oplit/shared-module";
import {
  CSS_PARAMETERS_CONTAINER_CLASS,
  CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE,
  PARAMETERS_CONTAINER_DISABLED_ATTRIBUTE,
  PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS,
} from "@/config/constants";
import type {
  MenuItem,
  MSDField,
  Sector,
  ParametersColorsCategory,
  ParametersColorsCategoryOption,
  OplitCalculusFormulaItem,
} from "@/interfaces";

const isLinkConditionVerified = (link: MenuItem): boolean =>
  !("condition" in link) || link.condition;

/**
 * logic to prevent the page change when navigating out a parameters page with unsaved modifications
 * shared between the Navbar & Parametres components
 * @returns a boolean representing the authorization to navigate
 */
const getParametersRouteChangePreventCondition = (): boolean => {
  const [parametersContainer] =
    document.getElementsByClassName(CSS_PARAMETERS_CONTAINER_CLASS) ?? [];
  if (!parametersContainer) return true;
  // parsing to get the original value type
  const isDisabled = JSON.parse(
    parametersContainer.getAttribute(PARAMETERS_CONTAINER_DISABLED_ATTRIBUTE),
  );
  if (isDisabled) return true;
  return false;
};

/**
 * returns the `icon` defaults for the fields inside the parameters list
 */
const getFieldsDefaultIcon = (model: string): string => {
  switch (model) {
    case "nb_equipe":
      return "users";

    case "nb_machine":
      return "settings";

    case "duree_shift":
      return "clock";

    default:
      return "file";
  }
};

/**
 * returns the `default_value` defaults for the fields inside the parameters list
 */
const getFieldsDefaultValue = (model: string) => {
  switch (model) {
    case "nb_equipe":
      return 1;

    case "nb_machine":
      return 0;

    case "duree_shift":
      return 8;

    default:
      return;
  }
};

/**
 * returns whether or not the `model` key from @item is mandatory
 */
const isParameterCapaMandatoryField = (item: {
  model?: MSDField["model"];
}): boolean => PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS.includes(item.model);

/**
 * returns a default `parameter_msd` object
 * NB: the `id` key has been kept out to prevent importing dbHelper in this file
 * FIXME: similar logic is probably used by the back : the logics should be harmonized
 */
const getInitialMSDParameter = (sector: Sector) => ({
  secteur_id: sector.id,
  secteur_name: sector.name,
  level: sector.level / 1,
  type: sector.type,
  collection: sector.collection,
  client_id: sector.client_id,
  start_date: moment().format("YYYY-MM"),
  ...getLevelCorrespFieldsForSector(sector),
});

/*
 * returns a random color from the CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE constant\
 * will try to avoid the colors inside @avoidedColors to offer new colors first
 */
const getDefaultColorCategoryColor = (avoidedColors: string[] = []): string => {
  const colorPalette = CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE.filter(
    (color: string) => !avoidedColors.includes(color),
  );
  const finalColorPalette = colorPalette.length
    ? colorPalette
    : CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE;

  return finalColorPalette[
    Math.floor(Math.random() * finalColorPalette.length)
  ];
};

const getDefaultColorCategoryOption = (
  avoidedColors: string[] = [],
): ParametersColorsCategoryOption => ({
  name: null,
  color_name: getDefaultColorCategoryColor(avoidedColors),
});

const getDefaultColorCategory = (
  payload = {},
): Partial<ParametersColorsCategory> => ({
  oplit_name: null,
  excel_name: null,
  randomize: true,
  options: [],
  ...payload,
});

const isInFormulaField = (
  field: OplitCalculusFormulaItem | MSDField,
): boolean => !!field.in_formula;

const getParametersCalculusFormulaChipColor = (
  type: string,
  isDisabledChip = false,
): string => {
  if (isDisabledChip) return "newSelected";

  switch (type) {
    case "child":
      return "newPrimaryDark2";
    case "hourMode":
      return "newGreenRegular";
    case "capa_journaliere":
      return "newGreenRegular";
    case "operation":
    case "parenthese_ouvrante":
    case "parenthese_fermante":
    case "function":
      return "newDisableText";
    default:
      return "newPrimaryRegular";
  }
};

const isCalculusRuleParameterType = (field: MSDField): boolean =>
  ![
    "child",
    "children",
    "constant",
    "function",
    "hourMode",
    "operation",
    "parenthese_fermante",
    "parenthese_ouvrante",
  ].includes(field.type);

export {
  getFieldsDefaultIcon,
  getFieldsDefaultValue,
  getInitialMSDParameter,
  getParametersRouteChangePreventCondition,
  isLinkConditionVerified,
  isParameterCapaMandatoryField,
  getDefaultColorCategory,
  getDefaultColorCategoryOption,
  isInFormulaField,
  getParametersCalculusFormulaChipColor,
  isCalculusRuleParameterType,
  getDefaultColorCategoryColor,
};
