function getReadableFileWeight(file: Partial<File>): string {
  if (!file?.size) return;

  const sizeMapping = {
    GB: 1000000000,
    MB: 1000000,
    KB: 1000,
    B: 0,
  };

  for (const [suffix, size] of Object.entries(sizeMapping)) {
    if (file.size > size)
      return `${+(file.size / Math.max(1, size)).toFixed(1)} ${suffix}`;
  }
}

function toHumanReadableSize(bytes: number): string {
  if (bytes === 0) return "0.00 B";

  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B"
  );
}

export {getReadableFileWeight, toHumanReadableSize};
