import {App, computed, defineComponent, h, render, VNode} from "vue";
import FDialog from "@/components/Global/Homemade/Feedback/FDialog.vue";
import SendToMercateamCheckbox from "@/views/Simulation/SendToMercateamCheckbox.vue";
import {AlertComponentProps, FDialogProps, UnknownError} from "@/interfaces";

/**
 * since we seem unable to invoke a specific vue component within the store,
 * we allowed passing as 4th argument of the function a `string` rather than a Vue component
 * this is a workaround used for the use case of archiving a Simulation that has been moved from
 * a Vue component to the store's simulation.module
 * NB : when calling $openDialog() from a .vue file, one's able to proceed without using
 * this workaround (see details below)
 */
const allowedStringsAsComponents = {
  SendToMercateamCheckbox,
};

/**
 * normalization of the dialog/snackbar plugins inside a class
 * rather than multiple functions that would require the i18n
 * translation function as a parameter
 * @param translateObject : app's vue-i18n
 */
function AlertPlugin(translateObject) {
  /**
   * creates a simple configuration with { type, message } from the constant name
   * the constant name within the i18n files should be placed into the "Alert" group
   * and should be the lowercase version of the constant within this file
   */
  this.getArrayOfTypedConfigurations = function (
    arrayOfVariables: string[],
    type: string,
  ): {[key: string]: AlertComponentProps} {
    return arrayOfVariables.reduce(function (
      acc: {[key: string]: AlertComponentProps},
      current: string,
    ): {[key: string]: AlertComponentProps} {
      return {
        ...acc,
        [current]: {
          type,
          message: translateObject.t(`Alert.${current.toLowerCase()}`),
        },
      };
    },
    {});
  };

  /**
   * default handler usually called upon an API response
   * to specifically open a dialog/snackbar with this handler,
   * this plugin has to be invoked with (null, null, <APIResponse>) as parameters
   * NB: the parameter namings remained "error"-related to preserve the previous convention
   */
  this.getGenericConfigurationFromAPIResponse = function (
    error: UnknownError,
  ): Omit<AlertComponentProps, "message"> & {
    message: unknown | string;
  } {
    if (!error) {
      return {
        type: "positive",
        message: translateObject.t("Alert.save_success"),
      };
    }

    //try to have an error message as explicit as possible
    let message = translateObject.t("Alert.generic_error");
    if (error.message && error.response?.data?.error)
      message = `${error.message}: ${error.response.data.error}`;
    else if (error.message) message = error.message;
    else if (error.error) message = error.error;
    else if (error.e) message = error.e;

    return {type: "negative", message};
  };

  /**
   * the following arrays contain constants that are translated to common use cases through this.getArrayOfTypedConfigurations
   * they should be invoked with the following args configuration : (null, <constant>)
   * they are included in the following plugins : dialog, snackbar
   */
  this.negativeConstants = [
    "NO_CLIENT_ID",
    "NO_LOADING",
    "NO_PERMISSION",
    "NO_DUPLICATES",
    "NO_PERIOD",
    "NO_SIMULATION",
    "GENERIC_ERROR",
  ] as string[];
  this.warningConstants = [] as string[];
  this.positiveConstants = [
    "SAVE_SUCCESS", // unused
    "DELETE_SUCCESS",
    "EVENT_SUCCESS", // snackbar only
    "IMPORT_SUCCESS",
  ] as string[];

  // returns an object containing all the pre-defined configurations
  this.defaultAlertConfigurations = {
    ...this.getArrayOfTypedConfigurations(this.negativeConstants, "negative"),
    ...this.getArrayOfTypedConfigurations(this.warningConstants, "warning"),
    ...this.getArrayOfTypedConfigurations(this.positiveConstants, "positive"),
  } as {
    [key: string]: AlertComponentProps;
  };
}

/**
 * creates a FDialog component with a configured properties
 * @dialogConfiguration : an object containing props for the FDialog component
 * @constant : a string that's relative to a pre-defined configuration (see negativeConstants, warningConstants, positiveConstants)
 * @error : an unknown entity coming usually from an API response
 *
 * NB: the dialogConfiguration object will always overwrite automatically-defined parameters from the following arguments
 */
function DialogPlugin(options: any, app: App) {
  const {defaultAlertConfigurations, getGenericConfigurationFromAPIResponse} =
    new AlertPlugin(options.i18n);

  const configurations = computed(() => ({
    ...defaultAlertConfigurations,
    ASK_DELETE: {
      type: "warning",
      message: options.i18n.t("Alert.ask_delete"),
    },
    ASK_UNSAVED: {
      // only used inside deactivated() hooks - wasn't able to trigger
      type: "warning",
      hidePrefix: true,
      header: options.i18n.t("Alert.warn_unsaved"),
      message: options.i18n.t("Alert.ask_unsaved"),
      cancelText: options.i18n.t("Alert.deny_unsaved"),
      validateText: options.i18n.t("Alert.confirm_unsaved"),
    },
    PARAMETERS_ASK_UNSAVED: {
      type: "warning",
      message: options.i18n.t(
        "Parameters.others.alert_unsaved_changes_message",
      ),
      validateText: options.i18n.t(
        "Parameters.others.alert_unsaved_changes_confirm_btn",
      ),
      confirmBtnProps: {
        filled: "newPinkRegular",
      },
    },
  }));

  this.openDialog = function (
    dialogConfiguration: FDialogProps,
    constant: string,
    error: UnknownError,
    /**
     * additional args, currently supports only the first argument (slots)
     */
    ...args
  ): Record<string, unknown> {
    const returned: Record<string, unknown> = {};
    const [slots] = args;

    const actualConfiguration: FDialogProps = {
      /**
       * to specifically handle with the getGenericConfigurationFromAPIResponse,
       * this plugin has to be invoked with (null, null, <error>) as parameters
       */
      // since we pass this in any case, this has to remain first so that it is overriden by the next objects
      ...getGenericConfigurationFromAPIResponse(error),
      ...(configurations.value[constant] || {}),
      // this has to remain the last so that overriding is possible for every scenario
      ...(dialogConfiguration || {}),
    };

    const dialogInstanceArgs: [
      ReturnType<typeof defineComponent>,
      FDialogProps,
      {default: () => VNode}?,
    ] = [
      FDialog,
      {
        modelValue: true,
        ...actualConfiguration,
      },
    ];

    /**
     * the slots property logic was made so that we can "easily" add components from other components using this plugin
     * to do so, pass as 4th argument of the instance method an imported component as such :
     *
     * import {MyCustomButton} from "@/components/MyCustomButton.vue"
     * this.$openDialog(..., ..., ..., { <name-of-the-slot-of-fdialog>: MyCustomButton })
     *
     * if you need to retrieve the value of such slotted components, as of yet there is no clean way to do so
     * it remains doable using a state variable and doing a logic similar to the following :
     *
     * data: () => ({
     *  customDialog: null
     * }),
     * methods: {
     *  myMethod () {
     *    this.customDialog = this.$openDialog({ action: this.onValidate }, null, null, {'additional-body': MyCustomCheckbox })
     *  },
     *  onValidate () {
     *    if (!this.customDialog?.['additional-body']) return
     *    const {<my-state-variable-from-MyCustomCheckbox>} = this.customDialog['additional-body'].componentInstance
     *    ...
     *  }
     * }
     *
     * it is also doable to pass custom HTML on-the-fly if you don't want to create a specific component.
     * to do so, pass a logic similar to the following as 4th argument :
     * {
     *   <name-of-the-slot-of-fdialog>: {
     *     functional: true,
     *     // if you want to use the context (`this`) of the component you invoke this method in,
     *     // you should use an arrow function below instead of the regular function writing
     *     render: function (h) {
     *       h("my-html-tag", {
     *         class: "my-class-a my-class-b",
     *         style: {
     *             property: value,
     *             ...
     *          },
     *          domProps: {
     *            innerHTML: "your-custom-html",
     *            ...
     *          },
     *       }),
     *   },
     * }
     */
    if (slots) {
      for (const [slotName, slotValue] of Object.entries(slots)) {
        // see allowedStringsAsComponents
        const slotValueAsComponent =
          typeof slotValue === "string"
            ? allowedStringsAsComponents[slotValue]
            : slotValue;
        if (!slotValueAsComponent) return;

        const node = h(slotValueAsComponent);
        dialogInstanceArgs.push({default: () => node});

        returned[slotName] = node;
      }
    }

    const container = document.createElement("div");
    document.body.appendChild(container);

    const dialogInstance = h(...dialogInstanceArgs);
    dialogInstance.appContext = app._context;
    returned.dialogInstance = dialogInstance;

    render(dialogInstance, container);

    return returned;
  };
}

// creates a placeholder DOM element to be mounted by various plugins
const createHTMLElementWithID = (
  id: string = null,
): {container: HTMLElement; id: string} => {
  const theId = id || `temp-id-${Math.ceil(Math.random() * 99999999)}`;
  const div = document.createElement("div");
  div.setAttribute("id", theId);
  document.getElementById("app").appendChild(div);
  return {
    container: div,
    id: theId,
  };
};

export {AlertPlugin, DialogPlugin, createHTMLElementWithID};
