<script setup lang="ts">
import {toRefs, computed} from "vue";

const props = defineProps<{
  progress?: number;
  goal?: number;
  height?: number;
  goalText: string;
  goalTextOnTop?: boolean;
  hidePercentage?: boolean;
  hideGoalText?: boolean;
  flatContainer?: boolean;
  roundedProgress?: boolean;
  redByDefault?: boolean;
}>();

const {
  progress,
  goal,
  height,
  goalText,
  goalTextOnTop,
  hidePercentage,
  hideGoalText,
  flatContainer,
  roundedProgress,
  redByDefault,
} = toRefs(props);

const goalTickPosition = computed(() => {
  return `${Math.max(Math.min(goal.value, 100), 0)}%`;
});

const transformTickText = computed(() => {
  if (goal.value < 25) return "0%";
  if (goal.value > 75) return "-100%";
  return `-50%`;
});

const topTickText = computed(() => {
  return goalTextOnTop.value ? "-100%" : "100%";
});

const shouldBeGreen = computed(() => {
  if (redByDefault.value) return progress.value > goal.value;
  return progress.value >= goal.value;
});

const progressBorderRadius = computed(() => {
  if (progress.value >= 100) return "0px";
  return "16px";
});
</script>

<template>
  <div class="progress-linear-main-container">
    <div class="progress-linear-container">
      <span
        v-if="goal != null"
        class="progress-tick"
        :style="{height: 8 + (height ?? 8) + 'px'}"
      />
      <span v-if="goal && !hideGoalText" class="progress-tick-text">
        {{ goalText }}
      </span>
      <v-progress-linear
        :class="roundedProgress ? 'progress-linear-rounded-progress' : ''"
        :color="shouldBeGreen ? 'newGreenRegular' : 'newPinkRegular'"
        :rounded="!flatContainer"
        :model-value="progress"
        :height="height ?? 8"
      />
    </div>
    <h4 v-if="!hidePercentage">{{ progress ?? "- " }}%</h4>
  </div>
</template>

<style scoped lang="scss">
.progress-linear-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  & h4 {
    white-space: nowrap;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }
}
.progress-linear-container {
  position: relative;
  width: 100%;
}

.progress-tick {
  position: absolute;
  left: v-bind(goalTickPosition);
  top: -4px;
  height: 16px;
  width: 2px;
  background: rgb(var(--v-theme-newMainText));
  border-radius: 100px;
  z-index: 2;
}

.progress-tick-text {
  position: absolute;
  left: v-bind(goalTickPosition);
  top: v-bind(topTickText);
  transform: translateX(v-bind(transformTickText));
  font-size: 10px;
  color: rgb(var(--v-theme-newMainText));
  line-height: 24px;
  white-space: nowrap;
}
</style>

<style lang="scss">
.progress-linear-main-container {
  .progress-linear-container {
    .progress-linear-rounded-progress {
      .v-progress-linear {
        &__background {
          left: 0% !important;
          width: 100% !important;
        }
        &__determinate {
          border-radius: 0px v-bind(progressBorderRadius)
            v-bind(progressBorderRadius) 0px;
        }
      }
    }
  }
}
</style>
