<template>
  <v-menu
    v-model="isShownMenu"
    :close-on-content-click="false"
    :z-index="zIndex"
    content-class="mt-2"
    offset="12px"
  >
    <template #activator="{props}">
      <FChip
        v-bind="props"
        color="newPrimaryRegular"
        text-color="newPrimaryLight2"
        class="px-2"
        :data-testid="TEST_IDS.SCHEDULING_NEW_TAGS_MENU__ACTIVATOR"
      >
        {{ $t("scheduling.create_tag") }}
      </FChip>
    </template>

    <div class="new-tags-menu--content-wrapper">
      <FTextField
        v-model="search"
        :placeholder="$t('global.search')"
        class="new-tags-menu--search input-multitag"
        height="auto"
        :data-testid="TEST_IDS.SCHEDULING_NEW_TAGS_MENU__SEARCH_INPUT"
      >
        <template #input-multitag>
          <SchedulingTagChip
            v-for="tag in addedTags"
            class="mt-1 mr-1"
            :key="tag.id"
            :tag="tag"
            removable
            @remove="onRemoveTag"
          >
            {{ tag.label }}
          </SchedulingTagChip>
        </template>
      </FTextField>

      <div
        v-if="filteredTags.length || isDisplayedCreateButton"
        class="new-tags-menu--tags-wrapper"
        :data-testid="TEST_IDS.SCHEDULING_NEW_TAGS_MENU__TAGS_WRAPPER"
      >
        <div
          v-for="tag in filteredTags"
          :key="tag.id"
          class="new-tags-menu--tag"
        >
          <SchedulingTagChip :tag="tag" @click="onToggleTag(tag)" />

          <SchedulingTagUpdateMenu :tag="tag" />
        </div>

        <div v-if="isDisplayedCreateButton" class="new-tags-menu--tag-create">
          <div @click="onCreateTag">
            <strong class="text-newSubText">
              {{ $t("Tags.create") }}
            </strong>

            <SchedulingTagChip
              :tag="newTag"
              force-display
              class="cursor-initial"
            >
              <span class="text-ellipsis">
                {{ search }}
              </span>
            </SchedulingTagChip>

            <v-progress-circular
              v-if="isOngoingSchedulingUpdate"
              :color="variables.newSelected"
              size="12"
              width="2"
              indeterminate
            />
          </div>
        </div>
      </div>

      <FButton
        :disabled="!addedTags.length"
        :loading="isOngoingSchedulingUpdate"
        filled
        :data-cy="TEST_IDS.SCHEDULING_NEW_TAGS_MENU__CONFIRM_BUTTON"
        @click="onAddTags"
      >
        {{ $t("global.save") }}
      </FButton>
    </div>
  </v-menu>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {FButton, FChip, FTextField} from "@/components/Global";
import SchedulingTagUpdateMenu from "./SchedulingTagUpdateMenu.vue";
import SchedulingTagChip from "./SchedulingTagChip.vue";
import {getTagsTextColor} from "@/tscript/utils/schedulingUtils";
import {SCHEDULING_TAGS_AVAILABLE_COLORS} from "@/config/constants";
import {getDefaultTagObject, SchedulingTag} from "@oplit/shared-module";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {TEST_IDS} from "@/config/constants";

export default defineComponent({
  name: "scheduling-new-tags-menu",
  components: {
    FButton,
    FChip,
    FTextField,
    SchedulingTagChip,
    SchedulingTagUpdateMenu,
  },
  props: {
    tags: {
      type: Array as PropType<SchedulingTag[]>,
      default: () => [] as SchedulingTag[],
    },
    zIndex: {type: Number, default: 2000},
  },
  emits: ["add"],
  setup() {
    const {updateSchedulingTag} = useSchedulingStore();
    const {isOngoingSchedulingUpdate, schedulingTags} = storeToRefs(
      useSchedulingStore(),
    );

    const mainStore = useMainStore();
    const {variables, userData} = storeToRefs(mainStore);

    return {
      isShownMenu: ref<boolean>(false),
      addedTags: ref<SchedulingTag[]>([]),
      search: ref<string>(null),
      newTagColor: ref<string>(null),
      updateSchedulingTag,
      isOngoingSchedulingUpdate,
      schedulingTags,
      variables,
      userData,
      TEST_IDS,
    };
  },
  computed: {
    isDisplayedCreateButton(): boolean {
      const {search, schedulingTags} = this;
      return (
        !!search &&
        !schedulingTags.find(
          (tag: SchedulingTag) =>
            tag.label.toLowerCase() === search.toLowerCase(),
        )
      );
    },
    filteredTags(): SchedulingTag[] {
      const {search, schedulingTags} = this;

      const MAX_DISPLAYED_TAGS = 5;

      const orderedTags = _.orderBy(
        schedulingTags,
        ({last_clicked}: SchedulingTag) => last_clicked || "",
        ["desc"],
      );

      if (!search) return orderedTags.slice(0, MAX_DISPLAYED_TAGS);

      return orderedTags
        .filter((tag: SchedulingTag) =>
          tag.label.toLowerCase().match(search.toLowerCase()),
        )
        .slice(0, MAX_DISPLAYED_TAGS);
    },
    newTag(): Partial<SchedulingTag> {
      return getDefaultTagObject({
        label: this.search,
        color_name: this.newTagColor,
      });
    },
  },
  watch: {
    /**
     * randomizes background color for newly created tags
     */
    isDisplayedCreateButton(newBool: boolean): void {
      if (!newBool) this.newTagColor = null;
      else {
        const randomColor =
          SCHEDULING_TAGS_AVAILABLE_COLORS[
            Math.floor(Math.random() * SCHEDULING_TAGS_AVAILABLE_COLORS.length)
          ];

        this.newTagColor = randomColor;
      }
    },
    schedulingTags(newTags: SchedulingTag[]) {
      // if we change a previously added tag, we update the local array
      this.addedTags = this.addedTags.map(
        (tag: SchedulingTag) =>
          newTags.find((t: SchedulingTag) => t.id === tag.id) ?? tag,
      );
    },
    isShownMenu(newBool: boolean): void {
      if (!newBool) {
        setTimeout(() => {
          this.addedTags = [];
          this.search = null;
        }, 250); // waiting for the fade animation end
      }
    },
  },
  methods: {
    async onCreateTag(): Promise<void> {
      if (this.isOngoingSchedulingUpdate) return;
      const [error, newTag] = await this.updateSchedulingTag(this.newTag);
      if (error) return this.$openSnackbar(null, null, error);
      /**
       * this is the case when trying to create an already-existing tag
       */
      if (!newTag) return;
      this.addedTags.push(newTag);
      this.search = null;
    },
    getAddedTagIndex(tag: SchedulingTag): number {
      return this.addedTags.findIndex((t: SchedulingTag) => t.id === tag.id);
    },
    onRemoveTag(tag: SchedulingTag): void {
      this.addedTags.splice(this.getAddedTagIndex(tag), 1);
    },
    onToggleTag(tag: SchedulingTag): void {
      if (this.getAddedTagIndex(tag) > -1) this.onRemoveTag(tag);
      else {
        this.addedTags.push(tag);
        // reset `search` upon clicking on a tag
        this.search = null;
      }
    },
    onAddTags(): void {
      this.$emit("add", this.addedTags);
      this.isShownMenu = false;
    },
    getTagsTextColor,
  },
});
</script>

<style lang="scss">
.new-tags-menu--content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  & .new-tags-menu--tags-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .new-tags-menu--tag-create {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-top: 8px;

      & > div:first-child {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        & .text-ellipsis {
          max-width: 100px;
        }
      }

      & .v-progress-circular {
        position: absolute;
        right: 0;
      }
    }

    & .new-tags-menu--tag {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
    }
  }

  .new-tags-menu--search {
    max-width: 208px;

    // tweaking vuetify v-input ruleset for a proper `prepend-inner` display
    & .v-field__prepend-inner {
      // width: 120px;
      flex-wrap: wrap;
      gap: 8px;
    }

    & .v-input__slot {
      flex-wrap: wrap;
      height: auto !important;
      padding: 8px 12px !important;
      gap: 8px;
    }

    & .v-text-field__slot {
      flex: 1 0 80px !important;

      & > input {
        padding: 0;
      }
    }
  }
}
.input-multitag {
  & input {
    width: 170px;
    flex: 1 0 auto;
  }
}
</style>
