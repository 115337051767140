import _ from "lodash";
import uniqid from "uniqid";
import {evaluateCapa, levelCorresp} from "@oplit/shared-module";
import {allFields} from "@/components/Simulation/Modals/helper";
import {divideAndConquer, parsePeriode} from "@oplit/shared-module";
import {
  QUALIFICATIONS_CHARGE_SERIE,
  EVENTS_NEW_ID_PREFIX,
} from "@/config/constants";
import {CleanOplitEvent, MSDField, PermissiveField, Sector} from "@/interfaces";
import moment from "moment";

const calculateImpactOnMSD = function (
  parsedPeriod: any,
  initial: any,
  child: any,
) {
  const {total_days = 0} = parsedPeriod;
  const modified = {...initial, ...child} as any;
  //For events on a sector that impact the tag, I need to multiply the value by the number of operators or machines
  if (modified.absences === 1) {
    const nb_equipe = _.get(
      modified,
      ["nb_equipe", "values", 0],
      _.get(modified, "nb_equipe"),
    );
    if (!isNaN(nb_equipe)) modified.absences = nb_equipe;
  }
  if (modified.arrets === 1) {
    const nb_machine = _.get(
      modified,
      ["nb_machine", "values", 0],
      _.get(modified, "nb_machine"),
    );
    if (!isNaN(nb_machine)) modified.arrets = nb_machine;
  }

  const {dailyImpact, locImpact} = computeImpact(
    initial,
    modified,
    total_days,
    total_days,
    "msd",
  );
  return {dailyImpact, locImpact};
};

const computeImpact = function (
  initial: any,
  modified: any,
  total_days: number,
  periodTotalDays: number,
  capaJourType = "events",
) {
  const capaInit = initial?.data_value
    ? initial.data_value[`capa_jour_${capaJourType}`]
    : evaluateCapa(initial.formula, initial, true, periodTotalDays, total_days);
  const capaChanged = evaluateCapa(
    initial.formula,
    modified,
    true,
    periodTotalDays,
    total_days,
  );

  const dailyImpact = capaChanged - capaInit || 0;
  const locImpact = (capaChanged - capaInit) * periodTotalDays;
  return {dailyImpact, locImpact};
};

const removeOneEventInMany = function (
  exEvent: any,
  event: any,
  msdFields: any = [],
) {
  const splitEvent: any = {};
  const lastModifIdx = _.findLastIndex(
    exEvent.modifications,
    (x: any) => x.event_id === event.id,
  );
  const lastModif = _.get(exEvent, ["modifications", lastModifIdx]);
  const laterEvents = exEvent.modifications.slice(lastModifIdx + 1);
  msdFields.forEach((field) => {
    if (lastModif && lastModif[field.model]) {
      if (
        laterEvents.some(
          (x: any) =>
            x.type !== "removed" && x.status !== "removed" && x[field.model],
        )
      ) {
        // loggerHelper.log(
        //   "Cette modif a déjà été écrasée par la suite, rien à faire",
        // );
      } else if ((lastModif.initial || {})[field.model] !== undefined)
        splitEvent[field.model] = lastModif.initial[field.model];
      else splitEvent[field.model] = null;
    }
  });
  return splitEvent;
};

const computePeriod = function (
  child: any,
  filteredMacroEvents: any,
  maille: moment.unitOfTime.StartOf,
  calendar: any,
  simulation: any,
) {
  if (!maille) maille = "month";
  const periode = child.periode;
  const workdays = parsePeriode(
    periode,
    calendar,
    simulation.forceToday,
    undefined,
    undefined,
    maille,
    simulation.forceToday,
  );

  const sharedParams = {maille, calendar, simulation};
  return {
    periode,
    workdays,
    newPeriods: divideAndConquer(
      filteredMacroEvents,
      workdays.startDate,
      workdays.endDate,
      sharedParams,
    ),
  };
};

const getImportantKeys = function (
  initial: any,
  parametres_msd: any = {},
  idType = "",
) {
  const cleaned: any = {};
  [...levelCorresp, {type: "parent"}].forEach((level: any) => {
    if (initial[level.type + "_id"]) {
      cleaned[level.type + "_id"] = initial[level.type + "_id"];
      cleaned[level.type + "_name"] = initial[level.type + "_name"] || null;
    }
  });
  [...(parametres_msd?.fields || []), ...allFields].forEach((field: any) => {
    if (initial[field.model] !== undefined && !isNaN(initial[field.model]))
      cleaned[field.model] = initial[field.model];
  });
  const parent_level = initial.parent_level;
  if (parent_level) {
    cleaned.parent_level = {
      collection: parent_level.collection || null,
      type: parent_level.type || null,
      level: parent_level.level || null,
    };
  }
  cleaned.collection = initial.collection || null;
  cleaned.level = _.get(initial, "level", null); //_ to handle 0 case
  cleaned.type = initial.type || null;
  if (idType !== "secteur")
    cleaned.id = initial.id || initial.secteur_id || null;
  if (idType !== "secteur")
    cleaned.name = initial.name || initial.secteur_name || null;
  if (idType !== "id")
    cleaned.secteur_id = initial.secteur_id || initial.id || null;
  if (idType !== "id")
    cleaned.secteur_name = initial.secteur_name || initial.name || null;
  if (initial.isComputedWChildren)
    cleaned.isComputedWChildren = initial.isComputedWChildren || false;
  if (initial.is_machine) cleaned.is_machine = true;
  if (initial.is_operator) cleaned.is_operator = true;
  if (initial.is_tag) cleaned.is_tag = true;
  if (initial.is_filled) cleaned.is_filled = true;
  if (initial.msd_secteur_id) cleaned.msd_secteur_id = initial.msd_secteur_id;
  if (initial.msd_level !== undefined) cleaned.msd_level = initial.msd_level;
  if (initial.datelancement !== undefined)
    cleaned.datelancement = initial.datelancement;
  if (initial.periods?.length) cleaned.periods = initial.periods;
  if (initial.of_id) cleaned.of_id = initial.of_id;
  if (initial.op_sequence) cleaned.op_sequence = initial.op_sequence;
  return cleaned;
};

const computeChipColor = (event: any) => {
  return (event.impact?.value || 0) / 1 > 0
    ? "newGreenRegular"
    : "newPinkDark1";
};

/**
 * returns a list of fields overwritten with the `required` attribute\
 * if said field is required for this event's configuration
 */
const getEventRequiredFields = (
  event: CleanOplitEvent,
  fields: PermissiveField[],
) => {
  if (
    !event.is_simple_event &&
    ![
      QUALIFICATIONS_CHARGE_SERIE.ADDITION,
      QUALIFICATIONS_CHARGE_SERIE.MODIFICATION,
    ].includes(event.qualification.serie)
  )
    return fields;

  const requiredValues = event.is_simple_event
    ? ["impact"]
    : ["nom_op", "quantite"];

  return fields.map((field) =>
    requiredValues.includes(field?.label as string)
      ? {...field, required: true}
      : field,
  );
};

const isEventChargeQualification = (
  event: CleanOplitEvent,
  constantName: string,
): boolean => {
  return (
    event.qualification?.serie === QUALIFICATIONS_CHARGE_SERIE[constantName]
  );
};

const getEventSafeLoadItems = (
  loadItem: Record<string, unknown>,
  fields: MSDField[],
) => {
  if (!loadItem) return {};
  const {
    secteur_id = loadItem.id || "",
    secteur_name = loadItem.name || "",
    unit = loadItem.unite || null,
    level = null,
    type = null,
    collection,
    ...restOp
  } = loadItem;
  (fields || []).forEach((field) => {
    if (restOp[field.model] !== undefined && field.inputId === "percent")
      restOp[field.model] = +restOp[field.model] * 100;
  });
  return {
    ...restOp,
    ...(secteur_id && {secteur_id}),
    ...(secteur_id && {secteur_name}),
    ...(collection && {level, type, collection}),
    ...(unit && {unite: unit}),
  };
};

const getEventOptionalKeys = (
  event: CleanOplitEvent,
): Partial<CleanOplitEvent> => {
  const {should_overwrite: eventShouldOverwrite} = event;

  const properties: Partial<CleanOplitEvent> = {};

  if (isEventChargeQualification(event, "OVERWRITE") || eventShouldOverwrite)
    properties.should_overwrite = true;

  if (isEventChargeQualification(event, "DELETION"))
    properties.should_delete = true;

  return properties;
};

const getEventInitialBySector = (sector: Sector): Sector => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {name, disabled, id, ...initial} = sector;

  return {
    ...initial,
    secteur_id: sector.secteur_id || sector.id || null,
    secteur_name: sector.secteur_name || sector.name || null,
    id: uniqid(EVENTS_NEW_ID_PREFIX),
  };
};

export {
  calculateImpactOnMSD,
  computePeriod,
  removeOneEventInMany,
  getImportantKeys,
  computeChipColor,
  getEventRequiredFields,
  isEventChargeQualification,
  getEventSafeLoadItems,
  getEventOptionalKeys,
  getEventInitialBySector,
};
