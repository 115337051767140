<template>
  <div :class="['f-modal-header', headerWrapperClass]">
    <div class="f-modal-title-wrapper">
      <slot name="prepend-title" />

      <div class="f-modal-title-container">
        <h3 v-if="title" class="f-modal-title" data-testid="modal-header-title">
          {{ title }}
        </h3>

        <div
          v-if="!!subtitle || $slots.subtitle"
          class="f-modal-subtitle"
          data-testid="modal-header-subtitle"
        >
          <slot name="subtitle">
            {{ subtitle }}
          </slot>
        </div>
      </div>

      <slot name="append-title" />
    </div>

    <slot name="prepend-close-icon" />

    <vue-feather
      v-if="!hideCloseIcon"
      type="x"
      :stroke="variables.newMainText"
      data-cy="icon-close-modal"
      @click="closeModal"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {storeToRefs} from "pinia";
import {useModal} from "@/composables/useModal";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  props: {
    title: {type: String, required: true},
    subtitle: {type: String, default: null},
    hideCloseIcon: {type: Boolean, default: false},
    headerWrapperClass: {type: [String, Object, Array]},
  },
  setup(props, context) {
    const {closeModal} = useModal(context);

    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);

    return {
      closeModal,
      variables,
    };
  },
});
</script>

<style lang="scss">
.f-modal-header {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: var(--g-modal-spacing);
  padding: var(--g-modal-spacing);

  & .f-modal-title-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: calc(var(--g-modal-spacing) / 2);

    & .f-modal-title-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1;
    }

    & .f-modal-title {
      font-weight: 700;
    }
  }
}
</style>
