import {PermissionsGroupName} from "@/interfaces";

const PERMISSIONS_BY_ROLE_GROUPS: Record<PermissionsGroupName, string[]> = {
  general: [
    "create_update_simulation",
    "read_simulation_active",
    "read_parameters",
    "update_parameters",
    "create_update_user",
    "create_comment",
    "read_analytics",
    "create_read_update_action",
    "read_client_multisite",
  ],
  load_capacity: ["create_update_event", "read_event"],
  scheduling: [
    "update_of_machine",
    "update_of_date",
    "update_of_status",
    "create_update_of_tags",
    "can_see_scheduling_kpis",
    "update_conwip_configuration",
  ],
};

const PERMISSIONS_BY_ROLE_DEPENDENCIES_PER_GROUP: Record<
  PermissionsGroupName,
  Record<string, string[]>
> = {
  general: {
    update_parameters: ["read_parameters"],
    create_update_user: ["update_parameters"],
  },
  load_capacity: {
    create_update_event: ["read_event"],
  },
  scheduling: {},
};

export {PERMISSIONS_BY_ROLE_GROUPS, PERMISSIONS_BY_ROLE_DEPENDENCIES_PER_GROUP};
