import {inject, reactive, toRefs} from "vue";
import {storeToRefs} from "pinia";

import _ from "lodash";
import moment from "moment";
import {
  getStorage,
  ref as fbRef,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import * as uuid from "uuid";

import {useMainStore} from "@/stores/mainStore";

interface DropZoneFile extends File {
  imageName?: string;
  lastModifiedDate?: string;
}

export function useDropzone() {
  const mainStore = useMainStore();
  const {userData} = storeToRefs(mainStore);

  const openDialog = inject<Function>("openDialog");

  const data = reactive({
    processedImgsCount: -1,
    files: [],
    loading: false,
    feedback: "",
  });

  const afterComplete = async (
    uploads: DropZoneFile[],
    deleteOnLoadRef = "",
  ) => {
    const imageName = uuid.v1();
    try {
      //save image
      const [upload] = uploads;
      const file = upload;
      const fileName = file.name.replace(/[!&/\\#,+()$~%'":*?<>{}]/g, "");
      file.imageName = imageName;
      const metadata = {contentType: file.type};
      const storageRef = getStorage();

      const storageFolder = _.get(
        userData.value,
        "client_slug",
        "unknow_client",
      );
      const storageName =
        moment().format("YYYY_MM_DD_HH[h]mm[m]ss") + "-" + fileName;
      const storagePath = `${storageFolder}/${storageName}`;
      const imageRef = fbRef(storageRef, storagePath);
      await uploadBytes(imageRef, file, metadata);
      const downloadURL = await getDownloadURL(imageRef);
      data.files.push({
        imageName,
        src: downloadURL,
        name: file.name,
        lastModifiedDate: file.lastModifiedDate,
        size: file.size,
        type: file.type,
        storageFolder,
        storageName,
        storagePath,
      });
      if (deleteOnLoadRef) {
        (this as any).$refs[deleteOnLoadRef]?.$refs?.imgDropZone?.removeFile(
          upload,
        );
      }
      data.loading = false;
      data.feedback = "";
    } catch (error) {
      openDialog(null, null, error);
    }
  };

  const afterRemovedFile = (file: DropZoneFile) => {
    data.processedImgsCount -= 1;
    data.files = data.files.filter(
      ({imageName}: DropZoneFile) => imageName !== file.imageName,
    );
  };

  return {
    ...toRefs(data),
    afterComplete,
    afterRemovedFile,
  };
}
