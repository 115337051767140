<template>
  <v-menu min-width="250px">
    <template v-slot:activator="{props}">
      <FButtonIcon
        v-bind="props"
        icon="more-vertical"
        square
        width="24"
        :data-testid="TEST_IDS.WRAPPER_SETTING__ACTIVATOR"
      />
    </template>
    <v-list class="bg-newAppBackground fbody-1 cursor-pointer">
      <v-list-item
        v-for="action in displayedActions"
        :key="action.title"
        :data-testid="action.testid"
        :disabled="action.disabled"
        @click="action.onClick"
      >
        <template v-if="action.icon" #prepend>
          <vue-feather
            class="mr-2"
            :class="{'is-hacky': action.isHacky}"
            :type="action.icon"
            size="24"
          />
        </template>

        {{ action.title }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {storeToRefs} from "pinia";
import {FButtonIcon} from "@/components/Global/Homemade";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {filterOnPermissions} from "@/tscript/utils/permissions";
import {SIMULATION_STATUS, TEST_IDS} from "@/config/constants";
import type {
  PermissionsItem,
  SettingsAction,
  TemporarySettingsAction,
} from "@/interfaces";

import {useSimulationStore} from "@/stores/simulationStore";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useIsArchivedSimulation} from "@/composables/useIsArchivedSimulation";

export default defineComponent({
  name: "simulation-wrapper-setting",
  components: {FButtonIcon},
  setup() {
    const {currentPermissions} = storeToRefs(usePermissionsStore());

    const simulationStore = useSimulationStore();
    const {simulationBtnClicked} = storeToRefs(simulationStore);
    const {updateSimulationFromScheduling} = simulationStore;
    const {archiveSimulation, deleteSimulation} = simulationStore;

    const mainStore = useMainStore();
    const {simulation, userData, isDevEnv, isScheduling, clientParameters} =
      storeToRefs(mainStore);

    const {selectedSimulation} = storeToRefs(useSchedulingStore());

    const {isArchivedSimulation} = useIsArchivedSimulation();

    return {
      simulationBtnClicked,
      archiveSimulation,
      deleteSimulation,
      currentPermissions,
      TEST_IDS,
      simulation,
      userData,
      isDevEnv,
      isScheduling,
      clientParameters,
      selectedSimulation,
      updateSimulationFromScheduling,
      isArchivedSimulation,
    };
  },
  computed: {
    isAdmin(): boolean {
      return [
        "ADMIN",
        "GROUP_ADMIN",
        "CLIENT_SUPER_ADMIN",
        "CLIENT_ADMIN",
      ].includes(this.userData?.role);
    },
    displayedActions(): SettingsAction[] {
      const {simulationActions, schedulingActions, devActions, isScheduling} =
        this;
      return [
        ...this.mapDefaultActions(
          isScheduling ? schedulingActions : simulationActions,
        ),
        ...devActions,
      ].filter(
        (action: SettingsAction): boolean =>
          !("isDisplayed" in action) || action.isDisplayed,
      );
    },
    devActions(): Array<SettingsAction & {isHacky: boolean}> {
      if (!this.isDevEnv) return [];

      return Array.from(
        [
          {
            title: "Déclencher une FDialog",
            icon: "alert-triangle",
            onClick: () => this.$openDialog(null, "GENERIC_ERROR"),
          },
        ],
        (action: SettingsAction): SettingsAction & {isHacky: boolean} => ({
          ...action,
          isHacky: true,
        }),
      );
    },
    // FIXME: move store-able actions to the store
    // some logic has been moved to the store for usage at any point in the app
    // the corresponding items are the ones with onClick as a function
    simulationActions(): PermissionsItem<
      TemporarySettingsAction | SettingsAction
    >[] {
      const {isAdmin, simulation} = this;

      const actions = [
        {
          title: "Simulation.create_new_simulation",
          icon: "plus",
          onClick: () => this.$openSimulationModal(false),
          testid: TEST_IDS.WRAPPER_SETTING__PLANNING__CREATE_SIMULATION_BUTTON,
          permissions: [
            this.currentPermissions.general.create_update_simulation,
          ],
        },
        {
          title: "Simulation.export_perimeter",
          icon: "share",
          onClick: "export",
        },
        {
          title: `Simulation.${isAdmin ? "edit" : "view"}_simulation`,
          icon: isAdmin ? "edit-2" : "info",
          onClick: () => this.$openSimulationModal(true),
          testid: TEST_IDS.WRAPPER_SETTING__PLANNING__UPDATE_SIMULATION_BUTTON,
          permissions: [
            this.currentPermissions.general.create_update_simulation,
          ],
        },
        {
          title: `Simulation.update_simulation_from_ordo`,
          icon: "refresh-cw",
          onClick: () =>
            this.$openDialog({
              type: "warning",
              message: this.$t("Simulation.update_from_ordo_warning", {
                pdp_simulation: this.simulation.name,
                ordo_simulation: this.simulation.import_ids[0].name,
              }),
              action: () => {
                this.updateSimulationFromScheduling();
              },
            }),
          permissions: [
            this.currentPermissions.general.create_update_simulation,
            this.simulation.import_ids?.[0]?.from_simu,
            !this.isArchivedSimulation,
          ],
        },
        {
          title: "Simulation.archive_all",
          icon: "archive",
          isDisplayed:
            isAdmin && simulation?.status !== SIMULATION_STATUS.ARCHIVED,
          onClick: () => this.archiveSimulation({is_from_menu: true}),
          testid: TEST_IDS.WRAPPER_SETTING__PLANNING__ARCHIVE_SIMULATION_BUTTON,
          permissions: [
            this.currentPermissions.general.create_update_simulation,
          ],
        },
        {
          title: "Simulation.delete_all",
          icon: "trash-2",
          isDisplayed: isAdmin,
          onClick: () => this.deleteSimulation(),
          testid: TEST_IDS.WRAPPER_SETTING__PLANNING__DELETE_SIMULATION_BUTTON,
          permissions: [
            this.currentPermissions.general.create_update_simulation,
          ],
        },
      ];

      return filterOnPermissions(actions);
    },
    schedulingActions(): PermissionsItem<
      TemporarySettingsAction | SettingsAction
    >[] {
      const actions = [
        {
          title: "Simulation.create_new_simulation",
          icon: "plus",
          onClick: "createSimulation",
          testid:
            TEST_IDS.WRAPPER_SETTING__SCHEDULING__CREATE_SIMULATION_BUTTON,
          permissions: [
            this.currentPermissions.general.create_update_simulation,
            !this.clientParameters.unique_scheduling_simulation,
          ],
        },
        {
          title: "scheduling.edit_simulation",
          icon: "edit-2",
          onClick: "simulationModal",
          testid:
            TEST_IDS.WRAPPER_SETTING__SCHEDULING__UPDATE_SIMULATION_BUTTON,
          permissions: [
            this.currentPermissions.general.create_update_simulation,
          ],
        },
        {
          title: "scheduling.export_data",
          icon: "share",
          onClick: "export",
        },
        {
          title: "scheduling.delete_simulation",
          icon: "trash-2",
          onClick: () =>
            this.deleteSimulation({simulation: this.selectedSimulation}),
          testid:
            TEST_IDS.WRAPPER_SETTING__SCHEDULING__DELETE_SIMULATION_BUTTON,
          permissions: [
            this.currentPermissions.general.create_update_simulation,
          ],
          disabled:
            !this.isDevEnv &&
            this.clientParameters.unique_scheduling_simulation,
        },
      ];
      return filterOnPermissions(actions);
    },
  },
  methods: {
    /**
     * the onClick attribute of the @actions items is a string that is set to the store variable simulationBtnClicked
     * to perform specific logics upon clicking one of these actions, there must be a listener on simulationBtnClicked
     * within the corresponding component
     * NB: the logic behind this was refactorized *lightly* so that this component could be used globally
     *
     * e.g.
     * watch: {
     *  simulationBtnClicked (value: string) {
     *    if (value === "download") return this.downloadWhatever()
     *    ...
     *  }
     * }
     */
    mapDefaultActions(actions: TemporarySettingsAction[]): SettingsAction[] {
      return Array.from(
        actions,
        ({title, onClick, ...rest}): SettingsAction => ({
          title: this.$t(title),
          onClick:
            typeof onClick === "string"
              ? () => (this.simulationBtnClicked = onClick)
              : onClick,
          ...rest,
        }),
      );
    },
  },
});
</script>

<style scoped lang="scss">
.is-hacky {
  position: relative;

  &::after {
    content: "hacky";
    position: absolute;
    right: -16px;
    bottom: -8px;
    background-color: red;
    color: white;
    border-radius: 4px;
    padding: 2px;
    font-size: 8px;
    font-weight: 600;
  }
}
</style>
