// custom plugins creating custom components from an instance method
import openDialog from "./dialog";
import openSimulationModal from "./simulationModal";
import openSnackbar from "./snackbar";
import openOFSidebar from "./ofSidebar";
import dependenciesPlugin from "./dependencies";
import openConwipTicketsGaugeSidebar from "./conwipTicketsGaugeSidebar";

// custom components
import OplitContainer from "@/components/Commons/OplitContainer.vue";

// custom directives
import defineColor from "./defineColor";
import tooltip from "./tooltip";

const instancesMethods = [
  openDialog,
  openSimulationModal,
  openSnackbar,
  openOFSidebar,
  dependenciesPlugin,
  openConwipTicketsGaugeSidebar,
];

const components = {"oplit-container": OplitContainer};

const useCustomPlugins = (app, options) => {
  // creates instance methods from imported plugins
  instancesMethods.forEach((method) => app.use(method, options));

  // register all globally used custom components
  Object.entries(components).forEach(([name, component]) =>
    app.component(name, component),
  );

  // register all custom directives
  app.directive("tooltip", tooltip(app));
  app.directive("define-color", defineColor(app));
};

export default useCustomPlugins;
