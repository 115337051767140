<template>
  <v-switch
    v-model="currentValue"
    :class="switchClasses"
    :flat="!notFlat"
    hide-details
    :label="label"
    :disabled="disabled"
    :color="disabled ? 'blanc' : color"
    :ripple="false"
    density="compact"
    @update:model-value="() => $emit('update:model-value', currentValue)"
  >
    <template v-if="$slots.label" v-slot:label>
      <div class="switch--label" @click.prevent>
        <slot name="label" />
      </div>
    </template>
  </v-switch>
</template>

<script scoped lang="ts">
import {defineComponent, ref, computed} from "vue";

export default defineComponent({
  name: "figma-switch",
  props: {
    label: String,
    color: {type: String, default: "primary"},
    modelValue: Boolean,
    disabled: Boolean,
    notFlat: {
      type: Boolean,
      default: false,
    } /* enables/disables the shadox box around the toggle (seems to be broken on
    the current Vuetify version) */,
  },
  emits: ["update:model-value"],
  setup(props) {
    const currentValue = ref<boolean>();

    const switchClasses = computed(() => [
      "switch",
      ...[!props.notFlat && "switch-flat"],
      ...[!!currentValue.value && "switch-active"],
    ]);

    const computedColor = computed(
      () => "rgb(var(--v-theme-" + props.color + "))",
    );

    return {
      currentValue,
      switchClasses,
      computedColor,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler: function (val: any) {
        this.currentValue = !!val;
      },
    },
  },
});
</script>

<style scoped lang="scss">
.v-input {
  --v-input-control-height: unset;
  --v-input-padding-top: unset;
}

:deep(.v-selection-control) {
  --v-selection-control-size: unset;
}

.switch {
  width: fit-content;

  :deep(.v-selection-control--disabled) {
    opacity: unset;

    .v-switch__track {
      background-color: rgb(var(--v-theme-newDisableText));
    }

    .v-switch__thumb {
      background-color: rgb(var(--v-theme-newDisableText));
    }
  }

  :deep(label) {
    color: rgb(var(--v-theme-newSubText));
    opacity: unset;
  }

  &-flat {
    :deep(.v-switch__thumb) {
      box-shadow: none;
    }
  }

  &:not(.switch-active) {
    :deep(.v-switch__track) {
      background-color: rgb(var(--v-theme-newDisableBG));
    }

    :deep(.v-selection-control--disabled) {
      .v-switch__thumb {
        background-color: rgb(var(--v-theme-newDisableBG));
      }
    }
  }
  &-active {
    :deep(.v-switch__thumb) {
      background-color: v-bind(computedColor);
    }
  }

  .switch--label {
    cursor: default;

    * {
      cursor: default;
    }
  }
}
</style>
