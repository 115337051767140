<template>
  <div
    :class="[
      isSyntheticView ? 'gap-2' : 'gap-4',
      {'is-synthetic': isSyntheticView},
    ]"
    class="fd-flex-center flex-wrap"
  >
    <OperationStatus
      v-if="!hideStatus"
      :operation="operation"
      :hide-chip-text="hideOperationStatusText"
      @change-status="(update) => $emit('change-status', {update})"
    />

    <SchedulingTagChip
      v-for="tag in displayedTags"
      :key="tag"
      :tag="tag"
      :removable="canRemoveTag"
      :force-display="forceTagDisplay"
      @remove="$emit('remove', tag)"
    />

    <v-menu v-if="hiddenTags.length > 0">
      <template v-slot:activator="{props}">
        <FChip v-bind="props" color="newDisableBG" data-testid="add-tags-chip">
          +{{ hiddenTags.length }}
        </FChip>
      </template>

      <div class="d-flex flex-column gap-4 pa-2">
        <SchedulingTagChip v-for="tag in hiddenTags" :key="tag" :tag="tag" />
      </div>
    </v-menu>

    <FChip
      v-if="computedFault"
      color="newPinkLight2 "
      text-color="newPinkDark1"
      data-testid="fault-chip"
    >
      <v-img
        v-if="!['none', ''].includes(computedFault)"
        :src="getAssetURL('icons', `${computedFault}.svg`)"
        width="14"
        class="mr-2"
        cover
      />
      <span data-testid="fault-chip-label">
        {{ $t(`operation.faults.${computedFault || "none"}`) }}
      </span>
    </FChip>

    <SchedulingNewTagsMenu
      v-if="canAddTag"
      :tags="operation.tags"
      :z-index="zIndex"
      @add="(tags) => $emit('add', tags)"
    />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from "vue";
import {storeToRefs} from "pinia";
import {FChip} from "@/components/Global";
import {
  SchedulingNewTagsMenu,
  SchedulingTagChip,
} from "@/components/Scheduling/Tags";
import OperationStatus from "@/components/Scheduling/Operations/OperationStatus.vue";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {
  schedulingStatusColorBg,
  schedulingStatusColorText,
  getTagsTextColor,
} from "@/tscript/utils/schedulingUtils";
import {OF_STATUS} from "@/config/constants";
import type {SchedulingOperation} from "@/interfaces";
import {getAssetURL} from "@/tscript/utils/generalHelpers";
import {useSchedulingTag} from "@/composables/scheduling/useSchedulingTag";

export default defineComponent({
  name: "operation-tags",
  components: {
    FChip,
    SchedulingNewTagsMenu,
    SchedulingTagChip,
    OperationStatus,
  },
  props: {
    operation: {
      type: Object as PropType<Partial<SchedulingOperation>>,
      required: true,
      default: () => ({} as SchedulingOperation),
    },
    hideStatus: {type: Boolean, default: false},
    // prop passed down to the SchedulingTagChip component to force the display of a tag not existing in the `scheduling_tags` collection
    forceTagDisplay: {type: Boolean, default: false},
    removable: {type: Boolean, default: false},
    addable: {type: Boolean, default: false},
    isSyntheticView: {type: Boolean, default: false},
    zIndex: {type: Number, default: 2000},
    hideOperationStatusText: {
      type: Boolean,
      default: false,
    },
    maxDisplayedTags: {type: Number, default: -1},
  },
  emits: ["remove", "change-status", "add"],
  setup(props) {
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {getSchedulingTag} = useSchedulingTag();

    const operationTags = computed(() =>
      (props.operation.tags || [])
        .map((tag) => getSchedulingTag(tag))
        .filter(Boolean),
    );
    const displayedTags = computed(() => {
      if (props.maxDisplayedTags < 1) return props.operation.tags;
      return operationTags.value.slice(0, props.maxDisplayedTags);
    });
    const hiddenTags = computed(() => {
      if (props.maxDisplayedTags < 1) return [];
      return operationTags.value.slice(props.maxDisplayedTags);
    });

    return {
      statuses: ref<string[]>(Object.values(OF_STATUS)),
      currentPermissions,
      schedulingStatusColorBg,
      schedulingStatusColorText,
      getTagsTextColor,
      getAssetURL,
      displayedTags,
      hiddenTags,
      operationTags,
    };
  },
  computed: {
    canAddTag(): boolean {
      if (!this.currentPermissions.scheduling.create_update_of_tags)
        return false;
      return this.addable;
    },
    canRemoveTag(): boolean {
      if (!this.currentPermissions.scheduling.create_update_of_tags)
        return false;
      return this.removable;
    },
    computedFault(): string {
      const {operation} = this;
      return operation?.new_fault ?? operation?.fault ?? "";
    },
  },
});
</script>

<style lang="scss" scoped>
.is-synthetic {
  & .chip {
    font-size: 12px;
    padding: 0 4px;
  }
}
</style>
