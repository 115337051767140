import {createI18n} from "vue-i18n";
import fr from "./locales/fr.json";
import en from "./locales/en.json";
import _ from "lodash";

function loadLocaleMessages() {
  const messages = {};
  messages["fr"] = fr;
  messages["en"] = en;
  return messages;
}

const i18nInstance = createI18n({
  locale: import.meta.env.VITE_I18N_LOCALE || "fr",
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "fr",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  escapeParameterHtml: true,
  useComposition: true,
  legacy: false,
  postTranslation: (str: string) => {
    if (_.isString(str)) return str?.replace(/&apos;/gi, "´");
    return str;
  },
});

export default i18nInstance;

const i18n = i18nInstance.global;
export {i18n};
