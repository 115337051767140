import {computed} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {isArchivedSimulation as checkIsArchivedSimulation} from "@/tscript/utils/simulation";

export const useIsArchivedSimulation = () => {
  const isArchivedSimulation = computed<boolean>(() => {
    const {simulation, isScheduling} = storeToRefs(useMainStore());
    const {selectedSimulation} = storeToRefs(useSchedulingStore());
    const currentSimulation = isScheduling.value
      ? selectedSimulation.value
      : simulation.value;

    return checkIsArchivedSimulation(currentSimulation);
  });

  return {isArchivedSimulation};
};
