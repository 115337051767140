<template>
  <v-avatar class="ma-3 justify-center" :size="sizePicture" :color="color">
    <v-img v-if="computedPictureUrl" :src="computedPictureUrl" cover></v-img>
    <span v-else :class="classInitial" class="text-blanc">
      {{ initials }}
    </span>
  </v-avatar>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {stringToColor} from "@/tscript/utils";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";

export default defineComponent({
  name: "AvatarPicture",
  props: ["firstName", "lastName", "pictureUrl", "size", "userId"],
  setup() {
    const sizePicture = ref(70);
    const classInitial = ref("headline");
    const user = ref({});
    return {
      sizePicture,
      classInitial,
      user,
    };
  },
  methods: {
    async loadUser(uid: string) {
      this.user = await dbHelper.getDocFromCollection("users", uid);
    },
    loadData() {
      if (this.size) {
        this.sizePicture = this.size;
        if (this.size < 50) this.classInitial = "";
      }
    },
  },
  watch: {
    userId: {
      immediate: true,
      handler(val: string) {
        if (!val) return;
        this.loadUser(val);
      },
    },
  },
  computed: {
    color: function () {
      const computedColor = stringToColor(this.firstName + this.lastName);
      return computedColor;
    },
    computedPictureUrl() {
      const {user} = this;
      if (!user?.avatar_url) return this.pictureUrl;
      return user.avatar_url;
    },
    initials: function () {
      let computedInitials = "";
      const {user, firstName, lastName} = this;
      if (user?.last_name?.length > 0 && user?.first_name?.length > 0) {
        {
          computedInitials = `${user.first_name
            .charAt(0)
            .toUpperCase()}.${user.last_name.charAt(0).toUpperCase()}`;
        }
      } else if (lastName?.length > 0 && firstName?.length > 0)
        computedInitials = firstName.charAt(0) + lastName.charAt(0);

      return computedInitials;
    },
  },
  created() {
    this.loadData();
  },
});
</script>
