import {useI18n} from "vue-i18n";
import {
  getOFUnit as _getOFUnit,
  getOPUnit as _getOPUnit,
} from "@oplit/shared-module";

export function useInternationalizedFunctions() {
  const {t} = useI18n();

  const getOFUnit = (...args: Parameters<typeof _getOFUnit>) => {
    const [entity] = args;
    return _getOFUnit(entity, t("Commons.pieces"));
  };

  const getOPUnit = (...args: Parameters<typeof _getOPUnit>) => {
    const [entity, sector] = args;
    return _getOPUnit(entity, sector, t("Commons.hours"));
  };

  return {
    getOFUnit,
    getOPUnit,
  };
}
