import APIClient from "./index";
import type {IDailyCapacityRequest, IDailyCapacityResponse} from "@/interfaces";

class CapacitiesAPIClient extends APIClient {
  capacitiesAPIPath = "capacities";

  getCapacitiesRoutePath(members: string[]): string {
    return this.getRoutePath.call(this, [this.capacitiesAPIPath, ...members]);
  }

  async getDailyCapacityPerSector(
    parameters: IDailyCapacityRequest,
  ): Promise<[Error, IDailyCapacityResponse]> {
    try {
      const headers = await this.getHeaders();
      // /api/capacities/daily
      const {data}: {data: IDailyCapacityResponse} = await this.apiClient.post(
        this.getCapacitiesRoutePath(["daily"]),
        parameters,
        headers,
      );

      return [null, data];
    } catch (error) {
      return [error, null];
    }
  }

  // FIXME - move to correct api module/helper file all methods below (extern. from pgMixin)
  // also refacto using store values directly if possible
  async getAllProd(parameters: {
    client_id: string;
    secteur_id: string;
    parsedPeriod: any;
    team_id: string;
    detailed: boolean;
  }) {
    try {
      const {
        secteur_id,
        parsedPeriod,
        team_id = "",
        detailed = false,
      } = parameters;

      const {startDate, endDate} = parsedPeriod || {};
      const data = await this.apiClient.post(
        `/api/productions/all`,
        {
          end_date: endDate,
          secteur_id,
          detailed,
          team_id,
          start_date: startDate,
          group_by_sector: true,
        },
        await this.getHeaders(),
      );
      if (!data?.data?.length) return [null, []];
      return [null, data?.data];
    } catch (error) {
      return [error, null];
    }
  }

  async loadLastImport(parameters: {team: any; userData: any}) {
    try {
      const {team} = parameters;
      const {id: team_id} = team || {};
      const result = await this.apiClient.post(
        `/api/imports/last`,
        {team_id},
        await this.getHeaders(),
      );
      if (!result) return "";
      return [null, result?.import_id];
    } catch (error) {
      return [error, null];
    }
  }
}

export default CapacitiesAPIClient;
