<script setup lang="ts">
import {computed} from "vue";
import {getAssetURL} from "@/tscript/utils/generalHelpers";

interface MercateamLogoProps {
  height?: string | number;
  small?: boolean;
}

const props = withDefaults(defineProps<MercateamLogoProps>(), {
  height: "20",
  small: false,
});

const logoURL = computed<string>(() =>
  getAssetURL(props.small ? "mercateam_logo_small.png" : "mercateam_logo.png"),
);
</script>

<template>
  <img :src="logoURL" :height="height" alt="Mercateam Logo" />
</template>
