import {MaybeRef, ref, unref} from "vue";
import {useStorage} from "@vueuse/core";
import {buildLocalStorageUtils} from "@/tscript/utils/localStorageUtils";
import {MachineCenter, SchedulingFilter} from "@/interfaces";

const currentActiveLoadFilters = ref<SchedulingFilter[]>([]);
const currentActiveSchedulingCenters = ref<(MachineCenter | string)[]>([]);

const useSimulationDataExport = () => {
  function hasActiveFilters() {
    const filters = getCurrentExportedLoadFilters();
    return (
      filters.length && filters.some((filter) => filter.selectedItem.length)
    );
  }

  function getCurrentActiveSchedulingCenters() {
    return currentActiveSchedulingCenters.value;
  }

  function setCurrentActiveSchedulingCenters(
    centers: MaybeRef<(MachineCenter | string)[]>,
  ) {
    currentActiveSchedulingCenters.value = unref(centers);
  }

  function getCurrentExportedLoadFilters() {
    return currentActiveLoadFilters.value;
  }

  function setCurrentExportedLoadFilters(
    filters: MaybeRef<SchedulingFilter[]>,
  ) {
    currentActiveLoadFilters.value = unref(filters);
  }

  function initCurrentExportedLoadFilters(
    filterStorageId: string,
    baseFilters: SchedulingFilter[],
  ) {
    const {buildStorageKey} = buildLocalStorageUtils(
      `${filterStorageId}_planning_filter`,
    );
    const filters = useStorage(
      buildStorageKey("schedulingFilters"),
      baseFilters,
    );

    setCurrentExportedLoadFilters(filters.value);

    return getCurrentExportedLoadFilters();
  }

  return {
    hasActiveFilters,
    getCurrentActiveSchedulingCenters,
    setCurrentActiveSchedulingCenters,
    getCurrentExportedLoadFilters,
    setCurrentExportedLoadFilters,
    initCurrentExportedLoadFilters,
  };
};

export default useSimulationDataExport;
