import {ref} from "vue";
import {defineStore, storeToRefs} from "pinia";
import _ from "lodash";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {SchedulingTag} from "@oplit/shared-module";
import {useMainStore} from "./mainStore";

export const useCustomerInterfaceStore = defineStore(
  "customerInterface",
  () => {
    const mainStore = useMainStore();

    const customerInterfaceTags = ref<SchedulingTag[]>([]);
    const customerDetails = ref<{id: string; name: string}[]>([]);
    const providerIds = ref<string[]>([]);

    async function getTagsFromIds(tagIds: string[]): Promise<SchedulingTag[]> {
      const chunkIds = _.chunk(tagIds, 10);
      const promises = chunkIds.map(async (ids) => {
        const chunkedTags: SchedulingTag[] =
          await dbHelper.getAllDataFromCollectionWithAll("scheduling_tags", {
            where: [{field: "id", compare: "in", value: ids}],
          });
        return chunkedTags || [];
      });
      const tags = (await Promise.all(promises)).flat();

      return tags;
    }

    async function loadCustomersFromIds(): Promise<void> {
      const {apiClient} = storeToRefs(mainStore);
      const [, results] = await apiClient.value.getCustomersDetails();
      if (results) customerDetails.value = results;
    }

    async function loadProviderIds(): Promise<void> {
      const {apiClient} = storeToRefs(mainStore);
      const [, results] = await apiClient.value.getProviderIds();
      if (results) providerIds.value = results;
    }

    return {
      customerInterfaceTags,
      customerDetails,
      providerIds,
      getTagsFromIds,
      loadCustomersFromIds,
      loadProviderIds,
    };
  },
);
