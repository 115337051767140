// rm src/tscript/loggerHelper/index.ts && ln shared/loggerHelper/index.ts src/tscript/loggerHelper/index.ts

export default class loggerHelper {
  static report(e: any) {
    // if (e) console.log(e);
  }
  static log(...args: any) {
    // if (args) console.log(...args);
  }
  static timer(...args: any) {
    // if (args) console.log(...args);
  }
  static time(...args: any) {
    // if (args) return console.time(...args);
  }
  static timeEnd(...args: any) {
    // if (args) return console.timeEnd(...args);
  }
  static timeLog(...args: any) {
    // if (args) console.timeLog(...args);
  }
  static error(...args: any) {
    // if (args) console.error(...args);
  }
  static info(...args: any) {
    // if (args) console.info(...args);
  }
}
