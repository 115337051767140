<template>
  <FChip
    v-if="computedTag"
    :color="computedTag.color_name"
    :text-color="getTagsTextColor(computedTag.color_name)"
    :close="removable"
    class="tag-chip"
    show-tooltip
    :data-testid="`scheduling-tag-chip-${computedTag.label}`"
    @close="$emit('remove', computedTag)"
  >
    <slot>
      <span :data-testid="`scheduling-tag-chip-${computedTag.label}-label`">
        {{ computedTag.label }}
      </span>
    </slot>
  </FChip>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from "vue";
import {storeToRefs} from "pinia";
import {FChip} from "@/components/Global";
import {getTagsTextColor} from "@/tscript/utils/schedulingUtils";
import {type SchedulingTag} from "@oplit/shared-module";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingTag} from "@/composables/scheduling/useSchedulingTag";

export default defineComponent({
  name: "scheduling-tag-chip",
  components: {
    FChip,
  },
  props: {
    tag: {
      type: [String, Object] as PropType<string | SchedulingTag>,
      required: true,
    },
    forceDisplay: {
      type: Boolean,
      default: false,
    },
    removable: {type: Boolean, default: false},
  },
  emits: ["remove"],
  setup(props) {
    const {variables} = storeToRefs(useMainStore());
    const {getSchedulingTag} = useSchedulingTag();

    const computedTag = computed<SchedulingTag | Partial<SchedulingTag>>(() =>
      getSchedulingTag(props.tag, props.forceDisplay),
    );

    return {
      variables,
      computedTag,
      getTagsTextColor,
    };
  },
});
</script>

<style lang="scss">
.tag-chip {
  max-width: 150px;
  & button {
    // close icon size
    font-size: 14px !important;
  }
}
</style>
