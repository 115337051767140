import _ from "lodash";
import moment from "moment";

import {
  asyncForEach,
  levelCorresp,
  parseDate,
  simplifySector,
  getSortedPerimeters,
} from "@oplit/shared-module";

import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {collections} from "../simulationData";
import {getMSDData} from "@/tscript/utils/sharedModuleHelper";

const prepareImportPromises = async function (
  client_id: string,
  ignoreSimulations = false,
  existingSimulation: any = {},
  team: any = {},
) {
  let promises = [];
  if (!ignoreSimulations) {
    promises.push(
      dbHelper.getAllDataFromCollectionWithAll("simulations", {
        where: [{field: "client_id", value: client_id, compare: "=="}],
        orderBy: [{field: "created_at", direction: "desc"}],
      }),
    );
  } else promises.push([]);

  //We fetch latest imports
  const missingImportIds = collections.filter(
    (coll: any) =>
      !(existingSimulation?.import_ids || []).some(
        (importId: any) => importId.collection === coll.dataName,
      ),
  );
  if (missingImportIds.length)
    promises = [...promises, ...loadImportIds(client_id, missingImportIds)];

  const values = await Promise.all(promises);

  //simulations
  let simulations: any = [],
    simulation: any = existingSimulation;
  if (!ignoreSimulations) {
    simulations = _.get(values, 0, []);
    simulation =
      simulations.find((x: any) => x.status === "active") || existingSimulation;
  }

  //imports
  let import_ids = simulation?.import_ids || [];
  if (!simulation?.import_ids?.length) {
    const importValues = values.slice(1);
    import_ids = [];

    missingImportIds.forEach((collection: any, idx: number) => {
      if (
        import_ids.some(
          (importId: any) => collection.dataName === importId.collection,
        )
      )
        return;
      const value = (_.get(importValues, [idx]) || []).find(
        (x: any) =>
          x.status === "inserted" &&
          (!x.teams?.length ||
            (x.teams || []).some((t: any) => t.id === team?.id)),
      );
      if (!value) return;
      import_ids.push({
        collection: collection.dataName || null,
        collections: value.data || [],
        id: value.id || null,
        date: value.date || null,
        name: value.name || null,
        ...(value.data_in_bquery && {data_in_bquery: true}),
        ...(value.teams?.length && {teams: value.teams}),
      });
    });
  }

  return {simulations, simulation, import_ids};
};

const loadImportIds = function (
  client_id: string,
  missingImportIds: any = collections,
) {
  const promises = [];
  missingImportIds.forEach((collection: any) => {
    promises.push(
      dbHelper.getAllDataFromCollectionWithAll("imports", {
        where: [
          {field: "client_id", value: client_id, compare: "=="},
          {
            field: "data",
            value: collection.dataName,
            compare: "array-contains",
          },
        ],
        orderBy: [{field: "date", direction: "desc"}],
      }),
    );
  });
  return promises;
};

const initializeDates = (simulation: any = {}) => {
  let todayDate = undefined,
    dateMin = undefined;
  if (simulation?.forceToday) todayDate = simulation.forceToday;
  if (simulation?.dateMin) dateMin = simulation.dateMin;
  return {todayDate, dateMin};
};

const importAllData = async function (
  client_id: string,
  secteur: any,
  import_ids: any,
  active_msd: any,
  parametres: any,
  todayDate: string,
  perimetres: any,
  onlyImports = false,
) {
  const {
    netPeriodeHisto = 0,
    netPeriodePrev = 4,
    maille = "month",
  } = parametres || {};

  const ofDateQuery = [
    {
      field: "datelancement",
      value: moment(todayDate || undefined)
        .startOf(maille)
        .subtract(netPeriodeHisto, maille + "s")
        .format("YYYY-MM-DD"),
      compare: ">=",
    },
    {
      field: "datelancement",
      value: moment(todayDate || undefined)
        .endOf(maille)
        .add(netPeriodePrev - 1, (maille + "s") as any)
        .format("YYYY-MM-DD"),
      compare: "<=",
    },
  ];
  const commandesQuery = [{field: "name", value: secteur.name, compare: "=="}];

  const promises = [];
  import_ids.forEach((import_id: any) => {
    const {collection, data_in_bquery} = import_id;
    if (!data_in_bquery) {
      promises.push(
        dbHelper.getAllDataFromCollectionWithAll(import_id.collection, {
          where: [
            {field: "client_id", value: client_id, compare: "=="},
            {field: "import_id", value: import_id.id, compare: "=="},
            ...(collection !== "commandes"
              ? [
                  {
                    field: "level",
                    value: secteur.level / 1,
                    compare: "==",
                  },
                ]
              : []),
            ...(collection === "commandes"
              ? commandesQuery
              : [
                  {
                    field: ["ofs", "en_cours"].includes(collection)
                      ? "secteur_id"
                      : "id",
                    value: secteur.id,
                    compare: "==",
                  },
                ]),
            ...(["ofs", "en_cours"].includes(collection) ? ofDateQuery : []),
          ],
          ignoreIds: true,
        }),
      );
    }
  });
  //We fetch msd data
  if (active_msd.id) {
    promises.push(getMSDData(client_id, secteur, active_msd, "secteur_id"));
    promises.push(
      getMSDData(client_id, secteur, active_msd, secteur.type + "_id"),
    );
    promises.push(
      getMSDData(client_id, secteur, active_msd, "secteur_id", "charge"),
    );
    promises.push(
      getMSDData(
        client_id,
        secteur,
        active_msd,
        secteur.type + "_id",
        "charge",
      ),
    );
  } else {
    promises.push([]);
    promises.push([]);
    promises.push([]);
    promises.push([]);
  }
  //We fetch OFs
  const ofsImport = import_ids.find((x: any) => x?.collection === "ofs");
  if (ofsImport && !secteur.movex_id && !ofsImport.data_in_bquery) {
    promises.push(
      getChildrenOfs(
        ofsImport,
        client_id,
        secteur.type,
        secteur.id,
        ofDateQuery,
      ),
    );
  } else promises.push([]);

  const enCoursImport = import_ids.find(
    (x: any) => x?.collection === "en_cours",
  );
  if (enCoursImport && !enCoursImport.data_in_bquery) {
    promises.push(
      getChildrenOfs(
        enCoursImport,
        client_id,
        secteur.type,
        secteur.id,
        ofDateQuery,
      ),
    );
  } else promises.push([]);

  const values = await Promise.all(promises);
  const series: any = {};
  await asyncForEach(import_ids, async (import_id: any, idx: number) => {
    series[import_id.collection] = _.get(
      values,
      ["ofs", "en_cours"].includes(import_id.collection) ? idx : [idx, 0],
    );
    if (
      import_id.collection === "ofs" &&
      ofsImport &&
      !import_id.data_in_bquery
    ) {
      const [childOfs] = values.slice(-2);
      series.ofs = [
        ...(series.ofs || []),
        ...(childOfs || []).map((x: any) => ({
          ...x,
          name: [x.secteur_name, x.name].join(" > "),
        })),
      ];
    } else if (import_id.collection === "ofs" && import_id.data_in_bquery) {
      series.ofs = (_.get(values, [idx, "data"]) || []).map((of: any) => {
        const clean_of: any = {};
        Object.keys(of).forEach((key: string) => {
          if (of[key] !== "") clean_of[key.toLowerCase()] = of[key];
        });
        return clean_of;
      });
    }
    if (
      import_id.collection === "en_cours" &&
      enCoursImport &&
      !import_id.data_in_bquery
    ) {
      const [childEnCours] = values.slice(-1);
      series.en_cours = [
        ...(series.en_cours || []),
        ...(childEnCours || []).map((x: any) => ({
          ...x,
          name: [x.macrogamme, x.secteur_name, x.name].join(" > "),
        })),
      ];
    } else if (
      import_id.collection === "en_cours" &&
      import_id.data_in_bquery
    ) {
      series.en_cours = (_.get(values, [idx, "data"]) || []).map((of: any) => {
        const clean_of: any = {};
        Object.keys(of).forEach((key: string) => {
          if (of[key] !== "") clean_of[key.toLowerCase()] = of[key];
        });
        return clean_of;
      });
    }
  });

  //I need to handle the case where one lower level parent has a movex_id, thus filtering all the children OFs
  if (series.ofs?.length) {
    const filterRecursively = (serie: any, results: any = []) => {
      if (!serie?.children?.length) return results;
      if (serie.movex_id) results.push(serie);
      else {
        serie.children.forEach((child: any) => {
          if (!child) return;
          const childSerie = perimetres[child.collection].find(
            (x: any) => x.id === child.id,
          );
          results = filterRecursively(childSerie, results);
        });
      }
      return results;
    };
    const sectorFilters = filterRecursively(secteur) || [];
    series.ofs = series.ofs.filter(
      (of: any) => !sectorFilters.some((x: any) => x.id === of[x.type + "_id"]),
    );
  }
  const msdData = values
    .slice(-6, -4)
    .reduce((acc: any, curr: any) => [...acc, ...(curr || [])], []);
  const msdChargeData = values
    .slice(-4, -2)
    .reduce((acc: any, curr: any) => [...acc, ...(curr || [])], []);
  //I compute the capa serie from the perimetres
  if (onlyImports) return series;
  const capa = computeChildrenRecursively(perimetres, secteur, msdData);
  const charge = computeChildrenRecursively(
    perimetres,
    secteur,
    msdChargeData,
    true,
  );
  return {...series, capa, charge};
};

const getChildrenOfs = function (
  ofsImport: any,
  client_id: string,
  secteur: string,
  secteur_id: string,
  ofDateQuery: any,
) {
  return dbHelper.getAllDataFromCollectionWithAll(ofsImport.collection, {
    where: [
      {field: "client_id", value: client_id, compare: "=="},
      {field: "import_id", value: ofsImport.id, compare: "=="},
      {
        field: secteur + "_id",
        value: secteur_id,
        compare: "==",
      },
      ...ofDateQuery,
    ],
    ignoreIds: true,
  });
};

const computeChildrenRecursively = function (
  perimetres: any,
  serie: any,
  msdData: any,
  removeFormula = false,
) {
  if (!serie) return null;
  const simpleActivePerim = simplifySector(serie);
  let copySerie = JSON.parse(JSON.stringify(simpleActivePerim));
  //we match it with its msd
  const msdItem = {
    ...(msdData.find(
      (x: any) =>
        x.status !== "removed" &&
        x.secteur_id === copySerie.id &&
        levelCorresp.every(
          (level: any) =>
            x[level.type + "_id"] === copySerie[level.type + "_id"] ||
            (!x[level.type + "_id"] && !copySerie[level.type + "_id"]),
        ),
    ) || {}),
  };
  delete msdItem.id;
  delete msdItem.name;
  delete msdItem.secteur_id;
  delete msdItem.secteur_name;
  if (removeFormula) delete copySerie.formula;
  copySerie = {...copySerie, ...msdItem};
  if (copySerie?.children?.length) {
    copySerie.children = getSortedPerimeters(
      (copySerie.children || []).map((child: any) => {
        if (!child) return null;
        let item = (perimetres[child.collection] || []).find(
          (y: any) => y.id === child.id,
        );
        item = computeChildrenRecursively(
          perimetres,
          item,
          msdData,
          removeFormula,
        );
        return item;
      }),
    );
  }
  return copySerie;
};

const buildCommandesSerie = function (commandes: any, todayDate: any) {
  if (!commandes?.data?.length) return [];
  const retard =
    _.sumBy(commandes.data, (o: any) => o.retard_restante_reel) || 0;

  const newArray = _.sortBy(
    commandes.data.filter((x: any) =>
      moment(parseDate(x.label)).isBetween(
        moment(todayDate || undefined).startOf("month"),
        moment(todayDate || undefined)
          .endOf("month")
          .add(12, "months"),
      ),
    ),
    (o: any) => parseDate(o.label),
  );
  return newArray.map((x: any, idx: number) =>
    idx === 0 ? {...x, retard} : x,
  );
};

export {
  prepareImportPromises,
  initializeDates,
  importAllData,
  buildCommandesSerie,
};
