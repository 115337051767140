import {storeToRefs} from "pinia";
import {unref} from "vue";
import _ from "lodash";
import {ClientParameter, PermissionsByRole, User} from "@/interfaces";
import {LocationQuery, RouteLocation} from "vue-router";
import {useMainStore} from "@/stores/mainStore";

/**
 * returns the accessibility requirements for a specific route
 */
const routeRequires = (
  route: RouteLocation,
  metaAuthorizationKey: string,
): boolean => {
  return route.matched.some((rec: any) => rec.meta[metaAuthorizationKey]);
};

/**
 * redirects an unauthorized user out of a guarded route
 * every value that is a key of userData used here should be also set
 * to the localStorage when defining LOCAL_STORAGE.USER_DATA
 * because we fallback on these before loading the actual store userData
 * @returns an object passed as an argument to the vue router next() function
 */
const handleNewUserDataNavigation = (userData: User, to: RouteLocation) => {
  const {defaultRouteName} = storeToRefs(useMainStore());
  if (
    ![
      "requiresAdmin",
      "requiresSuperAdmin",
      "requiresScheduling",
      "requiresPDP",
      "requiresCustomerInterface",
    ].some((metaKey: string): boolean => routeRequires(to, metaKey))
  )
    return;

  if (routeRequires(to, "requiresScheduling") && !userData?.has_scheduling)
    return {name: unref(defaultRouteName)};

  if (routeRequires(to, "requiresPDP") && !userData?.has_production_planning)
    return {name: unref(defaultRouteName)};

  if (
    routeRequires(to, "requiresCustomerInterface") &&
    !userData?.has_customer_interface_customer_side
  )
    return {name: unref(defaultRouteName)};

  if (routeRequires(to, "requiresAdmin") && userData?.role !== "ADMIN")
    return {name: unref(defaultRouteName)};

  if (
    routeRequires(to, "requiresSuperAdmin") &&
    !["ADMIN", "CLIENT_SUPER_ADMIN"].includes(userData?.role)
  )
    return {name: unref(defaultRouteName)};

  return null;
};

/**
 * replaces a query parameter (@key) if already present and its value differs from @valueAgainst
 * returns the router query object updated
 * @param query the original query object
 * @param key the key to search for in the query object
 * @param valueAgainst the value to compare against in the original query
 * @returns the updated query object or undefined
 */
const handleRouteQueryReplace = function (
  query: LocationQuery,
  key: string,
  valueAgainst: string,
): LocationQuery | void {
  const queryValue: string | string[] = query?.[key];
  // there is no change between @valueAgainst and the current query value, we return
  if (queryValue && _.isEqual(queryValue, valueAgainst)) return;
  const newQuery = {...query};
  // we remove the key from the query if null or undefined
  if ([undefined, null, ""].includes(valueAgainst)) delete newQuery[key];
  // we replace otherwise
  else newQuery[key] = valueAgainst;
  if (_.isEqual(newQuery, query)) return;

  return newQuery;
};

const handleRouteUserPermissions = (
  route: RouteLocation,
  permissions: Partial<PermissionsByRole>,
): boolean => {
  const requirements = route.matched
    .map(({meta}) => meta?.permissions)
    .flat()
    .filter(Boolean);
  if (!requirements.length) return true;
  return requirements.every((requirement: string) =>
    _.get(permissions, requirement),
  );
};

const handleRouteClientPermissions = (
  route: RouteLocation,
  permissions: Pick<
    ClientParameter,
    | "has_module_pdp"
    | "has_module_scheduling"
    | "has_module_customer_interface_customer_side"
  >,
): boolean => {
  const mapParameterWithAuthKey = {
    has_module_pdp: "requiresPDP",
    has_module_scheduling: "requiresScheduling",
    has_module_customer_interface_customer_side: "requiresCustomerInterface",
  } as const;

  return Object.keys(mapParameterWithAuthKey).every(
    (key) =>
      !routeRequires(route, mapParameterWithAuthKey[key]) || permissions[key],
  );
};

export {
  handleNewUserDataNavigation,
  routeRequires,
  handleRouteQueryReplace,
  handleRouteUserPermissions,
  handleRouteClientPermissions,
};
