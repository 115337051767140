import {unref} from "vue";
import {useSectorTree} from "./useSectorTree";
import {MaybeRef} from "@vueuse/core";
import {OplitSimulationPeriod, SectorLike} from "@/interfaces";
import {
  getImportantKeys,
  getOperatorsKeys,
  isWorkDay,
  levelCorresp,
} from "@oplit/shared-module";
import {serverTimestamp} from "firebase/firestore";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {saveEventInFirestore} from "@/tscript/utils/eventSaver";
import moment from "moment";
import {
  useCalendars,
  useOrderedCalendars,
  useSectorCalendars,
} from "./useCalendar";
import {i18n} from "@/i18n";
import loggerHelper from "@/tscript/loggerHelper";
import {storeToRefs} from "pinia";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {useSimulationStore} from "@/stores/simulationStore";
import {useParameterStore} from "@/stores/parameterStore";

export const useSchedulingCapaSectorUpdater = (
  perimeter: MaybeRef<SectorLike>,
  period?: MaybeRef<OplitSimulationPeriod>,
) => {
  const {userData, disablePgRefresh, pgSse} = storeToRefs(useMainStore());
  const {saveSSEAndListen} = useSimulationStore();
  const {selectedSimulation, areSchedulingSimulationUpdatesDisabled} =
    storeToRefs(useSchedulingStore());
  const {loadClientParametersList} = useParameterStore();

  const {sectorTree} = useSectorTree(perimeter);
  const calendars = useCalendars();
  const sectorCalendars = useSectorCalendars(perimeter, period);
  const orderedCalendars = useOrderedCalendars(perimeter, period);

  const {t} = i18n;

  const forceDateToWorkday = async (date: string) => {
    if (isWorkDay(date, unref(orderedCalendars))) return;
    const {client_id, name: user_name, id: user_id} = unref(userData) || {};
    const {
      id: secteur_id,
      name: secteur_name,
      level,
      type,
      collection,
    } = unref(sectorTree) || {};
    let {site_id = null} = unref(sectorTree);
    if (!site_id && collection === "sites") site_id = secteur_id;
    const existing = unref(sectorCalendars).find((x: any) => x.date === date);
    let calendar: any = {},
      id: string;
    if (existing) {
      calendar = {isWorkday: true};
      id = existing.id;
    } else {
      id = dbHelper.getCollectionId("daily_calendar");
      calendar = {
        id,
        client_id,
        site_id,
        isWorkday: true,
        date,
        secteur_id,
        secteur_name,
        level,
        type,
        collection,
      };
      levelCorresp.forEach((level: any) => {
        if (unref(sectorTree)[level.type + "_id"])
          calendar[level.type + "_id"] = unref(sectorTree)[level.type + "_id"];
      });
    }
    calendar = {
      ...calendar,
      updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
      updated_by: {user_name, user_id},
    };
    return dbHelper
      .setDataToCollection("daily_calendar", id, calendar, true)
      .then(() => {
        let newCalendars: any[];
        if (existing) {
          newCalendars = unref(calendars).map((x: any) =>
            x.id === id ? {...x, ...calendar} : x,
          );
        } else newCalendars = [...unref(calendars), calendar];
        calendars.value = newCalendars;
      });
  };

  const newQuickEvent = async (
    child: any,
    title: string,
    periode: [string, string],
    shouldNotForceOpenCalendarDays: boolean,
  ) => {
    const {id: simulation_id} = unref(selectedSimulation) || {};
    if (unref(areSchedulingSimulationUpdatesDisabled)) return false;
    const {id: user_id, first_name, last_name, client_id} = unref(userData);
    const useSse = unref(pgSse).includes("capa");

    const sector = unref(sectorTree);
    const {secteur_id, secteur_name} = sector;
    if (!sector?.id) return false;

    //We get the msd parameters
    const [, parametres_msd = {}] = await loadClientParametersList();

    //Filter unnecessary fields in operateurs
    if (child.operateurs?.length) {
      child.operateurs = child.operateurs.map((x: any) =>
        getOperatorsKeys(x, parametres_msd),
      );
    } else if (child.machine_tags?.length) {
      child.machine_tags = child.machine_tags.map((x: any) =>
        getOperatorsKeys(x, parametres_msd),
      );
    }

    const initial = getImportantKeys(sector, parametres_msd);

    //We fill the child
    const unit = sector.unite || sector.unit || null;
    child = {
      ...child,
      secteur_id,
      secteur_name,
      periode: periode,
      initial,
      unit,
    };

    //We prepare the event
    const id = dbHelper.getCollectionId("events");
    const event = {
      id,
      client_id,
      title,
      impact: {},
      secteur_ids: [secteur_id],
      unit,
      type: "capa",
      status: "active",
      created_at: serverTimestamp(),
      created_by: {user_id, first_name, last_name},
      simulation_id,
      is_quick_event: true,
      is_scheduling: true,
      children: [child],
      updated_at: serverTimestamp(),
      updated_by: {user_id, first_name, last_name},
    };

    const error = await saveEventInFirestore(event, {
      use_event_sse: useSse,
      disable_pg_refresh: unref(disablePgRefresh),
      shouldErasePreviousMacroEvent: false,
      storeCommitCallback: () => (disablePgRefresh.value = false),
      sseCallback: (event: Event) =>
        saveSSEAndListen({
          data: event,
          dataType: "added",
          sectorTree: sector,
        }),
    });
    if (error) {
      loggerHelper.error(error);
      return;
    }
    if (!shouldNotForceOpenCalendarDays) {
      let dateToChange = periode[0];
      while (dateToChange <= periode[1]) {
        await forceDateToWorkday(dateToChange);
        dateToChange = moment(dateToChange).add(1, "days").format("YYYY-MM-DD");
      }
    }
    return event;
  };

  const updateSector = async (
    fieldModel: string,
    val: number | string,
    periode: [string, string],
    params?: {shouldNotForceOpenCalendarDays?: boolean},
  ) => {
    const child = {[fieldModel]: val};
    const {shouldNotForceOpenCalendarDays} = params || {};
    //We create the event
    return newQuickEvent(
      child,
      `${fieldModel} Modification ${t("Commons.à")} ${val}`,
      periode,
      !!shouldNotForceOpenCalendarDays,
    );
  };

  return {
    updateSector,
  };
};
