<template>
  <div
    class="dropdown-list dropdown-list--users d-flex flex-column"
    data-cy="dropdown-list-users"
    :style="{height}"
  >
    <div class="dropdown-header" v-if="title || !hideSearch">
      <div
        class="powered-by-header fd-flex-center justify-end mr-4 pt-1 pd-1 pr-2 br-8"
      >
        <span>{{ $t("Simulation.powered_by") }}</span>
        <img
          v-if="theme === 'light'"
          alt="fabric-logo-black"
          :src="getAssetURL('logos', 'fabriq-logo-black.png')"
          width="50"
          class="ml-2"
        />
        <img
          v-else
          alt="fabric-logo-white"
          :src="getAssetURL('logos', 'fabriq-logo-white.png')"
          width="50"
          class="ml-2"
        />
      </div>
      <h4 v-if="title">{{ title }}</h4>
      <FTextField
        v-if="!hideSearch"
        v-model="search"
        class="dropdown-search"
        data-cy="fdropdown-operator-search"
        icon="search"
        clearable
        :label="$t('global.search')"
        flush-label
        @click.stop
      />
    </div>
    <v-virtual-scroll
      :items="filteredOperators"
      :class="{'dropdown-content-users': true, 'keep-scrollbar': keepScrollbar}"
      item-height="50"
    >
      <template v-slot:default="{item}">
        <v-list-item
          :key="(item || {}).id"
          class="dropdown-item dropdown-item--user"
          lines="two"
          data-cy="operator-list"
          @click="() => selectOperator(item)"
        >
          <v-list-item-title :class="{'user-mercateam': item.is_mercateam}">
            <OperatorHeader v-if="tag === 'operateur'" :operator="item" />

            <div v-else class="fd-flex-center">
              <vue-feather type="tool" size="18" class="mr-2" tag="div" />

              <div>{{ item.name }}</div>
            </div>
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ item[descriptionField] }}
          </v-list-item-subtitle>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script scoped lang="ts">
import {defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";
import {levelCorresp, searchSubstring} from "@oplit/shared-module";
import FTextField from "../Inputs/FTextField.vue";
import OperatorHeader from "@/components/Simulation/SideBars/OperatorHeader.vue";
import {getAssetURL} from "@/tscript/utils/generalHelpers";
import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "figma-dropdown-list-users",
  components: {FTextField, OperatorHeader},
  props: {
    hideSearch: Boolean,
    onSelect: Function,
    title: String,
    descriptionField: {type: String, default: "descriptionField"},
    tag: {type: String, default: "operateur"},
    height: {type: String, default: ""},
    operators: {type: Array, default: () => []},
    testArray: {type: Array, default: () => []},
    poweredBy: {type: String, default: ""},
    keepScrollbar: Boolean,
  },
  emits: ["close"],
  setup() {
    const mainStore = useMainStore();
    const {theme} = storeToRefs(mainStore);
    const search = ref("");
    return {
      search,
      theme,
      getAssetURL,
    };
  },
  computed: {
    filteredOperators(): any {
      const {search, operators, testArray} = this;
      if (!search) return operators;

      return operators.filter((op: any) =>
        testArray.some((test: string) => searchSubstring(op[test], search)),
      );
    },
  },
  methods: {
    selectOperator(operator: any) {
      const {tag = "operateur"} = this;

      let sectorId: string, sectorName: string;

      levelCorresp.forEach((level: any) => {
        if (operator[level.type + "_id"] !== undefined) {
          sectorId = operator[level.type + "_id"];
          sectorName = operator[level.type + "_name"];
        }
      });

      this.onSelect({
        ...operator,
        [tag + "_id"]: operator.id,
        [tag + "_name"]: operator.name,
        sectorId,
        sectorName,
      });

      this.$emit("close");
    },
  },
});
</script>
