import CapacitiesAPIClient from "@/api/capacities";
import loggerHelper from "@/tscript/loggerHelper";
import {slugOptions} from "@/tscript/utils/slugOptions";
import {getOfOperationList} from "./schedulingAPIFns";
import DbHelper from "@/tscript/dbHelper";
import db from "@/firebase/db";

const dbHelper = new DbHelper(db);
const apiClient = new CapacitiesAPIClient();

export {apiClient, loggerHelper, dbHelper, slugOptions, getOfOperationList};
