import {ComputedRef, computed} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

export const useClientId = (): {clientId: ComputedRef<string>} => {
  const {userData} = storeToRefs(useMainStore());
  const clientId = computed<string>(() => userData.value?.client_id || "");
  return {
    clientId,
  };
};
