import {OplitShift} from "@/interfaces";

const CSS_PARAMETERS_CONTAINER_CLASS = "parameters-container";
const PARAMETERS_CONTAINER_DISABLED_ATTRIBUTE = "data-disabled";

const PARAMETERS_OPTIONS_VALUES = {
  general: "parameters_general",
  by_sector: "parameters_by_sector",
  data_import: "data_import",
};

const PARAMETERS_PLANNING_DEFAULT_SHIFT: OplitShift = "morning";

const PARAMETERS_PLANNING_SHIFTS: OplitShift[] = [
  PARAMETERS_PLANNING_DEFAULT_SHIFT,
  "afternoon",
  "night",
];

// these colors are used in the style part of the PlanningNodeOperator component
const PARAMETERS_PLANNING_SHIFTS_COLOR_MAPPING = {
  [PARAMETERS_PLANNING_DEFAULT_SHIFT]: "Primary", // morning
  [PARAMETERS_PLANNING_SHIFTS[1]]: "Green", // afternoon
  [PARAMETERS_PLANNING_SHIFTS[2]]: "Purple", // night
};

const PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS = [
  "nb_machine",
  "nb_equipe",
  "duree_shift",
];

const PARAMETERS_FIELD_DEFAULT_FEATHER_ICON = "file";

const PARAMETERS_CALCULUS_FORMULA_AGGREGATION_METHODS = {
  OTHER: "other",
};

const PARAMETERS_CRUD_ICONS_SIZE = 20;
const PARAMETERS_DELETE_ICON_COLOR_NAME = "newPinkRegular";
const PARAMETERS_UPDATE_ICON_COLOR_NAME = "newSubText";

const PARAMETERS_PROFILE_NEW_USER_ID = "new";

const PARAMETERS_APPLICATION_MODULES = [
  "has_production_planning",
  "has_scheduling",
  "has_stocks",
  "has_customer_interface_provider_side",
  "has_customer_interface_customer_side",
];

const PARAMETERS_SCHEDULING_RULES_LAST_SELECTED =
  "parameters_scheduling_rules_last_selected";

const PARAMETERS_AGGREGATION_METHODS = ["min", "mean", "sum", "max"];
const PARAMETERS_PARAMETERS_INPUT_TYPES = {
  NUMBER: "number",
  PERCENT: "percent",
  DROPDOWN: "dropdown",
};

export {
  CSS_PARAMETERS_CONTAINER_CLASS,
  PARAMETERS_CONTAINER_DISABLED_ATTRIBUTE,
  PARAMETERS_OPTIONS_VALUES,
  PARAMETERS_PLANNING_DEFAULT_SHIFT,
  PARAMETERS_PLANNING_SHIFTS,
  PARAMETERS_PLANNING_SHIFTS_COLOR_MAPPING,
  PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS,
  PARAMETERS_FIELD_DEFAULT_FEATHER_ICON,
  PARAMETERS_CALCULUS_FORMULA_AGGREGATION_METHODS,
  PARAMETERS_CRUD_ICONS_SIZE,
  PARAMETERS_DELETE_ICON_COLOR_NAME,
  PARAMETERS_UPDATE_ICON_COLOR_NAME,
  PARAMETERS_PROFILE_NEW_USER_ID,
  PARAMETERS_APPLICATION_MODULES,
  PARAMETERS_SCHEDULING_RULES_LAST_SELECTED,
  PARAMETERS_AGGREGATION_METHODS,
  PARAMETERS_PARAMETERS_INPUT_TYPES,
};
