import {SetupContext} from "vue";

export const useModal = (context: SetupContext) => {
  const closeModal = (): void => {
    context.emit("close-modal");
  };

  const confirmModal = (): void => {
    context.emit("confirm-modal");
  };

  return {
    closeModal,
    confirmModal,
  };
};
