import {EventField, Qualification} from "@/interfaces";

import {
  QUALIFICATIONS_CHARGE_SERIE,
  QUALIFICATIONS_CAPA_SERIE,
} from "@/config/constants";

const newEventIdPrefix = "new_of_";

// those fields are always present in an event form of capa type
// they also can't be removed from this form (see EventField.vue)
const mandatoryCapaFields: string[] = ["sector", "periode"];
// simple event fields, which are immuable as of yet
const simpleEventFields: string[] = [...mandatoryCapaFields, "impact"];

const defaultFields: {[key: string]: string[]} = {
  // capa
  nb_equipe: [...mandatoryCapaFields, "nb_equipe"],
  arrets: [...mandatoryCapaFields, "arrets"],
  nb_machine: [...mandatoryCapaFields, "nb_machine"],
  machine_assignment: [...mandatoryCapaFields],
  pourcent_presence: [...mandatoryCapaFields, "pourcent_presence"],
  trs: [...mandatoryCapaFields, "trs"],
  capa: [...mandatoryCapaFields],
  // charge
  // for the ADDITION type, we handle the sector & quantite through the MultiSectorUpdate component
  [QUALIFICATIONS_CHARGE_SERIE.ADDITION]: [
    "nom_op",
    "datelancement" /*, "sector", "quantite"*/,
  ],
  [QUALIFICATIONS_CHARGE_SERIE.OVERWRITE]: [
    "nom_op",
    "quantite",
    "sector",
    "datelancement",
  ],
  [QUALIFICATIONS_CHARGE_SERIE.MODIFICATION]: ["quantite", "datelancement"],
  [QUALIFICATIONS_CHARGE_SERIE.DELETION]: [],
  [QUALIFICATIONS_CHARGE_SERIE.TRANSFER]: ["sector", "datelancement"],
};

// the qualifications within the capa key are the default ones which will always be present
// those are concatened at the end of the created qualifications, from the msd parameters of a sector
const qualifications: {[key: string]: Qualification[]} = {
  capa: [
    {
      text: "machine_assignment",
      serie: QUALIFICATIONS_CAPA_SERIE.MACHINE_ASSIGNMENT,
    },
    {
      text: "capa_validée",
      serie: QUALIFICATIONS_CAPA_SERIE.VALIDATED_CAPA,
    },
    {
      text: "autre",
      serie: QUALIFICATIONS_CAPA_SERIE.OTHER,
    },
  ],
  charge: [
    {
      text: "ajout de charge",
      serie: QUALIFICATIONS_CHARGE_SERIE.ADDITION,
    },
    {
      text: "overwrite_charge",
      serie: QUALIFICATIONS_CHARGE_SERIE.OVERWRITE,
    },
    {
      text: "modifier",
      serie: QUALIFICATIONS_CHARGE_SERIE.MODIFICATION,
    },
    {
      text: "supprimer",
      serie: QUALIFICATIONS_CHARGE_SERIE.DELETION,
    },
    {
      // this corresponds to the TRANSFER of load
      text: "charge",
      serie: QUALIFICATIONS_CHARGE_SERIE.TRANSFER, // <- the transfer is made when serie === "charge"
    },
  ],
};

const allFields: EventField[] = [
  {
    label: "Secteur",
    model: "sector",
    type: null,
    range: "local",
    modalName: "all",
    isI18n: false,
  },
  //Capa fields
  {
    label: "periode",
    placeholder: "no_periode",
    model: "periode",
    icon: "calendar",
    type: "date",
    computedValue: "dateRangeText",
    ref: "menuPeriode",
    multiple: true,
    range: "local",
    modalName: "capa",
    isI18n: true,
  },
  //Charge fields
  {
    label: "nom_op",
    model: "label_of",
    icon: "file-text",
    placeholder: "nom_op_placeholder",
    type: "text",
    range: "local",
    modalName: "charge",
    isI18n: true,
    hideBtn: true,
  },
  {
    label: "quantite",
    model: "quantite",
    icon: "box",
    type: "text",
    range: "local",
    inputType: "number",
    suffix: "pièces",
    modalName: "charge",
    placeholder: "quantite_placeholder",
    isI18n: true,
  },
  {
    // displaying the field label as "periode" but keeping the logic with .datelancement
    label: "periode",
    model: "datelancement",
    icon: "calendar",
    type: "date",
    computedValue: "dateLancementText",
    ref: "menuLancement",
    range: "local",
    modalName: "charge",
    multiple: true,
    isI18n: true,
  },
  {
    label: "capa_validee",
    placeholder: "no_capa_validee",
    model: "capa_validee",
    icon: "calendar",
    type: "text",
    range: "local",
    inputType: "number",
    suffix: "pièces",
    isI18n: true,
    modalName: "capa",
  },
  {
    label: "impact",
    placeholder: "impact_placeholder",
    model: "impact",
    type: "text",
    range: "local",
    inputType: "number",
    suffix: "pièces",
    isI18n: true,
    modalName: "capa",
    required: true,
  },
];

export {
  qualifications,
  allFields,
  defaultFields,
  simpleEventFields,
  mandatoryCapaFields,
  newEventIdPrefix,
};
