import {DialogPlugin} from "@/tscript/utils/pluginsHelper";

/**
 * creates a FDialog component with a configured properties
 * invoke with this.$openDialog(...)
 * see DialogPlugin
 */
const OplitDialog = {
  install(app, options) {
    const {openDialog} = new DialogPlugin(options, app);

    app.config.globalProperties.$openDialog = openDialog;
    app.provide("openDialog", openDialog);
  },
};

export default OplitDialog;
