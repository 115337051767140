<template>
  <div
    :id="id"
    :class="{disabled, 'f-check--no-padding': noPadding}"
    class="f-check"
    :checked="modelValue"
    :data-testid="dataTestid"
    @click="onCheckboxClick"
  >
    <vue-feather
      tag="div"
      size="24"
      :color="color"
      :type="computedIcon"
      :stroke="computedStroke"
    />
    <label
      v-if="!hideLabel"
      :for="id"
      class="fbody-1 text-newMainText cursor-pointer"
    >
      {{ label }}
    </label>
  </div>
</template>

<script scoped lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "figma-checkbox",
  props: {
    id: String,
    label: String,
    color: {type: String, default: "newPrimaryRegular"},
    disabled: {type: Boolean, default: false},
    modelValue: {type: Boolean, default: false},
    hideLabel: {type: Boolean, default: false},
    dataTestid: {type: String},
    checkedIcon: {type: String, default: "check-square"},
    uncheckedIcon: {type: String, default: "square"},
    noPadding: {type: Boolean, default: false},
  },
  setup(props, {emit}) {
    function onCheckboxClick() {
      if (props.disabled) return;

      emit("update:model-value", !props.modelValue);
    }

    return {onCheckboxClick};
  },
  computed: {
    computedIcon() {
      return this.modelValue ? this.checkedIcon : this.uncheckedIcon;
    },
    computedStroke() {
      return this.modelValue
        ? `rgb(var(--v-theme-${this.color}))`
        : "rgb(var(--v-theme-newSelected)) ";
    },
  },
});
</script>

<style scoped lang="scss">
.f-check {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  cursor: pointer;
  user-select: none;

  &.f-check--no-padding {
    padding: 0;
  }

  &.disabled {
    opacity: 0.67;
    cursor: not-allowed;
  }
}
</style>
