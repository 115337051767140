import {
  doc,
  query,
  collection as collQuery,
  where as whereQuery,
  orderBy as orderByQuery,
  limit as limitQuery,
  startAfter,
  onSnapshot,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  writeBatch,
  QueryStartAtConstraint,
  getCountFromServer,
} from "firebase/firestore";
import type {Query, DocumentData} from "firebase/firestore";
import moment from "moment";
import loggerHelper from "../loggerHelper/index";
import {nextTick} from "vue";
import {GenericObject} from "@/interfaces";
//ln shared/dbHelper/index.ts src/tscript/dbHelper/index.ts
//ln shared/dbHelper/index.ts app-engine/src/dbHelper/index.ts

type DbConstraints = {
  where?: {field: string; compare: string; value: any}[];
  orderBy?: {field: string; direction?: string}[];
  limit?: number;
  childrenPath?: string[];
  startAfterDocSnap?: QueryStartAtConstraint;
  ignoreIds?: boolean;
};
export default class DBHelper {
  db: any;
  constructor(db: any) {
    this.db = db;
  }

  private shouldRemoveDoc(doc: {status?: string}): boolean {
    return !!doc.status && ["removed", "deleted"].includes(doc.status);
  }

  createRef(collection: string, constraints: DbConstraints = {}) {
    const {where, orderBy, limit, startAfterDocSnap, childrenPath} =
      constraints || {};
    const queryParams = [];
    if (where) {
      where.forEach((cond: any) => {
        queryParams.push(whereQuery(cond.field, cond.compare, cond.value));
      });
    }
    if (orderBy) {
      orderBy.forEach((cond: any) => {
        queryParams.push(orderByQuery(cond.field, cond.direction || "asc"));
      });
    }
    if (limit) queryParams.push(limitQuery(limit));
    if (startAfterDocSnap) queryParams.push(startAfter(startAfterDocSnap));
    const q = query(
      collQuery(this.db, collection, ...(childrenPath || [])),
      ...queryParams,
    );
    return q;
  }

  async setDataToCollection(
    collection: string,
    id: string,
    data: any,
    merge = false,
    extraPath: Array<string> = [],
  ) {
    try {
      const ref = doc(this.db, collection, id, ...extraPath);
      return await setDoc(ref, data, {merge});
    } catch (e) {
      loggerHelper.log(
        "setDataToCollection error",
        e,
        collection,
        id,
        data,
        merge,
        extraPath,
      );
      return {e};
    }
  }

  async addDataToCollection(
    collection: string,
    data: any,
    children: Array<string> = [],
  ): Promise<any> {
    try {
      return await addDoc(collQuery(this.db, collection, ...children), data);
    } catch (e) {
      loggerHelper.log("addDataToCollection error", e, collection, data);
      return {e};
    }
  }

  async deleteData(collection: string, id: string) {
    try {
      return await deleteDoc(doc(this.db, collection, id));
    } catch (e) {
      loggerHelper.log("deleteData error", e, collection, id);
      return {e};
    }
  }

  async getDocFromCollection<T = any>(
    collection: string,
    id: string,
  ): Promise<T | undefined> {
    if (!id) return;
    try {
      const docRef = doc(this.db, collection, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data: any = {...docSnap.data(), id: docSnap.id}; //TODO: type properly here
        return data;
      } else
        loggerHelper.log("getDocFromCollection returned empty", collection, id);
    } catch (e) {
      loggerHelper.log("getDocFromCollection", e, collection, id);
      return;
    }
  }

  async getAllDataFromCollectionWithAll(
    collection: string,
    constraints: DbConstraints = {},
  ) {
    const {ignoreIds} = constraints || {};

    const q = this.createRef(collection, constraints);
    const querySnapshot = await getDocs(q);

    const returnArray = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (this.shouldRemoveDoc(doc.data())) return;
      returnArray.push({
        ...doc.data(),
        ...(!ignoreIds && {id: doc.id}),
      });
    });
    return returnArray;
  }

  async getAllDataFromCollectionWithSpecificColumns<
    T extends {[K: string]: any},
  >(
    collection: string,
    columns: string[],
    constraints: DbConstraints = {},
  ): Promise<T[]> {
    const {ignoreIds} = constraints || {};

    const q = this.createRef(collection, constraints);
    const querySnapshot = await getDocs(q);

    const returnArray = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (this.shouldRemoveDoc(doc.data())) return;
      returnArray.push({
        ...columns.reduce(
          (acc, field) => ({...acc, [field]: doc.get(field)}),
          {},
        ),
        ...(!ignoreIds && {id: doc.id}),
      });
    });
    return returnArray;
  }

  async getCountFromCollecton(
    collection: string,
    constraints: DbConstraints = {},
  ): Promise<number> {
    const q = this.createRef(collection, constraints);
    const querySnapshot = await getCountFromServer(q);
    return querySnapshot.data().count;
  }

  async getAllDataFromCollectionWithAllOnSnapshot(
    collection: string,
    constraints: DbConstraints = {},
    callBack: any,
  ) {
    const q = this.createRef(collection, constraints);

    const unsub = onSnapshot(
      q,
      (snapshot) => {
        snapshot.docChanges().forEach((change: any) => {
          const result = {...{id: change.doc.id}, ...change.doc.data()};
          callBack(change.type, result);
        });
      },
      (e: any) => {
        const error = `getAllDataFromCollectionWithAllOnSnapshot - ${collection} - ${JSON.stringify(
          constraints,
        )} - ${e}`;
        loggerHelper.report(error);
      },
    );

    return unsub;
  }

  watchDocumentChanges(
    collection: string,
    id: string,
    callback: (data: Record<string, any>) => void,
  ) {
    const unsub = onSnapshot(doc(this.db, collection, id), (doc) => {
      callback(doc.data());
    });

    return unsub;
  }

  async deleteCollection(
    collection: string,
    batchSize = 500,
    params: {childrenPath?: string[]} = {},
  ) {
    const {childrenPath} = params;
    const query = this.createRef(collection, {
      orderBy: [{field: "__name__"}],
      limit: batchSize,
      childrenPath,
    });
    return new Promise((resolve, reject) => {
      this.deleteQueryBatch(query, resolve, false, batchSize).catch(reject);
    });
  }

  async deleteQueryBatch(
    query: any,
    resolve: any,
    pg_parsed = false,
    targetBatchSize = -1,
  ) {
    const snapshot = await getDocs(query);

    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }

    // Delete documents in a batch
    let batch = writeBatch(this.db);
    if (pg_parsed) {
      snapshot.docs.forEach((doc: any) => {
        batch.set(doc.ref, {
          pg_parsed: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
          trigger_function: false,
        });
      });
      await batch.commit();
      batch = writeBatch(this.db);
    }

    snapshot.docs.forEach((doc: any) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    //if we have less element than what we should have, we can stop
    if (targetBatchSize > 0 && batchSize < targetBatchSize) {
      resolve();
      return;
    }

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    nextTick(() => {
      this.deleteQueryBatch(query, resolve, pg_parsed);
    });
  }

  createBatch() {
    return writeBatch(this.db);
  }
  async commitBatch(batch: any) {
    return await batch.commit();
  }
  setBatch(batch: any, docPath: Array<string>, child: any, update = false) {
    if (update) batch.update(doc(this.db, ...docPath), child);
    else batch.set(doc(this.db, ...docPath), child);
  }
  deleteBatch(batch: any, docPath: Array<string>) {
    batch.delete(doc(this.db, ...docPath));
  }

  getCollectionId(collection: string, children: Array<string> = []): string {
    return doc(collQuery(this.db, collection, ...children)).id;
  }

  async deleteAllDataFromCollectionWithAll(
    collection: string,
    constraints: DbConstraints = {},
    batchSize = 500,
  ) {
    const query = this.createRef(collection, {
      ...constraints,
      limit: batchSize,
    });
    return new Promise((resolve, reject) => {
      this.deleteQueryBatch(query, resolve).catch(reject);
    });
  }

  async updateAllDataFromCollectionWithAll(
    collection: string,
    constraints: DbConstraints = {},
    update: GenericObject = {},
  ) {
    const query = this.createRef(collection, constraints);
    return new Promise((resolve, reject) => {
      this.updateQueryBatch(query, update, resolve).catch(reject);
    });
  }

  async updateQueryBatch(
    query: Query<DocumentData>,
    update: GenericObject,
    resolve: any,
  ) {
    const snapshot = await getDocs(query);
    // Update documents in a batch
    let batch = writeBatch(this.db);
    for (const [index, doc] of snapshot.docs.entries()) {
      batch.update(doc.ref, update);
      if ((index + 1) % 500 === 0) {
        await batch.commit();
        batch = writeBatch(this.db);
      }
    }
    try {
      await batch.commit();
    } catch (e) {
      loggerHelper.log("updateQueryBatch error", e);
    } finally {
      resolve();
    }
  }
}
