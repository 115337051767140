<template>
  <div :class="containerClasses" :style="{gap}" :data-testid="dataTestid">
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {OPLIT_CONTAINER_DEFAULT_GAP} from "@/config/constants";
import type {VueClassesArray} from "@/interfaces";

export default defineComponent({
  props: {
    /**
     * is there any DOM element containing a scrollbar (.padded-scrollbar) within this container ?
     * values accepted are true, "x", "y"
     * when specifying "x" or "y" the user specifies that (resp.) only a horizontal or a vertical scrollbar can be visible
     * when specifying true, the DOM element with the .padded-scrollbar class will have its overflow set to scroll, applying the according padding
     */
    scrollbar: {type: [Boolean, String], default: false},
    /**
     * nullify the top padding (applies .pt-0)
     * can be required when the OplitContainer component is a children of the template's root tag
     * e.g. there is a subnavigation
     */
    noTop: {type: Boolean, default: false},
    /**
     * by default, the wrapper applies a padding-right matching the scrollbar area width
     * to siblings of the scroll container automatically (genericity purposes)
     * setting this to `true` removes this behavior
     */
    noChildrenPadding: {type: Boolean, default: false},
    /**
     * the container is the wrapping element, containing the scrollbar container and its siblings
     */
    main: {type: Boolean, default: true},
    /**
     * the container is the element which is scrollable
     */
    self: {type: Boolean, default: false},
    /**
     * a gap string value that is applied to the style of the container
     */
    gap: {type: String, default: OPLIT_CONTAINER_DEFAULT_GAP},
    /**
     * id for testing purposes
     */
    dataTestid: {type: String},
  },
  computed: {
    containerClasses(): VueClassesArray {
      const {scrollbar, main, noTop, noChildrenPadding, self} = this;

      return [
        "oplit-container",
        {"has-scrollbar": !!scrollbar},
        {"has-scrollbar-x": scrollbar === "x"},
        {"has-scrollbar-y": scrollbar === "y"},
        {"self keep-scrollbar padded-scrollbar": self},
        {"no-children-padding": noChildrenPadding},
        {main: main && !self},
        {"pt-0": noTop},
      ];
    },
  },
});
</script>

<style lang="scss">
.oplit-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: var(--g-vertical-spacing) var(--g-horizontal-spacing);

  &.has-scrollbar {
    padding-right: 0;
    padding-bottom: 0;
    overflow-x: auto;
    overflow-y: auto;

    &:not(.main) {
      @-moz-document url-prefix() {
        /* 8px is equivalent to the "scrollbar-width: thin" property */
        padding-right: calc(var(--g-horizontal-spacing) - 8px);
        padding-bottom: calc(var(--g-vertical-spacing) - 8px);
      }
    }

    &:not(.no-children-padding) {
      &.main,
      & .scrollbar-container {
        & > *:not(.padded-scrollbar):not(.scrollbar-container) {
          padding-right: var(--g-scrollbar-area);
        }
      }
    }

    &.has-scrollbar-x {
      overflow-x: scroll;

      @-moz-document url-prefix() {
        padding-right: 0;
      }

      & .padded-scrollbar,
      &.self {
        // the following property makes it so that the padding is applied at the end of the scrolling area
        padding-right: var(--g-horizontal-spacing);

        @-moz-document url-prefix() {
          padding-right: 0;
        }
      }
    }

    &.has-scrollbar-y {
      overflow-y: scroll;

      /**
       * exceptions :
       * - GenericTable's virtual scroll (padding is handled locally)
       * - Modal is as of writing not bound to the root App and inherits these properties : this should not be the case
       */
      & .padded-scrollbar:not(.v-virtual-scroll):not(.f-modal-content-wrapper),
      &.self {
        padding-bottom: var(--g-vertical-spacing);
      }
    }
  }
}
</style>
