<script setup lang="ts">
import {computed, inject, ref} from "vue";
import {UseDropZoneOptions, useDropZone} from "@vueuse/core";
import {getReadableFileWeight} from "@/tscript/utils/filesUtils";
import {TEST_IDS} from "@/config/constants";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {useI18n} from "vue-i18n";
import {OpenDialogFunction} from "@/interfaces";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    multiple?: boolean;
    acceptedExtensions?: string[];
    acceptedDataTypes?: string[];
    files?: File[];
  }>(),
  {
    multiple: false,
    // this has been set as such given the usage at the time of development
    acceptedExtensions: () => [".txt", ".csv"],
    acceptedDataTypes: () => ["text/plain", "text/csv"],
    files: () => [],
  },
);

const emit = defineEmits<{
  (e: "add-files", files: File[]);
  (e: "remove-file", file: File);
}>();

const {variables} = storeToRefs(useMainStore());

const {t} = useI18n();

const openDialog = inject<OpenDialogFunction>("openDialog");

const dropZoneRef = ref<HTMLDivElement>();
const filePickerRef = ref<HTMLDivElement>();
const localFiles = ref<File[]>(props.files);

const acceptString = computed<string>(() => props.acceptedExtensions.join(","));
const acceptFormat = computed<string>(() =>
  props.acceptedExtensions.join(", ").replaceAll(".", "").toUpperCase(),
);

function openFilePicker(): void {
  filePickerRef.value.click();
}

function onDropFile(files: File[] | null) {
  const hasInvalidFile = files.some(
    (file) =>
      !props.acceptedExtensions.includes(`.${file.name.split(".").pop()}`),
  );

  if (hasInvalidFile) {
    openDialog({
      header: t("Import.invalid_file_error"),
      message: t(
        "Import.invalid_file_desc",
        {
          format: acceptFormat.value,
        },
        props.acceptedExtensions.length,
      ),
      type: "negative",
      hidePrefix: true,
      cancelBtnProps: {filled: true},
    });

    return;
  }

  if (props.multiple) localFiles.value.push(...files);
  else localFiles.value = [files[0]];

  emit("add-files", files);
}

function getImageSrc(file: File) {
  return URL.createObjectURL(file);
}

function removeFile(fileIndex: number) {
  const [removedFile] = localFiles.value.splice(fileIndex, 1);

  emit("remove-file", removedFile);
}

function onFilePicked(event: Event) {
  const files = [...(event.target as HTMLInputElement).files];
  if (!files.length) return;
  onDropFile(files);
}

// The built-in file type check doesn't work well right now, and I couldn't find an
// issue on the subject in their repo. Last tested @vueuse/core version is 10.9.0.
// The default event won't be prevented on drop.
// Another issue is that some edge cases where the file extension and the file type
// don't match exist, so far we haven't found much more explanation behind it than
// Windows/Excel shenanigans
useDropZone(dropZoneRef, {
  onDrop: onDropFile,
  // dataTypes: props.acceptedDataTypes,
} as UseDropZoneOptions);
</script>

<template>
  <div
    ref="dropZoneRef"
    class="drag-and-drop"
    :data-testid="TEST_IDS.DRAG_AND_DROP"
    @click="() => openFilePicker()"
  >
    <slot>
      <div
        :class="{scaled: localFiles.length > 0}"
        class="drag-and-drop__placeholder"
      >
        <vue-feather type="upload-cloud" size="36" />

        <h3 class="font-weight-bold text-center">
          {{ title || $t("Import.Glisser_fichier") }}
        </h3>

        <div>
          {{ description || $t("Import.selectionner") }}
        </div>
      </div>
    </slot>

    <div
      v-if="localFiles.length > 0"
      class="drag-and-drop__files-container keep-scrollbar"
    >
      <div
        v-for="(file, fileIndex) in localFiles"
        :key="JSON.stringify(file)"
        class="fd-flex-center gap-3"
      >
        <v-tooltip location="top">
          <template v-slot:activator="{props}">
            <strong v-bind="props" class="text-ellipsis flex-1">
              {{ file.name }}
            </strong>
          </template>

          <img
            v-if="file.type.startsWith('image/')"
            :src="getImageSrc(file)"
            width="250"
          />

          <template v-else>
            {{ file.name }}
          </template>
        </v-tooltip>

        {{ getReadableFileWeight(file) }}

        <vue-feather
          :stroke="variables.newPinkRegular"
          type="x"
          size="14"
          class="cursor-pointer"
          @click.stop="() => removeFile(fileIndex)"
        />
      </div>
    </div>

    <input
      ref="filePickerRef"
      :accept="acceptString"
      :multiple="multiple"
      type="file"
      class="d-none"
      @change="onFilePicked"
    />
  </div>
</template>

<style lang="scss" scoped>
.drag-and-drop {
  height: 200px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background: rgb(var(--v-theme-newAppBackground));
  border: 2px dashed rgb(var(--v-theme-newSelected));
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;

  .drag-and-drop__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.scaled {
      transform: scale(0.67);
    }
  }

  .drag-and-drop__files-container {
    width: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>
