<template>
  <div :class="['gantt-row-prefix', {'is-synthetic': isSyntheticView}]">
    <div
      :class="currentColorCategoryClass"
      class="gantt-row-prefix--entity-name-wrapper"
    >
      <div class="gantt-row-prefix--entity-name">
        <vue-feather
          v-if="isOF"
          :stroke="variables[isSelected ? 'newPrimaryRegular' : 'newSelected']"
          :type="isSelected ? 'check-square' : 'square'"
          class="cursor-pointer"
          size="20"
          @click="selectEntity"
        />

        <div class="gantt-row-prefix--entity-name-content">
          <div class="gantt-row-prefix--entity-name-content--first">
            <strong
              v-tooltip="displayedName"
              class="cursor-pointer text-ellipsis"
              @click="() => onEntityNameClick(0)"
            >
              <FChip
                class="mr-2"
                v-if="computePriority"
                :color="computePriorityColorsBG"
                :text-color="computePriorityColorsText"
                x-small
              >
                {{ computePriority }}
              </FChip>
              {{ displayedName }}
              <DevHelper mini>
                ({{ entity.secteur_id || entity.of_id }})
              </DevHelper>
            </strong>

            <DelayChip
              v-if="entity.nbAvanceRetard"
              :delay="entity.nbAvanceRetard"
            />
          </div>

          <div
            v-if="!isSyntheticView"
            :class="[
              {'justify-end': hideProgressRate},
              'gantt-row-prefix--entity-name-content--second',
            ]"
          >
            <template v-if="!hideProgressRate">
              <EntityProgressRate
                :current="progressRate.produced"
                :total="progressRate.qte"
                :unit="progressRate.unit"
                :entity="entity"
                :period="ganttPeriod"
                hide-entity-name
                class="flex-1"
              />

              <span class="fbody-2 font-italic">
                {{ computeCurrentPeriodText }}
              </span>
            </template>

            <div
              v-if="isDisplayedCommentaryContainer"
              class="gantt-row-prefix--commentary-container"
              @click="onEntityNameClick(1)"
            >
              <v-progress-circular
                v-if="loading"
                size="12"
                width="2"
                :color="variables.newSelected"
                indeterminate
              />

              <vue-feather
                v-else-if="entity.messages.length > 0"
                :stroke="variables.newDisableText"
                type="message-circle"
                class="has-messages position-relative"
                size="16"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="isOF">
      <div
        v-if="!getHiddenHeaders().includes('details')"
        class="gantt-row-prefix--entity-details-wrapper"
      >
        <span
          v-tooltip="entityAsOFsType.details"
          class="semi-bold text-ellipsis"
        >
          {{ entityAsOFsType.details }}
        </span>
      </div>

      <div
        v-if="!getHiddenHeaders().includes('quantite')"
        class="gantt-row-prefix--entity-quantite-wrapper"
      >
        <span
          v-tooltip="entityAsOFsType.displayed_quantite"
          class="text-ellipsis"
        >
          {{ entityAsOFsType.quantite }}
          {{ entityAsOFsType.unit.at(0) }}
        </span>
      </div>

      <div
        v-if="!getHiddenHeaders().includes('tags')"
        :class="{
          'has-tags-overflow': hasTagsOverflow,
        }"
        class="gantt-row-prefix--entity-tags-wrapper"
      >
        <OperationTags
          :operation="entity"
          :max-displayed-tags="MAX_DISPLAYED_TAGS"
          hide-status
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, PropType} from "vue";
import {storeToRefs} from "pinia";
import OperationTags from "@/components/Scheduling/Operations/OperationTags.vue";
import {EntityProgressRate} from "@/components/Commons";
import DelayChip from "@/components/Commons/DelayChip.vue";
import {FChip} from "@/components/Global";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {
  getOperationProgressRateItems,
  getColorCategoryClass,
  priorityColorsBG,
  priorityColorsText,
} from "@/tscript/utils/schedulingUtils";
import type {GanttableEntity, OFsType, Sector} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGanttStore} from "@/stores/ganttStore";

import {useMainStore} from "@/stores/mainStore";
import {getDisplayedPeriod, DATE_DEFAULT_FORMAT} from "@oplit/shared-module";
import {useSchedulingTag} from "@/composables/scheduling/useSchedulingTag";

export default defineComponent({
  components: {
    DelayChip,
    EntityProgressRate,
    OperationTags,
    FChip,
  },
  props: {
    entity: {
      type: Object as PropType<GanttableEntity>,
      required: true,
      default: () => ({} as GanttableEntity),
    },
    hideProgressRate: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    isSyntheticView: {type: Boolean, default: false},
  },
  setup(props) {
    const getHiddenHeaders = inject<() => string[]>(
      "getHiddenHeaders",
      () => [],
    );

    const {schedulingCurrentColorCategory} = storeToRefs(useSchedulingStore());
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {ganttMesh, ganttSelectedEntities, ganttPeriod} = storeToRefs(
      useGanttStore(),
    );

    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);

    const {getSchedulingTag} = useSchedulingTag();

    const MAX_DISPLAYED_TAGS = 3;

    const isDisplayedCommentaryContainer = computed(() => {
      if (!currentPermissions.value.general.create_comment) return false;
      return props.loading || props.entity.messages.length > 0;
    });
    const entityAsOFsType = computed(() => props.entity as OFsType);
    const hasTagsOverflow = computed(
      () =>
        (entityAsOFsType.value.tags || [])
          .map((tag) => getSchedulingTag(tag))
          .filter(Boolean).length >
        MAX_DISPLAYED_TAGS - 1,
    );

    return {
      schedulingCurrentColorCategory,
      currentPermissions,
      ganttMesh,
      ganttSelectedEntities,
      ganttPeriod,
      variables,
      isDisplayedCommentaryContainer,
      entityAsOFsType,
      getHiddenHeaders,
      DATE_DEFAULT_FORMAT,
      MAX_DISPLAYED_TAGS,
      hasTagsOverflow,
    };
  },
  computed: {
    progressRate(): {produced: number; qte: number; unit: string} {
      if (this.isOF) {
        // ofs
        return {
          produced: this.entity.completed_ops,
          qte: this.entity.number_of_operations,
          unit: "op.",
        };
      }
      return getOperationProgressRateItems(this.entity.operations);
    },
    isLate(): boolean {
      if (!this.isOF) return false;
      return this.entity.nbAvanceRetard < 0;
    },
    isSelected(): boolean {
      return !!this.ganttSelectedEntities.find(
        (entity: GanttableEntity) =>
          this.getEntityKey(entity) === this.getEntityKey(this.entity),
      );
    },
    isOF(): boolean {
      return !!this.entity.of_id;
    },
    currentColorCategoryClass(): string {
      if (!this.isOF) return;
      return getColorCategoryClass(
        this.entity,
        this.schedulingCurrentColorCategory,
      );
    },
    displayedName(): string {
      return this.entity.secteur_name ?? this.entity.of_id;
    },
    computePriority(): string {
      const {entity} = this;
      if (!entity.of_id || !entity.operations?.length) return;

      const priority = entity.operations[0]?.fast_track;
      return priority;
    },
    computePriorityColorsBG() {
      const {computePriority} = this;
      if (!computePriority) return;
      return priorityColorsBG(computePriority);
    },
    computePriorityColorsText() {
      const {computePriority} = this;
      if (!computePriority) return;
      return priorityColorsText(computePriority);
    },
    computeCurrentPeriodText(): string {
      const {ganttMesh, ganttPeriod} = this;

      if (!ganttMesh || !ganttPeriod.length) return "";

      return getDisplayedPeriod(ganttPeriod[0], ganttMesh === "month");
    },
  },
  methods: {
    onEntityNameClick(defaultSelectedTab: number): void {
      if (!this.isOF) return;
      this.$openOFSidebar(this.entity, {defaultSelectedTab});
    },
    selectEntity(): void {
      if (this.isSelected) {
        return (this.ganttSelectedEntities = this.ganttSelectedEntities.filter(
          (entity: GanttableEntity) =>
            this.getEntityKey(entity) !== this.getEntityKey(this.entity),
        ));
      }
      this.ganttSelectedEntities = [...this.ganttSelectedEntities, this.entity];
    },
    getEntityKey(entity: GanttableEntity): string {
      return this.isOF
        ? (entity as OFsType).of_id
        : (entity as Sector).secteur_id;
    },
    getColorCategoryClass,
  },
});
</script>

<style scoped lang="scss">
.gantt-row-prefix {
  --y-padding: 12px;

  position: sticky;
  left: 0;
  display: flex;
  border: var(--gantt-border);
  border-top: none;
  background: inherit;
  z-index: 4;

  & > div {
    padding: var(--y-padding) 16px;

    &:not(:last-child) {
      border-right: var(--gantt-border);
    }
  }

  & .gantt-row-prefix--entity-name-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: calc(var(--gantt-entity-name-width) - 1px);

    & .gantt-row-prefix--entity-name {
      position: sticky;
      top: calc(var(--gantt-total-header-height) + var(--y-padding));
      display: flex;
      align-items: flex-start;
      gap: 16px;

      & i {
        flex: var(--gantt-select-icon-flex);
      }

      & .gantt-row-prefix--entity-name-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;
        min-width: 0;

        & .gantt-row-prefix--entity-name-content--first {
          display: flex;
          align-items: flex-start;
          gap: 8px;
        }

        & .gantt-row-prefix--entity-name-content--second {
          display: flex;
          align-items: center;
          gap: 16px;
        }

        & .gantt-row-prefix--commentary-container {
          flex: var(--gantt-select-icon-flex);
          display: flex;
          align-items: center;
          cursor: pointer;

          & svg {
            margin-left: auto;
          }
        }

        & strong {
          flex: 1;
          font-size: 20px;
          line-height: 1;
        }
      }
    }
  }

  & .gantt-row-prefix--entity-details-wrapper,
  & .gantt-row-prefix--entity-quantite-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & .gantt-row-prefix--entity-details-wrapper {
    width: var(--gantt-entity-details-width);
  }

  & .gantt-row-prefix--entity-quantite-wrapper {
    width: var(--gantt-entity-quantite-width);
  }

  & .gantt-row-prefix--entity-tags-wrapper {
    width: var(--gantt-entity-tags-width);

    &.has-tags-overflow:deep(.v-chip) {
      max-width: calc((var(--gantt-entity-tags-width) - 80px) / 3);

      /**
       * FIXME:
       * this class specific in FChip should be dropped 
       * and the underlying logic should be put only where
       * required (see the specs from OPL-3713)
       */
      & .text-elipsis {
        direction: ltr !important;
      }
    }
  }

  & .entity-progress-rate--container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.is-synthetic {
    & > div {
      padding: 6px 12px;
    }

    &
      .gantt-row-prefix--entity-name
      .gantt-row-prefix--entity-name-content
      strong {
      font-size: 16px;
    }

    & .gantt-row-prefix--entity-details-wrapper,
    & .gantt-row-prefix--entity-quantite-wrapper {
      font-size: 14px;
    }
  }
}
</style>
