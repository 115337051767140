import {useRoute} from "vue-router";
import {computed} from "vue";
import {
  PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING,
  PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING,
  PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING,
} from "@/config/routes";

export function useRouteAssertions() {
  const route = useRoute();

  const isParametersRoute = computed(() =>
    isRouteAny(
      [
        Object.values(PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING),
        Object.values(PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING),
        Object.values(PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING),
      ].flat(),
    ),
  );
  const isParametersBySectorRoute = computed(() =>
    isRouteAny(Object.values(PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING)),
  );

  function isRouteAny(routeNames: string[]) {
    if (!route?.name) return false;
    return routeNames.some((routeName) => route.name === routeName);
  }

  return {
    isParametersRoute,
    isParametersBySectorRoute,
    isRouteAny,
  };
}
