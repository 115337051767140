import {FullAggregatedPdpLoadEvent, CleanOplitEvent} from "@/interfaces";
import {PdpLoadEventType} from "@oplit/shared-module";

function getSegmentPayload(
  type: string,
  object: Record<string, unknown | unknown[]>,
) {
  switch (type) {
    case "simulation":
      return {
        id: object.id,
        name: object.name,
        ...(object.forceToday && {start_date: object.forceToday}),
      };

    case "event": {
      const typedObject = object as unknown as CleanOplitEvent;
      return {
        event_id: typedObject.id,
        title: typedObject.title,
        type: typedObject.type,
        // this attribute is used for filtering purposes
        qualificationText: typedObject.qualification?.text,
        qualification: typedObject.qualification,
        nb_actions: typedObject.actions?.length || 0,
        nb_children: typedObject.children?.length || 0,
      };
    }

    case "load_event": {
      const typedObject = object as unknown as FullAggregatedPdpLoadEvent;
      let nb_children = 1;
      if (
        typedObject.event_type === PdpLoadEventType.ADDITION ||
        typedObject.event_type === PdpLoadEventType.OVERWRITE
      )
        nb_children = typedObject.assignations.length;
      else nb_children = typedObject.op_ids.length;

      return {
        event_id: typedObject.event_id,
        title: typedObject.event_name,
        type: typedObject.type,
        qualification: typedObject.event_type,
        nb_actions: 0,
        nb_children,
      };
    }

    case "operation":
      return {
        op_id: object.op_id,
        op_name: object.op_name,
      };

    default:
      return {};
  }
}

export {getSegmentPayload};
