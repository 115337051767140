import {App, Directive, createVNode, render} from "vue";
import {CustomColorDefiner} from "@/components/Commons";

/**
 * this directive opens a menu near an element to associate a color to a date\
 * the binding is meant to be used as such :\
 * ```vue
 * <my-element
 *    v-define-color="{ day_date: <string_date>, disabled: <is_the_directive_disabled> }"
 * />
 * ```
 * the `day_date` format could technically be anything, as long as it matches what you pass in parameter on the getter `getDailySchedulingColors`\
 * it was made using DATE_DEFAULT_FORMAT ("YYYY-MM-DD") and it is recommended to use with this format also
 */
const defineColor = (app: App): Directive => {
  return {
    mounted: function (
      el: HTMLElement,
      {value}: {value: Record<string, unknown>},
    ) {
      if (!value || value.disabled) return;
      // adds the `custor: pointer` rule for elements with the directive
      el.classList.add("cursor-pointer");
    },
    beforeMount: function (
      el: HTMLElement,
      {value}: {value: Record<string, unknown>},
    ) {
      if (!value) return;
      /**
       * `parameters` is an object passed as payload in the firebase update function
       * the only relevant key so far is `day_date`
       */
      const {disabled, ...parameters} = value;
      if (disabled) return;

      const handleClick = function (): void {
        const vNode = createVNode(
          CustomColorDefiner,
          {
            activator: el,
            parameters,
          },
          () => [value],
        );

        vNode.appContext = app._context;
        render(vNode, el);

        // removes the listener after opening to prevent multiple listening on the same element
        el.removeEventListener("click", handleClick);
      };

      el.addEventListener("click", handleClick);
    },
  };
};

export default defineColor;
