<template>
  <HelperSpan v-if="!hasChildren" class="font-italic">
    {{ $t("Commons.lowest_level_sector_helper") }}
  </HelperSpan>
</template>

<script setup lang="ts">
import {computed} from "vue";
import {HelperSpan} from "@/components/Commons";
import {hasChildrenSector} from "@/tscript/utils/coreHelper";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

const {activePerim} = storeToRefs(useMainStore());

const hasChildren = computed(() => hasChildrenSector(activePerim.value));
</script>
