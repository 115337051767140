import {apiClient} from "./requirements";
import {levelCorresp} from "@oplit/shared-module";
import {
  Sector,
  Simulation,
  LoadRateResponse,
  OplitLevel,
  Perimeters,
  OplitBreadcrumbItem,
} from "@/interfaces";

import _ from "lodash";

const hasChildrenSector = (sector: Sector): boolean => {
  if (!sector) return false;
  return (
    sector.children?.filter(
      (child) =>
        !!child &&
        !["removed", "deleted"].includes(child.status) &&
        !!child.collection,
    )?.length > 0
  );
};

const getAllSubSectors = (sector: Sector, postes: Sector[]): Sector[] => {
  const subSectors = [];
  const getRecursive = (sector: Sector) => {
    const fullSector: Sector = postes.find(({id}) => id === sector.id);
    if (!fullSector || !fullSector.id || fullSector.status === "removed")
      return;
    if (!fullSector.children?.length) {
      subSectors.push(fullSector);
      return;
    }
    fullSector.children.forEach((s) => getRecursive(s));
  };

  getRecursive(sector);
  return subSectors;
};

const getOverloadedSubSectors = async (
  sector: Sector,
  simulation: Simulation,
  period: string[],
  todayDate: string,
  client_id: string,
  postes: Sector[],
  has_stock = false,
): Promise<LoadRateResponse[]> => {
  const {id: simulation_id} = simulation;
  const [start_date, end_date] = period;

  const subSectors = getAllSubSectors(sector, postes);

  const promises = [];
  subSectors.forEach((s: Sector) => {
    const paramsLoadRateAndRemainingCapa = {
      client_id,
      simulation_id,
      startDate: start_date,
      endDate: end_date,
      sector_tree: s,
      today_date: todayDate,
      defined_periods: [{start_date, end_date}],
      load_auto_orga: true,
      has_stock,
    };

    promises.push(
      apiClient.postRequest(
        "/api/capacities/load-rate-and-remaining-capacity-by-period",
        paramsLoadRateAndRemainingCapa,
      ),
    );
  });

  const results: LoadRateResponse[] = await Promise.all(promises);

  const overloadedSectors = results.filter(
    (r) =>
      +_.get(r, ["periods", 0, "loadSum"], 0) > 0 &&
      +_.get(r, ["periods", 0, "loadRate"], 0) > 100,
  );

  return overloadedSectors;
};

function getBreadcrumbsItemsFromSector(
  sector: Sector,
  level: OplitLevel,
  perimeters: Perimeters,
): {breadcrumbs: OplitBreadcrumbItem[]; level: OplitLevel} {
  const defaultReturn = {breadcrumbs: [], level: null};

  if (!sector) return defaultReturn;

  // retrieves the "level" object given the secteur configuration
  const determinedLevel =
    level ||
    levelCorresp.find(
      (levelItem) =>
        levelItem.collection === sector.secteur_collection ||
        levelItem.level === sector.level,
    );
  if (!determinedLevel) return defaultReturn;

  const breadcrumbs = [];
  let higherLevelIds = {};

  levelCorresp.forEach((levelItem) => {
    const parentId = sector[levelItem.type + "_id"];
    if (parentId) {
      const match =
        (perimeters[levelItem.collection] || []).find(
          (x) => x.id === parentId,
        ) || {};

      breadcrumbs.push({
        ...higherLevelIds,
        ...levelItem,
        id: parentId,
        name: match.name || sector[levelItem.type + "_name"],
        disabled: false,
      });
      higherLevelIds = {
        ...higherLevelIds,
        [levelItem.type + "_id"]: parentId,
        [levelItem.type + "_name"]:
          match?.name || sector[levelItem.type + "_name"],
      };
    }
  });

  breadcrumbs.push({
    ...higherLevelIds,
    ...determinedLevel,
    id: sector.id,
    name: sector.name,
    disabled: true,
  });

  return {breadcrumbs, level: determinedLevel};
}

export {
  hasChildrenSector,
  getAllSubSectors,
  getOverloadedSubSectors,
  getBreadcrumbsItemsFromSector,
};
