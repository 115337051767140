<script setup lang="ts">
interface ConwipTicketsGaugeTicketsAreaProps {
  type: "ideal" | "moderate" | "critical";
  hasLeftBorder?: boolean;
  hasBorderedContent?: boolean;
}

defineProps<ConwipTicketsGaugeTicketsAreaProps>();

const iconNameMap = {
  ideal: "check-circle",
  moderate: "circle-half",
  critical: "warning-circle",
};
</script>

<template>
  <div
    :class="[
      `conwip-tickets-gauge-tickets-area__${type}`,
      {'has-left-border': hasLeftBorder},
    ]"
    class="conwip-tickets-gauge-tickets-area"
  >
    <div class="conwip-tickets-gauge-tickets-area__header">
      <OplitIcon :name="iconNameMap[type]" />

      <span class="fbody-2">
        {{ $t(`ConwipTicketsGaugeTicketsArea.${type}`) }}
      </span>

      <v-spacer />

      <slot name="append-header" />
    </div>

    <div
      :class="{'has-bordered-content': hasBorderedContent}"
      class="conwip-tickets-gauge-tickets-area__content"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
$colorsByType: (
  "ideal": "Green",
  "moderate": "Orange",
  "critical": "Pink",
);

@mixin get-colors-properties-from-type($type) {
  $baseColor: map-get($colorsByType, $type);

  background: rgb(var(--v-theme-new#{$baseColor}Light2));
  border-left-color: rgb(var(--v-theme-new#{$baseColor}Dark1));

  & .conwip-tickets-gauge-tickets-area__content.has-bordered-content {
    border: 1px dashed rgb(var(--v-theme-new#{$baseColor}Light1));
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  & .conwip-tickets-gauge-tickets-area__header {
    color: rgb(var(--v-theme-new#{$baseColor}Dark2));
  }
}

.conwip-tickets-gauge-tickets-area {
  &.has-left-border {
    border-left-width: 1px;
    border-left-style: solid;
  }

  &.conwip-tickets-gauge-tickets-area__ideal {
    @include get-colors-properties-from-type("ideal");
  }

  &.conwip-tickets-gauge-tickets-area__moderate {
    @include get-colors-properties-from-type("moderate");
  }

  &.conwip-tickets-gauge-tickets-area__critical {
    @include get-colors-properties-from-type("critical");
  }

  & .conwip-tickets-gauge-tickets-area__header {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
  }
}
</style>
