<script setup lang="ts">
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {TEST_IDS} from "@/config/constants";

interface DelayChipProps {
  delay: number | string;
  tooltip?: string;
}

const props = defineProps<DelayChipProps>();

const {t} = useI18n();

const isLate = computed(() => props.delay && +props.delay > 0);
const daysLate = computed(() => {
  const delay = !props.delay ? 0 : +props.delay;
  const sign = !delay ? "" : isLate.value ? "+" : "";
  return [sign, delay, t("global.day_letter")].join(" ");
});
</script>

<template>
  <span
    v-tooltip="tooltip || t('scheduling.estimated_delay_OF')"
    class="delay-chip"
    :class="`delay-chip__${isLate ? 'late' : 'early'}`"
    :data-testid="TEST_IDS.DELAY_CHIP"
  >
    {{ daysLate }}
  </span>
</template>

<style scoped lang="scss">
.delay-chip {
  padding: 0 3px;
  border-radius: 4px;
  font-size: 14px;
  background-color: rgb(var(--v-theme-newSelected));
  color: rgb(var(--v-theme-newSubText));
  flex: 0 0 auto;

  &.delay-chip__late {
    background-color: rgb(var(--v-theme-newPinkLight2));
    color: rgb(var(--v-theme-newPinkDark2));
  }

  &.delay-chip__early {
    background-color: rgb(var(--v-theme-newGreenLight2));
    color: rgb(var(--v-theme-newGreenDark1));
  }
}
</style>
