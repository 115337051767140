<template>
  <div
    v-if="item.id === undefined"
    class="import-type fd-flex-center text-newSubText"
    :data-testid="`import-item-${item.id}`"
  >
    <template v-if="isFloating">
      <vue-feather type="refresh-cw" tag="div" class="mr-2" />

      <v-tooltip v-if="importPlaceholder" location="top">
        <template v-slot:activator="{props}">
          <span v-bind="props">
            {{ $t("Import.last_available_import") }}
          </span>
        </template>

        <div>
          {{ importPlaceholder.name }}
        </div>
      </v-tooltip>

      <div v-else>
        {{ $t("Import.last_available_import") }}
      </div>
    </template>
  </div>

  <div v-else class="import-type fd-flex-center text-newSubText gap-4">
    <vue-feather :type="getIconType" tag="div" class="min-width-24" />

    <v-tooltip location="top">
      <template v-slot:activator="{props}">
        <span
          v-bind="props"
          :class="{'import-type-file': isDefaultImport}"
          class="import-name"
          :data-testid="item.id"
        >
          {{ nameDisplayed }}
        </span>
      </template>

      <div>
        {{ nameDisplayed }}
      </div>
    </v-tooltip>

    <template v-if="isDefaultImport">
      <v-chip v-if="!isSelection" class="mr-2 bg-newSelected" size="small">
        {{
          item.manual
            ? $t("Simulation.Form.import_item_manual")
            : $t("Simulation.Form.import_item_API")
        }}
      </v-chip>
      -
      <v-tooltip location="top">
        <template v-slot:activator="{props}">
          <span v-bind="props" class="import-type-name">
            {{ importDate }}
          </span>
        </template>
        <div>
          {{ importDate }}
        </div>
      </v-tooltip>

      <v-chip v-if="isSelection" class="bg-newSelected" size="small">
        {{
          item.manual
            ? $t("Simulation.Form.import_item_manual")
            : $t("Simulation.Form.import_item_API")
        }}
      </v-chip>
    </template>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from "vue";
import {storeToRefs} from "pinia";
import {TAG_SEPARATOR} from "@/config/constants";

import {useMainStore} from "@/stores/mainStore";
import {parseDate} from "@oplit/shared-module";

export default defineComponent({
  props: {
    item: {type: Object, default: () => ({})},
    importPlaceholder: {type: Object, default: () => ({})},
    isSelection: {type: Boolean, default: false},
    isFloating: {type: Boolean, default: false},
  },
  setup(props) {
    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);

    const importDate = computed<string>(() =>
      parseDate(props.item.date, false, true, `LL - HH:mm`),
    );

    return {variables, importDate};
  },
  computed: {
    // differenciates normal imports from simulations (available for customers with PDP & Ordo since merge)
    isDefaultImport(): boolean {
      return this.item && "files" in this.item;
    },
    nameDisplayed(): string {
      const {item, isDefaultImport} = this;
      if (isDefaultImport)
        return item.files[0] || this.$t("Simulation.Form.import_item_no_file");

      return item.name.replace(TAG_SEPARATOR, " - ");
    },
    getIconType(): string {
      return this.isDefaultImport ? "file" : "tool";
    },
  },
});
</script>

<style scoped>
span.import-name {
  flex: 1;
  text-align: left;
}
</style>
