import {ref, computed, onBeforeUnmount, watch, unref} from "vue";
import {onSnapshot, Unsubscribe} from "firebase/firestore";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {GenericFunction, SectorLike, Simulation} from "@/interfaces";
import {useSimulation} from "./useSimulation";
import {
  asyncForEach,
  reverseLevelCorresp,
  type CustomDateObject,
} from "@oplit/shared-module";

type PGComposableComponentInstance = Partial<{
  simulation: any; //Simulation;
  sectorTree: any; //SectorLike;
  parsedPeriod: any; //CustomDateObject;
}>;

type ListenableTable = {
  name: string;
  use_simulation?: boolean;
  use_secteur?: boolean;
  use_secteur_type?: boolean;
  use_parent?: boolean;
};

const usePGCommonProperties = (
  componentInstance?: PGComposableComponentInstance,
) => {
  const mainStore = useMainStore();
  const {simulation: storedSimulation} = useSimulation();
  const {sectorTree: storedSectorTree} = storeToRefs(mainStore);

  const simulation = computed<Simulation>(
    () => unref(componentInstance?.simulation) || storedSimulation.value,
  );
  const sectorTree = computed<SectorLike>(
    () => unref(componentInstance?.sectorTree) || storedSectorTree.value,
  );

  return {simulation, sectorTree};
};

const usePGComputedProperties = (
  componentInstance?: PGComposableComponentInstance,
) => {
  const mainStore = useMainStore();
  const {userData, hasAutoOrga} = storeToRefs(mainStore);
  const {simulation, sectorTree} = usePGCommonProperties(componentInstance);

  const hasClientID = computed<boolean>(() => !!userData.value?.client_id);
  const canUseSector = computed<boolean>(() => {
    return hasClientID.value && !!sectorTree.value?.id;
  });

  const canUseSimulation = computed<boolean>(
    () => canUseSector.value && !!simulation.value?.id,
  );
  const canUseDates = computed<boolean>(() => {
    const parsedPeriod = unref(componentInstance?.parsedPeriod);
    return canUseSector.value && !!parsedPeriod?.startDate;
  });
  const canLoadCapa = computed<boolean>(() => {
    return canUseSimulation.value && canUseDates.value;
  });
  const canLoadOrga = computed<boolean>(
    () => canLoadCapa.value && hasAutoOrga.value,
  );

  return {
    canUseSector,
    canUseSimulation,
    canUseDates,
    canLoadCapa,
    canLoadOrga,
  };
};

const usePGSubscriptions = (
  componentInstance?: PGComposableComponentInstance,
) => {
  const mainStore = useMainStore();
  const {userData, disablePgRefresh} = storeToRefs(mainStore);
  const {simulation, sectorTree} = usePGCommonProperties(componentInstance);

  const unsubscribers = ref<Unsubscribe[]>([]);

  function pg_init(): void {
    unsubscribers.value.forEach((unsubscribe) => {
      if (unsubscribe) unsubscribe();
    });
  }

  function listenToUpdates(
    table: ListenableTable | undefined,
    callback: GenericFunction,
  ) {
    const {client_id} = userData.value || {};
    const {id: simulation_id} = unref(simulation) || {};
    const {id: secteur_id, type: secteur_type} = unref(sectorTree) || {};
    const {name, use_simulation, use_secteur, use_secteur_type, use_parent} =
      table || {};
    if (!client_id || !name) return;
    const where_adds = [];
    if (use_simulation) {
      if (!simulation_id) return;
      where_adds.push({
        field: "simulation_id",
        value: simulation_id,
        compare: "==",
      });
    }
    if (use_secteur) {
      if (!secteur_id) return;
      where_adds.push({
        field: "secteur_id",
        value: secteur_id,
        compare: "==",
      });
    } else if (use_secteur_type) {
      if (!secteur_type || !secteur_id) return;
      where_adds.push({
        field: secteur_type + "_id",
        value: secteur_id,
        compare: "==",
      });
    } else if (use_parent) {
      if (!secteur_id) return;
      const parent_level = reverseLevelCorresp.find(
        (level) => unref(sectorTree)[level.type + "_id"] !== undefined,
      );
      if (!parent_level) return;
      const parent_id = unref(sectorTree)[parent_level.type + "_id"];
      where_adds.push({
        field: "secteur_id",
        value: parent_id,
        compare: "==",
      });
    }

    const q = dbHelper.createRef("pg_updates", {
      where: [
        {field: "client_id", value: client_id, compare: "=="},
        {field: "table", value: name, compare: "=="},
        {field: "timestamp", value: new Date().getTime(), compare: ">="},
        ...where_adds,
      ],
    });
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (disablePgRefresh.value) return;

      snapshot.docChanges().forEach(async (change) => {
        if (!["added", "modified"].includes(change.type)) return;
        if (!callback || typeof callback !== "function") return;
        callback(name, change.type);
      });
    });
    unsubscribers.value.push(unsubscribe);
  }

  async function pg_subscribe(
    tables: string[] = [],
    callback: GenericFunction,
    use_parent = false,
  ) {
    const all_tables: ListenableTable[] = [
      {name: "event_infos", use_simulation: true},
      {name: "sectors", use_secteur: true},
      {name: "sectors", use_secteur_type: true},
      {name: "mevent_to_event", use_simulation: true},
      {
        name: "mevents_by_sector",
        use_simulation: true,
        use_secteur: true,
      },
      {
        name: "mevents_by_sector",
        use_simulation: true,
        use_secteur_type: true,
      },
      {
        name: "daily_capa",
        use_simulation: true,
        use_secteur: !use_parent,
        use_parent,
      },
      {
        name: "default_capa",
        use_secteur: !use_parent,
        use_parent,
      },
      {name: "operators_by_sector", use_simulation: true, use_secteur: true},
      {
        name: "operators_by_sector",
        use_simulation: true,
        use_secteur_type: true,
      },
      {name: "machines_by_sector", use_simulation: true, use_secteur: true},
      {
        name: "machines_by_sector",
        use_simulation: true,
        use_secteur_type: true,
      },
      {name: "daily_load", use_simulation: true, use_secteur: true},
      {name: "absences_by_operator", use_simulation: true},
      {name: "stops_by_machine", use_simulation: true},
      {name: "msd_events", use_simulation: true},
      {name: "event_fields", use_simulation: true},
      {name: "daily_prod"},
    ];
    await asyncForEach(tables, async (table: string) => {
      const matches = all_tables.filter((x) => x.name === table);
      await asyncForEach(matches, (match: ListenableTable) => {
        listenToUpdates(match, callback);
      });
    });
  }

  onBeforeUnmount(pg_init);

  return {pg_subscribe, pg_init};
};

const usePGWatcher = (
  componentInstance: PGComposableComponentInstance & {[key: string]: any},
) => {
  const mainStore = useMainStore();
  const {userData} = storeToRefs(mainStore);
  const {simulation, sectorTree} = usePGCommonProperties(componentInstance);

  // FIXME: remove this state and handle directly in components
  const pg_unit = ref("");
  const spinner = ref({});

  watch(
    () => componentInstance.parsedPeriod,
    (val: CustomDateObject, oldVal: CustomDateObject) => {
      if (!val?.startDate) return;
      if (
        val?.startDate === oldVal?.startDate &&
        val?.endDate === oldVal?.endDate
      )
        return;

      componentInstance.loadTotalCapa?.();
      componentInstance.loadOrga?.();
      componentInstance.loadCapa?.();
      componentInstance.loadCharge?.();
      componentInstance.loadProd?.();
      componentInstance.loadRetard?.();
      componentInstance.loadTooltip?.();
      componentInstance.loadFields?.();
      componentInstance.loadFieldValue?.();
      componentInstance.loadEvents?.();
      componentInstance.loadGoulot?.();
      componentInstance.loadCalendar?.();
      componentInstance.loadSimpleEvents?.();
      componentInstance.loadLoadRate?.();

      if (componentInstance.loadMachinesAndOperators)
        componentInstance.loadMachinesAndOperators?.();
      else {
        componentInstance.loadOperators?.();
        componentInstance.loadMachines?.();
      }
    },
  );

  watch(
    () => userData.value,
    (val: {client_id: string}, oldVal: {client_id: string}) => {
      if (!val?.client_id || val?.client_id === oldVal?.client_id) return;

      componentInstance.loadTotalCapa?.();
      componentInstance.loadOrga?.();
      componentInstance.loadCapa?.();
      componentInstance.loadRetard?.();
      componentInstance.loadTooltip?.();
      componentInstance.loadProd?.();
      componentInstance.loadCharge?.();
      componentInstance.loadImportId?.();
      componentInstance.loadUnit?.();
      componentInstance.loadFields?.();
      componentInstance.loadEvents?.();
      componentInstance.loadSimpleEvents?.();
      componentInstance.loadGoulot?.();
      componentInstance.loadCalendar?.();
      componentInstance.loadFieldValue?.();
      componentInstance.loadPgParams?.();
      componentInstance.pg_subscriptions?.();
      componentInstance.loadLoadRate?.();

      if (componentInstance.loadMachinesAndOperators)
        componentInstance.loadMachinesAndOperators?.();
      else {
        componentInstance.loadOperators?.();
        componentInstance.loadMachines?.();
      }
    },
  );

  watch(simulation, (val: Simulation, oldVal: Simulation) => {
    if (!val?.id || val?.id === oldVal?.id) return;

    componentInstance.loadTotalCapa?.();
    componentInstance.loadOrga?.();
    componentInstance.loadCapa?.();
    componentInstance.loadImportId?.();
    componentInstance.loadProd?.();
    componentInstance.loadCharge?.();
    componentInstance.loadRetard?.();
    componentInstance.loadFields?.();
    componentInstance.loadEvents?.();
    componentInstance.loadSimpleEvents?.();
    componentInstance.loadGoulot?.();
    componentInstance.pg_subscriptions?.();
    componentInstance.loadFieldValue?.();
    componentInstance.loadLoadRate?.();

    if (componentInstance.loadMachinesAndOperators)
      componentInstance.loadMachinesAndOperators?.();
    else {
      componentInstance.loadOperators?.();
      componentInstance.loadMachines?.();
    }
  });

  watch(
    sectorTree,
    (val: SectorLike, oldVal: SectorLike) => {
      if (!val?.id || val?.id === oldVal?.id) return;

      componentInstance.loadOrga?.();
      componentInstance.loadCapa?.();
      componentInstance.loadTotalCapa?.();
      componentInstance.loadCharge?.();
      componentInstance.loadProd?.();
      componentInstance.loadRetard?.();
      componentInstance.loadTooltip?.();
      componentInstance.loadUnit?.();
      componentInstance.loadFields?.();
      componentInstance.loadFieldValue?.();
      componentInstance.loadEvents?.();
      componentInstance.loadSimpleEvents?.();
      componentInstance.loadGoulot?.();
      componentInstance.loadCalendar?.();
      componentInstance.loadPgParams?.();
      componentInstance.pg_subscriptions?.();
      componentInstance.loadLoadRate?.();

      if (componentInstance.loadMachinesAndOperators)
        componentInstance.loadMachinesAndOperators?.();
      else {
        componentInstance.loadOperators?.();
        componentInstance.loadMachines?.();
      }
    },
    {immediate: true, deep: true},
  );

  return {
    pg_unit,
    spinner,
  };
};

export {usePGComputedProperties, usePGSubscriptions, usePGWatcher};
