<script setup lang="ts">
import {computed, nextTick, ref} from "vue";
import {storeToRefs} from "pinia";
import {FTextField} from "@/components/Global/Homemade/Inputs";
import {FButton} from "@/components/Global/Homemade/Buttons";
import {DATE_DEFAULT_FORMAT} from "@/config/constants";
import {useParameterStore} from "@/stores/parameterStore";
import {useMainStore} from "@/stores/mainStore";
import FSwitchButton from "@/components/Global/Homemade/Buttons/FSwitchButton.vue";
import DatePicker, {DatePickerComponentProps} from "vue-datepicker-next";
import frLangObject from "vue-datepicker-next/locale/fr.es";
import moment from "moment";
import {
  getNewDateFromShift,
  getShiftFromDate,
  nbWorkDays,
  Calendar,
  DATE_WITH_TIME_FORMAT,
} from "@oplit/shared-module";
import {useI18n} from "vue-i18n";

interface FDatePickerProps {
  childRef?: string;
  fieldClass?: string;
  placeholder?: string;
  iconSize?: string;
  iconFill?: string;
  iconStroke?: string;
  btnSize?: string;
  label?: string;
  suffix?: string;
  icon?: string;
  textClass?: string;
  locale?: string;
  dataTestid?: string;
  /** @property {string|number} dateValue - text displayed on the DatePicker button */
  dateValue?: string | number;
  fieldValue?: string | unknown[];
  totalWorkdays?: number;
  large?: boolean;
  light?: boolean;
  /** @property {boolean} closeOnContentClick - if true, the datepicker will close when selecting a date */
  closeOnContentClick?: boolean;
  top?: boolean;
  pink?: boolean;
  onlyText?: boolean;
  onlyTextWithIcon?: boolean;
  onlyTextWithIconClass?: string;
  btnDisplay?: boolean;
  /** @property {boolean} multiple - if true you pick a range of dates */
  multiple?: DatePickerComponentProps["range"];
  outlined?: boolean;
  block?: boolean;
  input?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  // control boolean to ensure the shift-related logic isn't applied everywhere for the time being
  canSelectShift?: boolean;
  forceOpen?: boolean;
  hideFooter?: boolean;
  hideWeekNumber?: boolean;
  handleChange?: (value: null | string | [string, string]) => unknown;
  dateFunctionEvents?: (...args) => unknown;
  /** @property {Function} disabledDates - if it returns true, the date is disabled */
  disabledDates?: (date: Date) => boolean;
  textDateFormatter?: (...args) => string;
}

const props = withDefaults(defineProps<FDatePickerProps>(), {
  top: true,
  locale: "",
  clearable: false,
});

const emit = defineEmits<{
  (e: "validate", payload: string | unknown[]);
  (e: "pick", date: Date);
}>();

const {shiftPlanningItems} = storeToRefs(useParameterStore());
const mainStore = useMainStore();
const {getCtxSectorOrderedCalendars} = mainStore;
const {activePerim} = storeToRefs(mainStore);
const {locale} = useI18n();

const datePickerLangObject = computed(() => {
  switch (locale.value) {
    case "fr": {
      return {
        ...frLangObject,
        formatLocale: {
          ...frLangObject.formatLocale,
          firstDayOfWeek: 1,
          weekdaysMin: ["D", "L", "M", "M", "J", "V", "S"],
        },
      };
    }

    default:
      return "en";
  }
});

const menu = ref(false);
const lastClickedDate = ref<Date | null>(null);
const isLastClickDifferent = ref(false);
const hasTwoDates = ref(true);
const workdays = ref<number | null>(props.totalWorkdays);

function convertStrDateIntoDateObject(date: string) {
  /**
   * the `time` and `shift` logics are separated in this component but arrive within props.fieldValue
   * we slice (0, DATE_DEFAULT_FORMAT.length) to get the proper date object for the DatePicker component
   */
  const [year, month, day] = date
    .slice(0, DATE_DEFAULT_FORMAT.length)
    .split("-");
  return new Date(+year, +month - 1, +day);
}

const getDatePickerValue = computed(() => {
  if (!props.fieldValue) return;
  if (typeof props.fieldValue === "object")
    return Array.from(props.fieldValue, convertStrDateIntoDateObject);
  return convertStrDateIntoDateObject(props.fieldValue);
});

const shouldIntegrateShiftValues = computed(
  () => props.canSelectShift && shiftPlanningItems.value.length > 0,
);

/**
 * used to return the selected date with the shift (if the logic is activated)
 */
const getFieldValueWithShift = computed(() => {
  if (!shouldIntegrateShiftValues.value) return props.fieldValue;
  return getNewDateFromShift(props.fieldValue as string, selectedShift.value);
});

const textFieldSuffix = computed(() => {
  if (props.suffix) return props.suffix;
  if (!shouldIntegrateShiftValues.value) return;
  return selectedShift.value.name;
});

const displayedValue = computed(() => {
  return props.textDateFormatter
    ? props.textDateFormatter(props.dateValue)
    : props.dateValue;
});

const orderedCalendars = computed<Calendar[]>(() =>
  getCtxSectorOrderedCalendars(activePerim.value),
);

const getPopupClass = computed<string>(() => {
  if (props.hideFooter) return "hide-footer";
  return "";
});

function formatDate(date: Date) {
  const strDate = moment(date).format(DATE_DEFAULT_FORMAT);
  if (!shouldIntegrateShiftValues.value) return strDate;
  return getNewDateFromShift(strDate, selectedShift.value, {
    dateFormatToReturn: DATE_WITH_TIME_FORMAT,
  });
}

function onUpdateDatePickerValue(date: Date | [Date, Date]) {
  if (date == null) return props.handleChange(null);

  const correctedDate =
    date instanceof Date
      ? formatDate(date)
      : (Array.from(date as [Date, Date], formatDate) as [string, string]);

  if (date instanceof Date || !lastClickedDate.value)
    return props.handleChange(correctedDate);

  /**
   * this is a workaround for an existing behaviour with the old VDatePicker that isn't implemented in the v-datepicker-next module
   * when in "multiple" mode, clicking on a date then validating, VDatePicker would emit an array of 2 members that are this date
   * v-datepicker-next emits [null, null] in such case : the following logic is to replicate the former behaviour
   */
  if (
    (moment(lastClickedDate.value).isSame(moment(date.at(-1))) ||
      moment(lastClickedDate.value).isSame(moment(date.at(0)))) &&
    isLastClickDifferent.value
  )
    return props.handleChange(correctedDate);
  else {
    return props.handleChange(
      Array.from({length: 2}, () => formatDate(lastClickedDate.value)) as [
        string,
        string,
      ],
    );
  }
}

async function validate() {
  /**
   * methods from vue-datepicker-next aren't exposed, this is a workaround
   */
  const datePickerConfirmButton = document.querySelector(
    ".mx-datepicker-btn-confirm",
  ) as HTMLButtonElement;

  datePickerConfirmButton.click();

  menu.value = false;

  await nextTick();

  emit("validate", getFieldValueWithShift.value);
  lastClickedDate.value = null;
}

/**
 * recursive function called to close the datepicker upon clicking outside the activator elements
 * this is naturally done when the `open` property isn't specified on the DatePicker component,
 * but we cannot manually close (through the "cancel" button) without this property
 */
function onCancelClick(event): void {
  const {target} = event;
  const {parentNode} = target;
  if (parentNode?.classList) {
    if (
      ["mx-datepicker-main", "mx-calendar"].some((cl: string) =>
        parentNode.classList.contains(cl),
      )
    )
      return;
    onCancelClick({target: parentNode});
  } else menu.value = false;
}

function onDatePick(date: Date) {
  isLastClickDifferent.value =
    !!lastClickedDate.value &&
    !moment(lastClickedDate.value).isSame(moment(date));

  // Workdays Calculation
  if (lastClickedDate.value && date && props.multiple) {
    // Sorting dates
    if (lastClickedDate.value > date)
      [lastClickedDate.value, date] = [date, lastClickedDate.value];
    const startDate: string = moment(lastClickedDate.value).format(
      "YYYY-MM-DD",
    );
    const endDate: string = moment(date).format("YYYY-MM-DD");
    // Using sector ordered calendars
    workdays.value = nbWorkDays(
      startDate,
      endDate,
      orderedCalendars.value,
      "month",
      "",
      true,
    )?.total_days;
  } else workdays.value = null;

  // Workdays display
  if (lastClickedDate.value) {
    hasTwoDates.value = true;
    lastClickedDate.value = null;
  } else {
    lastClickedDate.value = date;
    hasTwoDates.value = false;
  }

  if (props.closeOnContentClick) menu.value = false;

  emit("pick", date);
}

const selectedShift = ref(
  getShiftFromDate(props.fieldValue as string, shiftPlanningItems.value),
);
</script>

<template>
  <DatePicker
    v-click-outside="onCancelClick"
    :value="getDatePickerValue"
    :default-date="getDatePickerValue"
    :range="multiple"
    :lang="datePickerLangObject"
    :disabled="disabled"
    :disabled-date="disabledDates"
    :open="forceOpen || menu"
    :class="{
      'w-fit': !multiple,
      'f-date-picker__button-display': btnDisplay,
    }"
    :popup-class="getPopupClass"
    :show-week-number="!hideWeekNumber"
    :clearable="clearable"
    :on-confirm="validate"
    class="w-auto"
    :confirm="!closeOnContentClick"
    @update:value="onUpdateDatePickerValue"
    @pick="onDatePick"
  >
    <template #input>
      <FButton
        v-if="btnDisplay"
        :class="fieldClass"
        :block="block"
        :outlined="outlined"
        :pink="pink"
        :input="input"
        :icon-fill="iconFill"
        :icon-size="iconSize"
        :icon-stroke="iconStroke"
        :large="large"
        :light="light"
        :height="btnSize"
        :text-class="textClass"
        expand-content
        icon="calendar"
        :data-testId="dataTestid"
        :disabled="disabled"
        @click="() => (menu = !menu)"
      >
        {{ displayedValue }}
      </FButton>
      <span
        v-else-if="onlyText"
        :data-testid="dataTestid"
        @click="() => (menu = !menu)"
      >
        {{ displayedValue }}
      </span>
      <div
        v-else-if="onlyTextWithIcon"
        class="fd-flex-center"
        :class="onlyTextWithIconClass"
        :data-testid="dataTestid"
        @click="() => (menu = !menu)"
      >
        <vue-feather class="mr-2" :type="icon" size="20px" />
        <span class="text-nowrap">{{ displayedValue }}</span>
      </div>

      <FTextField
        v-else
        :model-value="dateValue || ''"
        :placeholder="placeholder"
        :class="[fieldClass, {'cursor-pointer': !disabled}]"
        :large="large"
        :light="light"
        :suffix="textFieldSuffix"
        :label="label"
        :icon="icon"
        :disabled="disabled"
        :data-testid="dataTestid"
        :data-date="dateValue"
        :style="{width: `${`${dateValue}`.length}ch`}"
        readonly
        data-cy="fdatepicker-textfield"
        @click="() => (menu = !menu)"
      />
    </template>

    <template v-if="!hideFooter" #footer>
      <div class="d-flex flex-column gap-8 justify-center width-100">
        <slot name="prepend-action" />

        <div v-if="shouldIntegrateShiftValues">
          <FSwitchButton
            :model-value="selectedShift"
            :items="shiftPlanningItems"
            :outlined="['newLayerBackground']"
            item-text="name"
            item-value="name"
            return-object
            medium
            @update:model-value="(shift) => (selectedShift = shift)"
          />
        </div>

        <div class="d-flex items-center">
          <div
            class="text-center pa-1"
            v-show="multiple && workdays && hasTwoDates"
          >
            {{ workdays }}
            {{ $t("Simulation.business_days", Math.max(workdays, 1)) }}
          </div>
          <v-spacer />
          <FButton
            density="compact"
            data-cy="fdatepicker-cancel"
            @click="() => (menu = false)"
          >
            {{ $t("global.annuler") }}
          </FButton>

          <FButton filled data-cy="fdatepicker-validate" @click="validate">
            {{ $t("global.valider") }}
          </FButton>
        </div>
      </div>
    </template>
  </DatePicker>
</template>

<style lang="scss">
.hide-footer .mx-datepicker-footer {
  padding: 0;
}
.mx-icon-right::before,
.mx-icon-left::before {
  height: 15px !important;
  width: 15px !important;
  border-width: 4px 0 0 4px !important;
}
</style>
