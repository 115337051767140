<script setup lang="ts">
import {computed, ref} from "vue";
import {storeToRefs} from "pinia";
import {useRoute} from "vue-router";
import LeftSidebar from "@/components/Navigation/LeftSidebar.vue";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";

const route = useRoute();
const {environment} = storeToRefs(useMainStore());
const {isPiloting} = storeToRefs(useSchedulingStore());

// this value should be modified with its scss counterpart (constants.scss)
const leftSidebarMiniWidth = 68;
const leftMini = ref(true);

/**
 * returns a string representing the background color for the left sidebar
 * the coloring of the text is deduced from this color & made in the (s)css part of the LeftSidebar component
 */
const leftSidebarBgColor = computed(() => {
  if (isPiloting.value && route.name === "scheduling-piloting")
    return "newOrangeRegular";
  return "newPrimaryDark2";
});
</script>

<template>
  <!-- navigation drawers -->
  <v-navigation-drawer
    :class="`bg-${leftSidebarBgColor}`"
    :rail-width="leftSidebarMiniWidth"
    width="220"
    disable-resize-watcher
    expand-on-hover
    permanent
    touchless
    rail
    @update:rail="(bool) => (leftMini = bool)"
  >
    <LeftSidebar :mini="leftMini">
      <template #before-bottom-list>
        <DevHelper
          data-cy="env-span"
          :class="[
            {'env-span-prod': environment === 'prod'},
            'env-span font-weight-bold',
          ]"
        >
          {{ environment }}
        </DevHelper>
      </template>
    </LeftSidebar>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.v-navigation-drawer {
  /**
    * for proper circle around the icons inside the drawer, the x padding value has to be :
    * mini-variant-width (68 ~ propped) - xPadding = min-height item-list (44 ~ css'd in LeftSideBar)
    */

  padding: 0 12px;
  border: none;

  &.v-navigation-drawer--is-hovering {
    // rgba() is used instead of a variable to use the transparency
    // as it stood, the shadow was too dark in $noir in light-mode,
    // and too light in $newAppBackground in light mode
    box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1) !important;
  }
}
</style>
