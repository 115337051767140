const separator = "_";

/**
 * @param localStorageIdentifier prefix used to build a key
 */
const buildLocalStorageUtils = (localStorageIdentifier: string) => {
  /**
   * @returns a key to save into localstorage scoped with a prefix
   */
  function buildStorageKey(key: string): string {
    return `${localStorageIdentifier}${separator}${key}`;
  }
  return {
    buildStorageKey,
  };
};

export {buildLocalStorageUtils};
