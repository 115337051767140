import {ParametersHomepageUser} from "@/interfaces";

const DEFAULT_HOMEPAGE_PARAMETERS: ParametersHomepageUser = {
  last_version_read: "",
  display_settings: {
    planning: true,
    scheduling: true,
    piloting: true,
  },
  planning: {
    perimeters_ids: [],
    simulation_period: {
      temporal_scale: "month",
      period: "current",
    },
  },
  scheduling: {
    perimeters_ids: [],
    simulation_period: {
      temporal_scale: "month",
      period: "current",
    },
  },
  piloting: {
    perimeters_ids: [],
    simulation_period: {
      temporal_scale: "month",
      period: "current",
    },
  },
};

export {DEFAULT_HOMEPAGE_PARAMETERS};
