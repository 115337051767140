import {computed, ref, unref, watchEffect} from "vue";
import {storeToRefs} from "pinia";
import {buildRecursivelySectorTree} from "@oplit/shared-module";
import {Machine, MachineCenter, Sector, SectorLike} from "@/interfaces";
import {MaybeRef} from "@vueuse/core";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "../stores/mainStore";

export const useSectorTree = (
  perimeter: MaybeRef<SectorLike>,
  machinesArray?: MaybeRef<Machine[]>,
) => {
  const mainStore = useMainStore();
  const {isScheduling, machines, perimeters} = storeToRefs(mainStore);
  const {schedulingMachineCentersAndTags} = storeToRefs(useSchedulingStore());

  const sectorTree = ref<any>({});
  const computedPerimeter = computed(() => unref(perimeter));

  /**
   * list of machines in which to look for if @perimeter has the `is_machine` flag
   */
  const defaultedMachinesArray = computed<Machine[] | MachineCenter[]>(() => {
    const _machinesArray = unref(machinesArray);
    if (_machinesArray?.length) return _machinesArray;
    return isScheduling.value
      ? machines.value
      : schedulingMachineCentersAndTags.value;
  });

  const adjustedPerimeter = computed<SectorLike>(() => {
    const {secteur_id, secteur_collection, id, collection, is_machine} =
      unref(perimeter);

    const perimeterCollection = secteur_collection || collection;
    const perimeterId = secteur_id || id || "operations";

    if (is_machine) {
      // Very hackish, but TS still has trouble it seems with multiple possible array types
      return (defaultedMachinesArray.value as any[]).find(
        ({id}: Machine | MachineCenter) => id === perimeterId,
      );
    }

    const populatedPerimeter = (
      perimeters.value[perimeterCollection] || []
    ).find((sector: Sector) => sector.id === perimeterId);

    return populatedPerimeter ?? perimeter;
  });

  watchEffect(() => {
    sectorTree.value = computedPerimeter.value?.is_machine
      ? unref(adjustedPerimeter) // the sectorTree is the definition within the machines array if the considered perimeter is a machine
      : buildRecursivelySectorTree(unref(adjustedPerimeter), perimeters.value);
  });

  return {
    sectorTree,
  };
};
