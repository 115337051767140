<template>
  <div class="calendar-row-content">
    <div class="d-flex calendar-row">
      <SchedulingRowHeader
        :view-type="viewType"
        :key="`${mc.secteur_id}_scheduling_row`"
        :mc="mc"
        :period-data="periodData"
        :pg_update-charge="pg_updateCharge"
        :pg_ops="periodPgOps"
        :selected-view="selectedView"
        :can-toggle-operations="canToggleOperationsFromHeader"
        :are-toggled-operations="!shouldHide"
        class="scheduling-row"
        @toggle-operations="() => $emit('toggle-machine-center', mc.secteur_id)"
      />
      <div
        class="d-flex planning-calendar-body-row"
        :class="{'day-view': selectedView === 'day_view' && shouldHide}"
        :data-toggled-status="getToggledStatusMessage"
      >
        <div class="d-flex min-width-0">
          <div
            v-if="isPastDelayShown"
            :key="`${mc.secteur_id}_operation_card`"
            :class="computedColumnClasses[DEFAULT_PAST_START_DATE]"
            :style="getColumnStyle"
            class="planning-calendar-body-column past-delay"
            :data-testid="`past-delay-column-${mc.secteur_id}`"
          >
            <template
              v-for="(shift, shiftIndex) in shiftPlanningsArray"
              :key="`${shift.name}_${shift.start_time}_past`"
            >
              <CapacityDayCell
                :mc="mc"
                :period-data="periodData"
                :shift="shift"
                :show-prefix="selectedView === 'day_view'"
                :selected-view="selectedView"
                :loading="loading"
                :suffix="
                  getDayCellSuffix(
                    computedOperationsByShiftAndDatesOnMiniOps[
                      shift.start_time
                    ][DEFAULT_PAST_START_DATE],
                  )
                "
                is-past
              />

              <OperationsCardsGroup
                v-if="schedulingCurrentGroupBy?.field"
                :operations-list="
                  computedOperationsByShiftAndDatesOnMiniOps[shift.start_time][
                    DEFAULT_PAST_START_DATE
                  ]
                "
                :secteur-id="mc.secteur_id"
                :maille="periodData.maille"
                :selected-ops="selectedOps"
                :pg_ops="pg_ops"
                :selected-view="selectedView"
                :shift-pagination="computedShiftPagination[shiftIndex]"
                is-past
                @change-status="changeStatus"
                @select-card="clickCard"
                @move-operation="keyupHandler"
                @change-operation-shift="
                  (direction) =>
                    moveIndividualCards(
                      null,
                      getShiftChangeDirection(direction),
                      shiftIndex,
                    )
                "
              />

              <template v-else>
                <Component
                  v-for="(
                    op, index
                  ) in computedOperationsByShiftAndDatesOnMiniOps[
                    shift.start_time
                  ][DEFAULT_PAST_START_DATE]"
                  :is="getOperationDisplayComponent"
                  :id="op.op_id"
                  :key="`${op.op_id}_${op.day_date}`"
                  :operation="op"
                  :order="index + 1"
                  :selected-ops="selectedOps"
                  :current-array="miniOpsByDate[DEFAULT_PAST_START_DATE]"
                  :has-done-ops="hasSelectedDoneOPs"
                  is-past
                  @change-status="changeStatus"
                  @select-card="clickCard"
                  @move-operation="keyupHandler"
                  @change-operation-shift="
                    (direction, key) =>
                      moveIndividualCards(
                        null,
                        getShiftChangeDirection(direction),
                        shiftIndex,
                        key,
                      )
                  "
                />
              </template>
            </template>
          </div>
          <div
            v-for="(header, periodInfoIndex) in periodInfo"
            :key="`line_${periodInfoIndex}_${header.start_date}_simulation_cards`"
            :class="computedColumnClasses[header.start_date]"
            :style="getColumnStyle"
            class="planning-calendar-body-column"
            :data-testid="`planning-calendar-column-${mc.secteur_id}-${periodInfoIndex}`"
          >
            <div
              v-for="(shift, shiftIndex) in shiftPlanningsArray"
              :key="`${shift.name}_${shift.start_time}`"
              :data-testid="`${mc.secteur_id}_${header.start_date}_${shift.name}`"
            >
              <CapacityDayCell
                :mc="mc"
                :day="header.start_date"
                :period-data="periodData"
                :day-end="header.end_date"
                :shift="shift"
                :show-prefix="selectedView === 'day_view'"
                :suffix="
                  getDayCellSuffix(
                    computedOperationsByShiftAndDatesOnMiniOps[
                      shift.start_time
                    ][header.start_date],
                  )
                "
                :selected-view="selectedView"
                :loading="loading"
              />

              <OperationsCardsGroup
                v-if="schedulingCurrentGroupBy?.field"
                :operations-list="
                  computedOperationsByShiftAndDatesOnMiniOps[shift.start_time][
                    header.start_date
                  ]
                "
                :secteur-id="mc.secteur_id"
                :date-iso="header.start_date"
                :maille="periodData.maille"
                :selected-ops="selectedOps"
                :selected-view="selectedView"
                :display-actions-menu-side="
                  computedDisplayActionsMenuSide[periodInfoIndex]
                "
                :pg_ops="pg_ops"
                :shift-pagination="computedShiftPagination[shiftIndex]"
                :should-alert-split-of="shouldAlertForSplitOf"
                @change-status="changeStatus"
                @select-card="clickCard"
                @move-operation="keyupHandler"
                @change-operation-shift="
                  (direction, key) =>
                    moveIndividualCards(
                      null,
                      getShiftChangeDirection(direction),
                      shiftIndex,
                      key,
                    )
                "
              />

              <template v-else>
                <Component
                  v-for="(
                    op, index
                  ) in computedOperationsByShiftAndDatesOnMiniOps[
                    shift.start_time
                  ][header.start_date]"
                  :is="getOperationDisplayComponent"
                  :id="op.op_id"
                  :operation="op"
                  :key="`${op.op_id}_${op.day_date}`"
                  :order="index + 1"
                  :selected-ops="selectedOps"
                  :actions-menu-display-side="
                    computedDisplayActionsMenuSide[periodInfoIndex]
                  "
                  :shift-pagination="computedShiftPagination[shiftIndex]"
                  :current-array="miniOpsByDate[header.start_date]"
                  :has-done-ops="hasSelectedDoneOPs"
                  @change-status="changeStatus"
                  @select-card="clickCard"
                  @move-operation="keyupHandler"
                  @change-operation-shift="
                    (direction, key) =>
                      moveIndividualCards(
                        null,
                        getShiftChangeDirection(direction),
                        shiftIndex,
                        key,
                      )
                  "
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  provide,
  Ref,
  ref,
  unref,
  onUnmounted,
  onMounted,
  watch,
} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import moment from "moment";
import {useI18n} from "vue-i18n";
import {
  usePGWatcher,
  usePGComputedProperties,
  usePGSubscriptions,
} from "@/composables/pgComposable";
import {useSectorTree} from "@/composables/useSectorTree";
import {
  areSchedulingFiltersActive,
  filterSchedulingOperations,
  getFirstSelected,
  hasArrayDoneOperations,
} from "@/tscript/utils/schedulingUtils";
import {
  asyncForEach,
  DailyLoadNew,
  parseDate,
  pgOpsMapFn,
  getNewDateFromShift,
  getShiftFromDate,
  isWorkDay,
  DATE_DEFAULT_FORMAT,
  DATE_WITH_TIME_FORMAT,
  DATE_WITH_DEFAULT_TIME_FORMAT,
  isPastDate,
  TIME_DEFAULT_FORMAT,
  isDoneOperation,
  DEFAULT_PAST_START_DATE,
} from "@oplit/shared-module";
import {OperationsCardsGroup} from "@/components/Scheduling/Operations";
import {CapacityDayCell} from "@/components/Scheduling/Capacity";
import SchedulingRowHeader from "../SchedulingRowHeader.vue";
import {CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS} from "@/config/constants";
import {
  GenericObject,
  SchedulingFilter,
  SchedulingOperation,
  SectorLike,
  ShiftPlanningItem,
  CustomPeriod,
  ParametersSchedulingRule,
  OperationsActionsMenuSelectedOps,
} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useAPIStore} from "@/stores/apiStore";

import {useMainStore} from "@/stores/mainStore";
import {useParameterStore} from "@/stores/parameterStore";
import useSchedulingGroups from "@/composables/scheduling/useSchedulingGroups";

import SchedulingOperationRow from "@/components/Scheduling/Operations/SchedulingOperationRow.vue";
import SchedulingOperationCard from "@/components/Scheduling/Operations/SchedulingOperationCard.vue";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";

type OperationsByDates = Record<
  string,
  (SchedulingOperation & {is_split?: boolean})[]
>;

const swapElements = (
  arr: any[],
  firstIndex: number,
  secondIndex: number,
): void => {
  [arr[firstIndex], arr[secondIndex]] = [arr[secondIndex], arr[firstIndex]];
};
//we define them outside of the component to be able to call ".cancel()" on them
const loadChargeLightDebouncedFunction = _.debounce(function (func) {
  func();
}, 5_000);
const loadChargeHeavyDebouncedFunction = _.debounce(function (func) {
  func();
}, 60_000);

export default defineComponent({
  name: "calendar-row",
  components: {
    SchedulingRowHeader,
    OperationsCardsGroup,
    CapacityDayCell,
    SchedulingOperationRow,
    SchedulingOperationCard,
  },
  props: {
    mc: {
      type: Object as PropType<SectorLike>,
      default: () => ({} as SectorLike),
    },
    parentSelectedCard: {type: Object, default: () => ({})},
    filters: {type: Array as PropType<SchedulingFilter[]>, default: () => []},
    isPastDelayShown: {type: Boolean, default: false},
    areWeekendsHidden: {type: Boolean, default: false},
    periodData: {
      type: Object as PropType<CustomPeriod>,
      default: () => ({} as CustomPeriod),
    },
    filterDoneOps: {type: Boolean, default: false},
    viewType: {type: String, default: "scheduling"},
    selectedDelayMesh: {type: Object, default: () => ({})},
    selectedView: {
      type: String as PropType<"day_view" | "week_view" | "month_view">,
    },
    isToggled: {type: Boolean, default: false},
  },
  emits: [
    "set-new-selected-date",
    "toggle-machine-center",
    "select-card",
    "select-card",
    "operations-loaded",
  ],
  setup(props, context) {
    const {t} = useI18n();
    const {chargeFn} = useAPIStore();
    const mainStore = useMainStore();
    const {
      userData,
      apiClient,
      perimeters,
      pgRefresh,
      machines,
      stations,
      clientParameters,
      userParameters,
    } = storeToRefs(mainStore);
    const {getCtxSectorOrderedCalendars} = mainStore;
    const schedulingStore = useSchedulingStore();
    const {
      addNewSchedulingChanges,
      sendSchedulingLoadEventToBackend,
      setPgOpsModificationsFromUpdate,
      setTotalLoadBySectorFromOperations,
      getDayCellSuffix,
    } = schedulingStore;
    const {
      selectedSimulation: simulation,
      schedulingCurrentGroupBy,
      pgOpsModifications,
      calendarOfIdsToExport,
      pilotingOfIdsToExport,
      localSchedulingChanges,
      schedulingUpdateTrigger,
      hasLocalChanges,
      isSavingSchedulingChanges,
      canOperateOnOperationCards,
      shouldHandleOPDuration,
      totalCapaBySector,
    } = storeToRefs(schedulingStore);
    const parameterStore = useParameterStore();
    const {getSectorSchedulingRule} = parameterStore;
    const {doesClientHaveShiftPlanning, clientFirstShift} =
      storeToRefs(parameterStore);

    const {sectorTree} = useSectorTree(props.mc, machines.value);
    const {pg_init, pg_subscribe} = usePGSubscriptions({
      sectorTree,
      simulation,
    });

    const {toggledGroups, computeSchedulingGroupKey} =
      useSchedulingGroups(context);

    const {getSelectedOperationDetail} = useComputeQuantityUnit();

    // used for the moveIndividualCards function, to distinguish regular up/down movements from shifts'
    const shiftDirectionPrefix = "shift-";

    const columnsWidth = inject<Ref<number>>("columnsWidth");
    const columnsBackground = inject<Ref<string>>("columnsBackground");
    // inject shiftPlanning and hasShiftPlanning
    const shiftPlanningsArray =
      inject<ComputedRef<Partial<ShiftPlanningItem>[]>>("shiftPlanning");
    const hasShiftPlanning = inject<ComputedRef<boolean>>("hasShiftPlanning");

    const loading = ref(false);
    const pg_ops = ref<SchedulingOperation[]>([]);
    const schedulingAiRule = ref<ParametersSchedulingRule | null>(null);
    const hasSelectedDoneOPs = ref(false);
    const selectedOps = ref<OperationsActionsMenuSelectedOps>({});

    provide("pg_ops", pg_ops);

    const getOperationDisplayComponent = computed(() =>
      props.selectedView === "day_view"
        ? SchedulingOperationRow
        : SchedulingOperationCard,
    );

    const parsedPeriod = computed(() => {
      if (props.viewType !== "piloting") return props.periodData;
      const {startDate, endDate} = props.periodData;
      const simulationStart = parseDate(simulation.value?.created_at, true);
      return {
        startDate: _.max([
          startDate,
          moment(simulationStart).format(DATE_DEFAULT_FORMAT),
        ]),
        endDate,
      };
    });

    const {canLoadCapa, canUseSimulation} = usePGComputedProperties({
      parsedPeriod,
      sectorTree,
      simulation,
    });

    const getColumnStyle = computed(() => ({
      width: `${columnsWidth.value}px`,
      "background-color": `rgb(var(--v-theme-${columnsBackground.value}))`,
    }));

    const computedColumnClasses = computed(() => {
      const classesByDay = [
        ...periodInfo.value,
        {
          start_date: DEFAULT_PAST_START_DATE,
          end_date: DEFAULT_PAST_START_DATE,
        },
      ].reduce((acc, day) => {
        acc[day.start_date] = {
          "is-toggled": !shouldHide.value,
          "hide-right-border": !!getToggledStatusMessage.value,
          "bg-newLightGrey": isClosedDay(day),
        };
        return acc;
      }, {});
      return classesByDay;
    });

    const filteredOps = computed(() => {
      return filterSchedulingOperations(unref(pg_ops), props.filters, {
        with_pending_order:
          clientParameters.value.has_ordered_pending_op_status,
      });
    });

    const ofIdsToExport = computed<string[]>(() =>
      _.uniqBy(unref(filteredOps), (op) => op.of_id).map(({of_id}) => of_id),
    );

    const seeAll = computed(() => {
      return (
        !!schedulingCurrentGroupBy.value?.field &&
        props.selectedView !== "day_view"
      );
    });

    const shouldHide = computed(() => {
      return !seeAll.value && !props.isToggled;
    });

    const delayEndDate = computed(() => {
      const end_date = moment(
        _.min([
          moment().format(DATE_DEFAULT_FORMAT),
          parsedPeriod.value.startDate,
        ]),
      )
        .subtract(1, "days")
        .format(DATE_DEFAULT_FORMAT);
      return end_date;
    });

    const opsGroupedByDay = computed(() => {
      const {maille} = props.periodData || {};
      const opsGroupedByDay = _.groupBy(unref(pg_ops), (o: any) => {
        const {new_date, day_date} = o;

        let date = moment(new_date ?? day_date).format(DATE_DEFAULT_FORMAT);
        if (date <= delayEndDate.value) return DEFAULT_PAST_START_DATE;
        if (maille === "month") date = dateKeyForMonthMesh(date);
        return date;
      });
      return opsGroupedByDay;
    });

    const opsByDate = computed(() => {
      const {periodArray} = props.periodData || {};
      const datesArr = props.isPastDelayShown
        ? [{start_date: DEFAULT_PAST_START_DATE}, ...(periodArray || [])]
        : periodArray || [];
      let opsByDate = [];
      opsByDate = datesArr.reduce((acc: any, curr: any) => {
        let dayArr = _.orderBy(opsGroupedByDay.value[curr.start_date] || [], [
          (o: any) =>
            ![null, undefined].includes(o.new_order)
              ? +o.new_order
              : +o.op_order,
        ]);
        return {
          ...acc,
          [curr.start_date]: dayArr,
        };
      }, {});

      return opsByDate;
    });

    const miniOpsByDate = computed(() => {
      if (!shouldHide.value && !areSchedulingFiltersActive(props.filters))
        return opsByDate.value;
      return Object.keys(opsByDate.value).reduce((acc: any, curr: any) => {
        let dayArr = opsByDate.value[curr];

        if (shouldHide.value) dayArr = [];
        else if (areSchedulingFiltersActive(props.filters)) {
          dayArr = dayArr.filter((op: any) =>
            filteredOps.value.some((fo: any) => fo.op_id === op.op_id),
          );
        }

        return {
          ...acc,
          [curr]: dayArr,
        };
      }, {});
    });

    const periodInfo = computed(() => {
      return props.periodData?.periodArray || [];
    });

    const isCurrentGroupBySameAsRule = computed<boolean>(() => {
      const groupByParameter = schedulingAiRule.value?.ai_data?.group_by;
      const currentGroupByField = schedulingCurrentGroupBy.value?.field;
      if (!groupByParameter || !currentGroupByField) return false;
      return groupByParameter === currentGroupByField;
    });

    const shouldAlertForSplitOf = computed<boolean>(() => {
      const smallestMesh = doesClientHaveShiftPlanning.value
        ? "day_view"
        : "week_view";
      return (
        props.selectedView === smallestMesh && isCurrentGroupBySameAsRule.value
      );
    });

    const computedShiftPagination = computed(() => {
      return shiftPlanningsArray.value.map((_, idx) => ({
        current: idx,
        max: shiftPlanningsArray.value.length - 1,
      }));
    });

    const computedDisplayActionsMenuSide = computed(() => {
      return periodInfo.value.map((_, index) => {
        if (index >= periodInfo.value.length - 1) return "left";
        return "right";
      });
    });

    const selectedOpsIDs = computed(() => Object.keys(selectedOps.value));

    watch(
      () => selectedOps.value,
      (newOperations) => {
        const completeOPs = pg_ops.value.filter((o: SchedulingOperation) =>
          Object.keys(newOperations).includes(o.op_id),
        );

        hasSelectedDoneOPs.value = hasArrayDoneOperations(completeOPs);
      },
      {deep: true},
    );

    function getShiftFromOperation(
      operation: SchedulingOperation,
    ): Partial<ShiftPlanningItem> | null {
      const operationDate = operation.new_date ?? operation.day_date;
      if (!operationDate || !unref(hasShiftPlanning)) return null;

      return getShiftFromDate(
        operationDate,
        unref(shiftPlanningsArray) as ShiftPlanningItem[],
      );
    }

    function getOperationsByShift(
      operations: SchedulingOperation[],
      shift: Partial<ShiftPlanningItem>,
    ) {
      if (props.selectedView !== "day_view" || !hasShiftPlanning.value)
        return operations;

      const currentShiftIndex = shiftPlanningsArray.value.findIndex(
        (s) => JSON.stringify(s) === JSON.stringify(shift),
      );
      const isLastShift =
        currentShiftIndex === shiftPlanningsArray.value.length - 1;
      return operations.filter(({new_date, day_date}) => {
        const operationDate = new_date ?? day_date;
        if (!operationDate) return false;

        // if this is the case, there is no time defined for this operation : we set it in the first shift by default
        if (operationDate.match(/^\d{4}-\d{2}-\d{2}$/))
          return currentShiftIndex === 0;

        const operationTime = moment(
          operationDate,
          DATE_WITH_TIME_FORMAT,
        ).format(TIME_DEFAULT_FORMAT);

        /**
         * we return operations that are above the `start_time` of the current shift
         * and below the `start_time` of the next within the list
         * FIXME: ensure the shifts are sorted by their `start_time`
         */
        return (
          shift.start_time <= operationTime &&
          (isLastShift ||
            operationTime <
              shiftPlanningsArray.value[currentShiftIndex + 1].start_time)
        );
      });
    }

    function dateKeyForMonthMesh(date: string) {
      return _.max([
        moment(date).startOf("week").format(DATE_DEFAULT_FORMAT),
        parsedPeriod.value.startDate,
      ]);
    }

    function getIndex(shiftIndex: number, dateIndex: number) {
      return shiftIndex + dateIndex * shiftPlanningsArray.value.length;
    }

    const allPeriodDates = computed(() => [
      DEFAULT_PAST_START_DATE,
      ...periodInfo.value.map(({start_date}) => start_date),
    ]);

    /**
     * calculated object with operations by shifts and dates
     * {
     *    myShift1: {
     *      myDate1: [operations],
     *      myDate2: [operations],
     *      ...
     *    },
     *    myShift2: {...}
     * }
     */
    const computedOperationsByShiftAndDatesOnAllOps = computed(() =>
      getOperationsByShiftAndDates(true),
    );

    const computedOperationsByShiftAndDatesOnMiniOps = computed(() =>
      getOperationsByShiftAndDates(),
    );

    const hiddenOpsCount = computed(() => {
      const keys = Object.keys(opsByDate.value);
      if (!keys?.length) return 0;
      let opsByDateLength = 0,
        miniOpsByDateLength = 0;
      keys.forEach((key: string) => {
        if (areSchedulingFiltersActive(props.filters)) {
          opsByDateLength += opsByDate.value[key].filter((op: any) =>
            filteredOps.value.some((fo: any) => fo.op_id === op.op_id),
          ).length;
        } else opsByDateLength += opsByDate.value[key].length;
        miniOpsByDateLength += miniOpsByDate.value[key].length;
      });
      return opsByDateLength - miniOpsByDateLength;
    });

    const showSeeMore = computed(() => {
      if ((seeAll.value || hiddenOpsCount.value <= 0) && !props.isToggled)
        return false;
      return true;
    });

    const canToggleOperationsFromHeader = computed<boolean>(() => {
      if (unref(pg_ops).length < 1) return false;
      if (!showSeeMore.value) return false;
      return true;
    });

    const getToggledStatusMessage = computed<string>(() => {
      if (!canToggleOperationsFromHeader.value) return;
      if (props.selectedView === "day_view") return;
      if (props.isToggled) return;
      return t("CalendarRow.toggle_status_message");
    });

    watch(
      () => [
        filteredOps.value,
        userParameters.value.include_filters_in_load_rate,
      ],
      ([newFilteredOps, newIncludeFiltersInLoadRate]) => {
        const operations = newIncludeFiltersInLoadRate
          ? newFilteredOps
          : pg_ops.value;

        setTotalLoadBySectorFromOperations(
          operations,
          props.mc as {secteur_id: string},
        );
      },
    );

    function getOperationsByShiftAndDates(
      includeAllOperations = false,
    ): Record<string, OperationsByDates> {
      const ofGroupByFieldsByIndex: Record<number, string[]> = {};
      const opsByIndex: Record<number, SchedulingOperation[]> = {};

      const OPsToUse = includeAllOperations
        ? opsByDate.value
        : miniOpsByDate.value;

      shiftPlanningsArray.value.forEach((shift, shiftIndex) => {
        allPeriodDates.value.forEach((date, dateIndex) => {
          const opsByShift = getOperationsByShift(OPsToUse[date] || [], shift);
          const index = getIndex(shiftIndex, dateIndex);

          ofGroupByFieldsByIndex[index] = [
            ...new Set(
              Object.keys(
                _.groupBy(opsByShift, schedulingCurrentGroupBy.value?.field),
              ),
            ),
          ];

          opsByIndex[index] = opsByShift;
        });
      });

      return shiftPlanningsArray.value.reduce(
        (shiftsAcc, shift, shiftIndex): Record<string, OperationsByDates> => ({
          ...shiftsAcc,
          [shift.start_time]: allPeriodDates.value.reduce(
            (datesAcc, date, dateIndex) => {
              const index = getIndex(shiftIndex, dateIndex);

              const operationsByDates = {
                ...datesAcc,
                [date]: opsByIndex[index],
              };

              if (isCurrentGroupBySameAsRule.value) {
                operationsByDates[date] = operationsByDates[date].map((op) => ({
                  ...op,
                  is_split: [index - 1, index + 1].some((index) =>
                    ofGroupByFieldsByIndex[index]?.includes(
                      op[schedulingCurrentGroupBy.value.field],
                    ),
                  ),
                }));
              }

              return operationsByDates;
            },
            {} as OperationsByDates,
          ),
        }),
        {} as Record<string, OperationsByDates>,
      );
    }

    onMounted(async () => {
      const sectorAiRule = await getSectorSchedulingRule(props.mc);
      if (sectorAiRule) schedulingAiRule.value = sectorAiRule;
    });

    onUnmounted(() => {
      const id = props.mc?.secteur_id;
      calendarOfIdsToExport.value = _.omit(unref(calendarOfIdsToExport), id);
      pilotingOfIdsToExport.value = _.omit(unref(pilotingOfIdsToExport), id);
    });

    function isClosedDay(header: {start_date: string; end_date: string}) {
      const {start_date, end_date} = header;

      if (isPastDate(start_date)) return false;

      const currentSectorCapa =
        totalCapaBySector.value[props.mc.secteur_id] || [];

      const currentSectorCapaForPeriod = currentSectorCapa.filter(
        ({day_date}) => day_date >= start_date && day_date <= end_date,
      );

      return currentSectorCapaForPeriod.length === 0;
    }

    return {
      selectedCard: ref<unknown>(null),
      loading,
      pg_ops,
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      canLoadCapa,
      canUseSimulation,
      chargeFn,
      parsedPeriod,
      pg_init,
      pg_subscribe,
      sectorTree,
      simulation,
      userData,
      apiClient,
      perimeters,
      pgRefresh,
      machines,
      schedulingCurrentGroupBy,
      pgOpsModifications,
      getOperationDisplayComponent,
      getColumnStyle,
      shiftPlanningsArray,
      hasShiftPlanning,
      getShiftFromOperation,
      getOperationsByShift,
      shiftDirectionPrefix,
      toggledGroups,
      computeSchedulingGroupKey,
      filteredOps,
      calendarOfIdsToExport,
      pilotingOfIdsToExport,
      ofIdsToExport,
      addNewSchedulingChanges,
      localSchedulingChanges,
      schedulingUpdateTrigger,
      hasLocalChanges,
      isSavingSchedulingChanges,
      canOperateOnOperationCards,
      getCtxSectorOrderedCalendars,
      doesClientHaveShiftPlanning,
      clientFirstShift,
      shouldHandleOPDuration,
      sendSchedulingLoadEventToBackend,
      setPgOpsModificationsFromUpdate,
      stations,
      seeAll,
      shouldHide,
      opsByDate,
      miniOpsByDate,
      periodInfo,
      DEFAULT_PAST_START_DATE,
      shouldAlertForSplitOf,
      getOperationsByShiftAndDates,
      computedOperationsByShiftAndDatesOnAllOps,
      computedOperationsByShiftAndDatesOnMiniOps,
      isClosedDay,
      hiddenOpsCount,
      showSeeMore,
      canToggleOperationsFromHeader,
      getToggledStatusMessage,
      hasSelectedDoneOPs,
      computedColumnClasses,
      computedShiftPagination,
      computedDisplayActionsMenuSide,
      getDayCellSuffix,
      selectedOps,
      getSelectedOperationDetail,
      selectedOpsIDs,
    };
  },
  computed: {
    periodPgOps() {
      const {parsedPeriod, pg_ops} = this;
      const {startDate, endDate} = parsedPeriod;
      return pg_ops.filter((o: any) => {
        const {new_date, day_date} = o;
        const date = new_date ?? day_date;
        return date >= startDate && date <= endDate;
      });
    },
  },
  watch: {
    isPastDelayShown() {
      this.loadCharge();
    },
    pgRefresh(val: number) {
      if (val) this.loadCharge();
    },
    parentSelectedCard(val: any) {
      const match =
        val?.op_id && this.pg_ops.find((x: any) => x.op_id === val.op_id);
      if (!match) {
        this.selectedCard = null;
        this.selectedOps = {};
        return;
      }
      this.selectedCard = match;
      // this.clickCard(match); it creates bug
    },
    selectedDelayMesh() {
      this.loadCharge();
    },
    pgOpsModifications: {
      deep: true,
      handler: function (newOps) {
        const {filterDoneOps, pg_ops, sectorTree} = this;
        const updatedOps = pg_ops
          .map((x: any) => {
            const match = newOps.find((o: any) => o.op_id === x.op_id);
            if (!match) return x;
            if (filterDoneOps && match.new_status === "done") return;
            const match_secteur_id = match.new_secteur_id || match.secteur_id;
            if (match_secteur_id && match_secteur_id !== sectorTree?.id) return;

            let momentNewDate = moment(match.new_date || match.day_date);
            let daysToAdd = x.smooth_idx || 0;
            const weekday = moment(momentNewDate).isoWeekday();
            const weekdayWithSmoothIdx = moment(momentNewDate)
              .add(daysToAdd, "days")
              .isoWeekday();
            if ([weekday, weekdayWithSmoothIdx].includes(6)) daysToAdd += 2;
            else if ([weekday, weekdayWithSmoothIdx].includes(7))
              daysToAdd += x.smooth_idx && weekday !== 7 ? 2 : 1;
            momentNewDate.add(daysToAdd, "days");
            const newDate = momentNewDate.format(
              this.hasShiftPlanning
                ? DATE_WITH_DEFAULT_TIME_FORMAT
                : DATE_DEFAULT_FORMAT,
            );

            const finalOp = {...x, ...match};
            if (match.new_date) finalOp.new_date = newDate;
            else finalOp.day_date = newDate;

            return finalOp;
          })
          .filter(Boolean);
        const movedOps = newOps.filter(
          (o: any) =>
            sectorTree?.id &&
            (o.new_secteur_id || o.secteur_id) === sectorTree?.id &&
            (!filterDoneOps || o.new_status !== "done") &&
            !updatedOps.some((x: any) => x.op_id === o.op_id),
        );
        this.pg_ops = [...updatedOps, ...movedOps];
        if (this.selectedCard?.op_id) {
          this.selectedCard =
            this.pg_ops.find((x: any) => x.op_id === this.selectedCard.op_id) ||
            null;
        }
      },
    },
    ofIdsToExport(newVal: string[]) {
      const id = this.mc.secteur_id;
      if (this.viewType === "calendar") {
        this.calendarOfIdsToExport = {
          ...this.calendarOfIdsToExport,
          [id]: newVal,
        };
      } else if (this.viewType === "piloting") {
        this.pilotingOfIdsToExport = {
          ...this.pilotingOfIdsToExport,
          [id]: newVal,
        };
      }
    },
    schedulingUpdateTrigger(val: number, oldVal?: number) {
      const {hasLocalChanges, isSavingSchedulingChanges} = this;
      if (!val || val === oldVal) return;
      if (hasLocalChanges || isSavingSchedulingChanges)
        this.loadChargeLightDebounced();
      else this.loadCharge();
    },
  },
  created() {
    this.pg_subscriptions = _.debounce(this.pg_subscriptions, 150);
    this.loadCharge = _.debounce(this.loadCharge, 300);

    usePGWatcher(this);
  },
  mounted() {
    this.toggleKeyEventListener(true);
  },
  unmounted() {
    this.toggleKeyEventListener(false);
  },
  methods: {
    pg_subscriptions() {
      if (!this.canUseSimulation) return;
      this.pg_init();
      this.pg_ops = [];
      this.pg_subscribe(["daily_load"], () => {
        if (!this.pg_ops?.length) return this.loadCharge();
        this.loadChargeHeavyDebounced();
      });
      this.pg_subscribe(["daily_prod"], () => this.loadCharge());
    },
    loadChargeHeavyDebounced() {
      loadChargeHeavyDebouncedFunction(this.loadCharge);
    },
    loadChargeLightDebounced() {
      loadChargeLightDebouncedFunction(() => {
        const {hasLocalChanges, isSavingSchedulingChanges} = this;
        if (hasLocalChanges || isSavingSchedulingChanges)
          this.loadChargeLightDebounced(); //we keep debouncing as long as there are pending changes
        else this.loadCharge();
      });
    },
    async loadCharge() {
      const {
        parsedPeriod,
        sectorTree: sector_tree,
        simulation,
        canLoadCapa,
        isPastDelayShown,
        filterDoneOps,
        selectedDelayMesh,
      } = this;
      const {id: simulation_id} = simulation || {};

      //Cancel the debounced calls
      if (loadChargeLightDebouncedFunction.cancel)
        loadChargeLightDebouncedFunction.cancel();
      if (loadChargeHeavyDebouncedFunction.cancel)
        loadChargeHeavyDebouncedFunction.cancel();

      if (!canLoadCapa) return;
      this.loading = true;
      const {mesh: delayMesh, value: delayValue} = selectedDelayMesh;
      let {startDate, endDate} = parsedPeriod;
      if (isPastDelayShown) {
        startDate = delayMesh
          ? moment(startDate)
              .subtract(delayValue, delayMesh)
              .startOf("month")
              .format(DATE_DEFAULT_FORMAT)
          : this.DEFAULT_PAST_START_DATE;
      }
      const results: DailyLoadNew[] = await this.chargeFn({
        secteur_id: sector_tree.id,
        simulation_id,
        startDate,
        endDate,
        params: {
          detailed: true,
          is_scheduling: true,
          no_done_status: filterDoneOps,
        },
      });
      const pg_ops = pgOpsMapFn(results, {
        keepOpsDone: !filterDoneOps,
        removeSmoothedDuplicates: true,
      });
      this.pg_ops = pg_ops;
      //handle the case in which we switched weeks before the update happening in pg yet
      const {selectedCard} = this;
      if (
        selectedCard?.op_id &&
        selectedCard.new_date <= endDate &&
        selectedCard.new_date >= startDate &&
        !this.pg_ops.some((x: DailyLoadNew) => x.op_id === selectedCard.op_id)
      )
        this.pg_ops.push(selectedCard);

      //the case where we got to this week from the search bar : we want to select the card
      if (this.parentSelectedCard?.op_id) {
        const match = this.pg_ops.find(
          (x: any) => x.op_id === this.parentSelectedCard.op_id,
        );
        if (match) this.selectedCard = match;
      }

      /**
       * this emit exists because of the will to toggle every CalendarRow after we have toggled 2 rows\
       * we need to prevent the CalendarRows without operations from being added to the toggled list
       * since they cannot be untoggled (the toggler icon is not displayed)
       */
      this.$emit("operations-loaded", this.pg_ops.length);
      this.loading = false;
    },
    async pg_updateCharge() {
      await this.recalculateCharge();
      this.pgRefresh++;
    },
    async recalculateCharge() {
      const {userData, simulation, sectorTree, parsedPeriod, isPastDelayShown} =
        this;
      const {client_id} = userData || {};
      const {id: simulation_id} = simulation || {};
      const {id: secteur_id} = sectorTree || {};
      if (!client_id || !simulation_id) return;
      let {startDate, endDate} = parsedPeriod;
      if (isPastDelayShown) {
        startDate = moment(startDate)
          .subtract(6, "months")
          .startOf("month")
          .format(DATE_DEFAULT_FORMAT);
      }
      const load_affected_sectors = [];
      load_affected_sectors.push(secteur_id);

      await this.apiClient.pgParser({
        query_type: "daily_load",
        collection: "simulations",
        value: simulation,
        simulation,
        load_affected_sectors,
        load_period: {start_date: startDate, end_date: endDate},
      });
    },
    clickCard(op: any) {
      if (!this.canOperateOnOperationCards) return;
      this.addCard(op);
      this.$emit("select-card", op);
      if (!op) return;
    },
    async changeStatus({update, operation}) {
      if (!operation) return;
      const {filterDoneOps, shouldHandleOPDuration} = this;

      //OPL-4456 : nouveau format des évènements d'ordo
      const {updated_values} = await this.sendSchedulingLoadEventToBackend({
        data: [{initial: operation, update}],
        should_return_updated_values: true,
        should_handle_op_duration: shouldHandleOPDuration,
      });
      const mappedValues = pgOpsMapFn(updated_values, {
        keepOpsDone: true,
      });

      let newOps = this.setPgOpsModificationsFromUpdate(
        this.pg_ops,
        mappedValues,
      );

      if (filterDoneOps)
        newOps = newOps.filter((x: any) => x.new_status !== "done");
      this.pg_ops = newOps;

      this.$segment.value.track("[Ordo] Operation Status Updated", {
        new_value: update?.new_status,
      });
    },
    // MOVE OPERATIONS
    async keyupHandler(event: any) {
      const {code} = event || {};
      if (!code) return;
      if (!this.selectedOpsIDs.length) return;
      if (!["Arrow", "Enter"].some((ai: string) => code.includes(ai))) return;
      if (code.includes("Enter")) return this.addCard();

      const action = code.replace("Arrow", "").toLowerCase();
      let value = 0;
      switch (action) {
        case "left":
          value = -1;
          break;
        case "right":
          value = 1;
          break;
        default:
          value = 0;
      }

      await this.moveIndividualCards(value, action);

      this.scrollSelectedIntoView();
    },
    // returns the new index for an operation card given a vertical deplacement action
    getOperationNewIndex(
      operation: SchedulingOperation,
      arrayOps: SchedulingOperation[],
      action: string,
    ): number {
      const {schedulingCurrentGroupBy} = this;
      // index of this operation within the list of all operations
      const opIndexAllList: number = arrayOps.findIndex(
        (op: SchedulingOperation) => operation.op_id === op.op_id,
      );
      if (!schedulingCurrentGroupBy?.field)
        return action === "up" ? opIndexAllList - 1 : opIndexAllList + 1;

      // items before this operation within the list of all operations
      const previousItems: SchedulingOperation[] = arrayOps.filter(
        (_, i: number) => i < opIndexAllList,
      );
      // array of group ids before the group of this operation
      const previousGroupsIDs: string[] = _.uniq(
        Array.from(
          previousItems,
          (item: SchedulingOperation) => item[schedulingCurrentGroupBy.field],
        ),
      );
      // items after this operation within the list of all operations
      const nextItems: SchedulingOperation[] = arrayOps.filter(
        (_, i: number) => i > opIndexAllList,
      );
      // array of group ids after the group of this operation
      const nextGroupsIDs: string[] = _.uniq(
        Array.from(
          nextItems,
          (item: SchedulingOperation) => item[schedulingCurrentGroupBy.field],
        ),
      );
      // items from the same group as this operation
      const currentGroup: SchedulingOperation[] = arrayOps.filter(
        (op: SchedulingOperation) =>
          op[schedulingCurrentGroupBy.field] ===
          operation[schedulingCurrentGroupBy.field],
      );
      // index of this operation within its group
      const opIndexCurrentGroup: number = currentGroup.findIndex(
        (op: SchedulingOperation) => operation.op_id === op.op_id,
      );

      if (action === "up") {
        // is there any element from this group above this operation ?
        const previousElementsFromGroup: SchedulingOperation[] =
          currentGroup.filter((_, i: number) => i < opIndexCurrentGroup);
        const lastPreviousElementFromGroup: SchedulingOperation =
          previousElementsFromGroup.at(-1);
        // if not, we return the index of the operation from the previous group (if existing) or the current index
        if (!lastPreviousElementFromGroup) {
          if (previousGroupsIDs.length) {
            return arrayOps.findIndex(
              (op: SchedulingOperation) =>
                op[schedulingCurrentGroupBy.field] === previousGroupsIDs.at(-1),
            );
          }

          return opIndexAllList;
        }
        // if there is, we return its index
        const lastPreviousElementIndexInOriginalArray: number =
          arrayOps.findIndex(
            (op: SchedulingOperation) =>
              op.op_id === lastPreviousElementFromGroup.op_id,
          );
        return lastPreviousElementIndexInOriginalArray;
      } else if (action === "down") {
        // same reasoning, is there any element from this group below this operation ?
        const nextElements: SchedulingOperation[] = currentGroup.filter(
          (_, i: number) => i > opIndexCurrentGroup,
        );
        const firstNextElement: SchedulingOperation = nextElements.at(0);
        // if not, we return the index of the operation from the next group (if existing) or the current index
        if (!firstNextElement) {
          if (nextGroupsIDs.length) {
            return arrayOps.findIndex(
              (op: SchedulingOperation) =>
                op[schedulingCurrentGroupBy.field] === nextGroupsIDs.at(0),
            );
          }

          return opIndexAllList;
        }

        const firstNextElementIndexInOriginalArray: number = arrayOps.findIndex(
          (op: SchedulingOperation) => op.op_id === firstNextElement.op_id,
        );
        // otherwise we return the index of the operation below
        // there is no possibility to move the current group from moving an operation lower into its column
        return firstNextElementIndexInOriginalArray;
      }
    },
    // this function is used to prepare the postgres parsing
    getOperationUpdate(
      payload: Record<string, string>,
    ): Record<string, string | boolean> {
      if (Object.keys(payload).length < 1) return payload;
      return {
        ...payload,
        trigger_function: false,
      };
    },
    getShiftChangeDirection(direction: string): string {
      return `${this.shiftDirectionPrefix}${direction}`;
    },

    getShiftChangeUpdate(
      operation: SchedulingOperation,
      direction: string,
      currentShiftIndex: number,
    ): Record<string, string | boolean> {
      const cleanDirection = direction.replace(this.shiftDirectionPrefix, "");
      const movementDelta = cleanDirection === "up" ? -1 : 1;
      const nextShift =
        this.shiftPlanningsArray[currentShiftIndex + movementDelta];
      if (!nextShift) return;

      const {new_date, day_date} = operation || {};
      const actualDateISO = new_date ?? day_date;
      const newDateISO = getNewDateFromShift(actualDateISO, nextShift);
      if (actualDateISO === newDateISO) return;

      return this.getOperationUpdate({
        new_date: newDateISO,
      });
    },

    async moveIndividualCards(
      value = 0,
      action: string,
      shiftIndex?: number,
      key: GenericObject = {},
    ) {
      const {
        isPastDelayShown,
        parsedPeriod,
        areWeekendsHidden,
        simulation,
        schedulingCurrentGroupBy,
        periodData,
      } = this;
      const {id: simulationId} = simulation;
      const {startDate, endDate} = parsedPeriod;
      const {maille} = periodData;

      const cardList = _.uniqBy<SchedulingOperation>(
        this.pg_ops.filter((o: SchedulingOperation) =>
          this.selectedOpsIDs.includes(o.op_id),
        ),
        "of_id",
      );

      /**
       * operations that are done cannot have their `day_date`s updated
       * this early return is to prevent such updates through keyboard inputs
       */
      if (cardList.some(isDoneOperation) && [-1, 1].includes(value)) return;

      const dataForPg = [];
      let viewDateChange: any;
      //we first need to sort the cards in ascending order so that the reordering works correctly
      const orderedCardList = _.orderBy(
        cardList,
        (c: any) => c.new_order ?? c.op_order,
        "asc",
      );
      let newGroupPosition = -1,
        nextGroupPosition = -1;
      await asyncForEach(orderedCardList, async (card: any, idx: number) => {
        // handling shift change
        if (action.startsWith(this.shiftDirectionPrefix)) {
          const update = this.getShiftChangeUpdate(card, action, shiftIndex);
          if (update) dataForPg.push({update, initial: card});
          return;
        }

        const {new_date, day_date, new_order, op_order, op_id} = card || {};

        const actualISODate = new_date ?? day_date;
        let actualMeshStart =
          maille === "month"
            ? moment(actualISODate).startOf("week").format(DATE_DEFAULT_FORMAT)
            : actualISODate;
        const is_past = actualISODate < startDate;

        //handle vertical move
        if (is_past && ["up", "down", "left"].includes(action)) return;

        let newOrder = new_order ?? op_order;
        if (
          ["up", "down"].includes(action) &&
          idx > 0 &&
          schedulingCurrentGroupBy?.field &&
          newGroupPosition > -1 &&
          nextGroupPosition > -1
        ) {
          //We handle here the special case where we move a group : we want all the OPs to regroup around the same position, right before or after the next possible position
          newOrder =
            newGroupPosition +
            (nextGroupPosition - newGroupPosition) *
              (idx / orderedCardList.length);
        } else if (["up", "down"].includes(action)) {
          let meshArrayBuilder: SchedulingOperation[] = Object.values(
            this.computedOperationsByShiftAndDatesOnAllOps,
          ).at(0)[actualMeshStart];

          if (
            this.selectedView === "day_view" &&
            Object.keys(this.computedOperationsByShiftAndDatesOnAllOps).at(
              0,
            ) !== "null"
          ) {
            const actualMeshStartTime = moment(
              actualMeshStart,
              [DATE_WITH_TIME_FORMAT, DATE_WITH_DEFAULT_TIME_FORMAT],
              true,
            ).isValid()
              ? moment(actualMeshStart).format(TIME_DEFAULT_FORMAT)
              : this.clientFirstShift.start_time;
            const actualMeshStartDate =
              moment(actualMeshStart).format(DATE_DEFAULT_FORMAT);
            meshArrayBuilder =
              this.computedOperationsByShiftAndDatesOnAllOps[
                actualMeshStartTime
              ]?.[actualMeshStartDate];
          }

          // we must not use "this" here because we just want the initial image of the cards,
          // not the updated one after each update
          //remove the other selected cards: we focus on each update independantly
          const meshArray = (meshArrayBuilder || []).filter(
            (op) =>
              op.op_id === op_id ||
              !cardList.some((y: any) => y.op_id === op.op_id),
          );
          let currPosition: number = meshArray.findIndex(
            (op) => op.op_id === op_id,
          );

          const newPosition: number = this.getOperationNewIndex(
            card,
            meshArray,
            action,
          );

          let previousPositions = meshArray;

          // swap elements between next/current position
          swapElements(previousPositions, currPosition, newPosition);

          previousPositions = previousPositions.map((x: any, idx: number) => ({
            ...x,
            temp_order: idx,
          }));
          //[0, 1, 2.2, X, 2.5, 3]
          const minPosition = 0; //we can't use the min of the array, because it might be the same as the max
          let maxPosition: number = _.max([
            ...Array.from(
              previousPositions,
              (position, index: number) =>
                position.new_order ??
                position.op_order ??
                position.temp_order ??
                index,
            ),
            newPosition,
          ]);
          if (maxPosition === minPosition)
            maxPosition = previousPositions.length - 1;

          const step =
            (maxPosition - minPosition) / (previousPositions.length - 1);
          const correctedPositions: number[] = previousPositions.map(
            (_, idx: number) => minPosition + step * idx,
          );

          newOrder = correctedPositions[newPosition];

          //keep in memory this newOrder for group changes
          if (idx === 0 && schedulingCurrentGroupBy?.field) {
            newGroupPosition = newOrder;
            nextGroupPosition =
              correctedPositions[newPosition + 1] ?? newOrder + 1;
          }
          previousPositions.forEach((previousPosition, idx: number) => {
            let {op_id: previousOpId, new_order: previousOrder} =
              previousPosition;
            if (previousOpId === op_id) return;
            if ([null, undefined].includes(previousOrder))
              previousOrder = previousPosition.op_order;
            const correctedOrder = correctedPositions[idx];
            if (correctedOrder === previousOrder) return;
            const subupdate = {new_order: correctedOrder};
            this.pg_ops = this.pg_ops.map((x: any) =>
              x.op_id === previousOpId ? {...x, ...subupdate} : x,
            );
            dataForPg.push({
              update: subupdate,
              initial: previousPosition,
            });
            // this.saveTetrisMove(
            //   subupdate,
            //   previousPosition.op_id,
            //   previousPosition,
            // );
          });
        }

        //handle horizontal move
        const actualDate = moment(actualISODate);

        const timestep = maille === "month" ? "weeks" : "days";

        let dateIncrement = value;
        if (areWeekendsHidden && maille === "week") {
          if (value < 0 && actualDate.isoWeekday() === 1) dateIncrement -= 2;
          else if (value > 0 && actualDate.isoWeekday() === 5)
            dateIncrement += 2;
        }
        let newDate = actualDate.add(dateIncrement, timestep);
        if (value && is_past) newDate = moment(startDate);
        else if (
          isPastDelayShown &&
          value === -1 &&
          !is_past &&
          card?.day_date < startDate &&
          actualISODate === startDate
        ) {
          //The operation was already in the past, I give it back its value
          newDate = moment(card.day_date);
        }

        let sector = this.stations.find(
          ({id, machine_tags}) =>
            card.secteur_id === id ||
            (machine_tags || []).map((tag) => tag.id).includes(card.secteur_id),
        );

        while (
          value !== 0 &&
          !isWorkDay(newDate, this.getCtxSectorOrderedCalendars(sector))
        )
          newDate = newDate[value > 0 ? "add" : "subtract"](1, "days");

        const newDateISO = newDate.format(DATE_DEFAULT_FORMAT);

        const actualOrder = new_order ?? op_order;

        //check if we need to update the view
        if (idx === 0) {
          if (
            newDateISO > endDate ||
            (newDateISO < startDate && !isPastDelayShown)
          )
            viewDateChange = newDateISO;
        }

        let update: Record<string, string | boolean> = {};
        if (newOrder !== undefined && actualOrder !== newOrder)
          update.new_order = newOrder;
        if (newDateISO && actualISODate !== newDateISO)
          update.new_date = newDateISO;
        if (update.new_date && this.doesClientHaveShiftPlanning) {
          const shiftFromActualIsoDate =
            moment(actualISODate).format(TIME_DEFAULT_FORMAT);
          const currentShiftIndex = this.shiftPlanningsArray.findIndex(
            (shift: ShiftPlanningItem) =>
              shift.start_time === shiftFromActualIsoDate,
          );
          update.new_date = getNewDateFromShift(
            newDateISO,
            this.shiftPlanningsArray[currentShiftIndex],
          );
        }
        update = this.getOperationUpdate(update);

        //prepare what we'll save in the database
        dataForPg.push({update, initial: card});

        if (!["up", "down"].includes(action))
          this.updateToggledGroupKey(update, key);
      });
      if (viewDateChange) this.$emit("set-new-selected-date", viewDateChange);

      //group the changes by OP_ID
      const dataForPgGrouped = _.groupBy(dataForPg, (o) => o.initial.op_id);
      const dataForPgGroupedCleaned = {};
      Object.keys(dataForPgGrouped).forEach((op_id) => {
        dataForPgGroupedCleaned[op_id] = dataForPgGrouped[op_id].at(0);
      });

      this.pg_ops = this.pg_ops.map((operation: SchedulingOperation) => {
        const dataForPgItem = dataForPgGroupedCleaned[operation.op_id];
        if (!dataForPgItem) return operation;
        return {...operation, ...dataForPgItem.update};
      });

      //save in the store the local changes
      this.addNewSchedulingChanges(dataForPgGroupedCleaned, {
        simulation_id: simulationId,
      });

      this.$segment.value.track("[Ordo] Operation Planned Date Updated", {
        origin: "menu",
      });
    },
    // scrolls the calendar upon moving an operation/a group so that it is centered vertically on the first selected entity
    scrollSelectedIntoView(): void {
      const entity = getFirstSelected();

      if (!entity) return;

      entity.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    toggleKeyEventListener(boolToggle: boolean): void {
      const documentFunction: string = boolToggle
        ? "addEventListener"
        : "removeEventListener";
      document[documentFunction]("keyup", this.keyupHandler);
      document[documentFunction]("keydown", (e) => {
        const {code} = e;
        if (!code) return;
        const action = code.replace("Arrow", "").toLowerCase();
        if (["up", "down", "right", "left"].includes(action))
          e.preventDefault();
      });
    },
    addCard(op: any = null) {
      if (!op) {
        if (this.selectedOpsIDs.length) this.selectedOps = {};
        return;
      }
      const {isPastDelayShown, periodData} = this;
      const {
        new_date: day_date = op.day_date,
        op_id,
        new_secteur_id: secteur_id = op.secteur_id,
      } = op;
      const {startDate, maille} = periodData || {};

      const selectedOperationDetail = this.getSelectedOperationDetail(op);

      if (!this.selectedOpsIDs.length) {
        this.selectedOps = {[op_id]: selectedOperationDetail};
        return;
      }

      if (this.selectedOpsIDs.includes(op_id)) {
        delete this.selectedOps[op_id];
        return;
      }

      const firstOpInList = this.pg_ops.find(
        ({op_id}: {op_id: string}) => op_id === this.selectedOpsIDs[0],
      );

      if (!firstOpInList) {
        this.selectedOps = {};
        return;
      }
      const {
        new_date: firstOpDate = firstOpInList.day_date,
        new_secteur_id: firstOpSecteurId = firstOpInList.secteur_id,
      } = firstOpInList;

      let areOpsInSameColumn =
        moment(firstOpDate).format(DATE_DEFAULT_FORMAT) ===
        moment(day_date).format(DATE_DEFAULT_FORMAT);
      if (maille === "month") {
        areOpsInSameColumn =
          moment(firstOpDate).startOf("week").format(DATE_DEFAULT_FORMAT) ===
          moment(day_date).startOf("week").format(DATE_DEFAULT_FORMAT);
      }

      const areOpsAllInPast =
        isPastDelayShown && firstOpDate < startDate && day_date < startDate;

      let areOpsInSameShift = true;
      if (this.hasShiftPlanning) {
        const opShift = this.getShiftFromOperation(op);
        const firstOpShift = this.getShiftFromOperation(firstOpInList);
        areOpsInSameShift =
          opShift && firstOpShift && opShift.name === firstOpShift.name;
      }

      if (
        (!(areOpsInSameColumn && areOpsInSameShift) && !areOpsAllInPast) ||
        firstOpSecteurId !== secteur_id
      ) {
        this.selectedOps = {[op_id]: selectedOperationDetail};
        return;
      }

      this.selectedOps[op_id] = selectedOperationDetail;
    },
    updateToggledGroupKey(updateobj: any, key: GenericObject) {
      const updatedToggledGroups = this.toggledGroups.map(
        (group: GenericObject) => {
          if (
            this.computeSchedulingGroupKey(key) ===
            this.computeSchedulingGroupKey(group)
          )
            return {...group, dateIso: updateobj.new_date};

          return group;
        },
      );

      this.toggledGroups = updatedToggledGroups;
    },
  },
});
</script>
<style scoped lang="scss">
.planning-calendar {
  flex-grow: 1;
  display: inline-block;
  border-radius: 8px;
  &-wrapper {
    max-width: 100%;
    width: max-content;
    overflow: scroll;
    max-height: 50vh;
    border-radius: 8px;
  }
  &-header {
    z-index: 3;
    top: 0;
    position: sticky;
    border-radius: 8px;
  }
  &-body {
    &-row {
      min-height: 100%;
    }
    &-column {
      border: 1px solid rgb(var(--v-theme-newSelected));
      border-left: none;

      &:not(:last-child).hide-right-border {
        border-right: none;

        &:deep(.sheduling-row-percent) {
          border-right: 1px solid rgb(var(--v-theme-newSelected));
        }
      }
    }
  }
}
.scheduling-row {
  z-index: 7;
  width: var(--scheduling-calendar--sector-name-width);
  min-height: 100%;
  position: sticky;
  left: 0;
  background-color: rgb(var(--v-theme-newLayerBackground));
}

.calendar-row {
  /* handling multi-elements' borders problem */
  margin-top: -1px;
}

.calendar-row-content {
  &:nth-child(2) {
    &:deep(.sheduling-row-sector, .sheduling-row-tx-charge) {
      border-top-left-radius: 8px;
    }
    &:deep(.capacity-day-cell:not(.text-only)) {
      border-top-color: rgb(var(--v-theme-newSelected));
    }
  }

  &:last-child .sheduling-row-sector {
    border-bottom-left-radius: 8px;
  }
}
// FIXME: scoped / rework
// applies style for the day view specifically
.planning-calendar-body-column {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  & > div {
    display: flex;
    flex-direction: column;

    & > .capacity-day-cell {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:last-child {
      /** case when sector has one operation only - second child because of CapacityDayCell */
      &:nth-child(2) :deep(.operation-card-actions-menu) {
        z-index: 7;
      }
    }
  }

  &.is-toggled {
    padding-bottom: 8px;

    & > div {
      flex: 0;
    }
  }

  & .scheduling-operation-wrapper + .capacity-day-cell {
    margin-top: 4px;
  }
}

.planning-calendar-body-row {
  position: relative;

  &::after {
    content: attr(data-toggled-status);
    position: absolute;
    left: 50%;
    // 10px is half the height of the CapacityDayCell
    top: calc(50% + 10.5px);
    transform: translate(-50%, -50%);
    font-style: italic;
  }

  &.day-view {
    & .planning-calendar-body-column {
      & > div {
        flex: 1;
      }
    }
  }
}
</style>
