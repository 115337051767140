<template>
  <span :class="{dense}" class="helper-span text-14">
    <vue-feather v-if="icon" :type="icon" size="13" />

    <slot />
  </span>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    icon: {type: String, default: "info"},
    dense: {type: Boolean, default: false},
  },
});
</script>

<style lang="scss">
.helper-span {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  line-height: 20px;
  border-radius: 8px;
  background: rgb(var(--v-theme-newOrangeLight2));
  color: rgb(var(--v-theme-newOrangeRegular));

  &.dense {
    padding: 2px 4px;
  }

  & i {
    // icon size
    flex: 0 0 13px;
  }
}
</style>
