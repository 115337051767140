import uniqid from "uniqid";
import {i18n} from "@/i18n";
import {
  MenuItem,
  NamedValue,
  OplitCalculusFormulaItem,
  ParametersAppMappingObject,
} from "@/interfaces";
import {
  PARAMETERS_AGGREGATION_METHODS,
  PARAMETERS_CALCULUS_FORMULA_AGGREGATION_METHODS,
  PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS,
  SCHEDULING_EXCLUDED_FIELDS_FOR_AI_RULES,
} from "@/config/constants";
import {
  getFieldsDefaultIcon,
  getFieldsDefaultValue,
} from "@/tscript/utils/parameters";
import {fieldsCorresp} from "@/tscript/utils";
import {allFields} from "@/components/Simulation/Modals/helper";
import _ from "lodash";
import {computed, ref, unref} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {useParameterStore} from "@/stores/parameterStore";
import {PaceOption} from "@oplit/shared-module";

const oplitOnlyFields = [
  "is_late",
  "machine_tag_id",
  "machine_tag_pdc",
  "max_date_of",
  "real_datelancement",
  "retard_ligne",
  "retard_poids",
  "retard_poids_ligne",
  "raw_op_id",
  "extra_infos",
  "internal_extra_infos",
  "simulation_id",
  "macro_event_id",
  "op_order",
  "status_updated_at",
  "erp_date",
  "prod_target",
  "client_id",
  "import_id",
  "secteur_id",
  "collection",
  "type",
  "updated_at",
  "from_children",
  "raw_op_id",
  "max_date_of",
  "tags",
  "is_new",
  "data_source",
  "status_done_at",
  "status_ongoing_at",
  "status_available_at",
  "prod_updated_at",
  "is_late",
  "partition_key",
  "machine_tag_id",
  "real_datelancement",
  "initial_date",
  "pdp_ignore_is_late",
  "erp_status",
];

const additionalLoadSchedulingFields = [
  "of_delay",
  "op_delay",
  "op_status",
  "waiting_time",
  "erp_date",
  "day_date",
  "of_id",
  "op_id",
  "op_name",
];

const planificationSwitches = [
  "can_compute_needed_orga",
  "floating_simulation",
  "has_load_formula",
  "has_nomenclature",
  "keep_advance",
  "use_is_late",
  // "auto_orga",
  // "graph_period_checkbox",
];
const schedulingSwitches = [
  "can_create_sub_ofs",
  "can_see_oplit_date",
  "classic_view",
  "gantt_view",
  "has_shift_planning",
  "scheduling_display_tree",
  "unique_scheduling_simulation",
  "scheduling_ignore_machines",
  // "display_parent_text",
  // "has_most_used_tags_suggestion",
  // "list_view",
  "display_operation_details",
  "has_conwip",
  "has_analytics_crossing_times",
  "has_ordered_pending_op_status",
  "ignore_closed_days_for_lead_time",
];
const APISwitches = [
  "action_in_fabriq",
  "fabriq_to_oplit",
  "auto_archive_ticket",
  "use_merca_planning",
];
const devSwitches = [
  "apply_load_import_rules_to_prod",
  "enable_new_standard_parsing_types",
  // "use_new_pdp_load_event",
  "can_sync_ops",
];

export function useParametersUtils() {
  const {t} = i18n;

  const methodes = PARAMETERS_AGGREGATION_METHODS.map(getMethodItem);

  const schema = {
    fields: [
      {
        type: "text",
        inputType: "text",
        label: "Nom",
        model: "name",
        placeholder: "Nom",
        required: true,
      },
      {
        type: "text",
        inputType: "text",
        label: "Unité",
        model: "unite",
        placeholder: "Unité",
        required: true,
      },
      {
        type: "formula",
        label: "Règle de calcul",
        model: "formula",
        placeholder: "Règle de calcul",
        required: true,
      },
      ...allFields
        .filter((x: any) => x.modalName === "capa")
        .map((x: any) => ({
          ...x,
          label: t("Simulation." + x.label),
          model: fieldsCorresp.find((y: any) => y.camel === x.model)?.snake,
          ...(x.placeholder && {
            placeholder: t("Simulation." + x.placeholder),
          }),
        })),
      ...fieldsCorresp
        .filter((x: any) => !allFields.some((y: any) => x.camel === y.model))
        .map((x: any) => ({
          type: x.fieldType || "text",
          inputType: x.type || "text",
          label: x.displayName,
          model: x.snake,
          placeholder: x.displayName,
          required: false,
        })),
      {
        type: "search_dropdown",
        label: "Déplacer",
        model: "ilot_id",
        placeholder: "Secteur",
      },
      {
        type: "dropdown",
        label: "Méthode d'aggrégation",
        model: "methode_aggreg",
        placeholder: "Méthode d'aggrégation",
        required: true,
        forDevOnly: true,
        values: methodes,
      },
    ],
  };

  const lastImportFields = ref<string[]>([]);

  /**
   * returns an array of the mandatory capa fields for the parameters list, formatted appropriately
   */
  const getParametersCapaMandatoryConfiguredFields =
    PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS.map((model: string) => ({
      label: t(`Parameters.parameters_list.labels.${model}`),
      icon: getFieldsDefaultIcon(model),
      default_value: getFieldsDefaultValue(model),
      in_formula: true,
      inputId: "number",
      inputType: "number",
      model,
    }));

  const lastImportFieldsOptions = computed(() =>
    getOptionizedArray(lastImportFields.value, {
      addNoneField: true,
      shouldSort: true,
    }),
  );
  const mappedLoadImportParsingFields = computed(() => {
    const {importParsingRules} = storeToRefs(useParameterStore());
    const {LOAD: loadImportParsingRules} = importParsingRules.value ?? {};

    if (!loadImportParsingRules) return lastImportFieldsOptions.value;

    const fieldKeys: string[] = [
      ...new Set([
        ...Object.keys(loadImportParsingRules),
        ...additionalLoadSchedulingFields,
      ]),
    ];

    const parsingFields = fieldKeys.reduce(
      (
        acc: {value: string; label: string}[],
        key: string,
      ): NamedValue<string>[] => {
        const {client_field_name} = loadImportParsingRules[key] || {};

        return [
          ...acc,
          {
            label: client_field_name || key,
            value: key,
          },
        ];
      },
      [],
    );

    return _.orderBy(parsingFields, [
      ({label}) => label.toLowerCase(),
      ({value}) => value.toLowerCase(),
    ]);
  });
  const mappedAIRulesLoadImportParsingFields = computed<NamedValue<string>[]>(
    () =>
      mappedLoadImportParsingFields.value.filter(
        ({value}) =>
          !!value && !SCHEDULING_EXCLUDED_FIELDS_FOR_AI_RULES.includes(value),
      ),
  );
  const availableImportFieldsOptions = computed(() => {
    const {userData} = storeToRefs(useMainStore());
    const importFields = mappedLoadImportParsingFields.value.filter(
      ({value}) => !!value,
    );
    if (importFields.every(({value}) => value !== "quantite_op"))
      importFields.unshift({value: "quantite_op", label: "quantite_op"});

    if (userData.value?.client_id === "kihJoGUywfHfI7abZWDH") {
      importFields.push({
        value: "group_by_field",
        label: t("scheduling.group_by.article_op"),
      });
    }

    return _.orderBy(importFields, [
      ({label}) => label.toLowerCase(),
      ({value}) => value.toLowerCase(),
    ]);
  });
  const getParametersPrioritySortItems = computed(() => [
    {id: "asc", name: t("global.order.asc")},
    {id: "desc", name: t("global.order.desc")},
    {id: "custom", name: t("global.order.custom")},
  ]);
  const getParametersMeshesItems = computed<{name: string; field: string}[]>(
    () => {
      const {shiftPlanning} = storeToRefs(useParameterStore());
      const {clientParameters} = storeToRefs(useMainStore());
      const {has_shift_planning} = unref(clientParameters);

      const meshFields = [
        "day",
        "week",
        "month",
        /*"quarter", "year"*/
      ];

      if (has_shift_planning && unref(shiftPlanning))
        meshFields.unshift("shift");

      const meshItems = meshFields.map((field) => ({
        name: t(`ParametersSchedulingRulesConstraints.mesh_${field}`),
        field,
      }));

      return [
        {
          name: t("ParametersSchedulingRulesConstraints.mesh_none"),
          field: "",
        },
        ...meshItems,
      ];
    },
  );

  const getClientParametersPlanificationSwitches = computed(() => {
    return getOptionizedArray(planificationSwitches, {
      addNoneField: false,
      shouldSort: false,
    });
  });
  const getClientParametersSchedulingSwitches = computed(() => {
    const {clientParameters} = storeToRefs(useMainStore());

    const localSchedulingSwitches = [...schedulingSwitches];

    if (clientParameters.value.gantt_view) {
      localSchedulingSwitches.push("has_gantt_shift_view");
      localSchedulingSwitches.push("has_gantt_load_adjustment");
    }

    return getOptionizedArray(localSchedulingSwitches, {
      addNoneField: false,
      shouldSort: false,
    });
  });
  const getClientParametersAPISwitches = computed(() => {
    return getOptionizedArray(APISwitches, {
      addNoneField: false,
      shouldSort: true,
    });
  });
  const getClientParametersFabriqSwitches = computed(() => {
    const fabriqSwitches = APISwitches.filter((switchKey) =>
      ["action_in_fabriq", "fabriq_to_oplit", "auto_archive_ticket"].includes(
        switchKey,
      ),
    );

    return getOptionizedArray(fabriqSwitches, {
      addNoneField: false,
      shouldSort: true,
    });
  });
  const getClientParametersDevSwitches = computed(() => {
    return getOptionizedArray(devSwitches, {
      addNoneField: false,
      shouldSort: true,
    });
  });
  const featureFlags = computed(() => {
    const {clientParameters} = storeToRefs(useMainStore());
    const allSwitches = [
      ...getClientParametersPlanificationSwitches.value,
      ...getClientParametersSchedulingSwitches.value,
      ...getClientParametersFabriqSwitches.value,
      ...getClientParametersDevSwitches.value,
    ];
    const switchesWithValues = allSwitches.reduce(
      (acc: Record<string, true>, flag) => {
        const clientValue = clientParameters.value[flag.value];
        if (!clientValue) return acc;
        return {
          ...acc,
          [flag.value]: !!clientValue,
        };
      },
      {},
    );
    return switchesWithValues;
  });

  function getParametersCalculusDefaultFormulaItem(
    payload: Partial<OplitCalculusFormulaItem>,
  ): OplitCalculusFormulaItem {
    const {type, value, model} = payload;

    switch (type) {
      case "child":
        return {
          ...payload,
          id: uniqid("child_"),
          type,
        };

      case "children":
        return {
          id: uniqid("children_"),
          label: t("Parameters.calculus_rules_calculus_formula.lower_levels"),
          default_value: [],
          model: type,
          inputId: type,
          type,
        };

      case "constant": {
        return {
          id: uniqid(`${value}_`),
          model: value as string,
          value,
          type,
        };
      }

      default:
        return {
          ...payload,
          id: uniqid(`${model ?? ""}_`),
          type,
        };
    }
  }

  // i18n steps & links
  function mapParametersMenuItems(
    menuItems: MenuItem[],
    itemType: "item" | "link" = "item",
  ): MenuItem[] {
    return Array.from(
      menuItems,
      (item): MenuItem => ({
        ...item,
        title:
          item.title || t(`Parameters.menu_items.${itemType}_${item.name}`),
        links: mapParametersMenuItems(item.links || [], "link"),
      }),
    );
  }

  /**
   * function used to translate options inside the `parameters_planning_display` collection into configuration objects to use in the grouping components
   * @param options : the list of options as saved in firebase under the `options` field
   * @param includeNoneOption : a boolean representing whether or not we should include the "none" option to reset the selection
   */
  function mapPlanningColorsCategories(
    options: ParametersAppMappingObject[],
    includeNoneOption = false,
  ): {field: string; label: string}[] {
    // this option is made from an internal field that is absent from the available options (= import fields) for the groups definition
    const defaultOptions = [
      {
        field: "is_late",
        label: t("GraphCharge.Settings.group_is_late") as string,
      },
      {
        field: "is_from_event",
        label: t("GraphCharge.Settings.group_is_from_event") as string,
      },
    ];

    if (includeNoneOption) {
      defaultOptions.unshift({
        field: null,
        label: t("global.none") as string,
      });
    }

    const opts = options.map((option) => ({
      field: option.excel_name,
      label: option.oplit_name,
    }));

    const planningColorsCategories = _.uniqBy(
      [...defaultOptions, ...opts],
      "field",
    );

    return planningColorsCategories;
  }

  function getMethodItem(method: string): {text: string; value: string} {
    return {
      text: t(`SharedSettings.Formula.${method}`),
      value: method,
    };
  }

  function getAggregationMethods({
    includeNone = false,
    includeOther = false,
  }: {
    includeNone?: boolean;
    includeOther?: boolean;
  }) {
    const options = [...methodes];
    if (includeNone) options.unshift(getMethodItem("none"));
    if (includeOther) {
      options.push(
        getMethodItem(PARAMETERS_CALCULUS_FORMULA_AGGREGATION_METHODS.OTHER),
      );
    }
    return options;
  }

  function getOptionizedArray(
    array: string[],
    parameters?: {addNoneField: boolean; shouldSort: boolean},
  ): NamedValue<string>[] {
    if (!array) return [];

    const {addNoneField = false, shouldSort = false} = parameters || {};

    const defaultField: NamedValue<string> = {
      label: t("global.none"),
      value: null,
    };

    let returned = Array.from(array, (field: string) => ({
      label: field ?? defaultField.label,
      value: field,
    }));

    if (shouldSort) returned = _.sortBy(returned, "label");

    if (addNoneField) returned.unshift(defaultField);

    return returned;
  }

  /**
   * loads customized fields (string) from the last load import file into lastImportFields\
   * these customized fields are stored within and retrieved from the `daily_raw_load_info` table which is only filled with `extra_infos` content
   * @additionalFields : an array of additional strings to include within the fields from the last import\
   *                     this has been added with a view to be able to include fields that were previously
   *                     `extra_infos` but that have been changed and stored into a separate column for querying optimization
   * @returns : import_id for further use in logics
   */
  async function loadLastImportFields(parameters?: {
    additionalFields: string[];
  }): Promise<{import_id?: string}> {
    const {additionalFields = []} = parameters || {};

    const {loadLastImport, loadImportFields} = useMainStore();

    const {import_id} = await loadLastImport(); //TODO: this can be done in a Promise.all
    if (!import_id) return {};

    const keyValues = await loadImportFields();

    const fields = Array.from(
      keyValues,
      ({field}: {field: string}) => field,
    ).filter((field: string) => !oplitOnlyFields.includes(field));
    fields.push(...additionalFields);

    lastImportFields.value = Array.from(new Set(fields));

    return {import_id};
  }

  function getNewPaceOption(): PaceOption {
    return {
      id: uniqid("pace_option_") as string,
      amount: 0,
      value: "",
      constraint: "min",
    };
  }

  return {
    getAggregationMethods,
    getParametersCalculusDefaultFormulaItem,
    getParametersCapaMandatoryConfiguredFields,
    getClientParametersPlanificationSwitches,
    getClientParametersSchedulingSwitches,
    getClientParametersAPISwitches,
    getClientParametersDevSwitches,
    getClientParametersFabriqSwitches,
    mapParametersMenuItems,
    mapPlanningColorsCategories,
    methodes,
    schema,
    loadLastImportFields,
    lastImportFields,
    getOptionizedArray,
    lastImportFieldsOptions,
    mappedLoadImportParsingFields,
    mappedAIRulesLoadImportParsingFields,
    availableImportFieldsOptions,
    getParametersPrioritySortItems,
    getParametersMeshesItems,
    getNewPaceOption,
    oplitOnlyFields,
    additionalLoadSchedulingFields,
    featureFlags,
  };
}
